import * as React from "react";
import { ParticipantListData } from "../../../../../models/participantData";
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import logo from "../../../../../assets/general/logo_amanzii_print.png";
import { EventDataDto } from "../../../../../models/eventData";
import "./ParticipantsListPrint.scss";
import { LecturesData } from "../../../../../models/lecturesData";
import { EventType } from "../../../../../models/enums/eventType.enum";
import { CateringType } from "../../../../../models/enums/cateringType";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSeedling, faSlash, faUtensils } from "@fortawesome/free-solid-svg-icons";
import { UserType } from "../../../../../models/enums/userType.enum";
import { UserData } from "../../../../../models/userData";

type props = {
  participantList: ParticipantListData[];
  eventData: EventDataDto | LecturesData;
  currentUser?: UserData;
};

export class ParticipantsListPrint extends React.PureComponent<props> {
  setExtraRows() {
    const minRowNumber = 30; // number of lines that fit on one page, if the title is a maximum of two lines
    if (minRowNumber - this.props.participantList.length < 10) {
      return 10;
    } else {
      return minRowNumber - this.props.participantList.length;
    }
  }

  maskEmail(email: string) {
    if ([undefined, null, ""].includes(email)) return "";
    let split = email.split("@");
    return (
      email.substr(0, 1) +
      new Array(split[0].length - 1).fill("*").join("") +
      "@" +
      split[1]
    );
  }

  public render() {
    return (
      <Grid container style={{ width: "49.375rem" }}>
        <Grid item style={{ width: "9.375rem" }}>
          <img src={logo} alt="amanzii logo" style={{ height: "3.125rem" }} />
        </Grid>
        <Grid item style={{ width: "35rem" }}>
          <Grid container spacing={0}>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <div className={"participantlist-print-title"}>
                {this.props.eventData.title}
              </div>
            </Grid>
            <Grid item xs={1} className={"participantlist-print-lable"}>
              VNR
            </Grid>
            <Grid item xs={5} className={"participantlist-print-value"}>
              {this.props.eventData.vnr}
            </Grid>
            <Grid item xs={1} className={"participantlist-print-lable"}>
              Ort
            </Grid>
            <Grid item xs={5} className={"participantlist-print-value"}>
              {this.props.eventData.location}
            </Grid>
            <Grid item xs={1} className={"participantlist-print-lable"}>
              Von
            </Grid>
            <Grid item xs={5} className={"participantlist-print-value"}>
              {this.props.eventData.begin &&
                new Date(this.props.eventData.begin).toLocaleDateString()}{" "}
              (
              {this.props.eventData.begin &&
                new Date(this.props.eventData.begin).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })}{" "}
              Uhr)
            </Grid>
            <Grid item xs={1} className={"participantlist-print-lable"}>
              Bis
            </Grid>
            <Grid item xs={5} className={"participantlist-print-value"}>
              {this.props.eventData.end &&
                new Date(this.props.eventData.end).toLocaleDateString()}{" "}
              (
              {this.props.eventData.end &&
                new Date(this.props.eventData.end).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })}{" "}
              Uhr)
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <h3>Teilnahmeliste</h3>
        </Grid>
        <Grid item xs={12}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow className={"participantlist-print-tablecell"}>
                  <TableCell>Name</TableCell>
                  <TableCell>E-Mail</TableCell>
                  <TableCell>EFN</TableCell>
                  <TableCell>BSNR</TableCell>
                  {this.props.eventData.event_type === EventType.OnSite && (
                    <TableCell>Unterschrift</TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.participantList
                  .sort((firstUser, secondUser) =>
                    firstUser.user.last_name > secondUser.user.last_name
                      ? 1
                      : firstUser.user.last_name === secondUser.user.last_name
                        ? firstUser.user.first_name > secondUser.user.first_name
                          ? 1
                          : -1
                        : -1
                  )
                  .map((participant, index) => {
                    return (
                      <TableRow
                        className={"participantlist-print-tablecell"}
                        key={index}
                      >
                        <TableCell>
                          {participant.catering_option !== CateringType.Undefined &&
                            this.props.currentUser?.user_type !== UserType.Speaker &&
                            (participant.catering_option === CateringType.NoMeal ? (
                              <Box component={"span"} style={{ position: "relative" }}>
                                <FontAwesomeIcon
                                  icon={faUtensils}
                                  size={"1x"}
                                  color={"var(--text-color)"}
                                />
                                <FontAwesomeIcon
                                  icon={faSlash}
                                  style={{
                                    position: "absolute",
                                    left: "-0.125rem",
                                    top: ".125rem",
                                  }}
                                  color={"#f44336"}
                                  size={"1x"}
                                />
                              </Box>
                            ) : participant.catering_option ===
                              CateringType.DefaultMeal ? (
                              <FontAwesomeIcon
                                icon={faUtensils}
                                size={"1x"}
                                color={"var(--text-color)"}
                              />
                            ) : (
                              participant.catering_option ===
                                CateringType.Vegetarian && (
                                <FontAwesomeIcon
                                  icon={faSeedling}
                                  size={"1x"}
                                  color={"#95e917"}
                                />
                              )
                            ))}
                          {`${participant.user.last_name}, ${participant.user.first_name}`}
                          {participant.user.academic_title
                            ? `, ${participant.user.academic_title}`
                            : ""}
                        </TableCell>
                        <TableCell>{this.maskEmail(participant.user.email)}</TableCell>
                        <TableCell>{participant.user.efn}</TableCell>
                        <TableCell>{participant.user.bsnr}</TableCell>
                        {this.props.eventData.event_type === EventType.OnSite && (
                          <TableCell></TableCell>
                        )}
                      </TableRow>
                    );
                  })}
                {Array(this.setExtraRows())
                  .fill(0)
                  .map((val, index) => {
                    return (
                      <TableRow
                        className={"participantlist-print-tablecell"}
                        key={index}
                      >
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        {this.props.eventData.event_type === EventType.OnSite && (
                          <TableCell></TableCell>
                        )}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    );
  }
}

// TODO no translation yet
