import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Grid, Typography } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../../redux";
import { getOrganizations } from "../../../redux/organization/actions";
import Loader from "../../../components/theming/loader/Loader";
import UserOrganizationList from "../../../components/core/profile-settings/organization/UserOrganizationList";
import CrudOrganization from "../../../components/core/profile-settings/organization/CrudOrganization";

/**
 * Component for managing organization details and memberships.
 * @returns JSX.Element
 */
export const OrganizationOverviewPage = () => {
  const { t } = useTranslation(["organization", "snackbars"]);
  const organizations = useSelector((state: AppState) => state.organizations);
  const dispatch = useDispatch();
  const [createMode, setCreateMode] = useState(false);

  useEffect(() => {
    if (!organizations.isLoading && !organizations.organizationDataLoaded) {
      dispatch(getOrganizations());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizations.isLoading, organizations.organizationDataLoaded]);

  return (
    <>
      {organizations?.isLoading ? (
        <Loader />
      ) : organizations?.organizationData?.length > 0 ? (
        <UserOrganizationList />
      ) : (
        <Typography>{t("noOrganization", { ns: "organization" })}</Typography>
      )}

      <Grid
        container
        style={{ display: "flex", justifyContent: "flex-end" }}
        spacing={3}
      >
        {!createMode ? (
          <Grid item xs={12} sm={6} md={3}>
            <Button
              id="addOrganization"
              size="large"
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                setCreateMode(true);
              }}
              style={{ float: "right" }}
            >
              {t("crud.addOrganization", { ns: "organization" })}
            </Button>
          </Grid>
        ) : (
          <Grid item xs={12} sm={6} md={3}>
            <Button
              id="cancel-add-organization"
              size="large"
              variant="outlined"
              color="primary"
              fullWidth
              onClick={() => {
                setCreateMode(false);
              }}
            >
              {t("forms.buttons.cancel", { ns: "common" })}
            </Button>
          </Grid>
        )}
      </Grid>
      {createMode && <CrudOrganization />}
    </>
  );
};
