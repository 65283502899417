import { Dispatch } from "redux";
import { TargetGroupListData } from "../../models/targetGroupData";
import { targetGroupService } from "./../../api/index";
import {
  LOAD_TARGET_GROUP_DATA_SUCCESS,
  LoadTargetGroupsAction,
  TARGET_GROUP_FAILURE,
  TARGET_GROUP_REQUEST,
  TargetGroupActionTypes,
  TargetGroupsFailureAction,
  TargetGroupsRequestAction,
} from "./types";

export function getTargetGroups() {
  return (dispatch: Dispatch<TargetGroupActionTypes>) => {
    dispatch(targetGroupRequest());

    // #TODO this can be deleted, if new solution below works fine
    // Promise.all([targetGroupService.getParticipationTypes()])
    //   .then(([participationTypes]) => {
    //     dispatch(
    //       loadTargetGroupDataSuccess({
    //         participation_types: participationTypes,
    //       })
    //     );
    //   })
    //   .catch(() => {
    //     dispatch(targetGroupFailure());
    //   });

    Promise.resolve(targetGroupService.getParticipationTypes())
      .then(
        (participationTypes) => {
          Promise.all([participationTypes]).then(([res]) =>
            dispatch(
              loadTargetGroupDataSuccess({
                participation_types: res,
              })
            )
          );
        },
        () => {
          dispatch(targetGroupFailure());
        }
      )
      .catch(() => {
        dispatch(targetGroupFailure());
      });
  };
}

export function loadTargetGroupDataSuccess(
  data: TargetGroupListData
): LoadTargetGroupsAction {
  return {
    type: LOAD_TARGET_GROUP_DATA_SUCCESS,
    data,
  };
}

export function targetGroupRequest(): TargetGroupsRequestAction {
  return {
    type: TARGET_GROUP_REQUEST,
  };
}
export function targetGroupFailure(): TargetGroupsFailureAction {
  return {
    type: TARGET_GROUP_FAILURE,
  };
}
