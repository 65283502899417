import React from "react";
import { closeSnackbar, SnackbarProvider } from "notistack";

const CustomSnackbarProvider: React.FC = (props) => {
  const closeSnackbarOnClick = () => {
    closeSnackbar();
  };

  return (
    <SnackbarProvider
      preventDuplicate={true}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      SnackbarProps={{
        onClick: closeSnackbarOnClick,
      }}
    >
      {props.children}
    </SnackbarProvider>
  );
};

export default CustomSnackbarProvider;
