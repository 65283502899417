import { AppState } from "../index";
import { AxiosError } from "axios";
import { Dispatch } from "redux";
import { orderService } from "../../api";
import {
  CLEAR_ORDER,
  ClearOrderAction,
  OrderActionTypes,
  ORDER_FAILURE,
  ORDER_REQUEST,
  OrderRequestAction,
  OrderFailureAction,
  SetOrderDataAction,
  SET_ORDER_DATA,
} from "./types";
import { CartItemData } from "../../models/cartData";
import { ReducedOrderData } from "../../models/orderData";

export function createOrder(cartData: CartItemData[]) {
  return (dispatch: Dispatch<OrderActionTypes>, getState: () => AppState) => {
    dispatch(orderRequest());
    return orderService
      .createOrder(cartData)
      .then((order) => {
        dispatch(setOrderData(order));
      })
      .catch((err: AxiosError) => {
        const errorStatus = err.response?.status;
        const errorObject = err.response?.data;
        dispatch(orderFailure());
      });
  };
}

export function clearOrder(): ClearOrderAction {
  return { type: CLEAR_ORDER };
}

export function orderRequest(): OrderRequestAction {
  return {
    type: ORDER_REQUEST,
  };
}

export function orderFailure(): OrderFailureAction {
  return {
    type: ORDER_FAILURE,
  };
}

export function setOrderData(order: ReducedOrderData): SetOrderDataAction {
  return {
    type: SET_ORDER_DATA,
    order,
  };
}
