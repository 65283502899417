import { Grid, InputAdornment } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { ChangeEvent, useState } from "react";
import {
  DiscountGroupWrapper,
  DiscountType,
} from "../../../../../../models/discountData";
import { ParticipationType } from "../../../../../../models/targetGroupData";
import { default as CustomTextField } from "../../../../../forms/CustomTextField";
import CustomMultiSelect from "../../../../../forms/selects/CustomMultiSelect";
import CustomSelect from "../../../../../forms/selects/CustomSelect";
import { SelectData } from "../../../../../forms/selects/selectData";
import { useFormikContext } from "formik";
import { ELearningFormData } from "../../../../../../models/elearning";
import { EventFormData } from "../../../../../../models/eventData";
import { EventStatus } from "../../../../../../models/enums/eventStatus.enum";

interface DiscountItemFormProps {
  index: number;
  discount: DiscountGroupWrapper;
  allParticipaytionTypes: ParticipationType[];
  targetGroupsWithoutDiscount: ParticipationType[];
  changeGroup: (group: DiscountGroupWrapper) => void; // Change the type if needed
  deleteGroup: (group: DiscountGroupWrapper) => void; // Change the type if needed
}

const DiscountItemForm: React.FC<DiscountItemFormProps> = ({
  index,
  discount,
  allParticipaytionTypes,
  targetGroupsWithoutDiscount,
  changeGroup,
  deleteGroup,
}) => {
  const groupIndex = index;
  const canBeDeleted = groupIndex > 0;
  const [discountType, setDiscountType] = useState(discount.type);
  const isGroupDiscountsActivated = true;

  const targetGroups = allParticipaytionTypes;
  const selectedTargetGroupsID = discount.groups.map((x) => String(x.id));
  const optionsTargetGroups = targetGroups.filter(
    (x) => discount.groups.includes(x) || targetGroupsWithoutDiscount.includes(x)
  );
  const { values } = useFormikContext<EventFormData | ELearningFormData>();

  function changeTargetGroups(changedTargetGroups: string[]) {
    const newGroups = targetGroups.filter((x) =>
      changedTargetGroups.includes(String(x.id))
    );
    changeGroup({ ...discount, groups: newGroups });
  }

  function onChangeDiscountPercent(value: number) {
    discount.fixedAmount = 0;
    discount.percent = value;
    changeGroup(discount);
  }

  function onChangeDiscountFixedAmount(value: number) {
    discount.fixedAmount = value;
    discount.percent = 0;
    changeGroup(discount);
  }

  function onChangeDiscountType(value: DiscountType) {
    discount.type = value;
    if (value === DiscountType.Percent) {
      discount.fixedAmount = 0;
    } else {
      discount.percent = 0;
    }

    setDiscountType(value);
    changeGroup(discount);
  }

  return (
    <Grid container key={index} style={{ display: "flex", width: "80%" }} spacing={2}>
      <Grid item lg={4} xs={4} sm={4}>
        <CustomMultiSelect
          defaultOptions={selectedTargetGroupsID}
          value={selectedTargetGroupsID}
          required={values.status === EventStatus.Published}
          disabled={values.status === EventStatus.Completed}
          name="discount_target_groups"
          id="discount_target_groups"
          label="Zielgruppe"
          labelId="discount_target_groups-label"
          hasCustomChangeHandler={true}
          customChangeHandler={(vals) => {
            changeTargetGroups(vals as string[]);
          }}
          options={
            optionsTargetGroups.map((x) => {
              return {
                key: x.id,
                value: String(x.id),
                label: x.label,
              };
            }) as SelectData[]
          }
        />
      </Grid>
      <Grid item lg={3} xs={3} sm={3}>
        <CustomSelect
          name="discount_type"
          id="discount_type"
          label="Rabatt in"
          labelId="discount_type"
          //enabled default EUR as long as Taks LU-657 is not finished
          defaultValue={discount.type || DiscountType.Percent}
          // replace with this after finishig
          // defaultValue={discount.type || DiscountType.Percent}
          required={values.status === EventStatus.Published}
          disabled={values.status === EventStatus.Completed}
          value={discount.type}
          onChange={(event: React.ChangeEvent<{ name?: string; value: any }>) => {
            onChangeDiscountType(event.target.value);
          }}
          options={[
            //enabled default EUR as long as Taks LU-657 is not finished
            {
              key: DiscountType.FixedAmount,
              value: DiscountType.FixedAmount,
              label: "EUR",
            },
            {
              key: DiscountType.Percent,
              value: DiscountType.Percent,
              label: "Prozent",
            },
          ]}
        />
      </Grid>
      {discountType === DiscountType.Percent && (
        <Grid item lg={4}>
          <CustomTextField
            fullWidth
            required={values.status === EventStatus.Published}
            disabled={values.status === EventStatus.Completed}
            minRows={1}
            type="number"
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
              inputProps: { min: 0, max: 100, step: 1 },
            }}
            max={100}
            name="prozent"
            id="prozent"
            label="Prozent"
            value={discount.percent}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              onChangeDiscountPercent(Number(e.target.value));
            }}
          />
        </Grid>
      )}
      {discountType === DiscountType.FixedAmount && (
        <Grid item lg={4}>
          <CustomTextField
            fullWidth
            required={values.status === EventStatus.Published}
            disabled={values.status === EventStatus.Completed}
            type="number"
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
              inputProps: { min: 0.0, step: 0.01 },
            }}
            name="price"
            id="price"
            value={discount.fixedAmount}
            label="Preisnachlass"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              onChangeDiscountFixedAmount(Number(e.target.value));
            }}
          />
        </Grid>
      )}
      {canBeDeleted && isGroupDiscountsActivated && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <DeleteIcon
            onClick={() => {
              deleteGroup(discount);
            }}
            style={{ cursor: "pointer" }}
          />
        </div>
      )}
    </Grid>
  );
};

export default DiscountItemForm;

// TODO no translations yet
