export interface DocumentData {
  id: string;
  file: string;
  title: string;
  is_default_online: boolean;
  is_default_on_site: boolean;
  is_default_elearning: boolean;
  publish_speaker: boolean;
  publish_participant: boolean;
  publish_after_event: boolean;
  publish_at_elearning_start: boolean;
  last_changed_by_id: string;
  is_scorm_file: boolean;
}
export enum DocumentType {
  IdentityCard = "identity_card",
  ResidencePermit = "residence_permit",
  Passport = "passport",
}

export interface DocumentUploadDataResponse {
  id?: string;
  data: DocumentData;
  status?: string | number;
}

export interface DocumentIdData {
  id: string;
}

export interface DocumentByIdDataResponse {
  id?: string | undefined;
  data: DocumentData;
  status?: string | number;
}

export interface ScormFile {
  id: string;
  title: string;
  version: string;
}
