import {
  ORGANIZATION_EVENT_PARTICIPATIONS_FAILURE,
  ORGANIZATION_EVENT_PARTICIPATIONS_REQUEST,
  OrganizationEventParticipationsActionTypes,
  OrganizationEventParticipationsState,
  SET_ORGANIZATION_EVENT_PARTICIPATIONS_DATA,
} from "./types";

/**
 * The initial state for the organization event participation
 * @type {OrganizationEventParticipationsState}
 * @property {OrganizationEventParticipationsData[]} organizationEventParticipationsData - The event participation data
 * @property {boolean} isLoading - The loading state
 */
export const initialOrganizationEventParticipationState: OrganizationEventParticipationsState =
  {
    organizationEventParticipationsData: [],
    isLoading: false,
  };

/**
 * The organization event participation reducer.
 * @param {OrganizationEventParticipationsState} state - The organization event participation state
 * @param {OrganizationEventParticipationsActionTypes} action - The action to perform
 * @returns {OrganizationEventParticipationsState}
 */
export function organizationEventParticipationsReducer(
  state = initialOrganizationEventParticipationState,
  action: OrganizationEventParticipationsActionTypes
): OrganizationEventParticipationsState {
  switch (action.type) {
    case ORGANIZATION_EVENT_PARTICIPATIONS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ORGANIZATION_EVENT_PARTICIPATIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case SET_ORGANIZATION_EVENT_PARTICIPATIONS_DATA:
      return {
        ...state,
        organizationEventParticipationsData: action.eventParticipations,
        isLoading: false,
      };
    default:
      return state;
  }
}
