import {
  LOAD_CERTIFICATE_TYPE_DATA_SUCCESS,
  CertificateTypeActionTypes,
  CertificateTypeState,
  CERTIFICATE_TYPE_FAILURE,
  CERTIFICATE_TYPE_REQUEST,
} from "./types";

export const initialCertificateTypeState: CertificateTypeState = {
  certificateTypes: [],
  isLoading: false,
  certificateTypesLoaded: false,
  isError: false,
};

export function certificateTypeReducer(
  state = initialCertificateTypeState,
  action: CertificateTypeActionTypes
): CertificateTypeState {
  switch (action.type) {
    case CERTIFICATE_TYPE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case LOAD_CERTIFICATE_TYPE_DATA_SUCCESS:
      return {
        ...state,
        certificateTypes: action.data,
        isLoading: false,
        certificateTypesLoaded: true,
      };
    case CERTIFICATE_TYPE_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    default:
      return state;
  }
}

export default certificateTypeReducer;
