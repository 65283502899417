/* eslint-disable camelcase*/
/* eslint-disable react/hook-use-state*/
/* eslint-disable  no-unused-vars*/
import React, { useCallback, useState } from "react";
import {
  Box,
  Button,
  Grid,
  FormControl,
  Switch,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";
import Alert from "@material-ui/lab/Alert";
import { TERMS_AND_CONDITIONS_URL } from "../../routing/externalUrls";
import routes from "../../routing/routes";
import { usersService, authService } from "../../api";
import { UserData } from "../../models/userData";
import { enqueueSnackbar } from "notistack";
import { getDynamicTranslation } from "../core/localization/getStringTranslation";

interface TermsAgreedModalProps {
  open: boolean;
  onAgree: (terms_agreed_at: string) => void;
  currentUser: UserData | undefined;
}

const TermsAgreedModal: React.FC<TermsAgreedModalProps> = ({
  open,
  onAgree,
  currentUser,
}) => {
  const { t } = useTranslation(["booking", "layout", "common", "customer"]);
  const [ATBandPrivacy, setATBandPrivacy] = useState(!!currentUser?.terms_agreed_at);

  const handleAgree = useCallback(
    async (values: { terms_agreed_at: string }) => {
      if (currentUser) {
        const updatedUser = {
          ...currentUser,
          terms_agreed_at: values.terms_agreed_at,
          ...currentUser.address,
        };

        try {
          await usersService.updateProfile(updatedUser);
          enqueueSnackbar(getDynamicTranslation("profile.termsAgreed", "snackbars"), {
            variant: "info",
          });
          onAgree(values.terms_agreed_at);
        } catch (error) {
          new Error("Fehler beim Aktualisieren des Profils:");
        }
      } else {
        new Error("Aktuelle Nutzerdaten sind nicht verfügbar");
      }
    },
    [currentUser, onAgree]
  );

  const handleDecline = useCallback(() => {
    authService.logout();
    window.location.href = routes.login;
  }, []);

  return (
    <Dialog open={open}>
      <DialogTitle>{t("terms", { ns: "customer" })}</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{ terms_agreed_at: currentUser?.terms_agreed_at || "" }}
          onSubmit={handleAgree}
        >
          {({ setFieldValue }) => (
            <Form>
              <Box component="div" className="checkout-atb-box">
                {t("checkout.AGBPrivacyAccepted.part1", { ns: "booking" })}{" "}
                <Link
                  href={TERMS_AND_CONDITIONS_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("footer.agb", { ns: "layout" })}
                </Link>{" "}
                {t("checkout.AGBPrivacyAccepted.part2", { ns: "booking" })}{" "}
                <Link
                  href={routes.privacy_policy}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("privacyDec", { ns: "common" })}
                </Link>{" "}
                {t("checkout.AGBPrivacyAccepted.part3", { ns: "booking" })}{" "}
                <Switch
                  checked={ATBandPrivacy}
                  onChange={(event) => {
                    const isChecked = event.target.checked;
                    setATBandPrivacy(isChecked);
                    if (isChecked) {
                      setFieldValue("terms_agreed_at", new Date().toISOString());
                    } else {
                      setFieldValue("terms_agreed_at", "");
                    }
                  }}
                  color="primary"
                  name="ATBandPrivacyCheck"
                  inputProps={{ "aria-label": "ATB and Privacy checkbox" }}
                />
              </Box>
              {!ATBandPrivacy && (
                <Box my={2}>
                  <Alert severity="error">
                    {t("checkout.AGBPrivacyAccept", { ns: "booking" })}
                  </Alert>
                </Box>
              )}
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <FormControl className="registrationButton" fullWidth>
                    <Button
                      type="submit"
                      size="large"
                      variant="contained"
                      color="primary"
                      disableElevation
                      disableRipple
                      disabled={!ATBandPrivacy}
                    >
                      {t("login", { ns: "authentication" })}
                    </Button>
                  </FormControl>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDecline} color="secondary">
          {t("decline", { ns: "common" })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TermsAgreedModal;
