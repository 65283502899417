import { Box, Grid, Link } from "@material-ui/core";
import * as React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { AppState } from "../../../redux";
import {
  setTryLinkAccountWithoutLogin,
  verifyLinkLanrRequestCode,
} from "../../../redux/linkAccount/actions";
import { history } from "../../../routing/history";
import routes from "../../../routing/routes";
import Loader from "../../../components/theming/loader/Loader";

export const LinkConfirmationPage: React.FC = () => {
  const dispatch = useDispatch();
  const verifyStatus = useSelector((state: AppState) => state.linkAccount);

  useEffect(() => {
    dispatch(setTryLinkAccountWithoutLogin());
  }, [dispatch]);

  function verfiyCode() {
    if (
      !verifyStatus.verifyingLanrRequestCode &&
      !verifyStatus.verifyLanrRequestCodeAccepted &&
      verifyStatus.verifyLanrRequestCodeErrorMessage === ""
    ) {
      let query = new URLSearchParams(history.location.search);
      const verifyCode = query.get("code") || "";
      dispatch(verifyLinkLanrRequestCode(verifyCode));
    }
  }

  useEffect(verfiyCode, [
    dispatch,
    verifyStatus.verifyLanrRequestCodeAccepted,
    verifyStatus.verifyLanrRequestCodeErrorMessage,
    verifyStatus.verifyingLanrRequestCode,
  ]);

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          {verifyStatus.verifyingLanrRequestCode && (
            <>
              <h3>Ihr Validierungscode zur Kontoverknüpfung wird verifiziert.</h3>
              <Loader />
            </>
          )}
          {!verifyStatus.verifyingLanrRequestCode &&
            verifyStatus.verifyLanrRequestCodeAccepted && (
              <>
                <h3>Vielen Dank!</h3>
                <br />
                <br />
                Ihr Konto wurde erfolgreich verknüpft.
                <br />
              </>
            )}
          {!verifyStatus.verifyingLanrRequestCode &&
            !verifyStatus.verifyLanrRequestCodeAccepted &&
            verifyStatus.verifyLanrRequestCodeErrorMessage !== "" && (
              <>
                <h3>Fehler beim Verknüpfen</h3>
                <br />
                <br />
                {verifyStatus.verifyLanrRequestCodeErrorMessage}
                <br />
              </>
            )}
          <Box mt={3} textAlign="center">
            <Link component={RouterLink} to={routes.settings}>
              Zu den Kontenverknüpfungen
            </Link>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

// TODO no translation yet
