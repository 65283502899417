import { ChecklistData } from "../../models/checklistData";

export interface ChecklistState {
  checklist: ChecklistData[];
  checklistLoaded: boolean;
  isLoading: boolean;
}

export const CHECKLIST_REQUEST = "CHECKLIST_REQUEST";
export const CHECKLIST_FAILURE = "CHECKLIST_FAILURE";

export const SET_CHECKLIST = "SET_CHECKLIST";
export const CLEAR_CHECKLIST = "CLEAR_CHECKLIST";

// actions that are the same for all api calls (request and failure)
export interface ChecklistRequestAction {
  type: typeof CHECKLIST_REQUEST;
}

export interface ChecklistFailureAction {
  type: typeof CHECKLIST_FAILURE;
}

export interface SetChecklistAction {
  type: typeof SET_CHECKLIST;
  data: ChecklistData[];
}

export interface ClearChecklistAction {
  type: typeof CLEAR_CHECKLIST;
}

export type ChecklistActionTypes =
  | ChecklistRequestAction
  | ChecklistFailureAction
  | SetChecklistAction
  | ClearChecklistAction;
