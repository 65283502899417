import { MaintenanceBannerData } from "../../models/maintenanceData";

// The types for the maintenance feature are defined in this file.
// The types include the state shape and the action types for the maintenance feature.
export const FETCH_BANNER_REQUEST = "FETCH_BANNER_REQUEST";
export const FETCH_BANNER_SUCCESS = "FETCH_BANNER_SUCCESS";
export const FETCH_BANNER_FAILURE = "FETCH_BANNER_FAILURE";

export interface MaintenanceBannerState {
  maintenanceBanner: MaintenanceBannerData[];
  isLoading: boolean;
  error: string | null;
}

export interface FetchBannerRequestAction {
  type: typeof FETCH_BANNER_REQUEST;
}

export interface FetchBannerSuccessAction {
  type: typeof FETCH_BANNER_SUCCESS;
  payload: MaintenanceBannerData[];
}

export interface FetchBannerFailureAction {
  type: typeof FETCH_BANNER_FAILURE;
  error: string;
}

export type MaintenanceActionTypes =
  | FetchBannerRequestAction
  | FetchBannerSuccessAction
  | FetchBannerFailureAction;
