import { ReducedOrderData } from "../../models/orderData";

export interface OrderState {
  orderData: ReducedOrderData;
  isSending: boolean;
}

export const CLEAR_ORDER = "CLEAR_ORDER";
export const ORDER_REQUEST = "ORDER_REQUEST";
export const ORDER_FAILURE = "ORDER_FAILURE";
export const LOAD_ORDER_DATA_SUCCESS = "LOAD_ORDER_DATA_SUCCESS";
export const SET_ORDER_NUMBER = "SET_ORDER_NUMBER";
export const SET_ORDER_DATA = "SET_ORDER_DATA";

export interface ClearOrderAction {
  type: typeof CLEAR_ORDER;
}

export interface OrderRequestAction {
  type: typeof ORDER_REQUEST;
}

export interface OrderFailureAction {
  type: typeof ORDER_FAILURE;
}

export interface SetOrderNumberAction {
  type: typeof SET_ORDER_NUMBER;
  order_number: string;
}

export interface SetOrderDataAction {
  type: typeof SET_ORDER_DATA;
  order: ReducedOrderData;
}

export interface LoadOrderDataAction {
  type: typeof LOAD_ORDER_DATA_SUCCESS;
  order: string;
}

export type OrderActionTypes =
  | ClearOrderAction
  | OrderRequestAction
  | OrderFailureAction
  | LoadOrderDataAction
  | SetOrderDataAction
  | SetOrderNumberAction;
