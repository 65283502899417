import { OrganizationEventParticipationsData } from "../../models/organizationEventParticipationsData";

export interface OrganizationEventParticipationsState {
  organizationEventParticipationsData: OrganizationEventParticipationsData[];
  isLoading: boolean;
}
export const ORGANIZATION_EVENT_PARTICIPATIONS_REQUEST =
  "ORGANIZATION_EVENT_PARTICIPATIONS_REQUEST";
export const ORGANIZATION_EVENT_PARTICIPATIONS_FAILURE =
  "ORGANIZATION_EVENT_PARTICIPATIONS_FAILURE";
export const SET_ORGANIZATION_EVENT_PARTICIPATIONS_DATA =
  "SET_ORGANIZATION_EVENT_PARTICIPATIONS_DATA";
export interface OrganizationEventParticipationsRequestAction {
  type: typeof ORGANIZATION_EVENT_PARTICIPATIONS_REQUEST;
}
export interface OrganizationEventParticipationsFailureAction {
  type: typeof ORGANIZATION_EVENT_PARTICIPATIONS_FAILURE;
}

export interface SetOrganizationEventParticipationsDataAction {
  type: typeof SET_ORGANIZATION_EVENT_PARTICIPATIONS_DATA;
  eventParticipations: OrganizationEventParticipationsData[];
}

export type OrganizationEventParticipationsActionTypes =
  | OrganizationEventParticipationsRequestAction
  | OrganizationEventParticipationsFailureAction
  | SetOrganizationEventParticipationsDataAction;
