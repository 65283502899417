export enum EventsSortBy {
  BeginOldest = "sortBy.beginOldest",
  BeginNewest = "sortBy.beginNewest",
  CourseNumber = "sortBy.courseNumber",
}

export enum BookingsSortBy {
  BeginAscending = "sortBy.beginAscending",
  BeginDescending = "sortBy.beginDescending",
  EndAscending = "sortBy.endAscending",
  EndDescending = "sortBy.endDescending",
  StatusAscending = "sortBy.statusAscending",
  StatusDescending = "sortBy.statusDescending",
}

export enum TasksSortBy {
  StatusAscending = "Status aufsteigend",
  StatusDesending = "Status absteigend",
}
