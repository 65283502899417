import { Box, IconButton, Link, makeStyles, Theme } from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import packageJson from "../../../../package.json";
import { LumosPrintSvg } from "../../../assets/icons/custom-svg-components/LumosSvgCollection";
import { AppState } from "../../../redux";
import {
  IMPRINT_URL,
  KONTAKT_URL,
  TERMS_AND_CONDITIONS_URL,
  WEB_ACCESSIBILITY_URL,
} from "../../../routing/externalUrls";
import routes from "../../../routing/routes";
import { CookieConsent } from "../../core/events/shared/CookieConsent";
import LeavePageDialog from "../dialog/LeavePageDialog";
import "./Footer.scss";
import { metaDataService } from "../../../api";
import Loader from "../../theming/loader/Loader";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: "#fff",
    cursor: "pointer",
    "&:hover": {
      color: "#fff",
      textDecoration: "underline",
    },
  },
  margin: {
    marginLeft: theme.spacing(10),
  },
  logo: { height: "3.125rem", paddingRight: "1rem" },
}));

export const Footer: React.FC = () => {
  const classes = useStyles();
  const accessibility = useSelector((state: AppState) => state.accessibility);
  const { t } = useTranslation(["layout", "snackbars"]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [logo1, setLogo1] = useState(null);
  const [logo2, setLogo2] = useState(null);
  const [logo3, setLogo3] = useState(null);

  const [version, setVersion] = useState("");
  const [customer, setCustomer] = useState("");
  const [metaDataIsLoading, setMetaDataIsLoading] = useState(true);

  useEffect(() => {
    if (version.length === 0) {
      metaDataService
        .getMetaData()
        .then((metaData) => {
          setVersion(metaData.data.version);
          setCustomer(metaData.data.customer);
          setMetaDataIsLoading(false);
        })
        // in case of an error fall back to package.json
        .catch(() => {
          setVersion(packageJson.version);
          setMetaDataIsLoading(false);
        });
    }
  }, [version, metaDataIsLoading]);

  useEffect(() => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const img = require(`../../../assets/general/customer_footer_1.svg`);
      setLogo1(img.default || img);
    } catch (error) {
      setLogo1(null);
    }

    try {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const img2 = require(`../../../assets/general/customer_footer_2.svg`);
      setLogo2(img2.default || img2);
    } catch (error) {
      setLogo2(null);
    }

    try {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const img3 = require(`../../../assets/general/customer_footer_3.svg`);
      setLogo3(img3.default || img3);
    } catch (error) {
      setLogo3(null);
    }
  }, []);

  const contentText = t("dialogues.leavePage", {
    linkHref: IMPRINT_URL,
    customer: customer,
  });

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const handleConfirm = () => {
    setDialogOpen(false);
    if (IMPRINT_URL) {
      window.open(IMPRINT_URL, "_blank", "noopener,noreferrer");
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (version.length === 0 && metaDataIsLoading) {
    return <Loader />;
  }
  return (
    <footer id="footer" className="app-footer">
      <CookieConsent />

      <Box
        color="#fff"
        bgcolor={accessibility.monoMode ? "#000" : "var(--text-color)"}
        className="footer-bottom"
        display={windowWidth > 950 ? "flex" : "block"}
        justifyContent="space-between"
        p="1.5rem"
      >
        <Box pr={windowWidth > 950 ? "1rem" : 0} pb={windowWidth > 950 ? 0 : "1rem"}>
          <label id="copyright-owner">© {moment().year()} amanzii GmbH</label>
          <p />
          <label id="version">Version {version}</label>
        </Box>
        <Box display="flex">
          <Box display="flex" flexWrap="wrap" alignItems="center">
            <Box pr="1rem">
              <Link
                className={classes.root}
                href={routes.privacy_policy}
                rel="noopener noreferrer"
              >
                {t("footer.privacy", { ns: "layout" })}
              </Link>
            </Box>
            <Box pr="1rem">
              <LeavePageDialog
                content={contentText}
                open={dialogOpen}
                onClose={handleCloseDialog}
                onConfirm={handleConfirm}
              />
              <Link
                onClick={handleOpenDialog}
                className={classes.root}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("footer.legalNotice", { ns: "layout" })}
              </Link>
            </Box>
            <Box pr="1rem">
              <Link
                className={classes.root}
                href={TERMS_AND_CONDITIONS_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("footer.agb", { ns: "layout" })}
              </Link>
            </Box>
            <Box pr="1rem">
              <Link
                className={classes.root}
                href={WEB_ACCESSIBILITY_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("footer.accessibility", {
                  ns: "layout",
                })}
              </Link>
            </Box>
            <Box pr="1rem">
              <Link
                className={classes.root}
                href={KONTAKT_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("footer.contact", {
                  ns: "layout",
                })}
              </Link>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" flexDirection="row-reverse">
            {logo1 ? <img src={logo1} alt="Logo 1" className={classes.logo} /> : null}
            {logo2 ? <img src={logo2} alt="Logo 2" className={classes.logo} /> : null}
            {logo3 ? <img src={logo3} alt="Logo 3" className={classes.logo} /> : null}
          </Box>
          <Box display="flex" alignItems="center" flexDirection="row-reverse">
            <IconButton
              onClick={() => window.print()}
              aria-label="print"
              style={{
                padding: 0,
              }}
              color="inherit"
            >
              <LumosPrintSvg />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </footer>
  );
};
