import { OrganizationInvoiceData } from "./../../models/organizationInvoiceData";

export interface OrganizationInvoiceState {
  organizationInvoiceData: OrganizationInvoiceData[];
  isLoading: boolean;
}

export const ORGANIZATION_INVOICE_REQUEST = "ORGANIZATION_INVOICE_REQUEST";
export const ORGANIZATION_INVOICE_FAILURE = "INVOICE_FAILURE";
export const ORGANIZATION_LOAD_INVOICE_DATA_SUCCESS = "LOAD_INVOICE_DATA_SUCCESS";
export const SET_ORGANIZATION_INVOICE_DATA = "SET_ORGANIZATION_INVOICE_DATA";

export interface OrganizationInvoiceRequestAction {
  type: typeof ORGANIZATION_INVOICE_REQUEST;
}

export interface OrganizationInvoiceFailureAction {
  type: typeof ORGANIZATION_INVOICE_FAILURE;
}

export interface LoadOrganizationInvoiceDataAction {
  type: typeof ORGANIZATION_LOAD_INVOICE_DATA_SUCCESS;
  invoice: string;
}

export interface SetOrganizationInvoiceDataAction {
  type: typeof SET_ORGANIZATION_INVOICE_DATA;
  invoice: OrganizationInvoiceData[];
}

export type OrganizationInvoiceActionTypes =
  | OrganizationInvoiceRequestAction
  | OrganizationInvoiceFailureAction
  | SetOrganizationInvoiceDataAction
  | LoadOrganizationInvoiceDataAction;
