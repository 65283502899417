import { useTranslation } from "react-i18next";
import { Grid, Box } from "@material-ui/core";
import CustomTextField from "../../../../forms/CustomTextField";

const AddressSection: React.FC = () => {
  const { t } = useTranslation(["organization", "common"]);

  return (
    <>
      <Box style={{ paddingTop: "1rem" }}>
        <h3>{t("forms.organizationAddress", { ns: "organization" })}</h3>
      </Box>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <CustomTextField
            fullWidth
            required
            id="organization-street"
            name="street"
            label={t("profile.street", { ns: "common" })}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomTextField
            required
            fullWidth
            id="organization-street-number"
            name="street_number"
            label={t("profile.streetNumber", { ns: "common" })}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomTextField
            required
            fullWidth
            id="organization-zip-code"
            name="zip_code"
            label={t("profile.zipCode", { ns: "common" })}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomTextField
            required
            fullWidth
            id="organization-city"
            name="city"
            label={t("profile.city", { ns: "common" })}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomTextField
            required
            fullWidth
            id="organization-email"
            name="email"
            label={t("profile.mail", { ns: "common" })}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default AddressSection;
