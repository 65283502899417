import apiRoutes from "../apiRoutes";
import { BaseCrudService } from "../baseCrudService";
import { OrganizationData, OrganizationForm } from "../../models/organizationData";

/**
 * Service class for managing organization data.
 * Provides methods for CRUD operations on organizations.
 */
export class OrganizationService extends BaseCrudService {
  /**
   * Retrieves all organizations from the API.
   * @returns {Promise<OrganizationData[]>} A promise that resolves to an array of organization data.
   */
  async getOrganizations() {
    const response = await this.getAll<OrganizationData[]>(apiRoutes.organization);
    return response.data;
  }

  /**
   * Retrieves an organization by its ID from the API.
   * @param {string} id - The ID of the organization to retrieve.
   * @returns {Promise<OrganizationData>} A promise that resolves to the organization data.
   */
  async getOrganizationById(id: string) {
    const response = await this.get<OrganizationData>(apiRoutes.organization, id);
    return response.data;
  }

  /**
   * Adds a new organization to the API.
   * @param {OrganizationData} organization - The organization data to add.
   * @returns {Promise<OrganizationData>} A promise that resolves to the added organization data.
   */
  async addOrganization(organization: OrganizationForm) {
    const response = await this.create<OrganizationForm>(
      apiRoutes.organization,
      organization
    );
    return response.data;
  }

  /**
   * Updates an existing organization.
   * @param {string} id - The ID of the organization to update.
   * @param {OrganizationData} organizationData - The updated organization data.
   * @returns {Promise<any>} A promise that resolves to the response from the API.
   */
  async editOrganization(id: string, organizationData: OrganizationForm) {
    const response = await this.update<OrganizationForm>(
      apiRoutes.organization,
      id,
      organizationData
    );
    return response;
  }

  /**
   * Deletes an organization.
   * @param {string} id - The ID of the organization to delete.
   * @returns {Promise<any>} A promise that resolves to the response from the API.
   */
  async deleteOrganization(id: string) {
    const response = await this.delete<OrganizationData>(apiRoutes.organization, id);
    return response;
  }
}
