import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

import * as React from "react";

interface TableLumosProps {
  rowHeader: {
    key: string;
    label: string;
    content?: JSX.Element;
    alignment?: "center" | "left" | "right";
  }[];
  rowData: any[];
  deletable?: boolean;
  onDelete?: (item: any, index: number) => void;
}

export interface RowData extends RowDataBase {}

export interface RowDataBase {
  key: string;
  backgroundColor?: string;
}

// !basic dynamic generated table (if there needs to be more configuration adapt this component or add a new one)
const TableLumos: React.FC<TableLumosProps> = (props) => {
  return (
    <>
      <TableContainer>
        <Table style={{ minWidth: 650 }} aria-label="basic table">
          <TableHead>
            <TableRow>
              {props.rowHeader.map((header) => {
                return (
                  <TableCell key={header.label} align={header.alignment}>
                    {header.content ? header.content : header.label}
                  </TableCell>
                );
              })}
              {props.deletable ? <TableCell></TableCell> : <></>}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.rowData.map((row, index) => (
              <TableRow
                key={index}
                style={{
                  backgroundColor: row.backgroundColor ? row.backgroundColor : "#ffffff",
                }}
              >
                {props.rowHeader.map((header) => (
                  <TableCell key={header.key}>{row[header.key]}</TableCell>
                ))}
                {props.deletable ? (
                  <TableCell key={"delete"}>
                    <IconButton
                      aria-label="delete"
                      onClick={(event) => {
                        if (props.onDelete != null) {
                          props.onDelete(row, index);
                        }
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                ) : (
                  <></>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default TableLumos;
