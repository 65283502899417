import { AxiosError } from "axios";
import { Dispatch } from "redux";
import { elearningService } from "../../api";
import { history } from "../../routing/history";
import routes from "../../routing/routes";
import { clearDocuments } from "../documents/actions";
import { ClearDocumentsAction } from "../documents/types";
import { clearEditEvent, clearEventList, setEditEventSuccess } from "../events/actions";
import { ClearEventListAction } from "../events/types";
import { clearParticipant } from "../participants/actions";
import { ClearParticipantAction } from "../participants/types";
import { clearSpeakers } from "../speaker/actions";
import { ClearSpeakersAction } from "../speaker/types";
import { ELearningData, ELearningDto } from "./../../models/elearning";
import { EventActionTypes } from "./../events/types";
import {
  AddELearningSuccessAction,
  ADD_ELEARNING_SUCCESS,
  ELearningActionTypes,
  ELearningFailureAction,
  ELearningFetchingFinishedAction,
  ELearningRequestAction,
  ELEARNING_FAILURE,
  ELEARNING_REQUEST,
  FINISH_FETCHING_ELEARNING,
} from "./types";
import { enqueueSnackbar } from "notistack";

export function getELearning(id: string) {
  return (
    dispatch: Dispatch<
      | ELearningRequestAction
      | ELearningFailureAction
      | ELearningFetchingFinishedAction
      | EventActionTypes
    >
  ) => {
    dispatch(elearningRequest());

    elearningService
      .getElearning(id)
      .then((response) => {
        dispatch(elearningfinishedFetching());
        // TODO: maybe use own state for retrieving elearning?
        dispatch(setEditEventSuccess(response));
      })
      .catch((err: AxiosError) => {
        dispatch(elearningFailure());
        if (err.response?.status === 404) {
          enqueueSnackbar("Das gesuchte E-Learning existiert nicht.", {
            variant: "error",
          });
        }
      });
  };
}

export function addELearning(elearning: ELearningData) {
  return (
    dispatch: Dispatch<
      | ClearEventListAction
      | ELearningActionTypes
      | ClearSpeakersAction
      | ClearDocumentsAction
    >
  ) => {
    dispatch(elearningRequest());
    elearningService
      .addELearning(elearning)
      .then((response) => {
        dispatch(addELearningSuccess(response.data));
        enqueueSnackbar("Das E-Learning wurde erfolgreich angelegt.", {
          variant: "success",
        });
        dispatch(clearSpeakers());
        dispatch(clearDocuments());
        dispatch(clearEventList());
        history.push(routes.events); //load start page
      })
      .catch(() => {
        dispatch(elearningFailure());
      });
  };
}

export function getBlendedLearning(id: string) {
  return (
    dispatch: Dispatch<
      | ELearningRequestAction
      | ELearningFailureAction
      | ELearningFetchingFinishedAction
      | EventActionTypes
    >
  ) => {
    dispatch(elearningRequest());

    elearningService
      .getBlendedlearning(id)
      .then((response) => {
        dispatch(elearningfinishedFetching());
        dispatch(setEditEventSuccess(response));
      })
      .catch((err: AxiosError) => {
        dispatch(elearningFailure());
        if (err.response?.status === 404) {
          enqueueSnackbar("Das gesuchte Blended-Learning existiert nicht.", {
            variant: "error",
          });
        }
      });
  };
}

export function addBlendedLearning(blendedLearning: ELearningData) {
  return (
    dispatch: Dispatch<
      | ClearEventListAction
      | ELearningActionTypes
      | ClearSpeakersAction
      | ClearDocumentsAction
    >
  ) => {
    dispatch(elearningRequest());
    elearningService
      .addBlendedLearning(blendedLearning)
      .then((response) => {
        dispatch(addELearningSuccess(response.data));
        enqueueSnackbar("Das Blended-Learning wurde erfolgreich angelegt.", {
          variant: "success",
        });
        dispatch(clearSpeakers());
        dispatch(clearDocuments());
        dispatch(clearEventList());
        history.push(routes.events); //load start page
      })
      .catch(() => {
        dispatch(elearningFailure());
      });
  };
}

export function editELearning(data: ELearningData, id: string) {
  return (
    dispatch: Dispatch<
      | ELearningRequestAction
      | ELearningFailureAction
      | ELearningFetchingFinishedAction
      | EventActionTypes
      | ClearEventListAction
      | ClearSpeakersAction
      | ClearDocumentsAction
      | ClearParticipantAction
    >
  ) => {
    dispatch(elearningRequest());
    elearningService
      .editELearning(data, id)
      .then((response) => {
        dispatch(elearningfinishedFetching());
        dispatch(setEditEventSuccess(response.data as ELearningDto));
        enqueueSnackbar("Das E-Learning wurde erfolgreich bearbeitet.", {
          variant: "success",
        });
        dispatch(clearEditEvent());
        dispatch(clearSpeakers());
        dispatch(clearDocuments());
        dispatch(clearParticipant());
        dispatch(clearEventList());
        history.push(routes.events); //load start page
      })
      .catch((error: AxiosError) => {
        let throwError = true;
        if (
          error.response?.status === 400 &&
          JSON.stringify(error.response?.data).toLocaleLowerCase().includes("scorm")
        ) {
          throwError = false;
        }
        dispatch(elearningFailure(throwError));
      });
  };
}

export function elearningRequest(): ELearningRequestAction {
  return {
    type: ELEARNING_REQUEST,
  };
}

export function elearningFailure(throwError: boolean = true): ELearningFailureAction {
  return {
    type: ELEARNING_FAILURE,
    throwError: throwError,
  };
}

export function elearningfinishedFetching(): ELearningFetchingFinishedAction {
  return {
    type: FINISH_FETCHING_ELEARNING,
  };
}

export function addELearningSuccess(data: ELearningData): AddELearningSuccessAction {
  return {
    type: ADD_ELEARNING_SUCCESS,
    data,
  };
}
