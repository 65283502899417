import { Dispatch } from "redux";
import { UserData } from "../../models/userData";
import { usersService } from "./../../api/index";
import {
  GetCurrentUserAction,
  GetUserFailureAction,
  GetUserRequestAction,
  GET_CURRENT_USER,
  GET_USER_FAILURE,
  GET_USER_REQUEST,
  UserActionTypes,
} from "./types";

export function getCurrentUserData(dispatch: Dispatch<UserActionTypes>) {
  dispatch(getCurrentUserRequest());
  usersService
    .getCurrentUser()
    .then((response: UserData) => {
      dispatch(getCurrentUser(response));
    })
    .catch(() => {
      dispatch(getUserFailure());
    });
}

export function getCurrentLoggedInUser() {
  return (dispatch: Dispatch<UserActionTypes>) => {
    getCurrentUserData(dispatch);
  };
}

export function getCurrentUserRequest(): GetUserRequestAction {
  return {
    type: GET_USER_REQUEST,
  };
}

export function getUserFailure(): GetUserFailureAction {
  return {
    type: GET_USER_FAILURE,
  };
}

export function getCurrentUser(userData: UserData): GetCurrentUserAction {
  return {
    type: GET_CURRENT_USER,
    user: userData,
  };
}
