import React from "react";
import { Grid, Box, Typography } from "@material-ui/core";
//import StarIcon from '@material-ui/icons/Star';
//import StarBorderIcon from '@material-ui/icons/StarBorder';
import "./EventItem.scss";
import { EventItemTitle } from "./EventItemTitle";
import { EventItemDescription } from "./EventItemDescription";
import { EventItemProperties } from "./EventItemProperties";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { TrainingType } from "../../../../../models/enums/trainingType.enum";
import { ELearningListData } from "../../../../../models/elearning";
import { useTranslation } from "react-i18next";

interface EventItemProps {
  event: ELearningListData;
  preview?: boolean;
  favorite?: boolean;
}

export const EventItem: React.FC<EventItemProps> = (props) => {
  const { t } = useTranslation(["events"]);
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box p={small ? 1 : 0}>
      <Grid container spacing={small ? 0 : 4} className="event-item">
        <Grid item xs={12} md={9} lg={9}>
          <EventItemTitle
            event={props.event}
            preview={props.preview}
            favorite={props.favorite}
          />
          <br />
          {props.event.training_type !== TrainingType.DefaultEvent && (
            <Box my={1}>
              <Typography variant="h4">
                {props.event.number_of_units} {t("units", { ns: "events" })}{" "}
                {props.event.duration_per_unit_in_minutes}{" "}
                {t("minutes", { ns: "events" })}
              </Typography>
            </Box>
          )}
          <EventItemDescription description={props.event.description} />
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <EventItemProperties event={props.event} preview={props.preview} />
        </Grid>
      </Grid>
    </Box>
  );
};
