import * as yup from "yup";
import moment from "moment";
import { TaskStatus } from "../../../models/enums/taskStatus.enum";
import { TaskData } from "../../../models/taskData";
import { DATE_FUTURE } from "../../../libs/validation_strings";

const addTaskValidationSchema = (task?: TaskData) =>
  yup.object({
    description: yup.string().required("Die Aufgabenschreibung ist ein Pflichtfeld."),
    event: yup.string().nullable(),
    due_date: yup
      .date()
      .nullable()
      .test("due_date", DATE_FUTURE, function (value) {
        if (task && moment(value).isSame(task?.due_date, "day")) return true;
        // check if the date is today or lies further in the future
        return value == null || moment().diff(value, "days") <= 0;
      }),
    responsibility: yup.string().nullable(),
    additional_info: yup.string().nullable(),
    status: yup
      .number()
      .nullable()
      .oneOf(
        Object.values(TaskStatus)
          .filter((elem) => +elem)
          .map((elem) => +elem)
      ),
  });

export default addTaskValidationSchema;

// TODO no translation yet
