import { faUserMinus, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  PinboardFormData,
  PinboardListData,
} from "../../../../../../models/pinboardData";
import { AppState } from "../../../../../../redux";
import {
  unfollowQuestion,
  followQuestion,
} from "../../../../../../redux/pinboard-question/actions";
import "../../../../../../pages/core/pinboard/Pinboard.scss";
import { NIL as NIL_UUID } from "uuid";

interface IPinboardFollowButtonProps {
  question: PinboardListData;
}

const PinboardFollowButton: React.FunctionComponent<IPinboardFollowButtonProps> = (
  props
) => {
  const accessibility = useSelector((state: AppState) => state.accessibility);
  const dispatch = useDispatch();

  const data: PinboardFormData = {
    title: props.question.title,
    post: props.question.post,
    event: props.question.event_id,
  };

  return (
    <Box className={accessibility.monoMode ? "pinboard-button-mono" : "pinboard-button"}>
      <Button
        onClick={() => {
          dispatch(
            props.question.user_has_subscribed
              ? unfollowQuestion(
                  props.question ? props.question.id : NIL_UUID,
                  data as PinboardFormData
                )
              : followQuestion(
                  props.question ? props.question.id : NIL_UUID,
                  data as PinboardFormData
                )
          );
        }}
        className={
          accessibility.monoMode ? "pinboard-button-wider-mono" : "pinboard-button-wider"
        }
      >
        <FontAwesomeIcon
          icon={props.question.user_has_subscribed ? faUserMinus : faUserPlus}
          style={{ marginRight: "0.43rem" }}
        />{" "}
        {props.question.user_has_subscribed ? "Nicht mehr folgen" : "Folgen"}
      </Button>
    </Box>
  );
};

export default PinboardFollowButton;
