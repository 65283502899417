import React, { useState } from "react";
import { Box, Menu, MenuItem, Tooltip } from "@material-ui/core";
import { Info } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { CartItemData } from "../../../../../models/cartData";
import { EventStatus } from "../../../../../models/enums/eventStatus.enum";
import { InternalExternalEventType } from "../../../../../models/enums/eventType.enum";
import { ParticipationStatus } from "../../../../../models/enums/participationStatus.enum";
import { TrainingType } from "../../../../../models/enums/trainingType.enum";
import { UserType } from "../../../../../models/enums/userType.enum";
import { EventListData } from "../../../../../models/eventData";
import {
  LinkAccountData,
  LinkAccountStatus,
} from "../../../../../models/linkAccountData";
import { UserData } from "../../../../../models/userData";
import { AppState } from "../../../../../redux";
import { addEventToCart } from "../../../../../redux/cart/actions";
import {
  putOtherParticipantToWaitingList,
  putParticipantToWaitingList,
} from "../../../../../redux/waitinglist/actions";
import routes from "../../../../../routing/routes";
import { EventItemBookingButton } from "./EventItemBookingButton";
import TargetGroupsDontMatchPopover from "./TargetGroupsDontMatchPopover";
import TargetStopSignUpPopover from "./TargetStopSignUpPopover";
import { useTranslation } from "react-i18next";

interface EventItemRenderButtonProps {
  eventData: EventListData;
  eventWorkload: number;
}

const EventItemRenderButton: React.FunctionComponent<EventItemRenderButtonProps> = (
  props
) => {
  const { t } = useTranslation(["events", "snackbars"]);

  const [anchorElWrongTargetGroup, setAnchorElWrongTargetGroup] =
    React.useState<HTMLElement | null>(null);
  const openWrongTargetGroup = Boolean(anchorElWrongTargetGroup);

  const handlePopoverOpenWrongTargetGroup = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setAnchorElWrongTargetGroup(event.currentTarget);
  };

  const handlePopoverCloseWrongTargetGroup = () => {
    setAnchorElWrongTargetGroup(null);
  };

  const [bookingForAnchorEl, setBookingForAnchorEl] = useState<null | HTMLElement>(null);
  const [isBookedForMe, setIsBookedForMe] = useState<boolean>(true);

  const auth = useSelector((state: AppState) => state.auth);
  const cart = useSelector((state: AppState) => state.cart);
  const currentUser = useSelector((state: AppState) => state.user.currentUser);
  const linkAccount = useSelector((state: AppState) => state.linkAccount);
  const targetGroups = useSelector((state: AppState) => state.targetGroups.targetGroups);
  const eventIsFullyBooked =
    (props.eventWorkload === 100 ||
      props.eventData.status === EventStatus.Full ||
      props.eventData.seats_taken >= props.eventData.seats) &&
    props.eventData.training_type !== TrainingType.ELearning;

  const linkedAccountsLength = linkAccount.linkAccountList.filter(
    (x) => x.status === LinkAccountStatus.Linked
  ).length;

  const history = useHistory();
  const dispatch = useDispatch();

  //-----------
  const [anchorElStopSignUp, setAnchorElStopSignUp] = React.useState<HTMLElement | null>(
    null
  );
  const openStopSignUp = Boolean(anchorElStopSignUp);

  const handlePopoverOpenStopSignUp = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setAnchorElStopSignUp(event.currentTarget);
  };

  const handlePopoverCloseStopSignUp = () => {
    setAnchorElStopSignUp(null);
  };

  //-----------
  function checkTargetGroupMatch(user: UserData | LinkAccountData | undefined) {
    if (user) {
      return props.eventData.is_my_target_group
        ? props.eventData.is_my_target_group
        : false;
    }
    return !auth.loggedIn;
  }

  const handleBookingForClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setBookingForAnchorEl(event.currentTarget);
  };

  const handleBookingForClose = () => {
    setBookingForAnchorEl(null);
  };

  function setButtonName(props: any) {
    return `bookEvent_${props.eventData?.vnr}`;
  }

  // one user at a time
  function isAllowedToBook(bookforMyself: boolean, linkedUser?: LinkAccountData) {
    if (bookforMyself) {
      if (cart.cart.cart_items.find((x) => x.book_for?.id !== currentUser?.id)) {
        return false;
      }
      return true;
    } else {
      // book for someone else
      if (
        cart.cart.cart_items.find(
          (x) => x.book_for?.id === currentUser?.id || x.book_for?.id !== linkedUser?.id
        )
      ) {
        return false;
      }
      return true;
    }
  }

  const isInCart = (
    eventId: string,
    bookedForMe: boolean = true,
    linkedUser?: LinkAccountData
  ) => {
    if (
      cart.cart.cart_items.find((cartItem: CartItemData) => {
        if (bookedForMe) {
          if (
            cartItem.id === eventId &&
            //cartItem.book_for.id === currentUser?.id
            cartItem.book_for.id === currentUser?.id
          ) {
            return true;
          }
          return false;
        } else if (linkedUser) {
          if (
            cartItem.id === eventId &&
            cartItem.book_for.id !== currentUser?.id &&
            linkedUser.id === cartItem.book_for.id
          ) {
            return true;
          }
          return false;
        }
        return false;
      })
    ) {
      return true;
    }
    return false;
  };

  if (
    props.eventData.participation_status === ParticipationStatus.Booked &&
    linkedAccountsLength === 0
  ) {
    return (
      <EventItemBookingButton
        id={setButtonName(props)}
        disabled={true}
        color={"primary"}
        text={t("status.booked", { ns: "events" })}
      />
    );
  }

  if (
    props.eventData.participation_status === ParticipationStatus.WaitingList &&
    linkedAccountsLength === 0
  ) {
    return (
      <EventItemBookingButton
        id={setButtonName(props)}
        disabled={true}
        color={"secondary"}
        text={t("status.waiting", { ns: "events" })}
      />
    );
  }

  if (isInCart(props.eventData.id) && linkedAccountsLength === 0) {
    // if (isInCart(props.eventData.id)) {
    return (
      <EventItemBookingButton
        id={setButtonName(props)}
        disabled={true}
        color={"secondary"}
        text={t("status.inCart", { ns: "events" })}
      />
    );
  }

  if (
    props.eventData.participation_status === ParticipationStatus.Participated &&
    linkedAccountsLength === 0
  ) {
    return (
      <EventItemBookingButton
        id={setButtonName(props)}
        disabled={true}
        color={"secondary"}
        text={t("status.participated", { ns: "events" })}
      />
    );
  }

  if (props.eventData.is_capped === true) {
    return (
      <>
        <TargetStopSignUpPopover
          openStopSignUp={openStopSignUp}
          anchorElStopSignUp={anchorElStopSignUp}
          handlePopoverCloseStopSignUp={() => handlePopoverCloseStopSignUp()}
          book_for_me={isBookedForMe}
        />
        <Box
          component={"div"}
          onMouseEnter={(event) =>
            props.eventData.is_capped === true
              ? handlePopoverOpenStopSignUp(event)
              : undefined
          }
          onMouseLeave={
            props.eventData.is_capped === true ? handlePopoverCloseStopSignUp : undefined
          }
        >
          <EventItemBookingButton
            id={setButtonName(props)}
            disabled={props.eventData.is_capped}
            color={"secondary"}
            text={t("status.stop", { ns: "events" })}
            action={(event) => {
              if (!auth.loggedIn) {
                history.push(routes.login);
              } else {
                if (eventIsFullyBooked) {
                  if (linkedAccountsLength !== 0) {
                    handleBookingForClick(event);
                  } else if (currentUser) {
                    dispatch(putParticipantToWaitingList(props.eventData.id));
                  }
                } else {
                  if (linkedAccountsLength !== 0) {
                    handleBookingForClick(event);
                  } else if (currentUser) {
                    dispatch(addEventToCart(props.eventData.id, currentUser.id));
                  }
                }
              }
            }}
          />
        </Box>
      </>
    );
  } else if (
    props.eventData.internal_external === InternalExternalEventType.Internal ||
    targetGroups.participation_types.length === 0
  ) {
    return (
      <>
        <TargetGroupsDontMatchPopover
          openWrongTargetGroup={openWrongTargetGroup}
          anchorElWrongTargetGroup={anchorElWrongTargetGroup}
          handlePopoverCloseWrongTargetGroup={() => handlePopoverCloseWrongTargetGroup()}
          book_for_me={isBookedForMe}
        />
        <Menu
          id="bookingFor-menu"
          anchorEl={bookingForAnchorEl}
          keepMounted
          open={Boolean(bookingForAnchorEl)}
          onClose={handleBookingForClose}
        >
          <Box
            component={"div"}
            display={"flex"}
            alignItems={"center"}
            onMouseEnter={(event) => {
              setIsBookedForMe(true);
              return !checkTargetGroupMatch(currentUser)
                ? handlePopoverOpenWrongTargetGroup(event)
                : undefined;
            }}
            onMouseLeave={
              !checkTargetGroupMatch(currentUser)
                ? handlePopoverCloseWrongTargetGroup
                : undefined
            }
          >
            <MenuItem
              style={{
                width:
                  !isAllowedToBook(true) &&
                  props.eventData.participation_status !== ParticipationStatus.Booked
                    ? "auto"
                    : "100%",
              }}
              key={"book-for-me-menu-item"}
              disabled={
                !checkTargetGroupMatch(currentUser) ||
                props.eventData.participation_status === ParticipationStatus.Booked ||
                props.eventData.participation_status ===
                  ParticipationStatus.WaitingList ||
                isInCart(props.eventData.id) ||
                !isAllowedToBook(true)
              }
              onClick={(event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
                if (eventIsFullyBooked) {
                  handleBookingForClose();
                  if (currentUser) {
                    dispatch(putParticipantToWaitingList(props.eventData.id));
                  }
                } else {
                  handleBookingForClose();
                  if (currentUser) {
                    dispatch(addEventToCart(props.eventData.id, currentUser.id));
                  }
                }
              }}
            >
              {props.eventData.participation_status === ParticipationStatus.Booked
                ? "bereits für mich gebucht"
                : props.eventData.participation_status ===
                    ParticipationStatus.WaitingList
                  ? "bereits auf meiner Warteliste"
                  : isInCart(props.eventData.id)
                    ? "bereits in meinem Warenkorb"
                    : !checkTargetGroupMatch(currentUser)
                      ? "für mich*"
                      : "für mich"}
            </MenuItem>
            {!isAllowedToBook(true) &&
              props.eventData.participation_status !== ParticipationStatus.Booked && (
                <Tooltip
                  placement="right"
                  enterDelay={500}
                  leaveDelay={200}
                  title={
                    "Sie können nicht gleichzeitig für mehrere Nutzer buchen. Führen Sie zuerst den Buchungsvorgang durch oder leeren Sie den Warenkorb."
                  }
                  arrow
                >
                  <Info color="primary" style={{ cursor: "pointer" }} />
                </Tooltip>
              )}
          </Box>
          {/* TODO: maybe do the filering of the linked status in directly in backend, so only the linked accounts are being received instead of both linked and requested accounts */}
          {linkedAccountsLength > 0 &&
            linkAccount.linkAccountList
              .filter((x) => x.status === LinkAccountStatus.Linked)
              .map((linkedUserAccount, index) => {
                let statusText = `für ${linkedUserAccount.first_name} ${linkedUserAccount.last_name}`;
                switch (
                  props.eventData.participation_status_linked_accounts[index].status
                ) {
                  case ParticipationStatus.Booked:
                    statusText = `${linkedUserAccount.first_name} ${linkedUserAccount.last_name} nimmt bereits teil`;
                    break;
                  case ParticipationStatus.WaitingList:
                    statusText = `bereits auf der Warteliste von ${linkedUserAccount.first_name} ${linkedUserAccount.last_name}`;
                    break;
                  case ParticipationStatus.Participated:
                    statusText = `${linkedUserAccount.first_name} ${linkedUserAccount.last_name} hat bereits teilgenommen`;
                    break;
                  default:
                    if (isInCart(props.eventData.id, false, linkedUserAccount)) {
                      statusText = `bereits im Warenkorb von ${linkedUserAccount.first_name} ${linkedUserAccount.last_name}`;
                    } else if (!checkTargetGroupMatch(linkedUserAccount)) {
                      statusText = `für ${linkedUserAccount.first_name} ${linkedUserAccount.last_name}*`;
                    }
                }
                return (
                  <Box
                    key={"linked-account-menu-item-" + index}
                    component={"div"}
                    display={"flex"}
                    alignItems={"center"}
                    onMouseEnter={(event) => {
                      setIsBookedForMe(false);

                      return !checkTargetGroupMatch(linkedUserAccount)
                        ? handlePopoverOpenWrongTargetGroup(event)
                        : undefined;
                    }}
                    onMouseLeave={
                      !checkTargetGroupMatch(linkedUserAccount)
                        ? handlePopoverCloseWrongTargetGroup
                        : undefined
                    }
                  >
                    <MenuItem
                      style={{
                        width:
                          !isAllowedToBook(false, linkedUserAccount) &&
                          props.eventData.participation_status_linked_accounts[index]
                            .status !== ParticipationStatus.Booked
                            ? "auto"
                            : "100%",
                      }}
                      disabled={
                        linkedAccountsLength > 0 &&
                        linkedUserAccount !== undefined &&
                        (!checkTargetGroupMatch(linkedUserAccount) ||
                          props.eventData.participation_status_linked_accounts[index]
                            .status === ParticipationStatus.Booked ||
                          props.eventData.participation_status_linked_accounts[index]
                            .status === ParticipationStatus.WaitingList ||
                          isInCart(props.eventData.id, false, linkedUserAccount) ||
                          !isAllowedToBook(false, linkedUserAccount))
                      }
                      onClick={(event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
                        if (eventIsFullyBooked) {
                          handleBookingForClose();
                          dispatch(
                            putOtherParticipantToWaitingList(
                              props.eventData.id,
                              linkedUserAccount.id
                            )
                          );
                        } else {
                          handleBookingForClose();
                          dispatch(
                            addEventToCart(props.eventData.id, linkedUserAccount.id)
                          );
                        }
                      }}
                    >
                      {statusText}
                    </MenuItem>
                    {!isAllowedToBook(false, linkedUserAccount) &&
                      props.eventData.participation_status_linked_accounts[index]
                        .status !== ParticipationStatus.Booked && (
                        <Tooltip
                          placement="right"
                          enterDelay={500}
                          leaveDelay={200}
                          title={
                            "Sie können nicht gleichzeitig für mehrere Nutzer buchen. Führen Sie zuerst den Buchungsvorgang durch oder leeren Sie den Warenkorb."
                          }
                          arrow
                        >
                          <Info style={{ cursor: "pointer" }} color="primary" />
                        </Tooltip>
                      )}
                  </Box>
                );
              })}
        </Menu>
        <Box
          component={"div"}
          onMouseEnter={(event) =>
            linkedAccountsLength === 0 && !checkTargetGroupMatch(currentUser)
              ? handlePopoverOpenWrongTargetGroup(event)
              : undefined
          }
          onMouseLeave={
            linkedAccountsLength === 0 && !checkTargetGroupMatch(currentUser)
              ? handlePopoverCloseWrongTargetGroup
              : undefined
          }
        >
          {(!currentUser || currentUser?.user_type !== UserType.TestParticipant) && (
            <EventItemBookingButton
              id={setButtonName(props)}
              disabled={
                (linkAccount.linkAccountList.length === 0 &&
                  !checkTargetGroupMatch(currentUser)) ||
                props.eventData.status === EventStatus.Cancelled
              }
              color={eventIsFullyBooked ? "secondary" : "primary"}
              text={
                props.eventData.status === EventStatus.Cancelled
                  ? t("status.cancelled", { ns: "events" })
                  : eventIsFullyBooked
                    ? t("status.waiting", { ns: "events" })
                    : t("eventBooking.bookNow", { ns: "events" })
              }
              action={(event) => {
                if (!auth.loggedIn) {
                  history.push(routes.login);
                } else {
                  if (eventIsFullyBooked) {
                    if (linkedAccountsLength !== 0) {
                      handleBookingForClick(event);
                    } else if (currentUser) {
                      dispatch(putParticipantToWaitingList(props.eventData.id));
                    }
                  } else {
                    if (linkedAccountsLength !== 0) {
                      handleBookingForClick(event);
                    } else if (currentUser) {
                      dispatch(addEventToCart(props.eventData.id, currentUser.id));
                    }
                  }
                }
              }}
            />
          )}
        </Box>
      </>
    );
  } else {
    return <></>;
  }
};

export default EventItemRenderButton;
