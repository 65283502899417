import { makeStyles, Paper, Tab, Tabs, Theme, Tooltip } from "@material-ui/core";
import * as React from "react";
import { ReactNode } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux";
import { setOpenTab } from "../../../redux/tabs/actions";
import TabPanel from "./TabPanel";

interface TabsLumosProps {
  tabs: TabLumos[];
  openTabType?: string;
  onTabChange?: () => void;
}

interface TabLumos {
  content: ReactNode;
  label: string;
  disabled?: boolean;
  disabledTooltip?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: "max-content", // TODO: for now only make the tabs header as wide as the content within
  },
}));

const TabsLumos: React.FC<TabsLumosProps> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const openTab = useSelector((state: AppState) => state.tabs.openTab);

  const [value, setValue] = React.useState(openTab);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    if (props.onTabChange) props.onTabChange();
    if (props.openTabType === "templates") {
      dispatch(setOpenTab(newValue));
    }
    setValue(newValue);
  };

  return (
    <>
      <Paper className={classes.root} square elevation={0} variant="outlined">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          {props.tabs.map((tab, index) => {
            return (
              <Tab
                style={{ pointerEvents: "auto" }}
                key={tab.label}
                disabled={tab.disabled}
                label={
                  tab.disabled && tab.disabledTooltip ? (
                    <Tooltip arrow title={tab.disabledTooltip}>
                      <span>{tab.label}</span>
                    </Tooltip>
                  ) : (
                    tab.label
                  )
                }
                id={`tab-${index}`}
              />
            );
          })}
        </Tabs>
      </Paper>
      {props.tabs.map((tab, index) => {
        return (
          <TabPanel key={index} value={value} index={index}>
            {tab.content}
          </TabPanel>
        );
      })}
    </>
  );
};

export default TabsLumos;
