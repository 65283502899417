import { CartData, CartItemData } from "../../models/cartData";

export interface CartState {
  cart: CartData;
  cartInitialLoaded: boolean;
  isLoading: boolean;
}

export const CART_REQUEST = "CART_REQUEST";
export const CART_FAILURE = "CART_FAILURE";

export const SET_CATERING_OPTION = "SET_CATERING_OPTION";

export const CLEAR_CART = "CLEAR_CART";
export const SAVE_CART = "SAVE_CART";
export const SAVE_CART_ITEM = "SAVE_CART_ITEM";

// actions that are the same for all api calls (request and failure)
export interface CartRequestAction {
  type: typeof CART_REQUEST;
}

export interface CartFailureAction {
  type: typeof CART_FAILURE;
}

export interface SetCateringOptionAction {
  type: typeof SET_CATERING_OPTION;
  cartId: string;
  cateringOption: number;
}

export interface ClearCartAction {
  type: typeof CLEAR_CART;
}

export interface SaveCartAction {
  type: typeof SAVE_CART;
  data: CartData;
}

export interface SaveCartItemAction {
  type: typeof SAVE_CART_ITEM;
  data: CartItemData[];
}

export type CartActionTypes =
  | CartRequestAction
  | CartFailureAction
  | SetCateringOptionAction
  | ClearCartAction
  | SaveCartAction
  | SaveCartItemAction;
