/**
 * Dimensions for the residence permit photo.
 */
export const residencePermitPhotoDimensions = {
  x: 15,
  y: 7,
  w: 33,
  h: 43,
};

/**
 * Dimensions for the passport photo.
 */
export const passPhotoDimensions = {
  x: 15,
  y: 7,
  w: 33,
  h: 43,
};

/**
 * Dimensions for the ID card photo.
 */
export const cardIdPhotoDimensions = {
  x: 15,
  y: 7,
  w: 33,
  h: 43,
};

/**
 * Dimensions for the ID card.
 */
export const cardIdDimensions = {
  x: 85.6,
  y: 53.98,
};
