import {
  LOAD_PAYMENT_METHOD_DATA_SUCCESS,
  PaymentMethodActionTypes,
  PaymentMethodState,
  PAYMENT_METHOD_FAILURE,
  PAYMENT_METHOD_REQUEST,
  SET_PAYMENT_METHODS_LOADED,
} from "./types";

export const initialPaymentMethodState: PaymentMethodState = {
  paymentMethods: [],
  isLoading: false,
  paymentMethodsLoaded: false,
  isError: false,
};

export function paymentMethodReducer(
  state = initialPaymentMethodState,
  action: PaymentMethodActionTypes
): PaymentMethodState {
  switch (action.type) {
    case PAYMENT_METHOD_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case LOAD_PAYMENT_METHOD_DATA_SUCCESS:
      return {
        ...state,
        paymentMethods: action.data,
        isLoading: false,
        paymentMethodsLoaded: true,
      };
    case PAYMENT_METHOD_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    case SET_PAYMENT_METHODS_LOADED:
      return {
        ...state,
        paymentMethodsLoaded: true,
        isLoading: false,
      };
    default:
      return state;
  }
}

export default paymentMethodReducer;
