import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { enqueueSnackbar } from "notistack";
import {
  Box,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Typography,
} from "@material-ui/core";
import { MembershipInviteFormData } from "../../../../hooks/useCsvInviteValidation";
import { OrganizationMembershipInviteData } from "../../../../../models/organizationMembershipData";
import {
  createMembershipInvite,
  createBulkMembershipInvite,
} from "../../../../../redux/organizationMembership/action";

interface CsvBulkUploadProps {
  invitations: MembershipInviteFormData[];
  setValidInvitations: (invitations: MembershipInviteFormData[]) => void;
}

/**
 * Component for displaying a table of bulk membership invitations.
 * @param {CsvBulkUploadProps} props - The component props.
 * @returns {JSX.Element} - The rendered component.
 */
const BulkMembershipOverviewList: React.FC<CsvBulkUploadProps> = (props) => {
  const { t } = useTranslation(["common", "organization"]);
  const { invitations, setValidInvitations } = props;
  const dispatch = useDispatch();

  /**
   * Handles the deletion of a membership invitation.
   * @param {number} index - The index of the invitation to delete.
   * @returns {void}
   */
  const handleDeleteInvitation = (index: number) => {
    const updatedInvitations = [...invitations];
    updatedInvitations.splice(index, 1);
    setValidInvitations(updatedInvitations);
  };

  /**
   * Handles inviting a user to membership.
   * @param {MembershipInviteFormData} values - The form data containing user information.
   * @param {number} index - The index of the invitation.
   * @returns {void}
   */
  const handleInvite = async (values: MembershipInviteFormData, index: number) => {
    console.log(values);
    try {
      const data: OrganizationMembershipInviteData = {
        first_name: values?.first_name,
        last_name: values?.last_name,
        email: values?.email,
        organization_id: values?.organization_id,
      };
      const success = await dispatch(createMembershipInvite(data));
      // @ts-ignore
      if (success) {
        const updatedInvitations = [...invitations];
        updatedInvitations[index].status = "pending";
        setValidInvitations(updatedInvitations);
      } else {
        const updatedInvitations = [...invitations];
        updatedInvitations[index].status = "active";
        setValidInvitations(updatedInvitations);
      }
    } catch (error) {
      enqueueSnackbar(error, { variant: "error" });
    }
  };

  /**
   * Handles bulk inviting users to membership.
   * @param {OrganizationMembershipInviteData[]} invitations - The list of invitations to process in bulk.
   * @returns {void}
   */
  const handleBulkInvite = async (invitations: OrganizationMembershipInviteData[]) => {
    try {
      const results = await Promise.all(
        invitations.map(async (invitation, index) => {
          try {
            const success = await dispatch(createBulkMembershipInvite(invitation));
            return { index, success };
          } catch (error) {
            return { index, success: false, error };
          }
        })
      );

      const updatedInvitations = [...invitations];
      results.forEach(({ index, success }) => {
        updatedInvitations[index].status = success ? "pending" : "active";
      });
      setValidInvitations(updatedInvitations);
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  return (
    <>
      <Box style={{ display: "flex", justifyContent: "flex-end" }}>
        {invitations && (
          <Button
            size="large"
            variant="outlined"
            color="primary"
            onClick={() => setValidInvitations([])}
            style={{ marginRight: "1rem" }}
          >
            {t("membership.clearList", { ns: "organization" })}
          </Button>
        )}
        <Button
          size="large"
          variant="contained"
          color="primary"
          onClick={() => handleBulkInvite(invitations)}
        >
          {t("membership.inviteAll", { ns: "organization" })}
        </Button>
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t("profile.firstName", { ns: "common" })}</TableCell>
            <TableCell>{t("profile.lastName", { ns: "common" })}</TableCell>
            <TableCell>{t("profile.mail", { ns: "common" })}</TableCell>
            <TableCell>{t("membership.status", { ns: "organization" })}</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invitations.map((invitation, index) => {
            return (
              <TableRow key={index}>
                <TableCell>{invitation?.first_name}</TableCell>
                <TableCell>{invitation?.last_name}</TableCell>
                <TableCell>{invitation?.email}</TableCell>
                <TableCell style={{ maxWidth: "8rem" }}>
                  {invitation?.status ? (
                    invitation.status === "active" ? (
                      <Typography>
                        {t("membership.alreadyMember", { ns: "organization" })}
                      </Typography>
                    ) : (
                      <Typography style={{ fontWeight: 600 }}>
                        {t("membership.invited", { ns: "organization" })}
                      </Typography>
                    )
                  ) : (
                    <Button
                      size="large"
                      variant="contained"
                      color="primary"
                      onClick={() => handleInvite(invitation, index)}
                      style={{ width: "100%" }}
                    >
                      {t("membership.invite", { ns: "organization" })}
                    </Button>
                  )}
                </TableCell>
                {!invitation?.status && (
                  <TableCell>
                    <Button
                      size="large"
                      variant="outlined"
                      color="primary"
                      onClick={() => handleDeleteInvitation(index)}
                    >
                      {t("delete", { ns: "common" })}
                    </Button>
                  </TableCell>
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};

export default BulkMembershipOverviewList;
