import { Box, Collapse, Divider, Grid, Link } from "@material-ui/core";
import DOMPurify from "dompurify";
import * as React from "react";
import { CustomSelectArrow } from "../../../../assets/icons/custom-svg-components/LumosSvgCollection";
import { EventType } from "../../../../models/enums/eventType.enum";
import { Utils } from "../../../../services/utils";

import "../../../../pages/core/event/UserEventsPage.scss";

import { LecturesData } from "../../../../models/lecturesData";

interface MyLecturesBasicInformationProps {
  lecture: LecturesData;
}
const MyLecturesBasicInformation: React.FC<MyLecturesBasicInformationProps> = (
  props
) => {
  const [checked, setChecked] = React.useState(false);
  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  return (
    <Grid container spacing={2} style={{ marginTop: "1.25rem" }}>
      <Grid item xs={12} sm={6}>
        <Grid container spacing={0}>
          <Grid
            item
            xs={6}
            className={
              "myevents-details-property-label myevents-details-property-container"
            }
          >
            Veranstaltungsnummer
          </Grid>
          <Grid item xs={6} className={"myevents-details-property-container"}>
            {props.lecture.public_id}
          </Grid>
          <Grid
            item
            xs={6}
            className={
              "myevents-details-property-label myevents-details-property-container"
            }
          >
            Preis
          </Grid>
          <Grid item xs={6} className={"myevents-details-property-container"}>
            {Utils.formatPrice(props.lecture.price)}
          </Grid>
          <Grid
            item
            xs={6}
            className={
              "myevents-details-property-label myevents-details-property-container"
            }
          >
            Punkte
          </Grid>
          <Grid item xs={6} className={"myevents-details-property-container"}>
            {props.lecture.score}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={5}>
        <Grid container spacing={0}>
          <Grid
            item
            xs={6}
            className={
              "myevents-details-property-label myevents-details-property-container"
            }
          >
            Startzeit
          </Grid>
          <Grid item xs={6} className={"myevents-details-property-container"}>
            {new Date(props.lecture.begin).toLocaleDateString()} (
            {new Date(props.lecture.begin).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            })}{" "}
            Uhr)
          </Grid>
          <Grid
            item
            xs={6}
            className={
              "myevents-details-property-label myevents-details-property-container"
            }
          >
            Endzeit
          </Grid>
          <Grid item xs={6} className={"myevents-details-property-container"}>
            {new Date(props.lecture.end).toLocaleDateString()} (
            {new Date(props.lecture.end).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            })}{" "}
            Uhr)
          </Grid>
          <Grid
            item
            xs={6}
            className={
              "myevents-details-property-label myevents-details-property-container"
            }
          >
            Veranstaltungsort
          </Grid>
          <Grid item xs={6} className={"myevents-details-property-container"}>
            {props.lecture.location}
          </Grid>
          <Grid
            item
            xs={6}
            className={
              "myevents-details-property-label myevents-details-property-container"
            }
          >
            {props.lecture.event_type === EventType.Online ? "Videoraum" : "Adresse"}
          </Grid>
          <Grid item xs={6} className={"myevents-details-property-container"}>
            {props.lecture.location_details}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider />
        <Box component={"div"} className={"myevents-details-headline"}>
          Beschreibung
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Collapse in={checked} collapsedSize={90}>
          <Box
            className={"myevents-description"}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(
                Utils.convertRteStateToHtml(
                  Utils.convertJsonToRteState(props.lecture.description)
                )
              ),
            }}
          />
        </Collapse>
        <Grid container>
          <Grid item xs={12}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              style={{ marginTop: "0.62rem" }}
            >
              <Link onClick={handleChange} className={"event-item-details-icon"}>
                {checked ? (
                  <CustomSelectArrow className={"event-item-shrink-icon"} />
                ) : (
                  <CustomSelectArrow className={"event-item-expand-icon"} />
                )}
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MyLecturesBasicInformation;

// TODO no translation yet
