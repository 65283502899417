import apiRoutes from "./apiRoutes";
import { BaseCrudService } from "./baseCrudService";
import { LoggerData } from "../models/loggerData";

export class LoggerService extends BaseCrudService {
  async addLog(message: string, log_level: string) {
    const response = await this.create<LoggerData>(apiRoutes.frontend_logger, {
      message: message,
      log_level: log_level,
    });
    return response;
  }
}
