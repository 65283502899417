import { CaptureMetaData } from "../../components/core/events/shared/authentication/Instructions";

export interface VideoAuthenticationState {
  isAuthenticated: boolean;
  captures: string[];
  documentCaptures: string[];
  error?: string;
  forElearning: string | null;
  metaData?: CaptureMetaData;
  authenticationProcessCompleted?: boolean;
}

export const AUTHENTICATION_SUCCESS = "AuthenticationSuccess";
export const AUTHENTICATION_FAILURE = "AuthenticationFailure";
export const AUTHENTICATION_RESET = "AuthenticationReset";
export const AUTHENTICATION_ERROR = "AuthenticationError";
export const AUTHENTICATION_ADD_CAPTURE = "AuthenticationAddCapture";
export const AUTHENTICATION_ADD_PASS_CAPTURE = "AuthenticationAddPassCapture";
export const AUTHENTICATION_REMOVE_CAPTURE = "AuthenticationRemoveCapture";
export const AUTHENTICATION_REMOVE_PASS_CAPTURE = "AuthenticationRemovePassCapture";
export const SET_CAPTURE_META = "AuthenticationSetCaptureMeta";
export const SET_PARTICIPANT_CAPTURE_RECT = "SET_PARTICIPANT_CAPTURE_RECT";
export const SET_DOCUMENT_CAPTURE_RECT = "SET_DOCUMENT_CAPTURE_RECT";

interface AuthenticationSuccessAction {
  type: typeof AUTHENTICATION_SUCCESS;
  forElearning: string;
}

interface AuthenticationFailureAction {
  type: typeof AUTHENTICATION_FAILURE;
  forElearning: string;
}

interface AuthenticationResetAction {
  type: typeof AUTHENTICATION_RESET;
}

interface AuthenticationErrorAction {
  type: typeof AUTHENTICATION_ERROR;
  error: string;
}

interface AuthenticationAddCaptureAction {
  type: typeof AUTHENTICATION_ADD_CAPTURE;
  capture: string;
}

interface AuthenticationRemoveCaptureAction {
  type: typeof AUTHENTICATION_REMOVE_CAPTURE;
  capture: string;
}

interface AuthenticationAddPassCaptureAction {
  type: typeof AUTHENTICATION_ADD_PASS_CAPTURE;
  capture: string;
}

interface AuthenticationRemovePassCaptureAction {
  type: typeof AUTHENTICATION_REMOVE_PASS_CAPTURE;
  capture: string;
}

interface AuthenticationSetCaptureMeta {
  type: typeof SET_CAPTURE_META;
  metaData: CaptureMetaData;
}
interface AuthenticationSetParticipantCaptureRect {
  type: typeof SET_PARTICIPANT_CAPTURE_RECT;
  x: number;
  y: number;
  width: number;
  height: number;
}
interface AuthenticationSetDocumentCaptureRect {
  type: typeof SET_DOCUMENT_CAPTURE_RECT;
  x: number;
  y: number;
  width: number;
  height: number;
}
export type VideoAuthResponseActionTypes =
  | AuthenticationSuccessAction
  | AuthenticationFailureAction
  | AuthenticationErrorAction;

export type VideoAuthActionTypes =
  | VideoAuthResponseActionTypes
  | AuthenticationResetAction
  | AuthenticationAddCaptureAction
  | AuthenticationRemoveCaptureAction
  | AuthenticationAddPassCaptureAction
  | AuthenticationRemovePassCaptureAction
  | AuthenticationSetCaptureMeta
  | AuthenticationSetParticipantCaptureRect
  | AuthenticationSetDocumentCaptureRect;
