import { Pagination } from "../models/pagination";
import apiRoutes from "./apiRoutes";
import { BaseCrudService } from "./baseCrudService";
import { TaskData, EditTaskData, TaskUserData } from "../models/taskData";
import { TaskStatus } from "../models/enums/taskStatus.enum";
import { AxiosResponse } from "axios";

export class TasksService extends BaseCrudService {
  async getTasks(filter: string) {
    const response = await this.getAll<Pagination<TaskData>>(apiRoutes.tasks + filter);
    return response.data;
  }

  async getTask(id: string) {
    const response = await this.get<TaskData>(apiRoutes.tasks, id);
    return response.data;
  }

  async addTask(task: EditTaskData) {
    const response = await this.create<EditTaskData>(apiRoutes.tasks, task);
    return response;
  }

  async updateTask(task: EditTaskData) {
    const response = await this.update<EditTaskData>(
      apiRoutes.tasks,
      task.id.toString(),
      task
    );
    return response;
  }

  async editTaskStatus(id: string, status: TaskStatus) {
    const response = await this.edit(apiRoutes.tasks, id, {
      status: status,
    });
    return response;
  }

  // TODO: check any type
  async bulkEditResponsibility(ids: string[], userId: string) {
    const data = { responsibility: userId };
    return Promise.all(
      ids.map(async (id) => {
        return (await this.edit<any, AxiosResponse<TaskData>>(apiRoutes.tasks, id, data))
          .data;
      })
    );
  }

  async bulkEditStatus(ids: string[], status: TaskStatus) {
    const data = { status: status };
    return Promise.all(
      ids.map(async (id) => {
        return (await this.edit<any, AxiosResponse<TaskData>>(apiRoutes.tasks, id, data))
          .data;
      })
    );
  }

  async bulkDelete(ids: string[]) {
    return Promise.all(
      ids.map(async (id) => {
        return (await this.delete<TaskData>(apiRoutes.tasks, id.toString())).data;
      })
    );
  }

  async getFilterUsers() {
    const response = await this.getAll<TaskUserData[]>(apiRoutes.tasks_filter_users);
    return response.data;
  }

  async deleteTask(id: string) {
    const response = await this.delete<TaskData>(apiRoutes.tasks, id);
    return response;
  }
}
