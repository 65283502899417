import {
  ADD_DEFAULT_DOCUMENTS_LIST,
  ADD_DOCUMENTS_LIST,
  CHANGE_DOCUMENT_PUBLISH_STATE,
  CLEAR_DOCUMENTS,
  DOCUMENTS_FAILURE,
  DOCUMENTS_REQUEST,
  DocumentsActionTypes,
  DocumentsState,
  REMOVE_DOCUMENTS_LIST,
  SET_DOCUMENTS_LOADED,
  SET_DOCUMENTS_LIST,
  MARK_DOCUMENT_FOR_DELETE,
} from "./types";
import { DocumentData } from "../../models/documentData";

export const initialDocumentsState: DocumentsState = {
  documentList: [],
  documentListTemp: [],
  documentListLoaded: false,
  isLoading: false,
  documentsMarkedForDelete: [],
};

function addDocumentToList(
  documentListForEvent: DocumentData[],
  document: DocumentData
) {
  // ! NOTE: always use copy and don't work on state directly to prevent nasty side effects
  const copiedDocumentsList = [...documentListForEvent];
  copiedDocumentsList.push(document);
  return copiedDocumentsList;
}

function removeDocumentFromList(
  documentListForEvent: DocumentData[],
  document: DocumentData
) {
  // ! NOTE: always use copy and don't work on state directly to prevent nasty side effects
  const copiedDocumentsList = [...documentListForEvent];
  let index = copiedDocumentsList.indexOf(document);
  copiedDocumentsList.splice(index, 1);
  return copiedDocumentsList;
}

function changeDocumentPublishState(
  documentListForEvent: DocumentData[],
  document: DocumentData
) {
  // ! NOTE: always use copy and don't work on state directly to prevent nasty side effects
  const copiedDocumentsList = [...documentListForEvent];
  let index = copiedDocumentsList.indexOf(document);
  copiedDocumentsList[index] = document;
  return copiedDocumentsList;
}

function addDefaultDocumentToList(
  documentListForEvent: DocumentData[],
  defaultDocumentList: DocumentData[],
  defaultType: string
) {
  // ! NOTE: always use copy and don't work on state directly to prevent nasty side effects
  const copiedDocumentsList = [...documentListForEvent];
  let clearedDocumentsList = copiedDocumentsList.filter((document) => {
    return !(document?.is_default_on_site || document?.is_default_online);
  });
  const combinedDocuments = defaultDocumentList.concat(clearedDocumentsList);
  return combinedDocuments;
}

export function documentsReducer(
  state = initialDocumentsState,
  action: DocumentsActionTypes
): DocumentsState {
  switch (action.type) {
    case DOCUMENTS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case DOCUMENTS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case ADD_DOCUMENTS_LIST:
      return {
        ...state,
        documentList: [...addDocumentToList(state.documentList, action.data)],
        documentListTemp: [...addDocumentToList(state.documentListTemp, action.data)],
        isLoading: false,
      };
    case SET_DOCUMENTS_LIST:
      return {
        ...state,
        documentList: action.data,
        isLoading: false,
      };
    case ADD_DEFAULT_DOCUMENTS_LIST:
      return {
        ...state,
        documentList: [
          ...addDefaultDocumentToList(
            state.documentList,
            action.data,
            action.defaultType
          ),
        ],
        isLoading: false,
      };
    case REMOVE_DOCUMENTS_LIST:
      return {
        ...state,
        documentList: [...removeDocumentFromList(state.documentList, action.data)],
        documentListTemp: [
          ...removeDocumentFromList(state.documentListTemp, action.data),
        ],
        isLoading: false,
      };
    case CHANGE_DOCUMENT_PUBLISH_STATE:
      return {
        ...state,
        documentList: [...changeDocumentPublishState(state.documentList, action.data)],
        isLoading: false,
      };
    case SET_DOCUMENTS_LOADED:
      return {
        ...state,
        documentListLoaded: true,
        isLoading: false,
      };
    case CLEAR_DOCUMENTS:
      return initialDocumentsState;
    case MARK_DOCUMENT_FOR_DELETE:
      return {
        ...state,
        documentsMarkedForDelete: state.documentsMarkedForDelete.concat([
          action.document,
        ]),
        isLoading: false,
      };
    default:
      return state;
  }
}

export default documentsReducer;
