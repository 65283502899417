import { faEdit, faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Card, CardContent, Fade, Tooltip } from "@material-ui/core";
import { useState } from "react";

import { useSelector } from "react-redux";
import {
  mapToPopupTitle,
  PinboardPopupType,
} from "../../../../../../models/enums/pinboardType.enum";
import { UserType } from "../../../../../../models/enums/userType.enum";
import {
  PinboardCommentsListData,
  PinboardListData,
} from "../../../../../../models/pinboardData";
import { AppState } from "../../../../../../redux";
import useMetaData, { formatDateInfo } from "../../../../../hooks/usePinboardMetaData";
import "../../../../../../pages/core/pinboard/Pinboard.scss";
import PinboardPopup from "./PinboardPopup";
import PinboardText from "./PinboardText";
import LockIcon from "@material-ui/icons/Lock";
import styles from "./../../../../../../styles/custom-styles.module.scss";

interface IPinboardCommentProps {
  index: number;
  comment: PinboardCommentsListData;
  question: PinboardListData;
  close: () => void;
}

const PinboardComment: React.FunctionComponent<IPinboardCommentProps> = (props) => {
  const accessibility = useSelector((state: AppState) => state.accessibility);
  const currentUser = useSelector((state: AppState) => state.user.currentUser);
  const [popupType, setPopupType] = useState(PinboardPopupType.UpdateAnswer);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
    props.close();
  };
  const isSelf = props.comment.created_by === currentUser?.id;

  return (
    <Fade in={true}>
      <Box display={"flex"}>
        {isSelf && (
          <Box
            style={{
              backgroundColor: !accessibility.monoMode ? styles.berry : "#000",
              width: "0.62rem",
              height: "4.68rem",
              margin: "1.25rem",
              marginRight: "-1.75rem",
            }}
          ></Box>
        )}
        <Card
          className={accessibility.monoMode ? "pinboard-card-mono" : "pinboard-card"}
          style={{
            marginRight: isSelf ? "0rem" : undefined,
            marginLeft: isSelf ? "1.87rem" : undefined,
          }}
        >
          <CardContent
            style={{
              backgroundColor:
                props.comment.is_archived && !accessibility.monoMode
                  ? styles["secondary-accent-2"]
                  : "#fff",
            }}
          >
            <Box style={{ direction: props.index % 2 === 0 ? "rtl" : "ltr" }}>
              {currentUser &&
                [UserType.Employee, UserType.Apprentice].includes(
                  currentUser.user_type
                ) && (
                  <Box display="flex">
                    <Tooltip
                      title={mapToPopupTitle(
                        props.comment.is_archived
                          ? PinboardPopupType.UnarchiveAnswer
                          : PinboardPopupType.ArchiveAnswer
                      )}
                      arrow
                    >
                      <Button
                        onClick={() => {
                          setPopupType(
                            props.comment.is_archived
                              ? PinboardPopupType.UnarchiveAnswer
                              : PinboardPopupType.ArchiveAnswer
                          );
                          setOpen(true);
                        }}
                        className={
                          accessibility.monoMode
                            ? "pinboard-sub-button-mono"
                            : "pinboard-sub-button"
                        }
                        style={{
                          backgroundColor:
                            accessibility.monoMode && props.comment.is_archived
                              ? "#000"
                              : undefined,
                        }}
                      >
                        <FontAwesomeIcon
                          icon={props.comment.is_archived ? faPlusCircle : faMinusCircle}
                          color={
                            accessibility.monoMode
                              ? props.comment.is_archived
                                ? "#fff"
                                : "#000"
                              : "var(--gray-2)"
                          }
                        />
                      </Button>
                    </Tooltip>
                    {props.comment.is_archived && (
                      <Box display={"flex"} alignItems={"center"}>
                        <LockIcon
                          color="secondary"
                          fontSize="small"
                          style={{
                            marginTop: "-0.12rem",
                            marginRight: props.index % 2 !== 0 ? "0.1em" : undefined,
                            marginLeft: props.index % 2 === 0 ? "0.1em" : undefined,
                          }}
                        />
                        Archiviert am{" "}
                        {formatDateInfo(new Date(props.comment.last_changed_on))}
                      </Box>
                    )}
                  </Box>
                )}
              {currentUser && currentUser?.id === props.comment.created_by && (
                <Tooltip title={mapToPopupTitle(PinboardPopupType.UpdateAnswer)} arrow>
                  <Button
                    onClick={() => setOpen(true)}
                    className={
                      accessibility.monoMode
                        ? "pinboard-sub-button-mono"
                        : "pinboard-sub-button"
                    }
                  >
                    <FontAwesomeIcon
                      icon={faEdit}
                      color={accessibility.monoMode ? "#000" : "var(--gray-2)"}
                    />
                  </Button>
                </Tooltip>
              )}
            </Box>
            <PinboardPopup
              question={props.question}
              open={open}
              close={handleClose}
              popupType={popupType}
              answer={props.comment}
            />
            <Box
              style={{
                direction: props.index % 2 === 0 ? "ltr" : "rtl",
              }}
              className={
                (accessibility.monoMode ? "pinboard-muted-mono " : "pinboard-muted ") +
                (isSelf ? "pinboard-self" : "")
              }
            >
              {useMetaData("Antwort", props.comment, isSelf)}
            </Box>
            <PinboardText
              content={props.comment.answer}
              float={props.index % 2 === 0 ? "left" : "right"}
            />
          </CardContent>
        </Card>
      </Box>
    </Fade>
  );
};

export default PinboardComment;
