import { Pagination } from "../../../models/pagination";
import { EventTemplateData } from "../../../models/templateData";

export interface EventTemplatesState {
  eventTemplateList: Pagination<EventTemplateData>;
  eventTemplate: EventTemplateData;
  eventListLoaded: boolean;
  eventTemplateLoaded: boolean;
  eventTemplateMode: boolean;
  currentPage: number;
  isLoading: boolean;
  error: boolean;
  notFoundError: boolean;
}

// general actions
export const EVENT_TEMPLATE_REQUEST = "EVENT_TEMPLATE_REQUEST";
export const EVENT_TEMPLATE_FAILURE = "EVENT_TEMPLATE_FAILURE";

// event Templates
export const SET_LIST_OF_EVENT_TEMPLATES_SUCCESS = "SET_LIST_OF_EVENT_TEMPLATES_SUCCESS";
export const SET_EVENT_TEMPLATE_SUCCESS = "SET_EVENT_TEMPLATE_SUCCESS";
export const ADD_OR_EDIT_EVENT_TEMPLATE_SUCCESS = "ADD_OR_EDIT_EVENT_TEMPLATE_SUCCESS";
export const SET_EVENT_TEMPLATE_MODE_AND_EVENT_TEMPLATE =
  "SET_EVENT_TEMPLATE_MODE_AND_EVENT_TEMPLATE";
export const DELETE_EVENT_TEMPLATE = "DELETE_EVENT_TEMPLATE";
export const SET_EVENT_TEMPLATE_PAGE_COUNT = "SET_EVENT_TEMPLATE_PAGE_COUNT";

export const CLEAR_EVENT_TEMPLATE = "CLEAR_EVENT_TEMPLATE";

// actions that are the same for all api calls (request and failure)
export interface TemplateRequestAction {
  type: typeof EVENT_TEMPLATE_REQUEST;
}

export interface TemplateFailureAction {
  type: typeof EVENT_TEMPLATE_FAILURE;
  notFoundError: boolean;
}

// other event actions
export interface SetListOfEventTemplatesSuccessAction {
  type: typeof SET_LIST_OF_EVENT_TEMPLATES_SUCCESS;
  data: Pagination<EventTemplateData>;
}

export interface SetEventTemplateSuccessAction {
  type: typeof SET_EVENT_TEMPLATE_SUCCESS;
  data: EventTemplateData;
}

export interface AddOrEditEventTemplateSuccessAction {
  type: typeof ADD_OR_EDIT_EVENT_TEMPLATE_SUCCESS;
  data: EventTemplateData;
}

export interface DeleteEventTemplateSuccessAction {
  type: typeof DELETE_EVENT_TEMPLATE;
  id: string;
}

export interface SetEventTemplatePageCountAction {
  type: typeof SET_EVENT_TEMPLATE_PAGE_COUNT;
  pageCount: number;
}

export interface SetEventTemplateModeAndEventTemplateAction {
  type: typeof SET_EVENT_TEMPLATE_MODE_AND_EVENT_TEMPLATE;
  data: EventTemplateData;
}

export interface EventTemplateClearAction {
  type: typeof CLEAR_EVENT_TEMPLATE;
}

export type EventTemplatesActionTypes =
  | TemplateRequestAction
  | TemplateFailureAction
  | SetListOfEventTemplatesSuccessAction
  | SetEventTemplateSuccessAction
  | AddOrEditEventTemplateSuccessAction
  | DeleteEventTemplateSuccessAction
  | SetEventTemplatePageCountAction
  | SetEventTemplateModeAndEventTemplateAction
  | EventTemplateClearAction;
