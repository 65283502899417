import { TargetGroupData } from "./targetGroupData";
export interface LinkAccountData extends TargetGroupData {
  id: string;
  first_name: string;
  last_name: string;
  lanr: string;
  status: LinkAccountStatus;
}

export enum LinkAccountStatus {
  Undefined = 0,
  Requested = 10,
  Linked = 20,
}

export function mapLinkStatusIdToLinkStatusString(status: number): string {
  switch (status) {
    case LinkAccountStatus.Requested:
      return "Angefragt";
    case LinkAccountStatus.Linked:
      return "Verknüpft";
    default:
      return "-";
  }
}
