import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import "./styles/main.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { CssBaseline } from "@material-ui/core";
import { Provider } from "react-redux";
import { store } from "./redux";
import CustomSnackbarProvider from "./components/theming/CustomSnackbarProvider";
import CustomThemeProvider from "./components/theming/CustomThemeProvider";
import "./localization/i18n";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <CustomThemeProvider>
        {/* Set a global css baseline that is similar to normalize.css (https://material-ui.com/components/css-baseline/) */}
        <CssBaseline />
        <CustomSnackbarProvider>
          <Suspense fallback={""}>
            <App />
          </Suspense>
        </CustomSnackbarProvider>
      </CustomThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
