import { AxiosError } from "axios";
import { Dispatch } from "redux";
import { ApiHelper } from "../../api/apiHelper";
import { bookingService } from "../../api/index";
import { ParticipationStatus } from "../../models/enums/participationStatus.enum";
import { IncrementWaitingListInEvent } from "../events/actions";
import { EventActionTypes } from "../events/types";
import { clearParticipant } from "../participants/actions";
import { ClearParticipantAction } from "../participants/types";
import {
  WaitingListActionTypes,
  WaitingListFailureAction,
  WaitingListRequestAction,
  WaitingListSuccessAction,
  WAITINGLIST_FAILURE,
  WAITINGLIST_REQUEST,
  WAITINGLIST_SUCCESS,
} from "./types";
import { enqueueSnackbar } from "notistack";

export function putParticipantToWaitingList(eventId: string) {
  return (dispatch: Dispatch<WaitingListActionTypes | EventActionTypes>) => {
    dispatch(waitingListRequest());
    bookingService
      .putOnWaitingList({ event: eventId, status: 10 })
      .then(() => {
        dispatch(waitingListSuccess());
        dispatch(IncrementWaitingListInEvent(eventId));
        enqueueSnackbar("Sie wurden erfolgreich auf die Warteliste gesetzt.", {
          variant: "success",
        });
      })
      .catch((err: AxiosError) => {
        dispatch(waitingListFailure());
        enqueueSnackbar(ApiHelper.showErrorMessageFromApi(err.response?.data), {
          variant: "error",
        });
      });
  };
}

export function putOtherParticipantToWaitingList(eventId: string, userId: string) {
  return (
    dispatch: Dispatch<
      WaitingListActionTypes | EventActionTypes | ClearParticipantAction
    >
  ) => {
    dispatch(waitingListRequest());
    bookingService
      .bookEventForOther({ event: eventId, status: 10 }, userId)
      .then(() => {
        dispatch(waitingListSuccess());
        dispatch(
          IncrementWaitingListInEvent(eventId, {
            user: userId,
            status: ParticipationStatus.WaitingList,
          })
        );
        dispatch(clearParticipant());
        enqueueSnackbar(
          "Der verknüpfte Teilnehmer wurde erfolgreich auf die Warteliste gesetzt.",
          { variant: "success" }
        );
      })
      .catch((err: AxiosError) => {
        dispatch(waitingListFailure());
        enqueueSnackbar(ApiHelper.showErrorMessageFromApi(err.response?.data), {
          variant: "error",
        });
      });
  };
}

export function waitingListRequest(): WaitingListRequestAction {
  return {
    type: WAITINGLIST_REQUEST,
  };
}

export function waitingListFailure(): WaitingListFailureAction {
  return {
    type: WAITINGLIST_FAILURE,
  };
}

export function waitingListSuccess(): WaitingListSuccessAction {
  return {
    type: WAITINGLIST_SUCCESS,
  };
}
