import { makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) => ({
  backdrop: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 999,
  },
  popup: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    padding: theme.spacing(4),
    zIndex: 1000,
    boxShadow: "0rem 0rem .625rem rgba(0, 0, 0, 0.1)",
    borderRadius: theme.shape.borderRadius,
    // Media queries for responsive width adjustment
    [theme.breakpoints.down("lg")]: {
      width: "40%",
    },
    [theme.breakpoints.down("md")]: {
      width: "60%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
  },
  buttonContainer: {
    paddingTop: "1.4rem",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: 50,
  },
  button: {
    width: "100%",
  },
  successText: {
    color: theme.palette.success.dark,
    fontSize: "1rem",
  },
  formGrid: {
    paddingLeft: ".7031rem",
    paddingRight: ".7031rem",
  },
  margin: {
    margin: theme.spacing(1),
  },
  loginForm: {
    marginTop: "1.25rem",
    "& > *": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      width: "100%",
    },
  },
  checkboxContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "0.6rem",
    marginTop: "0.8rem",
    marginBottom: "0.8rem",
  },
}));
