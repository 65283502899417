import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Link,
  makeStyles,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@material-ui/core";
import CommentIcon from "@material-ui/icons/Comment";
import { Pagination as PaginationUI } from "@material-ui/lab";
import { Form, Formik } from "formik";
import moment from "moment";
import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useHistory } from "react-router-dom";
import {
  CustomSelectArrow,
  LumosDateSvg,
  LumosEducationSvg,
} from "../../../assets/icons/custom-svg-components/LumosSvgCollection";
import {
  mapToTaskStatusString,
  TaskStatus,
} from "../../../models/enums/taskStatus.enum";
import { TrainingType } from "../../../models/enums/trainingType.enum";
import { PAGE_SIZE } from "../../../models/pagination";
import { TaskData, TaskFilterData } from "../../../models/taskData";
import { UserData } from "../../../models/userData";
import { AppState } from "../../../redux";
import { showDialog } from "../../../redux/dialogs/actions";
import { clearEditEvent } from "../../../redux/events/actions";
import { clearParticipant } from "../../../redux/participants/actions";
import {
  bulkDelete,
  bulkEditResponsibility,
  bulkEditStatus,
  clearTasks,
  deleteTask,
  getFilterUsers,
  setTasksPageCount,
  updateTaskStatus,
} from "../../../redux/tasks/actions";
import styles from "../../../styles/custom-styles.module.scss";
import CustomCheckbox from "../../forms/CustomCheckbox";
import routes, { getEditRouteBasedOnTrainingType } from "../../../routing/routes";
import CustomButton from "../../theming/CustomButton";
import HeadingLumos from "../../theming/HeadingLumos";
import TableLumos from "../../theming/table/TableLumos";
import TaskFilterForm, { taskFilterInitialValues } from "./TaskFilterForm";
import { NIL as NIL_UUID } from "uuid";

const useStyles = makeStyles({
  redText: {
    color: "#ff0000",
    padding: "0",
    whiteSpace: "nowrap",
  },
  defaultText: {
    padding: "0",
    whiteSpace: "nowrap",
  },
});

const MyTasks: React.FC = () => {
  const classes = useStyles();

  const history = useHistory();
  const dispatch = useDispatch();

  const tasks = useSelector((state: AppState) => state.tasks);
  const page = useSelector((state: AppState) => state.tasks.currentPage);
  const accessibility = useSelector((state: AppState) => state.accessibility);

  useEffect(() => {
    if (!tasks.isLoading && !tasks.filterUsersLoaded) {
      dispatch(getFilterUsers());
    }
  }, [tasks, dispatch]);

  const [statusSelectAnchorEl, setStatusSelectAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const [statusSelectTaskIds, setStatusSelectTaskIds] = useState([NIL_UUID]);
  const handleStatusSelectClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    taskIds: string[]
  ) => {
    setStatusSelectAnchorEl(event.currentTarget);
    setStatusSelectTaskIds(taskIds);
  };

  const handleStatusSelectClose = () => {
    setStatusSelectAnchorEl(null);
    setStatusSelectTaskIds([]);
  };

  // responsibility checkboxes
  const [selectedTasks, setSelectedTasks] = useState<TaskData[]>([]);
  const currentUser = useSelector((state: AppState) =>
    state.user.currentUser ? state.user.currentUser! : ({ id: NIL_UUID } as UserData)
  );

  const mapToTableRows = function () {
    const mappedTasks =
      tasks.tasksPage.page !== page
        ? []
        : tasks.tasksPage.results.map((task) => {
            let showWarning =
              task.due_date &&
              moment()
                .startOf("day")
                .diff(moment(task.due_date).startOf("day"), "days") > 0 &&
              task.status !== TaskStatus.Done;
            return {
              ...task, // copy the data (keep format) that should not be displayed differently
              trainingType: (
                <Box>
                  {task.event?.training_type !== TrainingType.DefaultEvent && (
                    <LumosEducationSvg strokeColor={styles.orange} fontSize={"1.4rem"} />
                  )}
                  {task.event?.training_type !== TrainingType.ELearning && (
                    <LumosDateSvg strokeColor={styles.orange} fontSize={"1.25rem"} />
                  )}
                </Box>
              ),
              description: (
                <Box component={"div"} display={"flex"} alignItems={"center"}>
                  <Link
                    onClick={() => {
                      dispatch(clearTasks());
                    }}
                    component={RouterLink}
                    to={`${routes.tasks}/bearbeiten/${task.id}`}
                  >
                    {task.description}
                  </Link>
                  {task.additional_info && (
                    <CommentIcon
                      style={{
                        marginLeft: ".3125rem",
                        fill: "var(--text-color)",
                      }}
                    />
                  )}
                </Box>
              ),
              eventName:
                task.event && task.event.name ? (
                  <>
                    #{task.event.public_id}
                    <br />
                    <Link
                      onClick={() => {
                        // do this to make sure the CrudEvent component reloads the event everytime the user clicks on the link
                        dispatch(clearEditEvent());
                        dispatch(clearParticipant());
                      }}
                      component={RouterLink}
                      to={`${getEditRouteBasedOnTrainingType(
                        task.event.training_type
                      )}/${task.event.id}`}
                    >
                      {task.event.name}
                    </Link>
                    <br />
                    Ort: {task.event.location}
                  </>
                ) : (
                  "-"
                ),
              dueDate: task.due_date ? (
                <>
                  {new Date(task.due_date).toLocaleDateString()}
                  {accessibility.monoMode && showWarning && (
                    <Tooltip
                      arrow
                      title="Vorsicht: die Aufgabe ist noch nicht abgeschlossen, das Fälligkeitsdatum ist bereits überschritten"
                    >
                      <Typography style={{ cursor: "pointer" }}>
                        <b>(Überfällig!)</b>
                      </Typography>
                    </Tooltip>
                  )}
                </>
              ) : (
                "-"
              ),
              status: (
                <Button
                  variant={accessibility.monoMode ? "contained" : undefined}
                  color={"secondary"}
                  style={{
                    border: accessibility.monoMode ? "none" : undefined,
                    padding: "0.25em",
                  }}
                  className={
                    showWarning && !accessibility.monoMode
                      ? classes.redText
                      : classes.defaultText
                  }
                  onClick={(event) => {
                    handleStatusSelectClick(event, [task.id]);
                  }}
                  endIcon={<CustomSelectArrow className={"event-item-expand-icon"} />}
                >
                  {mapToTaskStatusString(task.status)}
                </Button>
              ),
              responsibility: task.responsibility ? task.responsibility.fullname : "-",
              checkbox: buildCheckbox(task),
              backgroundColor:
                !accessibility.monoMode && showWarning ? "#ffe6e6" : "#ffffff",
            };
          });
    return mappedTasks;
  };

  const buildCheckbox = function (task: TaskData) {
    return (
      <CustomCheckbox
        small
        name={"selectTask" + task.id}
        checked={selectedTasks.findIndex((t) => task.id === t.id) >= 0}
        onChange={() => {
          let index = selectedTasks.findIndex((t) => task.id === t.id);
          if (index < 0) {
            setSelectedTasks([...selectedTasks, task]);
          } else {
            let newArray = [...selectedTasks];
            newArray.splice(index, 1);
            setSelectedTasks(newArray);
          }
        }}
      />
    );
  };

  const buildAllCheckbox = function () {
    return (
      <CustomCheckbox
        small
        name={"selectAllTasks"}
        checked={
          selectedTasks.length === tasks.tasksPage.results.length &&
          tasks.tasksPage.results.length !== 0
        }
        onChange={() => {
          if (selectedTasks.length === tasks.tasksPage.results.length) {
            setSelectedTasks([]);
          } else {
            setSelectedTasks([...tasks.tasksPage.results]);
          }
        }}
      />
    );
  };

  return (
    <>
      <Box display={"flex"} justifyContent="space-between">
        <HeadingLumos>Aufgaben</HeadingLumos>
        <Box mt={2}>
          <Button
            onClick={() => {
              dispatch(clearTasks());
              history.push(routes.tasks_new);
            }}
            fullWidth
            variant="contained"
            color="primary"
          >
            Neue Aufgabe
          </Button>
        </Box>
      </Box>

      <Formik
        onSubmit={(data: TaskFilterData) => {}}
        initialValues={taskFilterInitialValues}
      >
        {() => {
          return (
            <Form>
              <Grid container spacing={4}>
                <TaskFilterForm />
                <Grid item xs={12}>
                  <Menu
                    id="status-menu"
                    anchorEl={statusSelectAnchorEl}
                    keepMounted
                    open={Boolean(statusSelectAnchorEl)}
                    onClose={handleStatusSelectClose}
                  >
                    {[TaskStatus.Open, TaskStatus.InProgress, TaskStatus.Done].map(
                      (item) => (
                        <MenuItem
                          key={item}
                          onClick={(event: any) => {
                            handleStatusSelectClose();

                            if (statusSelectTaskIds.length === 1) {
                              const taskId = statusSelectTaskIds[0];
                              dispatch(
                                showDialog({
                                  title: "Status der Aufgabe ändern",
                                  message:
                                    'Sind Sie sich sicher, dass Sie die Aufgabe auf "' +
                                    mapToTaskStatusString(item) +
                                    '" setzen möchten?',
                                  action: () => {
                                    dispatch(updateTaskStatus(taskId, item));
                                    setSelectedTasks([]);
                                  },
                                })
                              );
                            } else {
                              dispatch(
                                showDialog({
                                  title: "Status der ausgewählten Aufgaben ändern",
                                  message:
                                    'Sind Sie sich sicher, dass Sie die ausgewählten Aufgaben auf "' +
                                    mapToTaskStatusString(item) +
                                    '" setzen möchten?',
                                  action: () => {
                                    dispatch(bulkEditStatus(statusSelectTaskIds, item));
                                    setSelectedTasks([]);
                                  },
                                })
                              );
                            }
                          }}
                        >
                          {mapToTaskStatusString(item)}
                        </MenuItem>
                      )
                    )}
                  </Menu>
                  <TableLumos
                    rowHeader={[
                      {
                        key: "checkbox",
                        label: "Checkbox",
                        content: buildAllCheckbox(),
                        alignment: "left",
                      },
                      { key: "trainingType", label: "Typ" },
                      { key: "description", label: "Beschreibung" },
                      {
                        key: "eventName",
                        label: "Fortbildungsmaßnahme",
                        alignment: "left",
                      },
                      {
                        key: "dueDate",
                        label: "Fälligkeitsdatum",
                        alignment: "left",
                      },
                      {
                        key: "responsibility",
                        label: "Zuständigkeit",
                        alignment: "left",
                      },
                      { key: "status", label: "Status", alignment: "left" },
                    ]}
                    rowData={mapToTableRows()}
                    deletable={true}
                    onDelete={(item, index) => {
                      dispatch(
                        showDialog({
                          title: "Aufgabe löschen",
                          message:
                            "Sind Sie sich sicher, dass Sie die Aufgabe löschen möchten?",
                          action: () => {
                            dispatch(deleteTask(tasks.tasksPage.results[index].id));
                          },
                        })
                      );
                    }}
                  />
                </Grid>

                <Grid item xs={12} style={{ textAlign: "center" }}>
                  {tasks.tasksPage.page !== page ? <CircularProgress /> : <></>}
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>

      <Grid container style={{ marginTop: ".625rem" }}>
        <Box component={"div"} style={{ margin: "auto" }}>
          <PaginationUI
            count={Math.ceil(tasks.tasksPage.count / PAGE_SIZE)}
            page={page}
            onChange={(event, value) => dispatch(setTasksPageCount(value))}
          />
        </Box>
      </Grid>

      <br />
      {selectedTasks.filter(
        (task) => !task.responsibility || task.responsibility.id !== currentUser.id
      ).length > 0 ? (
        <>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              dispatch(
                showDialog({
                  title: "Mir zuweisen",
                  message:
                    "Sind Sie sich sicher, dass Sie alle ausgewählten Aufgaben übernehmen möchten?",
                  action: () => {
                    dispatch(
                      bulkEditResponsibility(
                        selectedTasks.map((task) => task.id),
                        currentUser
                      )
                    );
                    setSelectedTasks([]);
                  },
                })
              );
            }}
          >
            Mir zuweisen
          </Button>
          {"\u00A0"}
        </>
      ) : (
        <></>
      )}
      {selectedTasks.filter((task) => task.responsibility).length > 0 ? (
        <>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              dispatch(
                showDialog({
                  title: "Zuweisungen entfernen",
                  message:
                    "Sind Sie sich sicher, dass Sie die Zuweisungen der ausgewählten Aufgaben entfernen möchten?",
                  action: () => {
                    dispatch(
                      bulkEditResponsibility(
                        selectedTasks.map((task) => task.id),
                        null
                      )
                    );
                    setSelectedTasks([]);
                  },
                })
              );
            }}
          >
            Zuweisung entfernen
          </Button>
          {"\u00A0"}
        </>
      ) : (
        <></>
      )}
      {selectedTasks.length > 0 ? (
        <>
          <Button
            variant="contained"
            color="primary"
            onClick={(event) => {
              handleStatusSelectClick(
                event,
                selectedTasks.map((task) => task.id)
              );
            }}
          >
            Status ändern
          </Button>
          {"\u00A0"}
          <CustomButton
            customColor={styles.red}
            textColor={"#fff"}
            hoverColor={styles["red-dark"]}
            onClick={() => {
              dispatch(
                showDialog({
                  title: "Ausgewählte löschen",
                  message:
                    "Sind Sie sich sicher, dass Sie die ausgewählten Aufgaben löschen möchten?",
                  action: () => {
                    dispatch(bulkDelete(selectedTasks.map((task) => task.id)));
                    setSelectedTasks([]);
                  },
                })
              );
            }}
          >
            Löschen
          </CustomButton>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
export default MyTasks;

// TODO no translation yet
