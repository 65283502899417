import apiRoutes from "./apiRoutes";
import { BaseCrudService } from "./baseCrudService";
import { Pagination } from "../models/pagination";
import { MyEventListData } from "../models/eventData";
import { BookingDataWithPayment, BookingEventData } from "../models/bookingData";
import { ParticipationStatus } from "../models/enums/participationStatus.enum";
import { MyELearningListData } from "../models/elearning";

export class BookingService extends BaseCrudService {
  async bookEvent(data: BookingDataWithPayment) {
    const postData: any = {
      ...data,
    };

    return await this.create(apiRoutes.booking, data);
  }

  async bookEventForOther(event: BookingEventData, userId: string) {
    const response = await this.create(
      `${apiRoutes.users}/${userId}${apiRoutes.book_for_users}`,
      event
    );
    return response;
  }

  async putOnWaitingList(event: BookingEventData) {
    const response = await this.create(apiRoutes.booking, event);
    return response;
  }

  async getMyEventList(filter: string) {
    const response = await this.getAll<Pagination<MyELearningListData>>(
      apiRoutes.booking + filter
    );
    return response.data;
  }

  async getMyEventListForPrint(filter: string) {
    const response = await this.getAll<MyEventListData[]>(
      apiRoutes.booking_all + filter
    );
    return response.data;
  }

  async getMyEvent(bookingId: string) {
    const response = await this.get<MyELearningListData>(apiRoutes.booking, bookingId);
    return response.data;
  }

  async cancelEvent(id: string) {
    const response = await this.edit<{ status: number }>(apiRoutes.booking, id, {
      status: ParticipationStatus.Cancelled,
    });
    return response;
  }

  async patchUserHasClickedLink(
    id: string,
    participationData: { user_has_clicked_evaluation_link: boolean }
  ) {
    const response = await this.edit(apiRoutes.booking, id, participationData);
    return response;
  }

  async removeFromWaitingList(id: string) {
    const response = await this.delete(apiRoutes.booking, id);
    return response;
  }

  async createElearningRegistration(id: string) {
    const response = await this.edit(
      apiRoutes.booking,
      id + "/create-elearning-registration",
      { createElearningRegistration: true }
    );
    return response;
  }
}
