import { GroupMembershipDocumentTypeData } from "../../models/groupMembershipDocumentTypeData";

export interface GroupMembershipDocumentTypeState {
  groupMembershipDocumentTypes: GroupMembershipDocumentTypeData[];
  isLoading: boolean;
  groupMembershipDocumentTypesLoaded: boolean;
  isError: boolean;
}

export const GROUP_MEMBERSHIP_DOCUMENT_TYPE_REQUEST =
  "GROUP_MEMBERSHIP_DOCUMENT_TYPE_REQUEST";
export const GROUP_MEMBERSHIP_DOCUMENT_TYPE_FAILURE =
  "GROUP_MEMBERSHIP_DOCUMENT_TYPE_FAILURE";
export const LOAD_GROUP_MEMBERSHIP_DOCUMENT_TYPE_DATA_SUCCESS =
  "LOAD_GROUP_MEMBERSHIP_DOCUMENT_TYPE_DATA_SUCCESS";

export interface GroupMembershipDocumentTypeRequestAction {
  type: typeof GROUP_MEMBERSHIP_DOCUMENT_TYPE_REQUEST;
}

export interface GroupMembershipDocumentTypeFailureAction {
  type: typeof GROUP_MEMBERSHIP_DOCUMENT_TYPE_FAILURE;
}

export interface LoadGroupMembershipDocumentTypeAction {
  type: typeof LOAD_GROUP_MEMBERSHIP_DOCUMENT_TYPE_DATA_SUCCESS;
  data: GroupMembershipDocumentTypeData[];
}

export type GroupMembershipDocumentTypeActionTypes =
  | GroupMembershipDocumentTypeRequestAction
  | GroupMembershipDocumentTypeFailureAction
  | LoadGroupMembershipDocumentTypeAction;
