import { BaseCrudService } from "../baseCrudService";
import apiRoutes from "../apiRoutes";
import { OnSiteAuthData } from "../../models/onSiteAuthData";

/**
 * The service for the on-site authentication feature.
 */
export class OnSiteAuthService extends BaseCrudService {
  /**
   * Sends a request to the server to authenticate the user.
   * @param data The data to authenticate the user and create a auth capture.
   */
  async onSiteAuthRequest(data: OnSiteAuthData) {
    return await this.create(apiRoutes.on_site_authentication, data);
  }
}

export const onSiteAuthService = new OnSiteAuthService();
