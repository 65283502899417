import {
  CLEAR_TASK_TEMPLATE,
  DELETE_TASK_TEMPLATE,
  RESET_DEFAULT_TASKS_FOR_EVENT_PREVIEW,
  SET_DEFAULT_TASKS_FOR_EVENT_PREVIEW,
  SET_LIST_OF_TASK_TEMPLATES_SUCCESS,
  SET_TASK_TEMPLATE_PAGE_COUNT,
  SET_TASK_TEMPLATE_SUCCESS,
  TaskTemplatesActionTypes,
  TaskTemplatesState,
  TEMPLATE_FAILURE,
  TEMPLATE_REQUEST,
} from "./types";
import { TaskTemplateData } from "../../../models/taskData";
import { NIL as NIL_UUID } from "uuid";

export const initialTaskTemplateState: TaskTemplatesState = {
  taskTemplateList: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  taskTemplate: {
    id: NIL_UUID,
    description: "",
    additional_info: "",
    task_type: 0,
    default_deadline_in_days: 0,
    event_related_deadline: 0,
  },
  taskTemplateLoaded: false,
  defaultTasksForEventPreview: [],
  defaultTasksForEventPreviewLoaded: false,
  currentPage: 1,
  isLoading: false,
  error: false,
  notFoundError: false,
};

function removeTemplateTaskFromList(taskTemplateList: TaskTemplateData[], id: string) {
  // ! NOTE: always use copy and don't work on state directly to prevent nasty side effects
  const copiedTaskTemplateList = [...taskTemplateList];
  let index = copiedTaskTemplateList.findIndex((TaskTemplate) => TaskTemplate.id === id);
  copiedTaskTemplateList.splice(index, 1);
  return copiedTaskTemplateList;
}

export function taskTemplatesReducer(
  state = initialTaskTemplateState,
  action: TaskTemplatesActionTypes
): TaskTemplatesState {
  switch (action.type) {
    case TEMPLATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: false,
        notFoundError: false,
      };
    case TEMPLATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
        notFoundError: action.notFoundError,
      };
    case SET_LIST_OF_TASK_TEMPLATES_SUCCESS:
      return {
        ...state,
        taskTemplateList: action.data,
        isLoading: false,
        error: false,
        notFoundError: false,
      };
    case SET_TASK_TEMPLATE_SUCCESS:
      return {
        ...state,
        taskTemplate: action.data,
        taskTemplateLoaded: true,
        isLoading: false,
        error: false,
        notFoundError: false,
      };
    case DELETE_TASK_TEMPLATE:
      return {
        ...state,
        taskTemplateList: {
          ...state.taskTemplateList,
          count: state.taskTemplateList.count - 1,
          results: removeTemplateTaskFromList(state.taskTemplateList.results, action.id),
        },
        /* for instant UI update list gets updated, but using eventTemplateLoaded = true list will be updated in background */
        taskTemplateLoaded: false,
        isLoading: false,
        error: false,
        notFoundError: false,
      };
    case SET_TASK_TEMPLATE_PAGE_COUNT:
      return {
        ...state,
        currentPage: action.pageCount,
        error: false,
        notFoundError: false,
      };
    case SET_DEFAULT_TASKS_FOR_EVENT_PREVIEW:
      return {
        ...state,
        defaultTasksForEventPreview: action.data,
        defaultTasksForEventPreviewLoaded: true,
        error: false,
        notFoundError: false,
      };
    case RESET_DEFAULT_TASKS_FOR_EVENT_PREVIEW:
      return {
        ...state,
        defaultTasksForEventPreviewLoaded: false,
        error: false,
        notFoundError: false,
      };
    case CLEAR_TASK_TEMPLATE:
      return {
        ...state,
        taskTemplate: initialTaskTemplateState.taskTemplate,
        taskTemplateLoaded: initialTaskTemplateState.taskTemplateLoaded,
        error: false,
        notFoundError: false,
      };
    default:
      return state;
  }
}

export default taskTemplatesReducer;
