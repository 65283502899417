import { OrganizationData } from "../../models/organizationData";

export interface OrganizationState {
  organizationData: OrganizationData[];
  isLoading: boolean;
  organizationDataLoaded: boolean;
}

export const CLEAR_ORGANIZATION = "CLEAR_ORGANIZATION";
export const ORGANIZATION_REQUEST = "ORGANIZATION_REQUEST";
export const ORGANIZATION_FAILURE = "ORGANIZATION_FAILURE";
export const ORGANIZATION_SUCCESS = "ORGANIZATION_SUCCESS";
export const LOAD_ORGANIZATION_DATA_SUCCESS = "LOAD_ORGANIZATION_DATA_SUCCESS";
export const SET_ORGANIZATION_NUMBER = "SET_ORGANIZATION_NUMBER";
export const SET_ORGANIZATION_DATA = "SET_ORGANIZATION_DATA";
export const DELETE_ORGANIZATION = "DELETE_ORGANIZATION";

export interface ClearOrganizationAction {
  type: typeof CLEAR_ORGANIZATION;
}

export interface OrganizationRequestAction {
  type: typeof ORGANIZATION_REQUEST;
}

export interface OrganizationFailureAction {
  type: typeof ORGANIZATION_FAILURE;
}

export interface OrganizationSuccessAction {
  type: typeof ORGANIZATION_SUCCESS;
}

export interface SetOrganizationDataAction {
  type: typeof SET_ORGANIZATION_DATA;
  organizationData: OrganizationData[];
}

export interface LoadOrganizationDataAction {
  type: typeof LOAD_ORGANIZATION_DATA_SUCCESS;
  organization: string;
}

export interface DeleteOrganizationSuccessAction {
  type: typeof DELETE_ORGANIZATION;
  id: string;
}

export type OrganizationActionTypes =
  | ClearOrganizationAction
  | OrganizationRequestAction
  | OrganizationFailureAction
  | LoadOrganizationDataAction
  | SetOrganizationDataAction
  | OrganizationSuccessAction
  | DeleteOrganizationSuccessAction;
