import { AxiosError } from "axios";
import { Dispatch } from "redux";
import { AppState } from "..";

import { organizationEventParticipationsService } from "../../api";
import {
  ORGANIZATION_EVENT_PARTICIPATIONS_FAILURE,
  ORGANIZATION_EVENT_PARTICIPATIONS_REQUEST,
  OrganizationEventParticipationsActionTypes,
  OrganizationEventParticipationsFailureAction,
  OrganizationEventParticipationsRequestAction,
  SET_ORGANIZATION_EVENT_PARTICIPATIONS_DATA,
  SetOrganizationEventParticipationsDataAction,
} from "./types";
import { OrganizationEventParticipationsData } from "../../models/organizationEventParticipationsData";

/**
 * Fetches event participations for a specific organization
 * @param organizationId - The ID of the organization
 * @returns {Function} A function that dispatches actions
 */
export function getOrganizationEventParticipationsByOrganizationId(
  organizationId: string
) {
  return (
    dispatch: Dispatch<OrganizationEventParticipationsActionTypes>,
    /* eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars */
    getState: () => AppState
  ) => {
    dispatch(organizationEventParticipationRequest());
    return (
      organizationEventParticipationsService
        .getEventParticipationsByOrganizationId(organizationId)
        .then((eventParticipations: OrganizationEventParticipationsData[]) => {
          dispatch(setOrganizationEventParticipationsData(eventParticipations));
        })
        /* eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars */
        .catch((err: AxiosError) => {
          dispatch(organizationEventParticipationFailure());
        })
    );
  };
}

/**
 * Dispatched when the organization event participations request is made
 * @returns {OrganizationEventParticipationsRequestAction}
 */
export function organizationEventParticipationRequest(): OrganizationEventParticipationsRequestAction {
  return {
    type: ORGANIZATION_EVENT_PARTICIPATIONS_REQUEST,
  };
}

/**
 * Dispatched when the organization event participations request fails
 * @returns {OrganizationEventParticipationsFailureAction}
 */
export function organizationEventParticipationFailure(): OrganizationEventParticipationsFailureAction {
  return {
    type: ORGANIZATION_EVENT_PARTICIPATIONS_FAILURE,
  };
}

/**
 * Sets the organization invoice data
 * @param {OrganizationInvoiceData} eventParticipations - The organization invoice data
 */
export function setOrganizationEventParticipationsData(
  eventParticipations: OrganizationEventParticipationsData[]
): SetOrganizationEventParticipationsDataAction {
  return {
    type: SET_ORGANIZATION_EVENT_PARTICIPATIONS_DATA,
    eventParticipations,
  };
}
