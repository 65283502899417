import { history } from "../../routing/history";
import { Utils } from "../utils";
import { ApiHelper } from "./../../api/apiHelper";
import { FilterData } from "./filterData";

export interface FilterOptions<T extends FilterData<S>, S> extends Record<string, any> {
  page: number;
  sort?: S;
  attributes?: T;
}

export enum SortDirection {
  Ascending = "",
  Descending = "-",
}

/**
 * Is used for parsing and mapping all the filter values for search, attributes, paging and sorting.
 */
export abstract class FilterService<T extends FilterData<S>, S> {
  private _searchFilter: FilterOptions<T, S>;

  constructor(filterOptions: FilterOptions<T, S>) {
    const filterDataCopy = this.buildValidFilterObject(
      filterOptions.attributes || ({} as T)
    );

    // set the filter options if they are not undefined (otherwise use current filter values from url)
    this._searchFilter = {
      sort: filterOptions.sort,
      page: filterOptions.page,
      attributes: filterDataCopy,
    };
  }

  // astract function definitions (are implemented in child filter services)
  abstract mapSorting(sort?: string | S): string;
  abstract mapSortStringToEnum(sort: string): S;

  // getter and setter
  set searchFilter(value: FilterOptions<T, S>) {
    this._searchFilter = { ...this._searchFilter, ...value }; // set as addition to JSON object (updates the attributes given in value)
  }

  get searchFilter(): FilterOptions<T, S> {
    return this._searchFilter;
  }

  /**
   * Create copy of filterData object, remove sorting attribute, because it is handled separately in FilterService and add page as object.
   */
  buildValidFilterObject(filterData: T) {
    let filterDataCopy = { ...filterData };
    delete filterDataCopy["sorting"]; // remove sorting from JSON object because it is handled separately in filter service
    filterDataCopy = { ...filterDataCopy };
    return filterDataCopy;
  }

  /**
   * Check if the current page (from query params) and the current page index are different. If that's the case, the page was incremented by one.
   * @param queryParams Query params in URL object
   */
  pageWasIncremented(queryParams: URLSearchParams) {
    // get the previous page from query params
    const previousPage = queryParams.get("page") || "1";
    const currentPage = this.searchFilter.page - 1; // check if the current page minus 1 is the previous page (page was incremented by 1)
    const pageWasIncremented = previousPage === currentPage.toString();
    return pageWasIncremented;
  }

  /**
   * Gets the current filter values from the query params in the URL and parses the values and assigns them to a FilterData object that can be used to initalize a filter form.
   * @param query the query params in the url als URLSearchParams object
   */
  parseFiltersFromUrl(query?: URLSearchParams): FilterData<S> {
    if (!query) {
      query = new URLSearchParams(history.location.search);
    }

    const sortBy = this.parseSortingFromQueryParams(query.get("ordering") || "");

    return {
      ...this._searchFilter.attributes,
      sorting: sortBy,
    };
  }

  /**
   * Parses the ordering value of the sort query param of the url into the given enum (S) value.
   * @param queryParam value of sort query parameter e.g. "begin" from query param "&ordering=begin"
   */
  parseSortingFromQueryParams(queryParam: string): S {
    // map to enum value from query param
    const sortFilter = this.mapSortStringToEnum(queryParam);

    return sortFilter;
  }

  /**
   * Parses a list of values from url and returns them as number [].
   * @param queryParam value of the query params e.g. ?status=value1,value2
   */
  parseNumberListFromFilterUrl(queryParam: string, defaultValue: number): number[] {
    const values = queryParam?.split(",");
    const mappedValuesAsNumbers: number[] = values
      .map((x) => {
        return Utils.tryParseInt(x, defaultValue);
      })
      .filter((x) => x !== defaultValue);
    return mappedValuesAsNumbers;
  }

  parseStringListFromFilterUrl(queryParam: string, defaultValue: string): string[] {
    const values = queryParam?.split(",");
    const mappedValues: string[] = values
      .map((x) => {
        return defaultValue;
      })
      .filter((x) => x !== defaultValue);
    return mappedValues;
  }

  /**
   * Gets the filter values of the query params strings and splits them by ",". The split values are added to a string array (values that are empty are excluded) and are returned.
   * @param queryParam
   */
  parseListFromFilterUrl(queryParam: string): string[] {
    const values = queryParam?.split(",");
    const mappedValues: string[] = values.filter((x) => x !== "");
    return mappedValues;
  }

  /**
   * Check if the provided string is a valid SortDirection as value of the enum.
   * @param direction direction as string (valid values are "asc" and "desc").
   */
  isValidSortDirection(direction: string) {
    const enumValues = Object.values(SortDirection) as string[];
    if (enumValues.includes(direction)) {
      return true;
    }
    return false;
  }

  /**
   * Parses the query param filter string from the given attributes of the filter data object.
   */
  parseFilterAttributes() {
    const attributes = this._searchFilter.attributes;
    if (attributes) {
      const parsedAttributes = ApiHelper.parseUrlWithParams("", attributes);
      return parsedAttributes;
    }
    return "";
  }

  /**
   * Returns the full route with search string, parsed attributes, paging and sorting.
   */
  getRoute(): string {
    let route = this.parseFilterAttributes();
    route += this.parsePaging(route) + this.parseSorting();
    return route;
  }

  reflectFiltersInUrl(route: string) {
    // reflect filters in url by pushing it to the history
    history.push(route);
  }

  /**
   * Parses the page as part of the filter string that is shown in the url.
   * @param route The current route with queryparams. If this is empty page is the first queryparam in the route string.
   */
  parsePaging(route: string) {
    if (route === "") {
      return "?page=" + this._searchFilter.page;
    } else {
      return "&page=" + this._searchFilter.page;
    }
  }

  /**
   * Parses the part of the URL that is responsible for the sorting. Values are based on the enum SortBy which provides the possible sortable values.
   */
  parseSorting() {
    let sortString = this.mapSorting(this._searchFilter.sort);
    return sortString !== "" ? "&ordering=" + sortString : "";
  }

  /**
   * Just appends the sortDirection to the ordering attribute.
   * @param name Attribute that is used for ordering the list
   * @param sortDirection Ascending = without prefix, Descending = "-" prefix before attribute
   */
  parseOrdering<T>(
    name: keyof T,
    sortDirection: SortDirection = SortDirection.Ascending
  ) {
    return `${sortDirection}${name as string}`;
  }

  /**
   * Check if a filter has changed (all values except the page) to evaluate if the page needs to be fully reloaded.
   * @param page new page value
   * @param filterData Filter object that has all new filter values
   */
  public checkIfFilterHasChanged(): boolean {
    const query = history.location.search; // get current query string from url
    let queryParams = new URLSearchParams(query); // get the query params object

    if (this.pageWasIncremented(queryParams)) {
      // don't mark as changed when page changes
      return false;
    }

    const querySorting = queryParams.get("sorting") || this.searchFilter?.sort;
    const sortingChanged = this.searchFilter.sort !== querySorting;

    if (sortingChanged) {
      // sorting has changed --> reload page
      return true;
    }

    let allPossibleQueryParams: string[] = [];
    if (this.searchFilter.attributes) {
      allPossibleQueryParams = Object.keys(this.searchFilter.attributes); // get the possible keys of the filterData object

      // iterate over all query params to check if one of them has changed
      for (const param of allPossibleQueryParams) {
        // dynamically get the filter value via the param
        const filterValue = this.searchFilter.attributes[param];

        // if the query param is null but there is a new filter
        const newFilterValueAdded =
          queryParams.get(param) === null &&
          filterValue.toString() !== null &&
          filterValue.toString() !== "";
        // if the query param had an entry but the filter value is now gone (removed)
        const filterValueRemoved =
          queryParams.get(param) !== null &&
          (filterValue.toString() === null || filterValue.toString() === "");

        // if filter values were added or removed, mark as changed
        if (newFilterValueAdded || filterValueRemoved) {
          return true;
        }

        // if filter value has changed
        if (
          filterValue.toString() !== null &&
          filterValue.toString() !== "" &&
          filterValue.toString() !== queryParams.get(param)
        ) {
          return true;
        }
      }
    }
    // there were no changes
    return false;
  }
}

export default FilterService;
