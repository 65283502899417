import { CustomSelectArrow } from "../../assets/icons/custom-svg-components/LumosSvgCollection";
import { borderError, validationErrorPrefix } from "./sharedThemeVariables";
import { ThemeOptions } from "@material-ui/core";
import {
  PaletteOptions,
  SimplePaletteColorOptions,
} from "@material-ui/core/styles/createPalette";
import { TypographyOptions } from "@material-ui/core/styles/createTypography";

const primaryColor = process.env.REACT_APP_PRIMARY_COLOR || "#6DB4BB";
const secondaryColor = process.env.REACT_APP_SECONDARY_COLOR || "#4D6879";
const redColor = process.env.REACT_APP_RED_COLOR || "#B46B6A";
const textColor1 = process.env.REACT_APP_TEXT_COLOR || "#3c3c3c";
const linkColor = process.env.REACT_APP_LINK_COLOR || "#40a9bf";
const hoverColor = process.env.REACT_APP_HOVER_COLOR || "#005b6a";
const titleColor = process.env.REACT_APP_TITLE_COLOR || "#0000";
const fontText = process.env.REACT_APP_FONT_TEXT || '"Source Sans 3", sans-serif';
const fontTitle = process.env.REACT_APP_FONT_TITLE || '"Source Sans 3", sans-serif';
const borderColor = process.env.REACT_APP_BORDER_COLOR || "#40a9bf";
const focusColor = process.env.REACT_APP_FOCUS_COLOR || "#7d959b";
const disabledColor = process.env.REACT_APP_DISABLED_COLOR || "#e1e3e4";
const buttonTextColor = process.env.REACT_APP_BUTTON_TEXT_COLOR || "#ffffff";

const backgroundColor = "#f8f8f8";
const whiteColor = "#fff";
const borderColorMediumGray = "#a2a2a2";
const backgroundColorWarmWhite = "#F1F4F6";

interface DefaultPaletteOptions extends PaletteOptions {
  primary?: SimplePaletteColorOptions;
  secondary?: SimplePaletteColorOptions;
  disabled?: SimplePaletteColorOptions;
  title?: SimplePaletteColorOptions;
}

export const defaultPalette: DefaultPaletteOptions = {
  background: {
    default: whiteColor,
  },
  title: {
    main: titleColor,
  },
  primary: {
    main: primaryColor,
  },
  secondary: {
    main: secondaryColor,
  },
  disabled: {
    main: disabledColor,
  },
  text: {
    primary: textColor1,
    disabled: borderColorMediumGray,
    secondary: whiteColor,
  },
  error: {
    main: redColor,
  },
  contrastThreshold: 1.5,
};

export const typographyDefault: TypographyOptions = {
  fontFamily: fontText,
  fontSize: 16,
  fontWeightRegular: 300,
  fontWeightBold: "bold",
  allVariants: {
    color: textColor1,
  },
  body1: {
    fontSize: 16,
    lineHeight: "1.4",
  },
  h2: {
    fontSize: "1.8rem",
  },
  h3: {
    fontSize: "1.6rem",
  },
  h4: {
    fontSize: "1.4rem",
    fontFamily: fontTitle,
  },
};

export const typographyBiggest: TypographyOptions = {
  fontFamily: fontText,
  fontSize: 26,
  fontWeightRegular: 300,
  fontWeightBold: "bold",
  allVariants: {
    color: textColor1,
  },
  body1: {
    // fontSize: 26,
    lineHeight: "1.4rem",
  },
  h2: {
    fontSize: "1.8rem",
  },
  h3: {
    fontSize: "1.6rem",
  },
  h4: {
    fontSize: "1.4rem",
  },
};

export default {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1140, // use 71.25rem to match the container style
      xl: 1920,
    },
  },
  props: {
    MuiButtonBase: {
      // change default props
      disableRipple: true, // no ripple for all buttons on the whole application
    },
    MuiButton: {
      disableElevation: true, // no elevation for all buttons
    },
    MuiLink: {
      underline: "none",
    },
    MuiSelect: {
      IconComponent: CustomSelectArrow,
    },
  },
  palette: defaultPalette,
  typography: typographyDefault,
  overrides: {
    MuiTypography: {
      root: {
        color: textColor1,
      },
    },
    MuiCheckbox: {
      root: {
        border: ".125rem solid " + borderColorMediumGray,
        backgroundColor: backgroundColor,
        borderRadius: 0,
        width: 32,
        height: 32,
        transition: "opacity 300ms, border 300ms",
        "&$checked": {
          color: secondaryColor,
        },
      },
    },
    MuiCardHeader: {
      root: {
        backgroundColor: primaryColor,
        color: textColor1,
      },
      title: {
        color: primaryColor,
      },
      subheader: {
        color: primaryColor,
      },
    },
    MuiToggleButton: {
      root: {
        border: ".0625rem solid var(--secondary-accent-1)",
        color: textColor1,
        "&:hover": {
          backgroundColor: hoverColor,
          color: whiteColor,
        },
        "&$selected": {
          color: whiteColor,
          backgroundColor: "#777",
          "&:hover": {
            backgroundColor: linkColor,
            color: whiteColor,
          },
        },
      },
    },
    MUIRichTextEditor: {
      root: {
        marginTop: 20,
        width: "100%",
        border: ".0625rem solid rgba(0, 0, 0, 0.12)",
      },
      container: {
        width: "100%",
      },
      editor: {
        backgroundColor: backgroundColor,
        padding: "1.25rem",
        height: "18.75rem",
        maxHeight: "18.75rem",
        overflow: "auto",
      },
      placeHolder: {
        padding: "1.25rem",
      },
    },
    MuiSvgIcon: {
      root: {
        fontSize: "1.5rem",
        color: secondaryColor,
      },
    },
    draftEditorProps: {
      spellCheck: true,
      stripPastedStyles: true,
    },
    MuiIconButton: {
      root: {
        color: buttonTextColor,
      },
    },
    MuiSelect: {
      icon: {
        transition: "ease-in-out 0.2s",
        transform: "rotate(90deg)",
      },
      iconOpen: {
        transition: "ease-in-out 0.2s",
        transform: "rotate(-90deg)",
      },
    },
    MuiAutocomplete: {
      inputRoot: {
        '&&[class*="MuiFilledInput-root"]': {
          padding: 0,
          "& .MuiAutocomplete-endAdornment": {
            right: "1.125rem",
          },
        },
        '&&[class*="MuiFilledInput-root"] $input': {
          padding: "1.25rem",
        },
      },
      popupIndicator: {
        transition: "ease-in-out 0.2s",
        transform: "rotate(90deg)",
      },
      popupIndicatorOpen: {
        transition: "ease-in-out 0.2s",
        transform: "rotate(-90deg)",
      },
    },
    MuiListItem: {
      root: {
        height: "3.125rem",
        "&$selected": {
          color: whiteColor,
          backgroundColor: secondaryColor,
          "&:hover": {
            backgroundColor: hoverColor,
            color: whiteColor,
          },
        },
      },
      button: {
        "&:hover": {
          backgroundColor: hoverColor,
          color: whiteColor,
        },
      },
    },
    MuiButton: {
      root: {
        border: "0",
        minWidth: "2.5rem",
        borderRadius: ".0625rem",
        textTransform: "none",
        color: textColor1,
      },
      containedPrimary: {
        color: buttonTextColor,
        "&:hover": {
          color: hoverColor,
        },
      },
      contained: {
        "&$disabled": {
          backgroundColor: disabledColor,
        },
      },
    },
    MuiBadge: {
      colorPrimary: {
        color: whiteColor,
        backgroundColor: secondaryColor,
        border: "0",
      },
    },
    MuiInputLabel: {
      filled: {
        transform: "translate(.75rem, 1.4375rem) scale(1)",
      },
      formControl: {
        left: ".5rem",
        top: "-0.1875rem",
      },
    },
    MuiFormLabel: {
      root: {
        color: textColor1,
        "&$focused": {
          color: textColor1,
        },
      },
    },
    MuiInputAdornment: {
      positionEnd: {
        marginTop: "0rem",
        marginRight: ".625rem",
      },
    },
    MuiFilledInput: {
      root: {
        borderTopLeftRadius: "0rem",
        borderTopRightRadius: "0rem",
        backgroundColor: backgroundColorWarmWhite,
        border: "0rem",
        borderRadius: ".0625rem",
        "&:hover": {
          backgroundColor: backgroundColor,
        },
        "&$focused": {
          backgroundColor: focusColor,
        },
        "&$disabled": {
          backgroundColor: disabledColor,
        },
        "&$error": {
          "&::before": validationErrorPrefix,
          backgroundColor: "var(--red-accent-3)",
          "&:hover": {
            "&:before": validationErrorPrefix,
            "&:after": borderError,
          },
        },
      },
      multiline: {
        padding: "1.25rem 1.25rem",
      },
      input: {
        padding: "1.25rem 1.25rem",
      },
      underline: {
        "&:before": {
          borderBottom: `.125rem solid ${borderColor}`,
          borderLeft: `0rem solid ${borderColor}`,
          borderRight: `0rem solid ${borderColor}`,
          borderTop: `0rem solid ${borderColor}`,
        },
        "&:after": {
          borderBottom: `.125rem solid ${borderColor}`,
          borderLeft: `0rem solid ${focusColor}`,
          borderRight: `0rem solid ${focusColor}`,
          borderTop: `0rem solid ${focusColor}`,
        },
        "&:hover": {
          "&:before": {
            borderBottom: `.125rem solid ${borderColor}`,
            borderLeft: `0rem solid ${borderColor}`,
            borderRight: `0rem solid ${borderColor}`,
            borderTop: `0rem solid ${borderColor}`,
          },
          "&:after": {
            borderBottom: `.125rem solid ${borderColor}`,
            borderLeft: `0rem solid ${focusColor}`,
            borderRight: `0rem solid ${focusColor}`,
            borderTop: `0rem solid ${focusColor}`,
          },
        },
        "&$disabled": {
          "&:before": {
            borderBottomStyle: "solid",
          },
        },
      },
    },
    MuiLink: {
      root: {
        color: linkColor,
        "&:hover": {
          color: hoverColor,
          borderBottom: "none",
        },
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: secondaryColor,
      },
    },
    MuiTab: {
      root: {
        "@media (min-width: 37.5rem)": {
          minWidth: "9.25rem",
        },
        backgroundColor: backgroundColor,
        "&$selected": {
          backgroundColor: whiteColor,
          color: textColor1,
        },
      },
    },
    MuiDialogContentText: {
      root: {
        color: textColor1,
      },
    },
  },
} as ThemeOptions;
