import { ParticipationType } from "./targetGroupData";

export interface DiscountItem {
  id: string;
  event: string;
  percent: number;
  fixed_amount: number;
  participation_type: string;
  // type: DiscountType;
}
// Define Discount Types
export enum DiscountType {
  Percent = "percent",
  FixedAmount = "FixedAmount",
}

// will be used to simplify discounts managment view
export interface DiscountGroupWrapper {
  groupIndex: number;
  percent: number;
  fixedAmount: number;
  type: DiscountType;
  groups: ParticipationType[];
}

export interface DiscountGroupWrapperMeta {
  groupIndex: number;
  percent: number;
  fixedAmount: number;
  type: DiscountType;
  groups: string[];
}
