import { SelectData } from "../components/forms/selects/selectData";
import {
  EventStatus,
  mapToAllEventStatusesString,
  mapToEventStatus,
  mapToEventStatusStringTranslationKey,
} from "../models/enums/eventStatus.enum";
import {
  mapToParticipationStatusesString,
  ParticipationStatus,
} from "../models/enums/participationStatus.enum";
import { TrainingType } from "../models/enums/trainingType.enum";
import { UserType } from "../models/enums/userType.enum";
import { Utils } from "./utils";
import { getDynamicTranslation } from "../components/core/localization/getStringTranslation";

const usePermission = (
  userType?: UserType,
  eventStatusId?: number,
  isCreateRoute?: boolean
) => {
  // get just the number values of the enum
  const eventStatuses: EventStatus[] = Object.values(EventStatus).filter(
    (value) => typeof value === "number" && value !== EventStatus.Undefined
  ) as EventStatus[];
  const participationStatuses: ParticipationStatus[] = Object.values(
    ParticipationStatus
  ).filter(
    (value) => typeof value === "number" && value !== ParticipationStatus.Undefined
  ) as ParticipationStatus[];

  const isStaff = () => {
    if (!userType) {
      return false;
    }
    return userType === UserType.Employee || userType === UserType.Apprentice;
  };
  const isParticipant = () => {
    if (!userType) {
      return false;
    }
    return userType === UserType.Participant;
  };
  const isSpeaker = () => {
    if (!userType) {
      return false;
    }
    return userType === UserType.Speaker;
  };

  const filterEventStatusesByUserType = () => {
    switch (userType) {
      case UserType.Participant:
        return eventStatuses.filter((status) => {
          return (
            status !== EventStatus.Completed &&
            status !== EventStatus.NotVisible &&
            status !== EventStatus.Cancelled
          );
        });

      case UserType.Employee:
      case UserType.Apprentice:
        if (eventStatusId === EventStatus.Full) {
          return eventStatuses.filter((status) => {
            return (
              status === EventStatus.Full ||
              status === EventStatus.Completed ||
              status === EventStatus.Cancelled
            );
          });
        }
        return eventStatuses;
      case UserType.Speaker:
        return eventStatuses.filter((status) => {
          return status !== EventStatus.NotVisible;
        });
      default:
        return eventStatuses.filter((status) => {
          return (
            status !== EventStatus.Completed &&
            status !== EventStatus.NotVisible &&
            status !== EventStatus.Cancelled
          );
        });
    }
  };

  const filterEventStatusesByEventAction = () => {
    if (isCreateRoute) {
      // create event
      if (userType === UserType.Employee) {
        // employee can only set published and not visible state
        return eventStatuses.filter((status) => {
          return status === EventStatus.Published || status === EventStatus.NotVisible;
        });
      } else if (userType === UserType.Apprentice) {
        // trainees are only allowed to create an event with not visible state
        return eventStatuses.filter((status) => status === EventStatus.NotVisible);
      }
      return eventStatuses;
    } else {
      if (eventStatusId === EventStatus.Full) {
        return eventStatuses.filter((status) => {
          return (
            status === EventStatus.Full ||
            status === EventStatus.Completed ||
            status === EventStatus.Cancelled
          );
        });
      }
      return eventStatuses;
    }
  };

  const getEventStatusBasedOnUserType = () => {
    const allMappedStatuses = filterEventStatusesByUserType().map((x) => {
      // map statuses to strings to display readable status value in UI
      return mapToAllEventStatusesString(x);
    });
    return allMappedStatuses;
  };

  const getEventStatusBasedOnEventAction = () => {
    const allMappedStatuses = filterEventStatusesByEventAction().map((x) => {
      // map statuses to strings to display readable status value in UI
      return mapToAllEventStatusesString(x);
    });
    return allMappedStatuses;
  };

  /**
   * Returns SelectData[] that can be used for select element in form based on the user_type of the currently logged in user.
   * The key is the enum key, the value is the number representation of the status value,
   * the label is the actual string representation for displaying purposes.
   */
  const getEventStatusForSelectBasedOnUserType = (
    trainingType: TrainingType
  ): SelectData[] => {
    let eventStatuses = getEventStatusBasedOnUserType();
    if (trainingType === TrainingType.ELearning) {
      eventStatuses = eventStatuses.filter((status) => {
        return (
          status !== mapToAllEventStatusesString(EventStatus.Cancelled) &&
          status !== mapToAllEventStatusesString(EventStatus.Full)
        );
      });
    }
    return eventStatuses.map((value) => {
      return {
        key: Utils.getEnumKeyByValue(EventStatus, mapToEventStatus(value)),
        value: mapToEventStatus(value),
        label: value,
        disabled:
          userType === UserType.Apprentice
            ? mapToEventStatus(value) === EventStatus.Full ||
              mapToEventStatus(value) === EventStatus.Published ||
              mapToEventStatus(value) === EventStatus.Cancelled
            : mapToEventStatus(value) === EventStatus.Full,
      };
    });
  };

  /**
   * Returns SelectData[] that can be used for select element in filter based on the user_type of the currently logged in user.
   * The key is the enum key, the value is the number representation of the status value,
   * the label is the actual translated string representation for displaying purposes.
   */
  const getEventStatusForSelectBasedOnUserTypeForFilter = (): SelectData[] => {
    return getEventStatusBasedOnUserType().map((value) => {
      return {
        key: mapToEventStatus(value).toString(),
        value: mapToEventStatus(value),
        label: getDynamicTranslation(
          mapToEventStatusStringTranslationKey(value),
          "events"
        ),
      };
    });
  };

  /**
   * Returns the SelectData[] for the event status select either for event creation or editing based on the given user type.
   */
  const getEventStatusForSelectBasedOnEventAction = (
    trainingType: TrainingType
  ): SelectData[] => {
    let eventStatuses = getEventStatusBasedOnEventAction();
    if (trainingType === TrainingType.ELearning) {
      eventStatuses = eventStatuses.filter((status) => {
        return (
          status !== mapToAllEventStatusesString(EventStatus.Cancelled) &&
          status !== mapToAllEventStatusesString(EventStatus.Full)
        );
      });
    }
    return eventStatuses.map((value) => {
      return {
        key: Utils.getEnumKeyByValue(EventStatus, mapToEventStatus(value)),
        value: mapToEventStatus(value),
        label: value,
        disabled:
          userType === UserType.Apprentice
            ? mapToEventStatus(value) === EventStatus.Full ||
              mapToEventStatus(value) === EventStatus.Published ||
              mapToEventStatus(value) === EventStatus.Cancelled ||
              mapToEventStatus(value) === EventStatus.Completed
            : mapToEventStatus(value) === EventStatus.Full,
      };
    });
  };

  const getDisabledStatusSelectState = () => {
    return userType === UserType.Apprentice
      ? eventStatusId === EventStatus.Cancelled ||
          eventStatusId === EventStatus.Completed ||
          eventStatusId === EventStatus.Published
      : eventStatusId === EventStatus.Cancelled ||
          eventStatusId === EventStatus.Completed;
  };

  /**
   * Returns the SelectData[] for the participation status select in the participants tab.
   */
  const getParticipationStatusForSelectBasedOnUserType = (): SelectData[] => {
    return participationStatuses
      .filter((status) => {
        if (isSpeaker())
          return [
            ParticipationStatus.Booked,
            ParticipationStatus.Participated,
            ParticipationStatus.NotParticipated,
          ].includes(status);
        return [
          ParticipationStatus.Booked,
          ParticipationStatus.WaitingList,
          ParticipationStatus.Participated,
          ParticipationStatus.NotParticipated,
          ParticipationStatus.Cancelled,
          ParticipationStatus.CancelledByStaff,
        ].includes(status);
      })
      .map((value) => {
        let readableString = mapToParticipationStatusesString(value);
        if (value === ParticipationStatus.Cancelled)
          readableString += " durch Teilnehmer";
        return {
          key: readableString,
          value: value,
          label: readableString,
          disabled: isSpeaker() && value === ParticipationStatus.Booked,
        };
      });
  };
  /**
   * Returns the SelectData[] for the participation status select in the participants tab without booking option. Required for multiselect in waiting and cancelled list.
   */
  const getParticipationStatusForSelectBasedOnUserTypeWithoutBookingOption =
    (): SelectData[] => {
      return participationStatuses
        .filter((status) => {
          if (isSpeaker())
            return [
              ParticipationStatus.Participated,
              ParticipationStatus.NotParticipated,
            ].includes(status);
          return [
            ParticipationStatus.WaitingList,
            ParticipationStatus.Participated,
            ParticipationStatus.NotParticipated,
            ParticipationStatus.Cancelled,
            ParticipationStatus.CancelledByStaff,
          ].includes(status);
        })
        .map((value) => {
          let readableString = mapToParticipationStatusesString(value);
          if (value === ParticipationStatus.Cancelled)
            readableString += " durch Teilnehmer";
          return {
            key: readableString,
            value: value,
            label: readableString,
            disabled: isSpeaker() && value === ParticipationStatus.Booked,
          };
        });
    };

  return {
    getEventStatusBasedOnEventAction,
    getEventStatusForSelectBasedOnUserType,
    getEventStatusForSelectBasedOnUserTypeForFilter,
    getEventStatusForSelectBasedOnEventAction,
    getDisabledStatusSelectState,
    getParticipationStatusForSelectBasedOnUserType,
    getParticipationStatusForSelectBasedOnUserTypeWithoutBookingOption,
    isStaff,
    isParticipant,
    isSpeaker,
  };
};

export default usePermission;
