import { Box, Button, Grid, Typography } from "@material-ui/core";
import { CheckCircle } from "@material-ui/icons";
import { Form, Formik } from "formik";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { useDynamicYupValidations } from "../../../libs/yup-validations";
import { ContactFormData } from "../../../models/contactFormData";
import { ContactMessageType } from "../../../models/enums/contactMessageType.enum";
import { mapSalutationTypeIdAsLabel } from "../../../models/enums/salutationType.enum";
import {
  TrainingType,
  mapToTrainingTypeString,
} from "../../../models/enums/trainingType.enum";
import { AppState } from "../../../redux";
import { sendContactMessage } from "../../../redux/contact/actions";
import CustomTextField from "../../../components/forms/CustomTextField";
import CustomSelect from "../../../components/forms/selects/CustomSelect";
import SalutationSelect from "../../../components/forms/selects/SalutationSelect";
import HeadingLumos from "../../../components/theming/HeadingLumos";
import Loader from "../../../components/theming/loader/Loader";

export const MAX_CHAR_LENGTH = 1024;

interface ContactFormProps {}

export const ContactFormPage: React.FC<ContactFormProps> = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state: AppState) => {
    return state.user.currentUser;
  });
  const contactForm = useSelector((state: AppState) => {
    return state.contact;
  });
  const accessibility = useSelector((state: AppState) => state.accessibility);
  const history = useHistory();
  const { t } = useTranslation(["common", "contactForm", "snackbars"]);

  const {
    YupValidationFirstName,
    YupValidationLastName,
    YupValidationEmail,
    YupValidationMessage,
    YupValidationTopic,
    YupValidationSalutation,
  } = useDynamicYupValidations();

  const contactValidationSchema = yup.object({
    salutation: YupValidationSalutation,
    firstname: YupValidationFirstName,
    lastname: YupValidationLastName,
    mail: YupValidationEmail,
    message: YupValidationMessage,
    topic: YupValidationTopic,
  });

  return (
    <>
      <HeadingLumos>{t("contact", { ns: "common" })}</HeadingLumos>
      <Grid container>
        <Grid item xs={12}>
          <Box mb={3}>
            <Typography>{t("note", { ns: "contactForm" })}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={8}>
          {contactForm.isSending ? (
            <Loader height={415} />
          ) : !contactForm.sendSuccess ? (
            <Formik
              onSubmit={(data: ContactFormData) => {
                data.salutation = mapSalutationTypeIdAsLabel(data.salutation);
                dispatch(sendContactMessage(data));
              }}
              enableReinitialize
              validationSchema={contactValidationSchema}
              initialValues={
                {
                  salutation: user?.salutation || "0",
                  title: user?.academic_title || "",
                  firstname: user?.first_name || "",
                  lastname: user?.last_name || "",
                  message: "",
                  topic: "",
                  subject_area: 0,
                  response_type: ContactMessageType.Mail,
                  mail: user?.email || "",
                  phone: user?.phone || "",
                } as ContactFormData
              }
            >
              {({ values, isValid }) => (
                <Form>
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={4}>
                      <SalutationSelect
                        name="salutation"
                        id="salutation-textfield-contactform"
                        value={values.salutation}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <CustomTextField
                        fullWidth
                        name="title"
                        id="title-textfield-contactform"
                        label={t("profile.overviewProfile.acadTitle", {
                          ns: "common",
                        })}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <CustomTextField
                        fullWidth
                        name="firstname"
                        id="firstname-textfield-contactform"
                        label={t("profile.firstName", { ns: "common" })}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <CustomTextField
                        fullWidth
                        name="lastname"
                        id="lastname-textfield-contactform"
                        label={t("profile.lastName", { ns: "common" })}
                        required
                      />
                    </Grid>
                    {values.response_type !== ContactMessageType.Undefined && (
                      <Grid item xs={12} md={6}>
                        <CustomTextField
                          fullWidth
                          name="mail"
                          id="email-textfield-contactform"
                          label={t("profile.mail", { ns: "common" })}
                          required
                        />
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <CustomTextField
                        fullWidth
                        name="topic"
                        id="subject-textfield-contactform"
                        label={t("subject", { ns: "contactForm" })}
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CustomTextField
                        fullWidth
                        multiline
                        rows={7}
                        required
                        name="message"
                        id="message-textfield-contactform"
                        label={t("messageHeader", { ns: "contactForm" })}
                        helperText={`${values.message.length}/${MAX_CHAR_LENGTH}`}
                        inputProps={{
                          maxLength: MAX_CHAR_LENGTH,
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Box my={3} display="flex" justifyContent="space-between">
                    <Box>
                      <Button
                        id="froms-buttons-send"
                        disabled={!isValid}
                        type="submit"
                        style={{ height: "inherit" }}
                        variant="contained"
                        color="primary"
                      >
                        {t("forms.buttons.send", { ns: "common" })}
                      </Button>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          ) : (
            <Box textAlign="center" my={10}>
              <CheckCircle
                color="primary"
                style={{ marginBottom: "3.125rem", fontSize: "5rem" }}
              />
              <Typography variant="body1">
                {t("noteSuccess", { ns: "contactForm" })}
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  );
};
