import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  content: string;
}

const LeavePageDialog: React.FC<Props> = ({ open, onClose, onConfirm, content }) => {
  const { t } = useTranslation(["common", "snackbars"]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t("forms.buttons.cancel", { ns: "common" })}
        </Button>
        <Button onClick={onConfirm} color="secondary">
          {t("forms.buttons.leavePage", { ns: "common" })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LeavePageDialog;
