export enum EventStatus {
  Undefined = 0,
  NotVisible = 10,
  Published = 20,
  Full = 30,
  Completed = 40,
  Cancelled = 50,
}

export function mapToEventStatus(eventStatus: string): EventStatus {
  switch (eventStatus) {
    case "":
      return EventStatus.Undefined;
    case "Nicht sichtbar":
      return EventStatus.NotVisible;
    case "Veröffentlicht":
      return EventStatus.Published;
    case "Ausgebucht":
      return EventStatus.Full;
    case "Abgeschlossen":
      return EventStatus.Completed;
    case "Storniert":
      return EventStatus.Cancelled;
    default:
      return EventStatus.Undefined;
  }
}

export function mapToEventStatusStringTranslationKey(eventStatusString: string): string {
  switch (eventStatusString) {
    case "Nicht sichtbar":
      return "status.notVisible";
    case "Veröffentlicht":
      return "status.published";
    case "Ausgebucht":
      return "status.full";
    case "Abgeschlossen":
      return "status.completed";
    case "Storniert":
      return "status.cancelled";
    default:
      return "";
  }
}

export function mapStatusIdToStatusString(eventStatusId: number): string {
  switch (eventStatusId) {
    case EventStatus.NotVisible:
      return "Nicht sichtbar";
    case EventStatus.Published:
      return "Veröffentlicht";
    case EventStatus.Full:
      return "Ausgebucht";
    case EventStatus.Completed:
      return "Abgeschlossen";
    case EventStatus.Cancelled:
      return "Storniert";
    default:
      return "";
  }
}

export function mapToAllEventStatusesString(status?: EventStatus): string {
  return mapStatusIdToStatusString(status as number);
}

export function mapStatusIdToStatusVerb(eventStatusId: number): string {
  switch (eventStatusId) {
    case EventStatus.NotVisible:
      return 'auf "Nicht sichtbar" ändern';
    case EventStatus.Published:
      return "veröffentlichen";
    case EventStatus.Full:
      return 'auf "Ausgebucht" ändern';
    case EventStatus.Completed:
      return "abschließen";
    case EventStatus.Cancelled:
      return "stornieren";
    default:
      return "";
  }
}

export function mapToStatusVerb(status?: EventStatus): string {
  return mapStatusIdToStatusVerb(status as number);
}

// TODO no translation yet
