import React, { ReactNode, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { AppState } from "../redux";
import { checkLoggedInState } from "../redux/auth/actions";
import Loader from "../components/theming/loader/Loader";
import routes from "./routes";

interface PrivateRouteProps {
  children: ReactNode;
  path?: string;
  exact?: boolean;
}

// A wrapper for <Route> that redirects to the login / registration page if not logged in
const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, ...props }) => {
  const auth = useSelector((state: AppState) => state.auth);
  const [initial, setInitial] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    if (initial) {
      dispatch(checkLoggedInState());
      setInitial(false);
    }
  }, [dispatch, initial]);

  return auth.isLoading ? (
    <Loader />
  ) : (
    <Route
      {...props}
      render={({ location }) =>
        auth.loggedIn || initial ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: routes.login,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
