import {
  CLEAR_PAYPAL_ORDER,
  PaypalOrderActionTypes,
  PAYPAL_ORDER_FAILURE,
  PaypalOrderState,
  PAYPAL_ORDER_REQUEST,
  SET_PAYPAL_ORDER_DATA,
} from "./types";
import { NIL as NIL_UUID } from "uuid";

export const initialPaypalOrderState: PaypalOrderState = {
  paypalOrderData: {
    id: NIL_UUID,
    transaction_id: "",
    url: "",
    order: "",
    useGiropay: false,
    status: "",
  },
  isSending: false,
};

export function paypalOrderReducer(
  state = initialPaypalOrderState,
  action: PaypalOrderActionTypes
): PaypalOrderState {
  switch (action.type) {
    case PAYPAL_ORDER_REQUEST:
      return {
        ...state,
        isSending: true,
      };
    case PAYPAL_ORDER_FAILURE:
      return {
        ...state,
        isSending: false,
      };
    case CLEAR_PAYPAL_ORDER:
      return initialPaypalOrderState;
    case SET_PAYPAL_ORDER_DATA:
      return {
        ...state,
        paypalOrderData: action.paypalOrder,
        isSending: false,
      };
    default:
      return state;
  }
}

export default paypalOrderReducer;
