import * as React from "react";
import { useEffect } from "react";
import {
  Hidden,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux";
import CartItem from "./CartItem";
import useInterval from "../../hooks/UseInterval";
import { CartItemData } from "../../../models/cartData";
import { getCart } from "../../../redux/cart/actions";
import {
  CHECK_CART_EACH_MINUTE,
  EXPIRATION_DURATION,
} from "../../../redux/cart/reducers";
import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Utils } from "../../../services/utils";
import { useTranslation } from "react-i18next";

interface CartListProps {
  checkout?: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      borderBottom: "none",
    },
  })
);

const CartList: React.FC<CartListProps> = (props) => {
  const { t } = useTranslation(["booking", "common", "snackbars"]);
  const classes = useStyles();
  const cart = useSelector((state: AppState) => state.cart);

  const dispatch = useDispatch();
  const isUserLoggedIn = useSelector((state: AppState) => state.auth.loggedIn);

  useInterval(() => {
    checkDurationOfCartItems(cart.cart!.cart_items);
  }, CHECK_CART_EACH_MINUTE);

  const checkDurationOfCartItems = (cartItems: CartItemData[]) => {
    cartItems.forEach((cartItem) => {
      if (Date.now() > new Date(cartItem.created_on).getTime() + EXPIRATION_DURATION) {
        dispatch(getCart());
      }
    });
  };

  useEffect(() => {
    if (!cart.cartInitialLoaded && isUserLoggedIn) {
      dispatch(getCart());
    }
  }, [cart.cart.cart_items, cart.cartInitialLoaded, dispatch, isUserLoggedIn]);

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.only("sm"));
  const xs = useMediaQuery(theme.breakpoints.only("xs"));

  if (cart.cart?.cart_items.length === 0) {
    return (
      <TableContainer className={"shopping-cart-tablebody"}>
        <Table aria-label="cart items">
          <TableBody>
            <TableRow>
              <TableCell className={"shopping-cart-emptyinfocell"}>
                {t("cart.emptyCartNote", { ns: "booking" })}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  const hasSomeDiscount = cart.cart?.cart_items.some((item) => item.discount !== null);
  const totalDiscount = cart.cart!.total_discount;

  const amountsPerTaxRate = cart.cart!.vat_totals;

  const nettoPrice = cart.cart!.net_total;

  const total = cart.cart!.gross_total;

  const getColumnCount = (): number => {
    const minColumn = props.checkout ? 5 : 3;

    if (xs) {
      return minColumn;
    }

    if (sm) {
      return 1 + minColumn;
    }

    return minColumn + 4;
  };

  const columnCount = getColumnCount();
  const summaryOffset = columnCount - 3 - 1;

  return (
    <TableContainer className={"shopping-cart-tablebody"}>
      <Table aria-label="cart items">
        <TableHead>
          <TableRow>
            <Hidden smDown>
              <TableCell component="th" scope="row">
                {t("course.courseID", { ns: "common" })}
              </TableCell>
            </Hidden>
            <TableCell align="left">
              {t("course.courseTitle", { ns: "common" })}
            </TableCell>
            <Hidden xsDown>
              <TableCell align="left">{t("cart.date", { ns: "booking" })}</TableCell>
            </Hidden>
            <Hidden smDown>
              <TableCell align="left"> {t("cart.net", { ns: "booking" })}</TableCell>
            </Hidden>
            <TableCell align="right"> {t("cart.discount", { ns: "booking" })}</TableCell>
            {props.checkout && (
              <>
                <TableCell align="right"> {t("cart.tax", { ns: "booking" })}</TableCell>
                <TableCell align="right">
                  {" "}
                  {t("cart.taxAmount", { ns: "booking" })}
                </TableCell>
              </>
            )}
            <TableCell align="right">{t("cart.gross", { ns: "booking" })}</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {cart.cart?.cart_items.map((cartItem, index) => {
            return <CartItem event={cartItem} key={index} checkout={props.checkout} />;
          })}

          {props.checkout && (
            <>
              <TableRow>
                {summaryOffset > 0 && (
                  <TableCell
                    className={classes.root}
                    colSpan={summaryOffset}
                  ></TableCell>
                )}
                <TableCell colSpan={2} align="right">
                  {t("cart.netTotal", { ns: "booking" })}
                  <Hidden smUp>
                    <br />
                    <span style={{ fontSize: "0.75rem" }}></span>
                  </Hidden>
                </TableCell>
                <TableCell align="right">
                  <b>{Utils.formatPrice(nettoPrice, true, false)}</b>
                </TableCell>
              </TableRow>

              {hasSomeDiscount && (
                <TableRow>
                  {summaryOffset > 0 && (
                    <TableCell
                      className={classes.root}
                      colSpan={summaryOffset}
                    ></TableCell>
                  )}
                  <TableCell colSpan={2} align="right">
                    {t("cart.discount", { ns: "booking" })}: {}
                    <Hidden smUp>
                      <br />
                      <span style={{ fontSize: "0.75rem" }}>10%</span>
                    </Hidden>
                  </TableCell>
                  <TableCell align="right">
                    <b>-{Utils.formatPrice(totalDiscount, true, false)}</b>
                  </TableCell>
                </TableRow>
              )}

              {amountsPerTaxRate.map((perTaxRate) => {
                return (
                  <TableRow key={perTaxRate.value_added_taxrate}>
                    {summaryOffset > 0 && (
                      <TableCell
                        className={classes.root}
                        colSpan={summaryOffset}
                      ></TableCell>
                    )}
                    <TableCell colSpan={2} align="right">
                      {t("cart.totalWithTax", { ns: "booking" })}{" "}
                      {Utils.formatTax(perTaxRate.value_added_taxrate)} %:
                    </TableCell>
                    <TableCell align="right">
                      {Utils.formatPrice(perTaxRate.total, true, false)}
                    </TableCell>
                  </TableRow>
                );
              })}
            </>
          )}

          <TableRow>
            {summaryOffset > 0 && (
              <TableCell className={classes.root} colSpan={summaryOffset}></TableCell>
            )}
            <TableCell colSpan={xs && !props.checkout ? 1 : 2} align="right">
              {props.checkout
                ? t("cart.priceAmountDue", { ns: "booking" })
                : t("cart.priceTotal", { ns: "booking" })}
            </TableCell>
            <TableCell align="right">
              <u>
                <b>{Utils.formatPrice(total, true, false)}</b>
              </u>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CartList;
