import {
  DiscountGroupWrapperMeta,
  DiscountItem,
  DiscountType,
} from "../../models/discountData";
import {
  ADD_DISCOUNT_GROUP,
  DELETE_DISCOUNT,
  DISCOUNT_FAILURE,
  DISCOUNT_REQUEST,
  DiscountActionTypes,
  DiscountState,
  SET_DISCOUNT_LIST,
  UPDATE_DISCOUNT,
} from "./types";

export const initialDiscountState: DiscountState = {
  discountListLoaded: false,
  discountList: [],
  isLoading: false,
  discountItemWrapper: [],
};

export function discountReducer(
  state = initialDiscountState,
  action: DiscountActionTypes
): DiscountState {
  switch (action.type) {
    case DISCOUNT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case DISCOUNT_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case DELETE_DISCOUNT:
      return {
        ...state,
        discountItemWrapper: [
          ...state.discountItemWrapper.slice(0, action.group.groupIndex),
          ...state.discountItemWrapper.slice(action.group.groupIndex + 1),
        ],
      };
    case ADD_DISCOUNT_GROUP:
      return {
        ...state,
        discountItemWrapper: [
          ...state.discountItemWrapper,
          newEmptyDiscountWrapper(state.discountItemWrapper.length),
        ],
      };
    case UPDATE_DISCOUNT:
      const replacedDiscountItemList = [...state.discountItemWrapper];
      replacedDiscountItemList[action.group.groupIndex] = action.group;

      return {
        ...state,
        discountItemWrapper: replacedDiscountItemList,
      };
    case SET_DISCOUNT_LIST:
      return {
        ...state,
        discountList: [...action.data],
        discountListLoaded: true,
        discountItemWrapper: toDiscountWrapperList(action.data),
        isLoading: false,
      };
    default:
      return state;
  }
}

const toDiscountWrapperList = (sourceDiscounts: DiscountItem[]) => {
  if (sourceDiscounts === undefined || sourceDiscounts?.length === 0)
    return [newEmptyDiscountWrapper(0)];

  let currentGroupIndex = 0;

  const groupedDiscounts = [] as DiscountGroupWrapperMeta[];

  sourceDiscounts.forEach((item) => {
    const existedDiscountGroup = groupedDiscounts.find((x) =>
      isSameDiscountGroup(item, x)
    );

    if (existedDiscountGroup === undefined) {
      groupedDiscounts.push(newDiscountWrapper(currentGroupIndex, item));
      currentGroupIndex++;
    } else {
      existedDiscountGroup.groups.push(item.participation_type);
    }
  });

  return groupedDiscounts;
};

const isSameDiscountGroup = (item: DiscountItem, x: DiscountGroupWrapperMeta): boolean =>
  item.percent === x.percent && item.fixed_amount === x.fixedAmount;

const getDiscountGroupOrDefault = (firstDiscount: DiscountItem): DiscountType => {
  return Number(firstDiscount.fixed_amount) > 0
    ? DiscountType.FixedAmount
    : DiscountType.Percent;
};

const newEmptyDiscountWrapper = (groupIndex: number): DiscountGroupWrapperMeta => {
  if (groupIndex < 0) groupIndex = 0;

  return newDiscountWrapper(groupIndex, {
    fixed_amount: 0,
    percent: 0,
    id: "",
    event: "",
    participation_type: "",
  });
};

const newDiscountWrapper = (
  index: number,
  item: DiscountItem
): DiscountGroupWrapperMeta => {
  return {
    groupIndex: index,
    percent: item.percent,
    fixedAmount: item.fixed_amount,
    groups: [item.participation_type],
    type: getDiscountGroupOrDefault(item),
  } as DiscountGroupWrapperMeta;
};
