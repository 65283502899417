import { useEffect } from "react";
import { Box, Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { AppState } from "../../../redux";
import { requestAuthenticationWithImageCapture } from "../../../redux/videoAuthentication/actions";
import { Capture } from "../../../components/core/events/shared/authentication/Capture";
import { CountDown } from "../../../components/core/events/shared/authentication/CountDown";
import { Instructions } from "../../../components/core/events/shared/authentication/Instructions";
import { useQueryParam } from "../../../components/core/events/shared/authentication/UseQueryParam";
import usePageLoader from "../../../components/hooks/usePageLoader";
import routes from "../../../routing/routes";
import Loader from "../../../components/theming/loader/Loader";
import { useTranslation } from "react-i18next";

/**
 * VideoAuthenticationPage Component
 *
 * This component handles the video authentication process, including capturing and processing
 * user data, image captures, and authentication steps. It manages the navigation between different
 * steps in the authentication process and redirects users upon completion.
 *
 * @returns JSX element representing the VideoAuthenticationPage.
 */
export function VideoAuthenticationPage() {
  const { t } = useTranslation(["authCapture", "snackbars"]);
  // Extract parameters from the URL
  const { id: elearningId, event_participation_id } = useParams<{
    id: string;
    event_participation_id: string;
  }>();

  // Retrieve data from the Redux store
  const {
    isAuthenticated,
    captures,
    documentCaptures,
    metaData,
    authenticationProcessCompleted,
  } = useSelector((state: AppState) => state.videoAuthentication);

  // Access page loading state and history from React Router
  const { isLoadingPage, setIsLoadingPage } = usePageLoader();
  const history = useHistory();

  // Extract the step parameter from the URL query
  const { step: stepParam = 0 } = useQueryParam<{ step: string }>();
  const step = parseInt(stepParam);

  // Effect to check completion of previous steps and redirect if incomplete
  useEffect(() => {
    // Check if all previous steps have been completed
    if (hasCompletedPrevSteps(step)) {
      // If completed, no further action is needed
      return;
    }
    // Redirect to the initial step if previous steps are incomplete
    history.push("?");
  }, []);

  // Effect to trigger authentication and image capture request
  useEffect(() => {
    // Check if the user is already authenticated or if the page is currently loading
    if (isAuthenticated && isLoadingPage) {
      // If authenticated and loading, no further action is needed
      return;
    }
    // Check if the current step is 2 (authentication step)
    if (step === 2) {
      // Set the loading state to true as the authentication process is starting
      setIsLoadingPage(true);
      // Trigger the authentication and image capture request
      requestAuthenticationWithImageCapture(event_participation_id);
    }
  }, [step]);

  // Effect to handle completion of authentication step
  useEffect(() => {
    // Check if the user is authenticated, the current step is 2, and the authentication process is completed
    if (isAuthenticated && step === 2 && authenticationProcessCompleted) {
      // If authenticated, the step is 2, and the authentication process is completed,
      // update the loading state and redirect to the next step (?step=3)
      setIsLoadingPage(false);
      history.push("?step=3");
    }

    // Check if the user is not authenticated, the current step is 2, and the authentication process is completed
    if (!isAuthenticated && step === 2 && authenticationProcessCompleted) {
      // If not authenticated, the step is 2, and the authentication process is completed,
      // update the loading state and redirect to the e-learning dashboard
      setIsLoadingPage(true);
      history.push(
        routes.my_events_details_elearning_id
          .replace(":elearning_id", elearningId)
          .replace(":event_participation_id", event_participation_id)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, step, authenticationProcessCompleted]);

  /**
   * Checks if a specific step has been completed.
   *
   * @param {number} currentStep - The step to check.
   * @returns {boolean} True if the step has been completed, false otherwise.
   */

  function hasCompletedStep(currentStep: number): boolean {
    switch (currentStep) {
      // Step 0: Check if all required metadata fields are filled
      case 0:
        return !!(
          metaData?.terms_accepted &&
          metaData?.biometric_data_processing_allowed &&
          metaData.birthday &&
          metaData.document_type &&
          metaData.document_number &&
          metaData.first_name &&
          metaData.last_name
        );
      // Step 1: Check if both image and document captures exist
      case 1:
        return captures.length === 1 && documentCaptures.length === 1;
      // Step 2: Check if user is authenticated
      case 2:
        return isAuthenticated;
    }
    return false;
  }

  /**
   * Checks if all previous steps have been completed.
   *
   * @param {number} currentStep - The current step.
   * @returns {boolean} True if all previous steps have been completed, false otherwise.
   */
  function hasCompletedPrevSteps(currentStep: number): boolean {
    for (let i = currentStep - 1; i >= 0; i--) {
      // Check if each previous step has been completed
      if (hasCompletedStep(i)) {
        continue;
      }
      // If any previous step is incomplete, return false
      return false;
    }
    // If all previous steps are completed, return true
    return true;
  }

  return (
    <Grid container spacing={2}>
      {/* Step 0: Display Instructions */}
      {step === 0 ? <Instructions /> : null}
      {/* Step 1: Display Capture Component */}
      {step === 1 ? <Capture /> : null}
      {/* Step 2: Display Loader during Information Submission */}
      {step === 2 && isLoadingPage ? (
        <Loader loadingText={t("loading", { ns: "authCapture" })} />
      ) : null}
      {/* Step 3: Display Success Message and Redirect on Authentication Success */}
      {step === 3 && isAuthenticated ? (
        <Box
          component="div"
          className="loader-wrapper"
          style={{
            height: "18.75rem",
            margin: "auto",
          }}
        >
          <span>
            {t("authSuccess1", { ns: "authCapture" })}{" "}
            <CountDown
              seconds={5}
              variant="inherit"
              onZero={() => {
                // Redirect to the E-Learning test page
                const location = routes.elearning_test_id
                  .replace(":elearning_id", elearningId)
                  .replace(":event_participation_id", event_participation_id);
                history.push(location);
              }}
            />{" "}
            {t("authSuccess2", { ns: "authCapture" })}
          </span>
        </Box>
      ) : null}
    </Grid>
  );
}
