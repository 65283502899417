import { AxiosError } from "axios";
import { Dispatch } from "redux";
import { bookingService } from "../../api";
import { ApiHelper } from "../../api/apiHelper";
import { history } from "../../routing/history";
import routes, { getEditRouteBasedOnTrainingType } from "../../routing/routes";
import { BookingDataWithPayment, BookingFilterData } from "../../models/bookingData";
import { CartItemData } from "../../models/cartData";
import { MyELearningListData } from "../../models/elearning";
import { MyEventListData } from "../../models/eventData";
import { Pagination } from "../../models/pagination";
import { BookingFilterService } from "../../services/filters/bookingFilterService";
import { billingAddressClear } from "../billingAddress/actions";
import { clearCart } from "../cart/actions";
import { CartActionTypes } from "../cart/types";
import { clearEditEvent, clearEventList } from "../events/actions";
import { EventActionTypes } from "../events/types";
import { clearFavoriteList } from "../favorites/actions";
import { FavoriteActionTypes } from "../favorites/types";
import { AppState } from "../index";
import { clearParticipant } from "../participants/actions";
import { ClearParticipantAction } from "../participants/types";
import { getParticipantsTabBasedOnTrainingType, setOpenTab } from "../tabs/actions";
import { TabsActionTypes } from "../tabs/types";
import { BillingAddressActionTypes } from "./../billingAddress/types";
import { ClearEventListAction } from "./../events/types";
import { initialBookingState } from "./reducers";
import {
  BookingActionTypes,
  BOOKINGS_FAILURE,
  BOOKINGS_REQUEST,
  BookingsFailureAction,
  BookingsRequestAction,
  CLEAR_BOOKINGS,
  ClearBookingsAction,
  SET_BOOKINGS_PAGE_COUNT,
  SET_MYEVENT,
  SET_MYEVENTS_LIST_FOR_PRINT_SUCCESS,
  SET_MYEVENTS_LIST_SUCCESS,
  SetMyEventAction,
  SetMyEventsListAction,
  SetMyEventsListForPrintAction,
  SetPageCountAction,
  UPDATE_MY_EVENT,
  UpdateMyEventAction,
} from "./types";
import { enqueueSnackbar } from "notistack";
import { getDynamicTranslation } from "../../components/core/localization/getStringTranslation";
import { clearPaypalOrder } from "../paypal/actions";
import { clearOrder } from "../orders/actions";
import { ClearPaypalOrderAction } from "../paypal/types";
import { ClearOrderAction } from "../orders/types";

export function bookForOtherUser(
  cartList: CartItemData[],
  useAlternativeBillingAddress: boolean,
  bookForOtherUser: boolean,
  ignoreMaxSeats: boolean
) {
  return (
    dispatch: Dispatch<
      | CartActionTypes
      | BookingActionTypes
      | FavoriteActionTypes
      | BillingAddressActionTypes
      | EventActionTypes
      | TabsActionTypes
      | ClearParticipantAction
      | ClearPaypalOrderAction
      | ClearOrderAction
    >,
    getState: () => AppState
  ): void => {
    dispatch(bookingsRequest());
    for (const cartItem of cartList) {
      const otherUserId = cartItem.book_for.id;
      bookingService
        .bookEventForOther(
          {
            event: cartItem.id,
            status: 20,
            catering_option: cartItem.catering_option,
            use_alternative_billing_address: useAlternativeBillingAddress,
            ignore_max_seats: ignoreMaxSeats,
          },
          otherUserId
        )
        .then(() => {
          enqueueSnackbar(
            getDynamicTranslation("booking.bookingSuccessForUser", "snackbars"),
            { variant: "success" }
          );

          dispatch(clearEditEvent());
          dispatch(clearParticipant());
          dispatch(clearCart());
          dispatch(clearFavoriteList());
          dispatch(clearEventList());
          dispatch(billingAddressClear());
          dispatch(clearPaypalOrder());
          dispatch(clearOrder());

          // show participants tab
          const trainingType = cartItem.training_type;
          const eventId = cartItem.id;
          const editTrainingRoute = getEditRouteBasedOnTrainingType(trainingType);

          history.push(`${editTrainingRoute}/${eventId}`);

          dispatch(setOpenTab(getParticipantsTabBasedOnTrainingType(trainingType)));
        })
        .catch((err: AxiosError) => {
          const errorStatus = err.response?.status;
          const errorObject = err.response?.data;

          if (errorStatus === 400) {
            enqueueSnackbar(ApiHelper.showErrorMessageFromApi(errorObject), {
              variant: "error",
            });
          } else {
            enqueueSnackbar(getDynamicTranslation("booking.bookingError", "snackbars"), {
              variant: "error",
            });
          }
          dispatch(bookingsFailure());
        });
    }
  };
}

export function bookEvent(bookingData: BookingDataWithPayment) {
  return (
    dispatch: Dispatch<
      | CartActionTypes
      | BookingActionTypes
      | FavoriteActionTypes
      | BillingAddressActionTypes
      | EventActionTypes
      | TabsActionTypes
      | ClearParticipantAction
      | ClearPaypalOrderAction
      | ClearOrderAction
    >,
    getState: () => AppState
  ) => {
    dispatch(bookingsRequest());

    return bookingService
      .bookEvent(bookingData)
      .then(() => {
        enqueueSnackbar(
          getDynamicTranslation("booking.bookingSuccessByUser", "snackbars"),
          {
            variant: "success",
          }
        );
        dispatch(clearCart());
        dispatch(clearBookings());
        dispatch(clearFavoriteList());
        dispatch(clearEventList());
        dispatch(billingAddressClear());
        dispatch(clearPaypalOrder());
        dispatch(clearOrder());
        loadAfterCheckout(
          true,
          bookingData.payment.payment_method === "Paypal" ? "paypal" : "bill"
        );
      })
      .catch((err: AxiosError) => {
        const errorStatus = err.response?.status;
        const errorObject = err.response?.data;

        if (errorStatus === 400) {
          enqueueSnackbar(ApiHelper.showErrorMessageFromApi(errorObject), {
            variant: "error",
          });
        } else {
          enqueueSnackbar(getDynamicTranslation("booking.bookingError", "snackbars"), {
            variant: "error",
          });
        }

        dispatch(bookingsFailure());
        loadAfterCheckout(
          false,
          bookingData.payment.payment_method === "Paypal" ? "paypal" : "bill"
        );
      });
  };
}

export function getAllMyEvents(page: number, filterData: BookingFilterData) {
  return (dispatch: Dispatch<BookingActionTypes>) => {
    const bookingFilterService = new BookingFilterService(page, filterData);
    const filterHasChanged = bookingFilterService.checkIfFilterHasChanged();

    if (filterHasChanged) {
      page = initialBookingState.currentPage;
      bookingFilterService.searchFilter = { page: page };
      dispatch(clearBookings()); // set initial state for booking list
    }
    dispatch(bookingsRequest());

    const route = bookingFilterService.getRoute();
    bookingFilterService.reflectFiltersInUrl(route);

    bookingService
      .getMyEventList(route)
      .then((response: Pagination<MyELearningListData>) => {
        dispatch(setMyEventsList(response));
      })
      .catch(() => {
        dispatch(bookingsFailure());
      });
  };
}

export function getAllMyEventsForPrint(filterData: BookingFilterData) {
  return (dispatch: Dispatch<BookingActionTypes>) => {
    const bookingFilterService = new BookingFilterService(0, filterData);
    const filterHasChanged = bookingFilterService.checkIfFilterHasChanged();

    if (filterHasChanged) {
      bookingFilterService.searchFilter = { page: 0 };
      dispatch(clearBookings()); // set initial state for booking list
    }

    const route = bookingFilterService.getRoute();
    const searchTermPage = route.replace("page=0&", ""); //removing page attribute from URL, because we need all entries

    bookingService
      .getMyEventListForPrint(searchTermPage)
      .then((response: MyEventListData[]) => {
        dispatch(setMyEventForPrint(response));
      })
      .catch(() => {
        dispatch(bookingsFailure());
      });
  };
}

export function getMyEvent(bookingId: string) {
  return (dispatch: Dispatch<BookingActionTypes>) => {
    dispatch(bookingsRequest());

    bookingService
      .getMyEvent(bookingId)
      .then((response: MyELearningListData) => {
        dispatch(SetMyEvent(response));
      })
      .catch(() => {
        dispatch(bookingsFailure());
      });
  };
}

export function clickedEvaluationLink(
  participantId: string,
  hasClickedLink: boolean,
  isELearningPartOfBlendedLearning: boolean
) {
  return (dispatch: Dispatch<BookingActionTypes>) => {
    dispatch(bookingsRequest());

    bookingService
      .patchUserHasClickedLink(participantId, {
        user_has_clicked_evaluation_link: hasClickedLink,
      })
      .then((response) => {
        if (isELearningPartOfBlendedLearning) {
          updateUserHasClickEvaluationLinkBlendedLearning(hasClickedLink);
        } else {
          updateUserHasClickEvaluationLink(hasClickedLink);
        }
      })
      .catch((err: AxiosError) => {
        dispatch(bookingsFailure());
      });
  };
}

export function cancelMyEvent(id: string) {
  return (dispatch: Dispatch<BookingActionTypes | ClearEventListAction>) => {
    dispatch(bookingsRequest());
    bookingService
      .cancelEvent(id)
      .then(() => {
        history.push(routes.my_events);
        enqueueSnackbar("Die Veranstaltung wurde erfolgreich storniert.", {
          variant: "success",
        });
        dispatch(clearBookings());
        dispatch(clearEventList());
      })
      .catch(() => {
        dispatch(bookingsFailure());
      });
  };
}

export function removeFromWaitingList(id: string) {
  return (dispatch: Dispatch<BookingActionTypes>) => {
    bookingService
      .removeFromWaitingList(id)
      .then(() => {
        history.push(routes.my_events);
        enqueueSnackbar("Sie wurden erfolgreich von der Warteliste entfernt.", {
          variant: "success",
        });
      })
      .catch((err: AxiosError) => {
        dispatch(bookingsFailure());
      });
  };
}

/**
 * Creates a registration for a booking on the provides service (SCORM)
 * @param id: string - The id of the booking.
 * @returns
 */
export function createElearningRegistration(id: string) {
  return (dispatch: Dispatch<BookingActionTypes | ClearEventListAction>) => {
    dispatch(bookingsRequest());
    bookingService.createElearningRegistration(id).catch((err: AxiosError) => {
      dispatch(bookingsFailure());
    });
  };
}

export function bookingsRequest(): BookingsRequestAction {
  return {
    type: BOOKINGS_REQUEST,
  };
}

export function bookingsFailure(): BookingsFailureAction {
  return {
    type: BOOKINGS_FAILURE,
  };
}

export function setMyEventsList(
  data: Pagination<MyELearningListData>
): SetMyEventsListAction {
  return {
    type: SET_MYEVENTS_LIST_SUCCESS,
    data,
  };
}

export function setMyEventForPrint(
  data: MyEventListData[]
): SetMyEventsListForPrintAction {
  return {
    type: SET_MYEVENTS_LIST_FOR_PRINT_SUCCESS,
    data,
  };
}

export function SetMyEvent(data: MyELearningListData): SetMyEventAction {
  return {
    type: SET_MYEVENT,
    data,
  };
}

export function clearBookings(): ClearBookingsAction {
  return { type: CLEAR_BOOKINGS };
}

export function setPageCount(pageCount: number): SetPageCountAction {
  return {
    type: SET_BOOKINGS_PAGE_COUNT,
    pageCount: pageCount,
  };
}

export function updateUserHasClickEvaluationLink(
  userHasClickEvaluationLink: boolean
): UpdateMyEventAction {
  return {
    type: UPDATE_MY_EVENT,
    userHasClickEvaluationLink: userHasClickEvaluationLink,
  };
}

export function updateUserHasClickEvaluationLinkBlendedLearning(
  userHasClickEvaluationLinkBlendedLearning: boolean
): UpdateMyEventAction {
  return {
    type: UPDATE_MY_EVENT,
    userHasClickEvaluationLinkBlendedLearning: userHasClickEvaluationLinkBlendedLearning,
  };
}

export function loadAfterCheckout(
  orderingSuccessful: boolean,
  paymentMethod: "paypal" | "bill"
): void {
  history.push({
    pathname:
      paymentMethod === "bill" ? routes.afterCheckoutBill : routes.afterCheckoutPaypal,
    state: orderingSuccessful,
  });
}
