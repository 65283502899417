export enum UserType {
  Undefined = 0,
  Speaker = 10,
  Participant = 20,
  Employee = 30,
  Apprentice = 40,
  TestParticipant = 50,
  OrganizationalAdmin = 60,
  Admin = 90,
}

export function mapToUserTypeString(userType?: UserType): string {
  // TODO: maybe find other neutral terms for the roles
  switch (userType) {
    case UserType.Undefined:
      return "";
    case UserType.Speaker:
      return "userType.speaker";
    case UserType.Participant:
      return "userType.participant";
    case UserType.Employee:
      return "userType.employee";
    case UserType.Apprentice:
      return "userType.apprentice";
    case UserType.Admin:
      return "userType.admin";
    case UserType.TestParticipant:
      return "userType.testParticipant";
    case UserType.OrganizationalAdmin:
      return "userType.organizationalAdmin";
    default:
      return "";
  }
}

// TODO Translation
