import React, { useEffect } from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ExpandMore } from "@material-ui/icons";
import { AppState } from "../../../../../redux";
import { useSelector, useDispatch } from "react-redux";
import { fetchMembershipsByOrganizationId } from "../../../../../redux/organizationMembership/action";
import { fetchAccountActivationByOrganizationId } from "../../../../../redux/accountInvitation/actions";
import HeadingLumos from "../../../../theming/HeadingLumos";
import Loader from "../../../../theming/loader/Loader";
import MembershipsList from "./MembershipsList";
import AccountInvitationList from "./AccountInvitationList";

interface MembershipsManagementProps {
  organizationId: string;
}

/**
 * Component for managing memberships and account invitations within an organization.
 * @param organizationId The ID of the organization
 */
export const MembershipsManagement: React.FC<MembershipsManagementProps> = (props) => {
  const { organizationId } = props;
  const { t } = useTranslation(["common", "organization"]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchMembershipsByOrganizationId(organizationId));
    dispatch(fetchAccountActivationByOrganizationId(organizationId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId]);

  const memberships = useSelector((state: AppState) => state.memberships);
  const accountInvitations = useSelector((state: AppState) => state.accountInvitations);

  return (
    <>
      <Box>
        <HeadingLumos>
          {t("membership.membersOverview", { ns: "organization" })}
        </HeadingLumos>
        {memberships?.isLoading || accountInvitations.isLoading ? (
          <Loader />
        ) : (
          <>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                {t("membership.memberships", {
                  ns: "organization",
                  number: memberships?.memberships?.length || 0,
                })}
              </AccordionSummary>
              <AccordionDetails>
                {memberships?.memberships?.length > 0 ? (
                  <MembershipsList
                    memberships={memberships.memberships}
                    organizationId={organizationId}
                  />
                ) : (
                  <Typography>
                    {t("membership.noMemberships", { ns: "organization" })}
                  </Typography>
                )}
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                {t("accountInvitation.accountInvitations", {
                  ns: "organization",
                  number: accountInvitations?.accountInvitations?.length || 0,
                })}
              </AccordionSummary>
              <AccordionDetails>
                {accountInvitations?.accountInvitations?.length > 0 ? (
                  <AccountInvitationList
                    accountInvitations={accountInvitations.accountInvitations}
                    organizationId={organizationId}
                  />
                ) : (
                  <Typography>
                    {t("accountInvitation.noInvitations", {
                      ns: "organization",
                    })}
                  </Typography>
                )}
              </AccordionDetails>
            </Accordion>
          </>
        )}
      </Box>
    </>
  );
};
