import { TrainingType } from "../models/enums/trainingType.enum";
import {
  ELearningData,
  ELearningDto,
  ELearningParticipantAuthenticationDto,
} from "./../models/elearning";
import apiRoutes from "./apiRoutes";
import { BaseCrudService } from "./baseCrudService";

export class ELearningService extends BaseCrudService {
  async editELearning(data: ELearningData, id: string) {
    const response = await this.edit<ELearningData>(
      apiRoutes.elearning_root +
        (data.training_type === TrainingType.ELearning
          ? apiRoutes.elearnings
          : apiRoutes.blended_learnings),
      id,
      data
    );
    return response;
  }

  /**
   * Fetches e-learning data by ID.
   *
   * @param {string} id - The ID of the e-learning data to fetch.
   * @returns {Promise<ELearningDto>} - A Promise resolving to the e-learning data.
   */
  async getElearning(id: string) {
    // Make a GET request to the e-learning endpoint with the provided ID
    const response = await this.get<ELearningDto>(
      apiRoutes.elearning_root + apiRoutes.elearnings,
      id
    );
    // Return the data from the response
    return response.data;
  }

  async getElearningParticipantAuthentication(id: string) {
    const response = await this.get<ELearningParticipantAuthenticationDto>(
      apiRoutes.elearning_root + apiRoutes.elearnings,
      id + "/participant-authentication" //looks slightly hacky but is the easiest way to get the route without messing with the logic too much
    );
    return response.data;
  }

  async getBlendedlearning(id: string) {
    const response = await this.get<ELearningDto>(
      apiRoutes.elearning_root + apiRoutes.blended_learnings,
      id
    );
    return response.data;
  }

  async addELearning(elearning: ELearningData) {
    const response = await this.create<ELearningData>(
      apiRoutes.elearning_root + apiRoutes.elearnings,
      elearning
    );
    return response;
  }

  async addBlendedLearning(blendedLearning: ELearningData) {
    const response = await this.create<ELearningData>(
      apiRoutes.elearning_root + apiRoutes.blended_learnings,
      blendedLearning
    );
    return response;
  }
}
