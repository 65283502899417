import {
  AccountInvitationState,
  AccountInvitationActionTypes,
  FETCH_ACCOUNT_INVITATIONS_REQUEST,
  FETCH_ACCOUNT_INVITATIONS_SUCCESS,
  FETCH_ACCOUNT_INVITATIONS_FAILURE,
  REMOVE_ACCOUNT_INVITATION_REQUEST,
  REMOVE_ACCOUNT_INVITATION_SUCCESS,
  REMOVE_ACCOUNT_INVITATION_FAILURE,
} from "./types";

// Initial state for membership reducer
const initialAccountInvitationState: AccountInvitationState = {
  accountInvitations: [],
  isLoading: false,
  error: null,
};

const accountInvitationReducer = (
  state = initialAccountInvitationState,
  action: AccountInvitationActionTypes
): AccountInvitationState => {
  switch (action.type) {
    case FETCH_ACCOUNT_INVITATIONS_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case FETCH_ACCOUNT_INVITATIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        accountInvitations: action.payload,
        error: null,
      };
    case FETCH_ACCOUNT_INVITATIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case REMOVE_ACCOUNT_INVITATION_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case REMOVE_ACCOUNT_INVITATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        accountInvitations: state.accountInvitations.filter(
          (invitation) => invitation.id !== action.invitationId
        ),
        error: null,
      };
    case REMOVE_ACCOUNT_INVITATION_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default accountInvitationReducer;
