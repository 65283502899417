/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Router } from "react-router-dom";
import "./App.scss";
import { useDispatch } from "react-redux";
import { checkLoggedInState } from "./redux/auth/actions";
import { fetchMaintenanceBanner } from "./redux/maintenance/actions";
import { history } from "./routing/history";
import ScrollToTop from "./routing/ScrollToTop";
import { Layout } from "./components/layout/Layout";

const App: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkLoggedInState());
  }, []);

  /**
   * Fetches the maintenance banner data
   */
  useEffect(() => {
    dispatch(fetchMaintenanceBanner());
  }, []);

  return (
    <React.Fragment>
      <Router history={history}>
        <ScrollToTop />
        <Layout />
      </Router>
    </React.Fragment>
  );
};

export default App;
