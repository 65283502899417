import { Box, Container, Grid, Link, Typography } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import noMatchImg from "../../assets/general/404.jpg";
import { AppState } from "../../redux";
import routes from "../../routing/routes";
import Loader from "../../components/theming/loader/Loader";
import TypographyWhiteText from "../../components/theming/TypographyWhiteText";
import "./NoMatchPage.scss";
import { useTranslation } from "react-i18next";
import { defaultPalette } from "../../components/theming/defaultTheme";

// * this component represents a page that is shown if the user navigates to a page that isn't found (route isn't existing, typo etc.)

export const NoMatchPage: React.FC = () => {
  const { t } = useTranslation(["errorPages", "snackbars"]);
  const user = useSelector((state: AppState) => state.user);

  // ! as long as there is no site matching but the loading of the auth state is not completed, show loader
  return user.isLoading ? (
    <Loader />
  ) : (
    <Box className="no-match">
      <img
        className="no-match-banner"
        src={noMatchImg}
        alt="Bananas on purple background"
      ></img>
      <Container>
        <Box className="no-match-text">
          <Box>
            <TypographyWhiteText
              style={{ backgroundColor: defaultPalette.primary?.main }}
              className="no-match-title"
              variant="h2"
            >
              404
            </TypographyWhiteText>
          </Box>
          <br />
          <Box>
            <TypographyWhiteText
              style={{ backgroundColor: defaultPalette.primary?.main }}
              className="no-match-title"
            >
              {t("noMatch.detail1", { ns: "errorPages" })}{" "}
              <Link
                className="no-match-link"
                component={RouterLink}
                to={routes.home}
                title={t("noMatch.linkTitle", { ns: "errorPages" })}
              >
                {t("noMatch.detail2", { ns: "errorPages" })}
              </Link>{" "}
              {t("noMatch.detail3", { ns: "errorPages" })}
            </TypographyWhiteText>
          </Box>
        </Box>
        <Grid container>
          <Grid item md={6}>
            <Typography>{t("noMatch.detail4", { ns: "errorPages" })}</Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
