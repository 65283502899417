import { CartItemData, CartData } from "../../models/cartData";
import { CateringType } from "../../models/enums/cateringType";
import {
  CartActionTypes,
  CartState,
  CART_FAILURE,
  CART_REQUEST,
  CLEAR_CART,
  SAVE_CART,
  SAVE_CART_ITEM,
  SET_CATERING_OPTION,
} from "./types";

export const CHECK_CART_EACH_MINUTE = 1000 * 60; // 60000 ms = 1 minute
export const EXPIRATION_DURATION = CHECK_CART_EACH_MINUTE * 60; // 3600000 ms (how long one event should be reserved in cart)

export const initialCartState: CartState = {
  cart: {
    id: "",
    gross_total: 0,
    total_discount: 0,
    net_total: 0,
    cart_owner: "",
    vat_totals: [],
    cart_items: [],
  },
  cartInitialLoaded: false,
  isLoading: false,
};

function setCateringOption(
  cart: CartData,
  cartId: string,
  cateringOption: CateringType
) {
  // ! NOTE: always use copy and don't work on state directly to prevent nasty side effects
  const copiedCartList = [...cart.cart_items];
  let index = copiedCartList.findIndex((cartItem) => cartItem.id === cartId);
  copiedCartList[index].catering_option = cateringOption;
  return copiedCartList;
}

export function cartReducer(
  state = initialCartState,
  action: CartActionTypes
): CartState {
  switch (action.type) {
    case CART_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case CART_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case SET_CATERING_OPTION:
      return {
        ...state,
        cart: {
          id: state.cart.id,
          gross_total: state.cart.gross_total,
          total_discount: state.cart.total_discount,
          net_total: state.cart.net_total,
          cart_owner: state.cart.cart_owner,
          vat_totals: state.cart.vat_totals,
          cart_items: setCateringOption(
            state.cart!,
            action.cartId,
            action.cateringOption
          ),
        },
        isLoading: false,
      };
    case SAVE_CART:
      return {
        ...state,
        cart: action.data,
        cartInitialLoaded: true,
      };
    case CLEAR_CART:
      return initialCartState;
    default:
      return state;
  }
}

export default cartReducer;
