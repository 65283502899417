import apiRoutes from "./apiRoutes";
import { BaseCrudService } from "./baseCrudService";
import { PaymentData } from "../models/paymentData";

export class PaymentMethodService extends BaseCrudService {
  async getPaymentMethods() {
    const response = await this.getAll<PaymentData[]>(apiRoutes.payment_methods);
    return response.data;
  }

  async getPaymentMethod(id: string) {
    const response = await this.getAll<PaymentData>(
      apiRoutes.payment_methods + "/" + id
    );
    return response.data;
  }
}
