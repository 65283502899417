import { ParticipantListData } from "../../models/participantData";
import {
  ADD_PARTICIPANT,
  CLEAR_PARTICIPANT,
  EDIT_PARTICIPANT_REQUEST,
  EDIT_PARTICIPANT_STATUS,
  ParticipantActionTypes,
  ParticipantState,
  PARTICIPANT_FAILURE,
  PARTICIPANT_REQUEST,
  SET_PARTICIPANT_LIST,
} from "./types";

export const initialParticipantState: ParticipantState = {
  participantListLoaded: false,
  participantList: [],
  isLoading: false,
};

function getIndexOfParticipationInList(
  participantList: ParticipantListData[],
  participantId: string
) {
  let index = participantList
    .map((participant) => {
      return participant.id;
    })
    .indexOf(participantId);
  return index;
}

function setParticipantRequestInList(
  participantList: ParticipantListData[],
  participantId: string
) {
  let copiedParticipantList = [...participantList];
  const index = getIndexOfParticipationInList(copiedParticipantList, participantId);
  if (copiedParticipantList[index].changeStatus) {
    copiedParticipantList[index].changeStatus = false;
  } else {
    copiedParticipantList[index].changeStatus = true;
  }
  return copiedParticipantList;
}

function changeParticipantStatusInList(
  participantList: ParticipantListData[],
  participantId: string,
  participantStatus: number
) {
  let copiedParticipantList = [...participantList];
  const index = getIndexOfParticipationInList(copiedParticipantList, participantId);
  copiedParticipantList[index].status = participantStatus;
  copiedParticipantList[index].changeStatus = false;
  return copiedParticipantList;
}

export function participantReducer(
  state = initialParticipantState,
  action: ParticipantActionTypes
): ParticipantState {
  switch (action.type) {
    case PARTICIPANT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case EDIT_PARTICIPANT_REQUEST:
      return {
        ...state,
        participantList: [
          ...setParticipantRequestInList(state.participantList, action.participantId),
        ],
      };
    case PARTICIPANT_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case SET_PARTICIPANT_LIST:
      return {
        ...state,
        participantList: [...action.data],
        participantListLoaded: true,
        isLoading: false,
      };

    case EDIT_PARTICIPANT_STATUS:
      return {
        ...state,
        participantList: [
          ...changeParticipantStatusInList(
            state.participantList,
            action.participantId,
            action.participantStatus
          ),
        ],
      };
    case ADD_PARTICIPANT:
      return {
        ...state,
        participantList: [...state.participantList, action.data],
      };
    case CLEAR_PARTICIPANT:
      return initialParticipantState;
    default:
      return state;
  }
}

export default participantReducer;
