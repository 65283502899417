import { Dispatch } from "redux";
import { GroupMembershipDocumentTypeData } from "../../models/groupMembershipDocumentTypeData";
import { groupMembershipDocumentTypeService } from "./../../api/index";
import {
  LOAD_GROUP_MEMBERSHIP_DOCUMENT_TYPE_DATA_SUCCESS,
  LoadGroupMembershipDocumentTypeAction,
  GROUP_MEMBERSHIP_DOCUMENT_TYPE_FAILURE,
  GROUP_MEMBERSHIP_DOCUMENT_TYPE_REQUEST,
  GroupMembershipDocumentTypeActionTypes,
  GroupMembershipDocumentTypeFailureAction,
  GroupMembershipDocumentTypeRequestAction,
} from "./types";

export function getGroupMembershipDocumentTypes() {
  return (dispatch: Dispatch<GroupMembershipDocumentTypeActionTypes>) => {
    dispatch(groupMembershipDocumentTypeRequest());
    groupMembershipDocumentTypeService
      .getGroupMembershipDocumentTypes()
      .then((response: GroupMembershipDocumentTypeData[]) => {
        dispatch(loadGroupMembershipDocumentTypeSuccess(response));
      })
      .catch(() => {
        dispatch(groupMembershipDocumentTypeFailure());
      });
  };
}

export function loadGroupMembershipDocumentTypeSuccess(
  data: GroupMembershipDocumentTypeData[]
): LoadGroupMembershipDocumentTypeAction {
  return {
    type: LOAD_GROUP_MEMBERSHIP_DOCUMENT_TYPE_DATA_SUCCESS,
    data,
  };
}

export function groupMembershipDocumentTypeRequest(): GroupMembershipDocumentTypeRequestAction {
  return {
    type: GROUP_MEMBERSHIP_DOCUMENT_TYPE_REQUEST,
  };
}

export function groupMembershipDocumentTypeFailure(): GroupMembershipDocumentTypeFailureAction {
  return {
    type: GROUP_MEMBERSHIP_DOCUMENT_TYPE_FAILURE,
  };
}
