import React, { ReactNode, useRef } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { UserType } from "../models/enums/userType.enum";
import { AppState } from "../redux";
import routes from "./routes";

interface AuthRouteProps {
  children: ReactNode;
  path?: string;
  exact?: boolean;
}

// A wrapper for <Route> that redirects to the home page if the user tries to manually route to auth pages
// views that should only be shown if the user is not logged in like login and registration screen
const AuthRoute: React.FC<AuthRouteProps> = ({ children, ...props }) => {
  const auth = useSelector((state: AppState) => state.auth);
  const user = useSelector((state: AppState) => state.user);
  let redirectRoute = useRef<string>(routes.events);
  let redirectQueryParams = useRef<string>("");

  if (auth.loggedInFrom !== routes.login) {
    redirectRoute.current = auth.loggedInFrom;
    redirectQueryParams.current = auth.queryParams;
  }

  return (
    <Route
      {...props}
      render={({ location }) =>
        auth.loggedIn && !auth.isLoading ? (
          <Redirect
            to={{
              pathname:
                user.currentUser?.user_type === UserType.Admin
                  ? routes.admin
                  : redirectRoute.current,
              search: redirectQueryParams.current,
              state: { from: location },
            }}
          />
        ) : (
          children
        )
      }
    />
  );
};

export default AuthRoute;
