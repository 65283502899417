import { PinboardCommentFormData } from "../../models/pinboardData";
import {
  PinboardAnswerActionTypes,
  PinboardCommentState,
  PINBOARD_POST_ANSWER,
  PINBOARD_POST_ANSWER_FAILURE,
  PINBOARD_POST_ANSWER_SUCCESS,
} from "./types";

export const initialCommentState: PinboardCommentState = {
  postedAnswer: {
    answer: "",
    pinboard_question: 0,
  },
  isLoading: false,
  error: false,
};

export function pinboardCommentReducer(
  state = initialCommentState,
  action: PinboardAnswerActionTypes
): PinboardCommentState {
  switch (action.type) {
    case PINBOARD_POST_ANSWER:
      return {
        ...state,
        isLoading: true,
      };
    case PINBOARD_POST_ANSWER_SUCCESS:
      return {
        ...state,
        postedAnswer: action.data as PinboardCommentFormData,
        isLoading: false,
        error: false,
      };
    case PINBOARD_POST_ANSWER_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    default:
      return state;
  }
}

export default pinboardCommentReducer;
