import {
  MembershipState,
  MembershipActionTypes,
  FETCH_MEMBERSHIPS_REQUEST,
  FETCH_MEMBERSHIPS_SUCCESS,
  FETCH_MEMBERSHIPS_FAILURE,
  MembershipInviteState,
  CREATE_MEMBERSHIP_INVITE_REQUEST,
  CREATE_MEMBERSHIP_INVITE_SUCCESS,
  CREATE_MEMBERSHIP_INVITE_FAILURE,
  REMOVE_MEMBERSHIP_REQUEST,
  REMOVE_MEMBERSHIP_SUCCESS,
  REMOVE_MEMBERSHIP_FAILURE,
  SET_MEMBERSHIPS_LOADED,
} from "./types";

// Initial state for membership reducer
const initialMembershipState: MembershipState = {
  memberships: [],
  memberShipsLoaded: false,
  isLoading: false,
  error: null,
};

/**
 * Reducer function for managing the state related to organization memberships.
 * @param state - Current state of organization memberships.
 * @param action - Action dispatched to update the state.
 * @returns Updated state based on the action type.
 */
const membershipReducer = (
  state = initialMembershipState,
  action: MembershipActionTypes
): MembershipState => {
  switch (action.type) {
    case FETCH_MEMBERSHIPS_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case FETCH_MEMBERSHIPS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        memberships: action.payload,
        error: null,
      };
    case FETCH_MEMBERSHIPS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case SET_MEMBERSHIPS_LOADED:
      return {
        ...state,
        memberShipsLoaded: true,
        isLoading: false,
      };
    default:
      return state;
  }
};

// Initial state for membership invite reducer
const initialMembershipInviteState: MembershipInviteState = {
  isLoading: false,
  error: null,
};

/**
 * Reducer function for managing the state related to membership invites.
 * @param state - Current state of membership invites.
 * @param action - Action dispatched to update the state.
 * @returns Updated state based on the action type.
 */
const membershipInviteReducer = (
  state = initialMembershipInviteState,
  action: MembershipActionTypes
): MembershipInviteState => {
  switch (action.type) {
    case CREATE_MEMBERSHIP_INVITE_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case CREATE_MEMBERSHIP_INVITE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    case CREATE_MEMBERSHIP_INVITE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case REMOVE_MEMBERSHIP_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case REMOVE_MEMBERSHIP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    case REMOVE_MEMBERSHIP_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export { membershipReducer, membershipInviteReducer };
