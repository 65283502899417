import { AxiosError } from "axios";
import { Dispatch } from "redux";
import { tasksService } from "../../api/index";
import { history } from "../../routing/history";
import {
  CLEAR_TASKS,
  ClearTasksAction,
  TASKS_FAILURE,
  TASKS_REQUEST,
  TasksActionTypes,
  TasksFailureAction,
  TasksRequestAction,
  REMOVE_TASKS_LIST,
  RemoveTasksListAction,
  SetTasksListAction,
  SET_TASKS_LIST,
  SetEditingTaskAction,
  SET_EDITING_TASK,
  UPDATE_TASKS_IN_LIST,
  SetFilterUsers,
  SET_FILTER_USERS,
  UpdateTasksInListAction,
  SetPageCountAction,
  SET_TASKS_PAGE_COUNT,
} from "./types";
import {
  TaskData,
  EditTaskData,
  TaskUserData,
  TasksFilterOptions,
} from "../../models/taskData";
import { Pagination } from "../../models/pagination";
import routes from "../../routing/routes";
import { initialEditTaskData } from "./reducers";
import { mapToTaskStatusString, TaskStatus } from "../../models/enums/taskStatus.enum";
import { store } from "..";
import { enqueueSnackbar } from "notistack";
import { NIL as NIL_UUID } from "uuid";

export function getTasks(page: number = 1, filter: TasksFilterOptions = {}) {
  return (dispatch: Dispatch<TasksActionTypes>) => {
    dispatch(tasksRequest());
    return tasksService
      .getTasks(
        "?page=" +
          page.toString() +
          "&" +
          Object.entries(filter)
            .filter((entry, _) => entry[1])
            .map((entry, _) => entry[0] + "=" + entry[1])
            .join("&")
      )
      .then((response: Pagination<TaskData>) => {
        response.page = page;
        dispatch(setTasksPageCount(page));
        dispatch(setTasksList(response));
      })
      .catch((err: AxiosError) => {
        dispatch(tasksFailure(err.response?.status === 404));
        enqueueSnackbar(
          "Beim Laden der Aufgaben ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut!",
          { variant: "error" }
        );
      });
  };
}

export function addTask(task: EditTaskData) {
  return (dispatch: Dispatch<TasksActionTypes>) => {
    dispatch(tasksRequest());
    return tasksService
      .addTask(task)
      .then((response: { data: EditTaskData }) => {
        dispatch(clearTasks());
        history.push(routes.tasks);
        enqueueSnackbar("Die Aufgabe wurde erfolgreich hinzugefügt.", {
          variant: "success",
        });
      })
      .catch((err: AxiosError) => {
        dispatch(tasksFailure(err.response?.status === 404));
        enqueueSnackbar(
          "Beim Anlegen der Aufgabe ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut!",
          { variant: "error" }
        );
      });
  };
}

export function updateTaskStatus(id: string, status: TaskStatus) {
  return (dispatch: Dispatch<TasksActionTypes>) => {
    dispatch(tasksRequest());
    return tasksService
      .editTaskStatus(id, status)
      .then((response) => {
        dispatch(updateTasksInList([id], { status: status }));
        enqueueSnackbar("Die Aufgabe wurde erfolgreich bearbeitet.", {
          variant: "success",
        });
      })
      .catch((err: AxiosError) => {
        dispatch(tasksFailure(err.response?.status === 404));
        enqueueSnackbar(
          "Beim Aktualisieren der Aufgabe ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut!",
          { variant: "error" }
        );
      });
  };
}

export function updateTask(task: EditTaskData) {
  return (dispatch: Dispatch<TasksActionTypes>) => {
    dispatch(tasksRequest());
    return tasksService
      .updateTask(task)
      .then((response) => {
        dispatch(clearTasks());
        history.push(routes.tasks);
        enqueueSnackbar("Die Aufgabe wurde erfolgreich bearbeitet.", {
          variant: "success",
        });
      })
      .catch((err: AxiosError) => {
        dispatch(tasksFailure(err.response?.status === 404));
        enqueueSnackbar(
          "Beim Bearbeiten der Aufgabe ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut!",
          { variant: "error" }
        );
      });
  };
}

export function deleteTask(id: string) {
  return (dispatch: Dispatch<TasksActionTypes>) => {
    dispatch(tasksRequest());
    tasksService
      .deleteTask(id)
      .then(() => {
        // TODO: refactor since +id is not poosible on string
        dispatch(removeFromTasksList(id));
        history.push(routes.tasks);
        enqueueSnackbar("Die Aufgabe wurde erfolgreich gelöscht.", {
          variant: "success",
        });
      })
      .catch((err: AxiosError) => {
        dispatch(tasksFailure(err.response?.status === 404));
        enqueueSnackbar(
          "Beim Löschen der Aufgabe ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut!",
          { variant: "error" }
        );
      });
  };
}

export function getTask(id: string) {
  return (dispatch: Dispatch<TasksActionTypes>) => {
    dispatch(tasksRequest());
    if (!id) {
      // TODO: refactor since +id is not possible
      dispatch(setEditingTask({ ...initialEditTaskData, id: id }));
    } else {
      tasksService
        .getTask(id)
        .then((response: TaskData) => {
          dispatch(setEditingTask(response));
        })
        .catch((err: AxiosError) => {
          dispatch(tasksFailure(err.response?.status === 404));
          enqueueSnackbar("Beim Laden der Aufgabe ist ein Fehler aufgetreten!", {
            variant: "error",
          });
        });
    }
  };
}

export function getFilterUsers() {
  return (dispatch: Dispatch<TasksActionTypes>) => {
    dispatch(tasksRequest());
    return tasksService
      .getFilterUsers()
      .then((response: TaskUserData[]) => {
        dispatch(setFilterUsers(response));
      })
      .catch((err: AxiosError) => {
        dispatch(tasksFailure(err.response?.status === 404));
        enqueueSnackbar(
          "Beim Laden der Zuständigkeiten ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut!",
          { variant: "error" }
        );
      });
  };
}

export function bulkEditResponsibility(ids: string[], user: TaskUserData | null) {
  return (dispatch: Dispatch<any>) => {
    dispatch(tasksRequest());
    if (user !== null) {
      tasksService
        .bulkEditResponsibility(ids, user.id !== NIL_UUID ? user.id : NIL_UUID)
        .then((tasks: TaskData[]) => {
          dispatch(
            updateTasksInList(ids, {
              responsibility: user !== null ? user : undefined,
            })
          );

          dispatch(getFilterUsers());

          enqueueSnackbar("Die Zuständigkeiten wurde erfolgreich gesetzt.", {
            variant: "success",
          });
        })
        .catch((err: AxiosError) => {
          dispatch(tasksFailure(err.response?.status === 404));
          enqueueSnackbar(
            "Beim Zuweisen der Zuständigkeit ist ein Fehler aufgetreten.",
            { variant: "error" }
          );
        });
    }
  };
}

export function bulkEditStatus(ids: string[], status: TaskStatus) {
  return (dispatch: Dispatch<TasksActionTypes>) => {
    dispatch(tasksRequest());
    tasksService
      .bulkEditStatus(ids, status)
      .then((tasks: TaskData[]) => {
        dispatch(updateTasksInList(ids, { status: status }));
        enqueueSnackbar(
          `Die Aufgaben wurden erfolgreich auf "${mapToTaskStatusString(
            status
          )}" gesetzt.`,
          { variant: "success" }
        );
      })
      .catch((err: AxiosError) => {
        dispatch(tasksFailure(err.response?.status === 404));
        enqueueSnackbar(
          `Beim Setzen der Aufgaben auf "${mapToTaskStatusString(
            status
          )}" ist ein Fehler aufgetreten.`,
          { variant: "error" }
        );
      });
  };
}

export function bulkDelete(ids: string[]) {
  return (dispatch: Dispatch<TasksActionTypes>) => {
    dispatch(tasksRequest());
    tasksService
      .bulkDelete(ids)
      .then((tasks: TaskData[]) => {
        ids.forEach((id) => dispatch(removeFromTasksList(id)));

        enqueueSnackbar("Die Aufgaben wurden erfolgreich gelöscht.", {
          variant: "success",
        });
      })
      .catch((err: AxiosError) => {
        dispatch(tasksFailure(err.response?.status === 404));
        enqueueSnackbar("Beim Löschen der Aufgaben ist ein Fehler aufgetreten.", {
          variant: "error",
        });
      });
  };
}

export function tasksRequest(): TasksRequestAction {
  return {
    type: TASKS_REQUEST,
  };
}

export function tasksFailure(notFoundError: boolean = false): TasksFailureAction {
  return {
    type: TASKS_FAILURE,
    notFoundError: notFoundError,
  };
}

export function setTasksList(data: Pagination<TaskData>): SetTasksListAction {
  return {
    type: SET_TASKS_LIST,
    data,
  };
}

export function setEditingTask(data: TaskData): SetEditingTaskAction {
  return {
    type: SET_EDITING_TASK,
    data,
  };
}

export function removeFromTasksList(id: string): RemoveTasksListAction {
  return {
    type: REMOVE_TASKS_LIST,
    id,
  };
}

function updateTasksInList(ids: string[], update: any): UpdateTasksInListAction {
  return {
    type: UPDATE_TASKS_IN_LIST,
    data: store
      .getState()
      .tasks.tasksPage.results.filter((task) => ids.includes(task.id))
      .map((task) => {
        return { ...task, ...update };
      }),
  };
}

export function setFilterUsers(users: TaskUserData[]): SetFilterUsers {
  return {
    type: SET_FILTER_USERS,
    data: users,
  };
}

export function clearTasks(): ClearTasksAction {
  return {
    type: CLEAR_TASKS,
  };
}

export function setTasksPageCount(pageCount: number): SetPageCountAction {
  return {
    type: SET_TASKS_PAGE_COUNT,
    pageCount: pageCount,
  };
}
