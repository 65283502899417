import { DialogProps } from "@material-ui/core";

export type ButtonAppearance = "default" | "delete";

export interface DialogState {
  show: boolean;
  dialog: DialogBody;
  size: DialogProps["maxWidth"];
  simple?: boolean;
  redirect?: boolean;
  buttonAppearance?: ButtonAppearance;
}

export interface DialogBody {
  title?: string;
  message: React.ReactNode;
  action?: () => void;
  confirmButtonText?: string;
}

export const SHOW = "SHOW";
export const CLOSE = "CLOSE";

export interface ShowAction {
  type: typeof SHOW;
  dialog: DialogBody;
  simple?: boolean;
  redirect?: boolean;
  size?: DialogProps["maxWidth"];
  buttonApperance?: ButtonAppearance;
}

export interface CloseAction {
  type: typeof CLOSE;
}

export type DialogActionTypes = ShowAction | CloseAction;
