import { ReactNode } from "react";
import { UserType } from "../../../models/enums/userType.enum";
import ProfileContactTab from "./ProfileContactTab";
import ChangePasswordTab from "./ChangePasswordTab";

interface TabsData {
  label: string;
  content: ReactNode;
}

const profileContact: TabsData = {
  label: "forms.tabs.userAccount",
  content: <ProfileContactTab />,
};

const changePassword: TabsData = {
  label: "forms.tabs.changePassword",
  content: <ChangePasswordTab />,
};

const tabsItemsUser: TabsData[] = [profileContact];

const tabsItemsSpeaker: TabsData[] = [profileContact];

const tabsItemsParticipant: TabsData[] = [profileContact, changePassword];

export function getTabsByRole(userType?: UserType): TabsData[] {
  switch (userType) {
    case UserType.Undefined:
      return tabsItemsUser;
    case UserType.Admin:
      return tabsItemsUser;
    case UserType.Speaker:
      return tabsItemsSpeaker;
    case UserType.Participant:
      return tabsItemsParticipant;
    case UserType.Employee:
    case UserType.Apprentice:
      return tabsItemsUser;
    default:
      return tabsItemsUser;
  }
}
