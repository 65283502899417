import { GroupMembership } from "../../models/groupMembership";

export interface GroupMembershipDocumentState {
  memberships: GroupMembership[];
}

export const MEMBERSHIPS_REQUEST = "MEMBERSHIPS_REQUEST";
export const REMOVE_MEMBERSHIP = "REMOVE_MEMBERSHIP";
export const ADD_MEMBERSHIP = "ADD_MEMBERSHIP";
export const CLEAR_MEMBERSHIPS = "CLEAR_MEMBERSHIPS";

interface MembershipsRequestAction {
  type: typeof MEMBERSHIPS_REQUEST;
  memberships: GroupMembership[];
}

interface RemoveMembershipAction {
  type: typeof REMOVE_MEMBERSHIP;
  membership: GroupMembership;
}

interface AddMembershipAction {
  type: typeof ADD_MEMBERSHIP;
  membership: GroupMembership;
}

interface ClearMembershipsAction {
  type: typeof CLEAR_MEMBERSHIPS;
}

export type MembershipDocumentActionType =
  | MembershipsRequestAction
  | RemoveMembershipAction
  | AddMembershipAction
  | ClearMembershipsAction;
