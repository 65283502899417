import { Box, Button, CircularProgress, Grid } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Form, Formik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { TrainingType } from "../../../models/enums/trainingType.enum";
import {
  EventTemplateFormData,
  mapEventTemplateDataToDto,
  mapEventTemplateDtoToFormData,
} from "../../../models/templateData";
import { AppState } from "../../../redux";
import { showDialog } from "../../../redux/dialogs/actions";
import { clearPreview } from "../../../redux/preview/actions";
import { clearSpeakers } from "../../../redux/speaker/actions";
import {
  addEventTemplate,
  applyTemplateForEvent,
  editEventTemplate,
  getEventTemplate,
} from "../../../redux/templates/events/actions";
import { Utils } from "../../../services/utils";
import { NoMatchPage } from "../../error/NoMatchPage";
import usePageLoader from "../../../components/hooks/usePageLoader";
import useValidId from "../../../components/hooks/useValidId";
import routes from "../../../routing/routes";
import HeadingLumos from "../../../components/theming/HeadingLumos";
import Loader from "../../../components/theming/loader/Loader";
import TabsLumos from "../../../components/theming/tabs/TabsLumos";
import { BasicInformation } from "../../../components/core/events/shared/basic-information/BasicInformation";
import { Content } from "../../../components/core/events/shared/Content";
import { Documents } from "../../../components/core/events/shared/Documents";
import eventTemplateValidationSchema from "../../../components/core/templates/event/eventTemplateValidationSchema";

export const CrudEventTemplatePage: React.FC = () => {
  let { id } = useParams<{ id: string }>();

  const { isLoadingPage } = usePageLoader();

  const dispatch = useDispatch();
  const history = useHistory();

  const templates = useSelector((state: AppState) => state.eventTemplates);
  const notFoundError = templates.notFoundError;

  const { isValidId } = useValidId(routes.templates_edit_event_id, id) || true;

  const isLoading = templates.isLoading;

  useEffect(() => {
    if (
      !templates.error &&
      id &&
      isValidId &&
      !templates.eventTemplateLoaded &&
      !templates.isLoading
    ) {
      dispatch(getEventTemplate(id));
    }
  }, [
    dispatch,
    templates.eventTemplateLoaded,
    id,
    isValidId,
    templates.isLoading,
    templates.error,
  ]);

  return (
    <>
      {(isLoadingPage || isLoading) && !templates.error ? (
        <>
          <Loader />
        </>
      ) : !isLoadingPage && id && !isLoading && (notFoundError || !isValidId) ? (
        <>
          <NoMatchPage />
        </>
      ) : (
        <>
          <HeadingLumos>
            {id
              ? "Veranstaltungsvorlage bearbeiten"
              : "Neue Veranstaltungsvorlage anlegen"}
          </HeadingLumos>
          <Formik
            enableReinitialize
            onSubmit={(data: EventTemplateFormData) => {
              id
                ? dispatch(
                    editEventTemplate(mapEventTemplateDataToDto(data, id), id, true)
                  )
                : dispatch(addEventTemplate(mapEventTemplateDataToDto(data), true));
            }}
            validationSchema={eventTemplateValidationSchema}
            initialValues={mapEventTemplateDtoToFormData(templates.eventTemplate)}
          >
            {({ values, dirty, isValid, setFieldValue, submitCount }) => (
              <Form onKeyDown={Utils.preventEnterToSubmit}>
                <TabsLumos
                  onTabChange={() => {
                    setFieldValue("descriptionState", values.description); //Workaround for strange behavior, when ENTER/BACKSPACE in Editor
                  }}
                  tabs={[
                    {
                      label: "Basisinformationen",
                      content: (
                        <BasicInformation
                          templateMode={true}
                          typeOfTrainingToBeCreated={TrainingType.DefaultEvent}
                        />
                      ),
                    },
                    {
                      label: "Inhalt",
                      content: <Content />,
                    },
                    {
                      label: "Material",
                      content: (
                        <Documents
                          template_mode={true}
                          typeOfTrainingToBeCreated={TrainingType.DefaultEvent}
                        />
                      ),
                    },
                  ]}
                />
                {!isValid && submitCount > 0 && (
                  <Box my={2}>
                    <Alert severity="error">
                      Sie müssen mindestens einen Titel für die Vorlage festlegen.
                    </Alert>
                  </Box>
                )}
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={3}>
                    {templates.isLoading ? (
                      <Box display="flex" alignItems="center">
                        <CircularProgress style={{ marginRight: ".625rem" }} />
                        Vorlage wird gespeichert...
                      </Box>
                    ) : (
                      <Button
                        size="large"
                        variant="contained"
                        color="primary"
                        fullWidth
                        type="submit"
                      >
                        Vorlage speichern
                      </Button>
                    )}
                  </Grid>
                  {id && (
                    <Grid item xs={12} sm={4}>
                      <Button
                        size="large"
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => {
                          if (dirty) {
                            dispatch(
                              showDialog({
                                title: "Änderungen an der Vorlage",
                                message:
                                  "Es wurden Änderungen an der Vorlage vorgenommen. Sie müssen diese Änderungen speichern, bevor Sie sie als Vorlage verwenden. Wollen Sie diese Daten speichern?",
                                action: () => {
                                  dispatch(
                                    editEventTemplate(
                                      mapEventTemplateDataToDto(values),
                                      id,
                                      false
                                    )
                                  );
                                  dispatch(clearPreview());
                                  dispatch(clearSpeakers());
                                  dispatch(
                                    applyTemplateForEvent(
                                      mapEventTemplateDataToDto(values)
                                    )
                                  );
                                },
                              })
                            );
                          } else {
                            dispatch(clearPreview());
                            dispatch(applyTemplateForEvent(templates.eventTemplate));
                          }
                        }}
                      >
                        Neue Veranstaltung aus Vorlage
                      </Button>
                    </Grid>
                  )}
                  <Grid item xs={12} sm={2}>
                    <Button
                      size="large"
                      variant="contained"
                      color="secondary"
                      fullWidth
                      onClick={() => {
                        history.push(routes.templates);
                      }}
                    >
                      Abbrechen
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </>
      )}
    </>
  );
};

// TODO no translation yet
