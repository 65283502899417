import {
  BILLING_ADDRESS_CLEAR,
  BILLING_ADDRESS_FAILURE,
  BILLING_ADDRESS_REQUEST,
  BillingAddressActionTypes,
  BillingAddressState,
  RELOAD_BILLING_ADDRESS,
  SET_BILLING_ADDRESS,
  SET_BILLING_ADDRESS_FOR_LINKED_USER,
  SET_COMPANY_BILLING_UPLOAD_FILE,
  SET_PAYMENT_METHOD,
  SET_PAYPAL_DATA,
  SET_INVOICE_DATA,
  SET_ORDER_DATA,
  SET_CARD_DATA,
} from "./types";
import { NIL as NIL_UUID } from "uuid";

export const initialBillingAddressState: BillingAddressState = {
  billingAddressList: [],
  linkedUser: 0,
  billingAddressOfLinkedUser: {
    address: {
      zip_code: "",
      city: "",
      street: "",
      street_number: "",
      country: "",
    },
    additional_billing_address: {
      company: "",
      first_name: "",
      last_name: "",
      street: "",
      street_number: "",
      zip_code: "",
      city: "",
      phone: "",
      id: 0,
      email: "",
    },
  },
  payment: {
    payment_method: "",
    invoice_organization: {
      //id: NIL_UUID,
      id: "",
    },
    invoice_is_for_user: false,
    paypal_data: {
      id: "",
      transaction_id: "",
      gross_amount: "",
      capture_id: "",
      currency_code: "",
    },
    order: {
      id: NIL_UUID,
      orderNumber: 0,
      orderSum: -1,
      events: [],
      items: [],
    },
    card_data: {
      card_number: "",
      card_expiration_date: "",
    },
  },
  billingAddressLoaded: false,
  isSending: false,
};

export function billingAddressReducer(
  state = initialBillingAddressState,
  action: BillingAddressActionTypes
): BillingAddressState {
  switch (action.type) {
    case BILLING_ADDRESS_REQUEST:
      return {
        ...state,
        isSending: true,
      };
    case BILLING_ADDRESS_FAILURE:
      return {
        ...state,
        isSending: false,
      };
    case SET_BILLING_ADDRESS:
      return {
        ...state,
        billingAddressList: action.data,
        billingAddressLoaded: true,
        isSending: false,
      };
    case SET_BILLING_ADDRESS_FOR_LINKED_USER:
      return {
        ...state,
        billingAddressOfLinkedUser: action.data,
        linkedUser: action.linkedUser,
        billingAddressLoaded: true,
        isSending: false,
      };
    case RELOAD_BILLING_ADDRESS:
      return {
        ...state,
        billingAddressLoaded: false,
        isSending: false,
      };
    case BILLING_ADDRESS_CLEAR:
      return initialBillingAddressState;
    case SET_PAYPAL_DATA:
      return {
        ...state,
        payment: {
          payment_method: "Paypal",
          paypal_data: {
            id: action.id,
            transaction_id: action.transaction_id,
            gross_amount: action.gross_amount,
            capture_id: action.capture_id,
            currency_code: action.currency_code,
          },
        },
      };
    case SET_CARD_DATA:
      return {
        ...state,
        payment: {
          payment_method: "Card",
          card_data: {
            card_number: action.card_number,
            card_expiration_date: action.card_expiration_date,
          },
        },
      };
    case SET_PAYMENT_METHOD:
      return {
        ...state,
        payment: {
          payment_method: action.payment_method,
          order: state.payment.order,
        },
      };
    case SET_INVOICE_DATA:
      return {
        ...state,
        payment: {
          payment_method: state.payment.payment_method,
          order: state.payment.order,
        },
      };
    case SET_ORDER_DATA:
      return {
        ...state,
        payment: {
          payment_method: state.payment.payment_method,
          order: action.order,
        },
      };
    default:
      return state;
  }
}

export default billingAddressReducer;
