import apiRoutes from "../apiRoutes";
import { BaseCrudService } from "../baseCrudService";
import { MinimumAgeData } from "../../models/systemSettingsData";

/**
 * Service for system settings
 * @extends BaseCrudService
 */
export class SystemSettingsService extends BaseCrudService {
  /**
   * Get minimum age
   * @returns MinimumAgeData
   */
  async getMinimumAge() {
    const response = await this.getAll<MinimumAgeData>(apiRoutes.minimum_age);
    return response.data;
  }
}

export const systemSettingsService = new SystemSettingsService();
