import { AxiosError } from "axios";
import { Dispatch } from "redux";
import { organizationTypeService } from "../../api";
import {
  CLEAR_ORGANIZATION_TYPE,
  ClearOrganizationTypeAction,
  OrganizationTypeActionTypes,
  ORGANIZATION_TYPE_FAILURE,
  ORGANIZATION_TYPE_REQUEST,
  OrganizationTypeRequestAction,
  OrganizationTypeFailureAction,
  SetOrganizationTypeDataAction,
  SET_ORGANIZATION_TYPE_DATA,
} from "./types";
import { OrganizationTypeData } from "../../models/organizationTypeData";

/**
 * Action creator to fetch organization types from the server.
 * Dispatches actions to update the Redux store based on the API response.
 */
export function getOrganizationTypes() {
  return (dispatch: Dispatch<OrganizationTypeActionTypes>) => {
    dispatch(organizationTypeRequest());
    organizationTypeService
      .getOrganizationTypes()
      .then((response) => {
        dispatch(setOrganizationTypeData(response));
      })
      .catch((err: AxiosError) => {
        dispatch(organizationTypeFailure());
      });
  };
}

/**
 * Action creator to clear organization type data from the Redux store.
 */
export function clearOrganizationType(): ClearOrganizationTypeAction {
  return { type: CLEAR_ORGANIZATION_TYPE };
}

/**
 * Action creator to dispatch an action indicating organization type data request.
 */
export function organizationTypeRequest(): OrganizationTypeRequestAction {
  return {
    type: ORGANIZATION_TYPE_REQUEST,
  };
}

/**
 * Action creator to dispatch an action indicating organization type data fetch failure.
 */
export function organizationTypeFailure(): OrganizationTypeFailureAction {
  return {
    type: ORGANIZATION_TYPE_FAILURE,
  };
}

/**
 * Action creator to set organization type data in the Redux store.
 * @param data The organization type data to be set in the store.
 */
export function setOrganizationTypeData(
  data: OrganizationTypeData[]
): SetOrganizationTypeDataAction {
  return {
    type: SET_ORGANIZATION_TYPE_DATA,
    data,
  };
}
