import React, { useState, useEffect, FC } from "react";
import { Button, Box, FormControl, Typography } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import { Form, Formik } from "formik";
import moment from "moment";
import * as yup from "yup";

import { loginEmployeeAndCreateCapture } from "../../../../../../redux/on-site-authentication/action";
import { useDynamicYupValidations } from "../../../../../../libs/yup-validations";
import { EmployeeLoginData } from "../../../../../../models/onSiteAuthData";
import CustomTextField from "../../../../../forms/CustomTextField";
import CustomCheckbox from "../../../../../forms/CustomCheckbox";
import i18n from "../../../../../../localization/i18n";
import { useStyles } from "./OnSiteAuthPopup.styles";
import { AppState } from "../../../../../../redux";

// Initial form data
const initialData: EmployeeLoginData = {
  email: "",
  password: "",
};

/**
 * OnSiteAuthPopup Component
 *
 * This component renders a popup modal that is triggered by a keyboard shortcut.
 * On Windows, the shortcut is Ctrl + Shift + s, and on Mac, it is Command + Shift + s.
 * The popup can be closed by clicking the "Close" button.
 *
 * @returns {JSX.Element} The rendered component.
 */
const OnSiteAuthPopup: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation(["authentication", "common", "snackbars"]);
  const dispatch = useDispatch();

  const { event_participation_id } = useParams<{
    event_participation_id: string;
  }>();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState<boolean>(false);
  const [isSuccessAuth, setIsAuthSuccess] = useState<boolean>(false);

  const { metaData } = useSelector((state: AppState) => state.videoAuthentication);

  const { YupValidationEmail, YupValidationPasswordfRequired } =
    useDynamicYupValidations();

  const authenticateEmployeeSchema = yup.object({
    email: YupValidationEmail,
    password: YupValidationPasswordfRequired,
  });

  /**
   * handleKeyDown
   *
   * This function handles the keydown event to check for the specific key combination
   * (Ctrl + Shift + S or Command + Shift + S) and toggles the popup state.
   *
   * @param {KeyboardEvent} event - The keyboard event object.
   */
  const handleKeyDown = (event: KeyboardEvent) => {
    if ((event.altKey || event.metaKey) && event.key === "z") {
      event.preventDefault();
      setIsOpen(true);
    }
  };

  // Add event listener on component mount
  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  /**
   * handleClosePopup
   * This function sets the popup state to false, closing the popup.
   */
  const handleClosePopup = () => {
    setIsOpen(false);
  };

  /**
   * handleAuthenticateUser
   *
   * This function handles the form submission and dispatches the
   * loginEmployeeAndCreateCapture action.
   *
   * @param {EmployeeLoginData} data - The form data.
   */
  const handleAuthenticateUser = async (data: EmployeeLoginData) => {
    if (metaData) {
      const fullDataSet = {
        ...data,
        first_name: metaData?.first_name,
        last_name: metaData?.last_name,
        event_participant_id: event_participation_id,
        birthday: moment(metaData?.birthday).format("DD.MM.YYYY"),
        document_type: metaData?.document_type,
        document_number: metaData?.document_number,
      };

      try {
        await dispatch(loginEmployeeAndCreateCapture(fullDataSet));
        setIsAuthSuccess(true);
      } catch (error) {
        setIsAuthSuccess(false);
      }
    } else {
      enqueueSnackbar(t("backendResponses.error.captureNotSaved", { ns: "snackbars" }), {
        variant: "error",
      });
    }
  };

  return (
    <>
      {isOpen && (
        <Box className={classes.backdrop}>
          <Box className={classes.popup}>
            <Typography variant="h6" align="left">
              {t("onSiteAuth.title", { ns: "authentication" })}
            </Typography>

            <Typography variant="body1" align="left">
              {t("onSiteAuth.description", { ns: "authentication" })}
            </Typography>

            <Formik
              key={i18n.language}
              onSubmit={handleAuthenticateUser}
              initialValues={initialData}
              validationSchema={authenticateEmployeeSchema}
            >
              <Form
                className={classes.loginForm}
                data-testid="on-siteauthentication-form"
              >
                <FormControl fullWidth>
                  <CustomTextField
                    disabled={isSuccessAuth}
                    autoComplete="email"
                    name="email"
                    label={t("profile.mail", { ns: "common" })}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <CustomTextField
                    disabled={isSuccessAuth}
                    autoComplete="current-password"
                    name="password"
                    type="password"
                    label={t("profile.password", { ns: "common" })}
                  />
                </FormControl>
                <Box className={classes.checkboxContainer}>
                  <CustomCheckbox
                    disabled={isSuccessAuth}
                    name="confirmation-checked"
                    required={true}
                    checked={isCheckboxChecked}
                    onChange={(event: any) => {
                      setIsCheckboxChecked(event.target.checked);
                    }}
                    label={
                      <span>
                        {t("onSiteAuth.confirmUserAuth", {
                          ns: "authentication",
                          name: `${metaData?.first_name} ${metaData?.last_name}`,
                        })}
                      </span>
                    }
                  />
                  <Typography variant="body2" color="textSecondary">
                    {t("onSiteAuth.authenticationText", {
                      ns: "authentication",
                    })}
                  </Typography>
                </Box>

                {isSuccessAuth && (
                  <Typography variant="h6" align="left" className={classes.successText}>
                    {t("onSiteAuth.success", {
                      ns: "authentication",
                    })}
                  </Typography>
                )}

                <Box className={classes.buttonContainer}>
                  <Button
                    type="submit"
                    size="large"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    disabled={!isCheckboxChecked || isSuccessAuth}
                  >
                    {t("onSiteAuth.verify", {
                      ns: "authentication",
                    })}
                  </Button>

                  <Button
                    size="large"
                    variant="outlined"
                    color="primary"
                    className={classes.button}
                    onClick={handleClosePopup}
                  >
                    {t("close", {
                      ns: "common",
                    })}
                  </Button>
                </Box>
              </Form>
            </Formik>
          </Box>
        </Box>
      )}
    </>
  );
};

export default OnSiteAuthPopup;
