import * as yup from "yup";
import {
  Box,
  Typography,
  Button,
  makeStyles,
  Grid,
  FormControl,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import { usersService } from "../../../api";
import { ResendActivationCodeData } from "../../../models/userData";
import { useDynamicYupValidations } from "../../../libs/yup-validations";
import CustomTextField from "../../forms/CustomTextField";
import { useTranslation } from "react-i18next";

interface ResendEmailLinkProps {
  setText: React.Dispatch<React.SetStateAction<string>>;
}
const successText = "registration.activationLink.success";

export const ResendEmailLink: React.FC<ResendEmailLinkProps> = ({ setText }) => {
  const { t } = useTranslation(["authentication", "common"]);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const handleResendActivationLink = (email: string) => {
    if (email !== null) {
      const data: ResendActivationCodeData = {
        email: email,
      };
      usersService.resend_activation_code(data).then((success) => {
        if (success) {
          enqueueSnackbar(t(successText, { ns: "authentication" }), {
            variant: "success",
          });
          setText(successText);
        } else {
          setText("registration.activationLink.error");
        }
      });
    }
  };

  const { YupValidationEmail } = useDynamicYupValidations();

  const emailValidationSchema = yup.object({
    email: YupValidationEmail,
  });

  return (
    <Box className={classes.box}>
      <Typography className={(classes.mt, classes.title)}>
        {t("registration.activationLink.linkInvalid", { ns: "authentication" })}
      </Typography>
      <Typography className={classes.mt}>
        {t("registration.activationLink.requestLink", { ns: "authentication" })}
      </Typography>
      <Formik
        initialValues={{ email: "" }}
        onSubmit={(values) => {
          handleResendActivationLink(values.email);
        }}
        validationSchema={emailValidationSchema}
      >
        <Form className={classes.mt}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <CustomTextField
                  autoComplete="email"
                  name="email"
                  label={t("profile.mail", { ns: "common" })}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Button type="submit" size="large" variant="contained" color="primary">
                  {t("registration.activationLink.requestLinkButton", {
                    ns: "authentication",
                  })}
                </Button>
              </FormControl>
            </Grid>
          </Grid>
        </Form>
      </Formik>
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  box: {
    marginTop: 60,
  },
  mt: {
    marginTop: 30,
  },
  title: {
    fontWeight: 500,
  },
}));
