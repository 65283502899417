import {
  CLEAR_EVENT_TEMPLATE,
  DELETE_EVENT_TEMPLATE,
  SET_EVENT_TEMPLATE_MODE_AND_EVENT_TEMPLATE,
  SET_EVENT_TEMPLATE_PAGE_COUNT,
  SET_EVENT_TEMPLATE_SUCCESS,
  SET_LIST_OF_EVENT_TEMPLATES_SUCCESS,
  EVENT_TEMPLATE_FAILURE,
  EVENT_TEMPLATE_REQUEST,
  EventTemplatesActionTypes,
  EventTemplatesState,
  ADD_OR_EDIT_EVENT_TEMPLATE_SUCCESS,
} from "./types";
import { EventTemplateData } from "../../../models/templateData";
import { NIL as NIL_UUID } from "uuid";

export const initialEventTemplateState: EventTemplatesState = {
  eventTemplateList: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  eventTemplate: {
    url: "",
    id: NIL_UUID,
    title: "",
    score: 0,
    price: 0,
    description: "",
    speakers: [],
    min_seats: 1,
    max_seats: 1,
    documents: [],
    participation_types: [],
  },
  eventTemplateLoaded: false,
  eventTemplateMode: false,
  eventListLoaded: false,
  currentPage: 1,
  isLoading: false,
  error: false,
  notFoundError: false,
};

function removeTemplateEventFromList(
  eventTemplateList: EventTemplateData[],
  id: string
) {
  // ! NOTE: always use copy and don't work on state directly to prevent nasty side effects
  const copiedEventTemplateList = [...eventTemplateList];
  let index = copiedEventTemplateList.findIndex(
    (EventTemplate) => EventTemplate.id === id
  );
  copiedEventTemplateList.splice(index, 1);
  return copiedEventTemplateList;
}

export function eventTemplatesReducer(
  state = initialEventTemplateState,
  action: EventTemplatesActionTypes
): EventTemplatesState {
  switch (action.type) {
    case EVENT_TEMPLATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: false,
        notFoundError: false,
      };
    case EVENT_TEMPLATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
        notFoundError: action.notFoundError,
      };
    case SET_LIST_OF_EVENT_TEMPLATES_SUCCESS:
      return {
        ...state,
        eventTemplateList: action.data,
        eventListLoaded: true,
        isLoading: false,
        error: false,
        notFoundError: false,
      };
    case SET_EVENT_TEMPLATE_SUCCESS:
      return {
        ...state,
        eventTemplate: action.data,
        eventTemplateLoaded: true,
        isLoading: false,
        error: false,
        notFoundError: false,
      };
    case ADD_OR_EDIT_EVENT_TEMPLATE_SUCCESS:
      return {
        ...state,
        eventTemplate: action.data,
        eventTemplateLoaded: false,
        eventListLoaded: false,
        isLoading: false,
        error: false,
        notFoundError: false,
      };
    case DELETE_EVENT_TEMPLATE:
      return {
        ...state,
        eventTemplateList: {
          ...state.eventTemplateList,
          count: state.eventTemplateList.count - 1,
          results: removeTemplateEventFromList(
            state.eventTemplateList.results,
            action.id
          ),
        },
        /* for instant UI update list gets updated, but using eventTemplateLoaded = true list will be updated in background */
        eventTemplateLoaded: false,
        isLoading: false,
        error: false,
        notFoundError: false,
      };
    case SET_EVENT_TEMPLATE_PAGE_COUNT:
      return {
        ...state,
        currentPage: action.pageCount,
        eventListLoaded: false,
        eventTemplateLoaded: false,
        error: false,
        notFoundError: false,
      };
    case SET_EVENT_TEMPLATE_MODE_AND_EVENT_TEMPLATE:
      return {
        ...state,
        eventTemplate: action.data,
        eventTemplateMode: true,
        error: false,
        notFoundError: false,
      };
    case CLEAR_EVENT_TEMPLATE:
      return {
        ...state,
        eventTemplate: initialEventTemplateState.eventTemplate,
        eventTemplateLoaded: initialEventTemplateState.eventTemplateLoaded,
        eventTemplateMode: initialEventTemplateState.eventTemplateMode,
        error: false,
        notFoundError: false,
      };
    default:
      return state;
  }
}

export default eventTemplatesReducer;
