import JSZip from "jszip";

/**
 * Check if a file has the necessary structure of a SCORM 1.2 package.
 * @param arrayBuffer - The file content as an ArrayBuffer.
 * @returns True if the file has a valid SCORM structure, false otherwise.
 */
async function hasValidStructure(arrayBuffer: ArrayBuffer): Promise<boolean> {
  try {
    const scormZip = await JSZip.loadAsync(arrayBuffer);
    // Use OS-independent path separator ("/") for the file path
    const imsmanifest = scormZip.file("imsmanifest.xml".replace(/\\/g, "/"));
    const isTinCan =
      scormZip.file("tincan.xml".replace(/\\/g, "/")) ||
      scormZip.file("tincan.json".replace(/\\/g, "/"));

    if (isTinCan) {
      console.log("IS TINCAN FILE :)");
      return true;
    }

    if (imsmanifest) {
      const asText = await imsmanifest.async("text");
      // Parse XML using DOMParser
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(asText, "text/xml");
      // Check if the root element is "manifest"
      const rootElement = xmlDoc.documentElement;
      if (rootElement.tagName === "manifest") {
        const metadata = xmlDoc.querySelector("manifest metadata");
        if (metadata) {
          const schema = metadata.querySelector("schema")?.textContent;
          const schemaversion = metadata.querySelector("schemaversion")?.textContent;
          // Check if the metadata has the expected schema and schemaversion
          if (schema === "ADL SCORM" && schemaversion === "1.2") {
            console.log("IS SCORM FILE :)");
            return true;
          }
        }
      }
    }
    console.log("its a zip but not a scorm or tincan");
    return false;
  } catch (error) {
    console.error("Error parsing SCORM file structure:", error);
    return false;
  }
}

/**
 * Check if a file is a valid SCORM 1.2 package.xw
 * @param file - The file to be checked.
 * @returns A Promise that resolves to true if the file is valid SCORM, false otherwise.
 */
export async function isValidScormFile(file: File): Promise<boolean> {
  try {
    // Convert the File to a Blob
    const blob = await file.arrayBuffer();
    const arrayBuffer = new Uint8Array(blob);

    // Check if the file has the necessary structure of a SCORM 1.2 package
    return hasValidStructure(arrayBuffer);
  } catch (error) {
    console.error("Error checking SCORM file validity:", error);
    return false;
  }
}
