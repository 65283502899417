import { NIL } from "uuid";
import {
  ELearningParticipationStatus,
  ParticipationStatus,
} from "../../models/enums/participationStatus.enum";
import { MyELearningListData } from "./../../models/elearning";
import {
  BookingActionTypes,
  BOOKINGS_FAILURE,
  BOOKINGS_REQUEST,
  BookingState,
  CLEAR_BOOKINGS,
  SET_BOOKINGS_PAGE_COUNT,
  SET_MYEVENT,
  SET_MYEVENTS_LIST_FOR_PRINT_SUCCESS,
  SET_MYEVENTS_LIST_SUCCESS,
  UPDATE_MY_EVENT,
} from "./types";

export const initialBookingEventState: MyELearningListData = {
  id: NIL,
  event: {
    id: NIL,
    public_id: 0,
    title: "",
    location: "",
    location_details: "",
    begin: new Date(),
    end: new Date(),
    score: 0,
    price: 0,
    description: "",
    min_seats: 0,
    max_seats: 0,
    status: 0,
    event_type: 0,
    training_type: 0,
    participation_types: [],
    speakers: [],
    documents: [],
    within_cancellation_period: false,
    max_time_period_to_finish_elearning_module_in_weeks: 0,
    publish_elearning_after_event: false,
    pinboard_is_activated: false,
    no_automatic_participation_certificate: false,
    elearning_registration_id: null,
  },
  restrict_access: true,
  elearning_started_count: 0,
  status: ParticipationStatus.Undefined,
  created_on: new Date(),
  last_changed_on: new Date(),
  last_changed_by: "",
  user_has_clicked_evaluation_link: false,
  user_has_clicked_evaluation_link_blended_learning: false,
  overall_begin: new Date(),
  overall_end: new Date(),
  reactivated: false,
  elearning_status: ELearningParticipationStatus.NotPassed,
  is_within_elearning_period: false,
  participation_certificate_was_downloaded: false,
  participation_certificate_availability_date: null,
  elearning_registration_id: null,
};

export const initialBookingState: BookingState = {
  myEventList: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  myEventListLoaded: false,
  myEventListPrint: [],
  myEvent: initialBookingEventState,
  myEventLoaded: false,
  currentPage: 1,
  isLoading: false,
};

function changeUserHasClickedEvaluationLink(
  event: MyELearningListData,
  hasClickedEvaluationLink?: boolean,
  hasClickEvaluationLinkBlendedLearning?: boolean
): MyELearningListData {
  let updatedEvent = { ...event };
  if (hasClickedEvaluationLink) {
    updatedEvent.user_has_clicked_evaluation_link = hasClickedEvaluationLink;
  }
  if (hasClickEvaluationLinkBlendedLearning) {
    updatedEvent.user_has_clicked_evaluation_link_blended_learning =
      hasClickEvaluationLinkBlendedLearning;
  }
  return updatedEvent;
}

export function bookingReducer(
  state = initialBookingState,
  action: BookingActionTypes
): BookingState {
  switch (action.type) {
    case BOOKINGS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case BOOKINGS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case SET_MYEVENTS_LIST_SUCCESS:
      return {
        ...state,
        myEventList: action.data,
        myEventListLoaded: true,
        isLoading: false,
      };
    case SET_MYEVENTS_LIST_FOR_PRINT_SUCCESS:
      return {
        ...state,
        myEventListPrint: action.data,
        isLoading: false,
      };
    case SET_MYEVENT:
      return {
        ...state,
        myEvent: action.data,
        myEventLoaded: true,
        isLoading: false,
      };

    case UPDATE_MY_EVENT:
      return {
        ...state,
        myEvent: {
          ...changeUserHasClickedEvaluationLink(
            state.myEvent,
            action.userHasClickEvaluationLink
          ),
        },
        isLoading: false,
      };
    case SET_BOOKINGS_PAGE_COUNT:
      return {
        ...state,
        currentPage: action.pageCount,
        myEventListLoaded: false,
      };
    case CLEAR_BOOKINGS:
      return initialBookingState;
    default:
      return state;
  }
}

export default bookingReducer;
