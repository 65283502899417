import { ContentState, convertToRaw } from "draft-js";
import moment, { Moment } from "moment";
import { Utils } from "../services/utils";
import { FilterData } from "../services/filters/filterData";
import { ChecklistData } from "./checklistData";
import { DocumentData, DocumentIdData } from "./documentData";
import { MyELearningData } from "./elearning";
import { EventStatus } from "./enums/eventStatus.enum";
import { EventType, InternalExternalEventType } from "./enums/eventType.enum";
import { ParticipationStatus } from "./enums/participationStatus.enum";
import { EventsSortBy } from "./enums/sortBy.enum";
import { TrainingType } from "./enums/trainingType.enum";
import { SpeakerBaseData } from "./speakerData";
import {
  ParticipationType,
  TargetGroupIdData,
  TargetGroupListData,
} from "./targetGroupData";
import { SimpleTaskData } from "./taskData";
import { NIL as NIL_UUID } from "uuid";
import { DiscountItem } from "./discountData";
import { CertificateTypeData } from "./certificateTypeData";

export interface MyEventData extends TargetGroupIdData {
  id: string;
  public_id: number;
  title: string;
  location: string;
  location_details: string;
  begin: Date;
  end: Date;
  score: number;
  price: number;
  no_automatic_participation_certificate: boolean;
  description: string;
  min_seats: number;
  max_seats: number;
  status: number;
  event_type: number;
  training_type: number;
  speakers: string[];
  documents?: DocumentData[];
  within_cancellation_period: boolean;
  evaluation_link?: string;
  max_time_period_to_finish_elearning_module_in_weeks?: number;
  publish_elearning_after_event?: boolean;
}

export interface UserLinkedAccountStats {
  user: string;
  status: ParticipationStatus;
}

export interface ActivityHistoryMinimalEventData {
  id: string;
  public_id: number;
  title: string;
  training_type: TrainingType;
  location: string;
}

export interface ActivityHistoryEventData extends ActivityHistoryMinimalEventData {
  location: string;
  begin: Date;
  end: Date;
  training_type: TrainingType;
  event_type: EventType;
  max_time_period_to_finish_elearning_module_in_weeks?: number;
  publish_elearning_after_event?: boolean;
}

export interface EventListData extends TargetGroupIdData {
  url: string;
  id: string;
  vnr: string;
  public_id: number;
  title: string;
  location: string;
  begin: Date | null;
  end: Date | null;
  score: number;
  price: number;
  no_automatic_participation_certificate: boolean;
  seats: number;
  seats_taken: number;
  status: number;
  is_capped: boolean;
  internal_external: InternalExternalEventType;
  event_type: number;
  training_type: number;
  participation_status: number;
  participation_status_linked_accounts: UserLinkedAccountStats[];
  waiting_list: number;
  description: string;
  speakers: SpeakerBaseData[];
  is_invoiced?: boolean;
  has_open_tasks?: boolean;
  participants_status_set?: boolean;
  is_favorite: boolean;
  evaluation_link?: string;
  evaluation_link_speaker?: string;
  is_my_target_group?: boolean;
  total_amount: number;
  vat_amount: number;
  value_added_tax_rate: number | undefined;
}

export interface MyEventListData {
  id: string;
  event: MyELearningData;
  status: ParticipationStatus;
  created_on: Date;
  last_changed_on: Date;
  last_changed_by: string;
  user_has_clicked_evaluation_link: boolean;
  overall_begin: Date;
  overall_end: Date;
  participation_certificate_was_downloaded: boolean;
  participation_certificate_availability_date: Date | null;
  access_granted_by_admin?: boolean;
}

export interface EventFormData extends TargetGroupIdData {
  vnr: string;
  title: string;
  score: number;
  price: number;
  minseats: number;
  maxseats: number;
  status: number;
  isCapped: boolean;
  internalExternalEvent: InternalExternalEventType;
  noReminder: boolean;
  noDiscount: boolean;
  noAutomaticParticipationCertificate: boolean;
  description: ContentState;
  descriptionState: ContentState;
  speakers: SpeakerBaseData[];
  location: string;
  city: string;
  zip_code: string;
  locationDetails: string;
  eventType: number;
  trainingType: number;
  beginDate: Moment | null;
  endDate: Moment | null;
  beginTime: Moment | null;
  endTime: Moment | null;
  catering: boolean;
  documents: string[];
  commentary: string;
  participation?: number;
  searchSpeakers: string;
  submitWithTemplate?: boolean;
  tasks: SimpleTaskData[];
  checklist: ChecklistData[];
  checklist_additional_info: string;
  evaluationLink: string;
  evaluationLinkSpeaker: string;
  value_added_tax_rate?: number;
  discounts: DiscountItem[];
  is_certification_selected: boolean;
  certificate_type: CertificateTypeData;
}
export interface CrudEventDataDto extends TargetGroupListData {
  public_id?: number;
  vnr: string;
  title: string;
  location: string;
  city: string | null;
  zip_code: string | null;
  location_details: string;
  begin: Date | null;
  end: Date | null;
  score: number;
  price: number;
  description: string;
  min_seats: number;
  max_seats: number;
  status: number;
  is_capped: boolean;
  internal_external: InternalExternalEventType;
  no_reminder: boolean;
  no_discount: boolean;
  no_automatic_participation_certificate: boolean;
  catering: boolean;
  event_type: number;
  training_type: number;
  speakers: SpeakerBaseData[];
  commentary: string;
  is_invoiced?: boolean;
  has_open_tasks?: boolean;
  participants_status_set?: boolean;
  documents?: DocumentIdData[];
  tasks: SimpleTaskData[];
  evaluation_link?: string;
  evaluation_link_speaker?: string;
  template: string | null;
  value_added_tax_rate?: number;
  discounts: DiscountItem[];
  is_certification_selected: boolean;
  certificate_type: CertificateTypeData;
}

export interface EventDataDto extends CrudEventDataDto {
  url: string;
  id: string;
  checklist: ChecklistData[];
  checklist_additional_info: string;
  evaluation_link?: string;
  max_seats_taken: boolean;
  discounts: DiscountItem[];
  certificate_type: CertificateTypeData;
}

export interface EventFilterValues {
  locations: string[];
}

export interface EventFilterData extends FilterData<EventsSortBy>, TargetGroupIdData {
  search: string;
  participation_types: string[];
  event_type: number[];
  training_type: number[];
  location: string[];
  status: string;
  my_target_group: boolean;
  begin_gte: string;
  begin_lte: string;
}

export interface EventListPossibleFilters {
  public_id?: number;
  location?: string[];
  begin?: Date;
  status?: string;
}

export function mapAddEventToDto(
  data: EventFormData,
  templateId?: string
): CrudEventDataDto {
  const addEventDataDto: CrudEventDataDto = {
    vnr: data.vnr,
    title: data.title,
    location: data.location,
    city: data.eventType === EventType.OnSite ? data.city : null,
    zip_code: data.eventType === EventType.OnSite ? data.zip_code : null,
    location_details: data.locationDetails,
    begin: mapDateAndTime(data.beginDate, data.beginTime),
    end: mapDateAndTime(data.endDate, data.endTime),
    score: data.score,
    price: parseFloat((data.price * 100).toFixed(2)),
    description: JSON.stringify(convertToRaw(data.description)),
    min_seats: data.minseats,
    max_seats: data.maxseats,
    status: data.status,
    is_capped: data.isCapped,
    internal_external: data.internalExternalEvent,
    event_type: data.eventType,
    training_type: data.trainingType,
    participation_types: mapToParticipationTypeList(data.participation_types),
    speakers: data.speakers,
    documents: mapToDocumentList(data.documents),
    commentary: data.commentary,
    no_reminder: data.noReminder,
    no_discount: data.noDiscount,
    no_automatic_participation_certificate: data.noAutomaticParticipationCertificate,
    tasks: data.tasks,
    catering: data.catering,
    evaluation_link: data.evaluationLink,
    evaluation_link_speaker: data.evaluationLinkSpeaker,
    template: templateId ? templateId : null,
    value_added_tax_rate: data.value_added_tax_rate,
    discounts: data.discounts,
    is_certification_selected: data.is_certification_selected,
    certificate_type: data.certificate_type,
  };
  return addEventDataDto;
}

export function mapEditEventDtoToEventForm(data: EventDataDto): EventFormData {
  const editEventFormData: EventFormData = {
    title: data.title,
    vnr: data.vnr,
    score: data.score,
    price: data.price / 100,
    minseats: data.min_seats,
    maxseats: data.max_seats,
    participation_types: mapToStringList(data.participation_types),
    status: data.status,
    isCapped: data.is_capped,
    internalExternalEvent: data.internal_external,
    noReminder: data.no_reminder,
    noDiscount: data.no_discount,
    noAutomaticParticipationCertificate: data.no_automatic_participation_certificate,
    description: Utils.convertJsonToRteState(data.description),
    descriptionState: Utils.convertJsonToRteState(data.description),
    speakers: data.speakers,
    location: data.location,
    city: data.city || "Frankfurt",
    zip_code: data.zip_code || "60486",
    locationDetails: data.location_details,
    eventType: data.event_type,
    trainingType: data.training_type,
    beginDate: moment(data.begin),
    endDate: moment(data.end),
    beginTime: moment(data.begin),
    endTime: moment(data.end),
    catering: data.catering,
    documents: mapDocumentToStringList(data.documents!),
    commentary: data.commentary,
    searchSpeakers: "",
    tasks: data.tasks,
    checklist: data.checklist,
    checklist_additional_info: data.checklist_additional_info,
    evaluationLink: data.evaluation_link || "",
    evaluationLinkSpeaker: data.evaluation_link_speaker || "",
    value_added_tax_rate: data.value_added_tax_rate,
    discounts: data.discounts,
    is_certification_selected: data.is_certification_selected,
    certificate_type: data.certificate_type,
  };
  return editEventFormData;
}
export function mapEventFormDataToEventListData(data: EventFormData): EventListData {
  const previewEventData: EventListData = {
    url: "",
    id: NIL_UUID,
    vnr: "",
    public_id: 0,
    title: data.title,
    location: data.location,
    begin: mapDateAndTime(data.beginDate, data.beginTime),
    end: mapDateAndTime(data.endDate, data.endTime),
    score: data.score,
    price: parseFloat((data.price * 100).toFixed(2)),
    seats: 10,
    seats_taken: 0,
    event_type: data.eventType,
    training_type: data.trainingType,
    status: EventStatus.Published,
    is_capped: false,
    no_automatic_participation_certificate: false,
    internal_external: data.internalExternalEvent,
    participation_status: ParticipationStatus.NotParticipated,
    participation_status_linked_accounts: [],
    waiting_list: 0,
    description: JSON.stringify(convertToRaw(data.description)),
    participation_types: data.participation_types,
    speakers: data.speakers,
    has_open_tasks: true, //Dont show DoneIcon in Preview
    is_favorite: false,
    total_amount: 0,
    vat_amount: 0,
    value_added_tax_rate: data.value_added_tax_rate,
  };
  return previewEventData;
}

export function mapDateAndTime(
  dateData: Moment | null,
  timeData: Moment | null
): Date | null {
  let combineDateTime = null;
  if (dateData !== null) {
    combineDateTime = moment(dateData);
    if (timeData !== null) {
      combineDateTime.hours(timeData.hours());
      combineDateTime.minutes(timeData.minutes());
    }
  }
  return combineDateTime !== null ? combineDateTime.toDate() : null;
}

function mapToStringList(participation_types: ParticipationType[]): string[] {
  return participation_types?.map((v) => v.id);
}

function mapToParticipationTypeList(participation_types: string[]): ParticipationType[] {
  return participation_types?.map((v) => ({
    id: v,
    document_type: "",
    label: "",
  }));
}

function mapDocumentToStringList(documents: DocumentIdData[]): string[] {
  return documents?.map((v) => v.id);
}

function mapToDocumentList(documents: string[]): DocumentIdData[] {
  return documents?.map((v) => ({ id: v }));
}
