import { faCalendarPlus } from "@fortawesome/free-solid-svg-icons/faCalendarPlus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Grid, Tooltip, Link } from "@material-ui/core";
import DOMPurify from "dompurify";
import * as React from "react";
import { useState } from "react";
import ICalendarLink from "react-icalendar-link";
import { useSelector } from "react-redux";
import { AppState } from "../../../../../redux";
import { Utils } from "../../../../../services/utils";
import "../../../../../pages/core/event/UserEventsPage.scss";
import { MyEventListData } from "../../../../../models/eventData";
import { TrainingType } from "../../../../../models/enums/trainingType.enum";
import { defaultPalette } from "../../../../theming/defaultTheme";
interface CalendarLinkProps {
  booking: MyEventListData;
  eLearningDeadline?: Date;
  trainingType?: TrainingType;
}

const CalendarLink: React.FC<CalendarLinkProps> = (props) => {
  const accessibility = useSelector((state: AppState) => state.accessibility);
  const [isHovered, setIsHovered] = useState<boolean>(false);

  let linkTitle = "Download Kalendereintrag";
  if (props.trainingType && props.trainingType === TrainingType.BlendedLearning) {
    linkTitle += " für diese Blended-Learning-Veranstaltung";
  } else if (props.eLearningDeadline) {
    linkTitle += " für dieses E-Learning";
  } else {
    linkTitle += " für diese Veranstaltung";
  }

  const eventTitle =
    props.booking.event.title.length > 0 ? props.booking.event.title : "(Kein Titel)";

  const calendarDescription = DOMPurify.sanitize(
    Utils.convertRteStateToHtml(
      Utils.convertJsonToRteState(props.booking.event.description)
    )
  ).replace(/<[^>]*>?/gm, "");

  const iCalenderEvent = props.eLearningDeadline
    ? {
        title: "Bearbeitungsende des E-Learnings",
        description: eventTitle + "\\n\\n" + calendarDescription,
        startTime: props.eLearningDeadline.toString(),
        endTime: props.eLearningDeadline.toString(),
        location: props.booking.event.location,
      }
    : {
        title: eventTitle,
        description:
          calendarDescription + "\\n\\n" + props.booking.event.location_details,
        startTime: new Date(props.booking.event.begin).toString(),
        endTime: new Date(props.booking.event.end).toString(),
        location: props.booking.event.location,
      };

  return (
    <Grid item xs={12} md={1}>
      <Box
        component="span"
        display="flex"
        alignItems="center"
        style={{ height: "100%" }}
      >
        <Tooltip
          placement="bottom"
          enterDelay={500}
          leaveDelay={200}
          title={linkTitle}
          arrow
        >
          <ICalendarLink event={iCalenderEvent}>
            <Link
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <FontAwesomeIcon
                color={accessibility.monoMode ? "#000" : defaultPalette.secondary?.main}
                icon={faCalendarPlus}
                className={"myevents-details-link"}
                style={{
                  color: isHovered
                    ? defaultPalette.primary?.main
                    : defaultPalette.secondary?.main,
                }}
              />
            </Link>
          </ICalendarLink>
        </Tooltip>
      </Box>
    </Grid>
  );
};

export default CalendarLink;

// TODO no translation yet
