import apiRoutes from "./apiRoutes";
import { BaseCrudService } from "./baseCrudService";
import { InvoiceData, MyInvoiceListData } from "../models/invoiceData";
import { Pagination } from "../models/pagination";

export class InvoiceService extends BaseCrudService {
  async createInvoice() {
    const response = await this.create<InvoiceData>(apiRoutes.invoices);
    return response.data;
  }

  async getInvoices() {
    const response = await this.getAll<Pagination<MyInvoiceListData>>(
      apiRoutes.invoices
    );
    return response.data;
  }

  async getMyInvoiceList(page: number) {
    const response = await this.getAll<Pagination<MyInvoiceListData>>(
      apiRoutes.invoices,
      { page: page }
    );
    return response.data;
  }

  async getMyInvoicesCount() {
    const response = await this.getAll<{ count: number }>(apiRoutes.invoices_count);
    return response.data;
  }
}
