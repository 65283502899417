import apiRoutes from "./apiRoutes";
import { BaseCrudService } from "./baseCrudService";
import { MaintenanceBannerData } from "../models/maintenanceData";

/**
 * Service class for maintenance related API calls
 * @extends BaseCrudService
 * @category Services
 */
export class MaintenanceService extends BaseCrudService {
  /**
   * Fetches the maintenance banner data
   * @returns {Promise<MaintenanceBannerData>} The maintenance banner data
   */
  async getMaintenanceBanner() {
    const response = await this.getAll<MaintenanceBannerData[]>(apiRoutes.maintenance);
    return response.data;
  }
}

export const maintenanceService = new MaintenanceService();
