import * as yup from "yup";
import { positiveInteger } from "../../events/shared/validationRules";

const eventTemplateValidationSchema = () =>
  yup.object({
    title: yup.string().required("Der Veranstaltungsname ist ein Pflichtfeld."),
    score: yup
      .number()
      .integer("Bitte geben Sie eine Ganzzahl ein.")
      .min(0, "Die Fortbildungspunkte dürfen nicht kleiner als 0 sein.")
      .required("Die Angabe der Fortbildungspunkte ist Pflicht."),
    price: yup
      .number()
      .min(0, "Die Kosten der Veranstaltung dürfen nicht kleiner als 0 sein."),
    minseats: positiveInteger,
    maxseats: positiveInteger.test(
      "maxseats",
      "Die maximale Teilnehmeranzahl muss größer als die minimale Teilnehmeranzahl sein.",
      function (maxSeats: number | undefined) {
        // !NOTE: theres a bug in yup that prevents using moreThan with a reference to the field (see https://github.com/jquense/yup/pull/1208)
        // !NOTE: use test function as workaround
        let minSeats = this.resolve(yup.ref("minseats")) as number;
        if (maxSeats && minSeats) return maxSeats >= minSeats;
        return false;
      }
    ),
    participation_types: yup
      .array()
      .min(1, "Es muss mindestens eine Zielgruppe ausgewählt sein.")
      .required("Es muss mindestens eine Zielgruppe ausgewählt sein."),
  });

export default eventTemplateValidationSchema;

// TODO no translation yet
