import apiRoutes from "./apiRoutes";
import { BaseCrudService } from "./baseCrudService";
import { OrganizationInvoiceData } from "../models/organizationInvoiceData";

/**
 * The organization invoice service
 */
export class OrganizationInvoiceService extends BaseCrudService {
  /**
   * Fetches the organization invoice data
   * @returns {Promise<OrganizationInvoiceData[]>}
   */
  async getInvoiceByOrganizationId(organizationId: string) {
    const response = await this.getAll<OrganizationInvoiceData[]>(
      `${apiRoutes.organization_invoice}/${organizationId}/get_invoices`
    );
    return response.data;
  }
}
