import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { matchPath, useLocation } from "react-router-dom";
import { AppState } from "../../redux";
import { Utils } from "../../services/utils";
import { BreadcrumbsService } from "./header/navigation/breadcrumbs/breadcrumbsService";
import { getAllRoutes } from "./header/navigation/navigationData";
import routes from "../../routing/routes";
import { validate as isValidUUID } from "uuid";

export const useFullWidth = () => {
  const location = useLocation();
  const lastPartOfRoute = Utils.getLastPartOfPathWithoutSlash(location.pathname);
  const isDetailPage = isValidUUID(lastPartOfRoute) !== false; // check if is page that has params /:id with UUID format (e.g. event edit page)
  const isMaintenancePage =
    location.pathname === routes.maintenance || location.pathname === routes.contact;

  const eventFetchingError = useSelector((state: AppState) => state.event.notFoundError);
  const eventTemplateFetchingError = useSelector(
    (state: AppState) => state.eventTemplates.notFoundError
  );
  const taskTemplateFetchingError = useSelector(
    (state: AppState) => state.taskTemplates.notFoundError
  );
  const taskFetchingError = useSelector((state: AppState) => state.tasks.notFoundError);

  // add more / other conditions that cause the container to be shown in full width if needed
  const [isFullWidth, setIsFullWidth] = useState<boolean>(false);

  /**
   * Checks if the current route is in the routes (and subroutes) the user should see and returns the matching routes as string[].
   */
  const checkAllAvailableRoutes = useCallback(() => {
    const availableRoutesByUser = getAllRoutes(); // get all routes by user
    // use breadcrumbservice to also consider the subroutes
    const breadcrumbsService = new BreadcrumbsService();
    const subPaths = breadcrumbsService.splitIntoSubpaths(location.pathname);

    // get the breadcrumbs for the current route
    const getRoutesFromNavigationData = breadcrumbsService
      .getBreadCrumbs(subPaths, availableRoutesByUser)
      .map((x) => x.route);

    return getRoutesFromNavigationData;
  }, [location.pathname]);

  const allAvailableRoutesByUser = checkAllAvailableRoutes();

  // check if the path with the params :id is a valid path (check UUID format to find out, if its an valid id)
  function doesPathMatch() {
    // check if the current route matches the routes the user can see
    const matchedPaths = Object.values(allAvailableRoutesByUser).filter((x) => {
      return (
        !!matchPath(location.pathname, {
          path: x,
          exact: true,
          strict: true,
        }) ||
        isDetailPage ||
        location.pathname.endsWith("pinnwand") || // for routes.pinboard_staff_blended_learning_id not to take full width
        location.pathname.endsWith("finaler-checkout")
      );
    });
    if (matchedPaths && matchedPaths.length > 0) {
      return true;
    }
    return false;
  }

  const routeDoesNotMatchKnownRoutes = !doesPathMatch();

  // TODO: maybe find some better solution than checking all edit pages for errors
  useEffect(() => {
    setIsFullWidth(
      (!isMaintenancePage && routeDoesNotMatchKnownRoutes) ||
        (eventFetchingError && isDetailPage) ||
        (eventTemplateFetchingError && isDetailPage) ||
        (taskTemplateFetchingError && isDetailPage) ||
        (taskFetchingError && isDetailPage)
    );
  }, [
    isDetailPage,
    allAvailableRoutesByUser,
    eventFetchingError,
    routeDoesNotMatchKnownRoutes,
    eventTemplateFetchingError,
    taskTemplateFetchingError,
    taskFetchingError,
    isMaintenancePage,
  ]);

  return { isFullWidth, routeDoesNotMatchKnownRoutes };
};

export default useFullWidth;
