import { Box, Grid, Link, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import GetAppIcon from "@material-ui/icons/GetApp";
import { Form, Formik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../redux";
import { showDialog } from "../../../../redux/dialogs/actions";
import {
  deleteDocument,
  editDocumentState,
  getAllDefaultDocuments,
  setDocumentsLoaded,
} from "../../../../redux/documents/actions";
import CustomCheckbox from "../../../forms/CustomCheckbox";
import "../../events/shared/Documents.scss";
import { UploadArea } from "../../events/shared/UploadArea";

export const DocumentTemplates: React.FC = () => {
  const dispatch = useDispatch();
  const documents = useSelector((state: AppState) => state.documents);
  const accessibility = useSelector((state: AppState) => state.accessibility);

  useEffect(() => {
    if (documents.documentList.length === 0 && !documents.documentListLoaded) {
      dispatch(getAllDefaultDocuments());
      dispatch(setDocumentsLoaded());
    }
  }, [dispatch, documents.documentList.length, documents.documentListLoaded]);

  return (
    <>
      <Grid container spacing={4}>
        <UploadArea defaultDocuments={true} />
        <Grid item xs={12}>
          <h3>Standard-Dokumente</h3>
          <Formik enableReinitialize onSubmit={() => {}} initialValues={{}}>
            {({ values }) => (
              <Form>
                <Grid container className={"documents-list-itemsHeader"}>
                  <Grid item xs={5}>
                    <b>Name</b>
                  </Grid>
                  <Grid item xs={1} style={{ textAlign: "center" }}>
                    <b>Online</b>
                  </Grid>
                  <Grid item xs={1} style={{ textAlign: "center" }}>
                    <b>Präsenz</b>
                  </Grid>
                  <Grid item xs={1} style={{ textAlign: "center" }}>
                    <b>E-Learning</b>
                  </Grid>
                  <Grid item xs={3} style={{ textAlign: "center" }}>
                    <b>Veröffentlichung</b>
                    <Grid container spacing={0}>
                      <Grid item xs={4}>
                        Referent
                      </Grid>
                      <Grid item xs={4}>
                        Teilnehmer
                      </Grid>
                      <Grid item xs={4}>
                        <Tooltip
                          placement="bottom"
                          title={"* wird nach Ende der Veranstaltung veröffentlicht"}
                          arrow
                        >
                          <Box component={"span"} style={{ cursor: "pointer" }}>
                            Nach VA*
                          </Box>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={1} style={{ textAlign: "center" }}>
                    <b>Löschen</b>
                  </Grid>
                </Grid>
                {documents.documentList.length === 0 ? (
                  <Box
                    component="span"
                    display="flex"
                    alignItems="center"
                    style={{ height: "6.25rem" }}
                  >
                    Es gibt keine Standard-Dokumente
                  </Box>
                ) : (
                  documents.documentList.map((document, index) => {
                    return (
                      <Grid container key={index} className={"documents-list-item"}>
                        <Grid item xs={5}>
                          <Box
                            component="span"
                            display="flex"
                            alignItems="center"
                            height={"100%"}
                          >
                            <Link href={document.file} target={"_blank"}>
                              <GetAppIcon className={"documents-list-downloadIcon"} />
                            </Link>{" "}
                            {document.title}
                          </Box>
                        </Grid>
                        <Grid item xs={1} style={{ textAlign: "center" }}>
                          <CustomCheckbox
                            name="is_default_online"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                              document.is_default_online = event.target.checked;
                              let data: FormData = new FormData();
                              data.append(
                                "is_default_online",
                                event.target.checked.toString()
                              );
                              dispatch(editDocumentState(document, data));
                            }}
                            checked={document?.is_default_online}
                          />
                        </Grid>
                        <Grid item xs={1} style={{ textAlign: "center" }}>
                          <CustomCheckbox
                            name="is_default_on_site"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                              document.is_default_on_site = event.target.checked;
                              let data: FormData = new FormData();
                              data.append(
                                "is_default_on_site",
                                event.target.checked.toString()
                              );
                              dispatch(editDocumentState(document, data));
                            }}
                            checked={document?.is_default_on_site}
                          />
                        </Grid>
                        <Grid item xs={1} style={{ textAlign: "center" }}>
                          <CustomCheckbox
                            name="is_default_elearning"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                              document.is_default_elearning = event.target.checked;
                              let data: FormData = new FormData();
                              data.append(
                                "is_default_elearning",
                                event.target.checked.toString()
                              );
                              dispatch(editDocumentState(document, data));
                            }}
                            checked={document?.is_default_elearning}
                          />
                        </Grid>
                        <Grid item xs={1} style={{ textAlign: "center" }}>
                          <CustomCheckbox
                            name="publish_speaker"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                              document.publish_speaker = event.target.checked;
                              let data: FormData = new FormData();
                              data.append(
                                "publish_speaker",
                                event.target.checked.toString()
                              );
                              dispatch(editDocumentState(document, data));
                            }}
                            checked={document.publish_speaker}
                          />
                        </Grid>
                        <Grid item xs={1} style={{ textAlign: "center" }}>
                          <CustomCheckbox
                            name="publish_participant"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                              document.publish_participant = event.target.checked;
                              let data: FormData = new FormData();
                              data.append(
                                "publish_participant",
                                event.target.checked.toString()
                              );
                              dispatch(editDocumentState(document, data));
                            }}
                            checked={document.publish_participant}
                          />
                        </Grid>
                        <Grid item xs={1} style={{ textAlign: "center" }}>
                          <CustomCheckbox
                            name="publish_after_event"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                              document.publish_after_event = event.target.checked;
                              let data: FormData = new FormData();
                              data.append(
                                "publish_after_event",
                                event.target.checked.toString()
                              );
                              dispatch(editDocumentState(document, data));
                            }}
                            checked={document.publish_after_event}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={1}
                          style={{
                            textAlign: "center",
                            margin: "auto",
                          }}
                        >
                          <DeleteIcon
                            onClick={() => {
                              dispatch(
                                showDialog({
                                  title: "Standard-Dokument entfernen",
                                  message:
                                    "Sind Sie sich sicher, dass Sie dieses Standard-Dokument entfernen möchten?",
                                  action: () => {
                                    dispatch(deleteDocument(document));
                                  },
                                })
                              );
                            }}
                            className={
                              accessibility.monoMode
                                ? "documents-list-item-delButton-mono"
                                : "documents-list-item-delButton"
                            }
                          />
                        </Grid>
                      </Grid>
                    );
                  })
                )}
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </>
  );
};

// TODO no translation yet
