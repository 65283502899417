import { MinimumAgeData } from "../../models/systemSettingsData";

// The types for the minimum age feature are defined in this file.
// The types include the state shape and the action types for the minimum age feature.
export const FETCH_MINIMUM_AGE_REQUEST = "FETCH_MINIMUM_AGE_REQUEST";
export const FETCH_MINIMUM_AGE_SUCCESS = "FETCH_MINIMUM_AGE_SUCCESS";
export const FETCH_MINIMUM_AGE_FAILURE = "FETCH_MINIMUM_AGE_FAILURE";

export interface MinimumAgeState {
  minimumAge: MinimumAgeData;
  isLoading: boolean;
  error: string | null;
}

export interface FetchMinimumAgeRequestAction {
  type: typeof FETCH_MINIMUM_AGE_REQUEST;
}

export interface FetchMinimumAgeSuccessAction {
  type: typeof FETCH_MINIMUM_AGE_SUCCESS;
  payload: MinimumAgeData;
}

export interface FetchMinimumAgeFailureAction {
  type: typeof FETCH_MINIMUM_AGE_FAILURE;
  error: string;
}

export type MinimumAgeActionTypes =
  | FetchMinimumAgeRequestAction
  | FetchMinimumAgeSuccessAction
  | FetchMinimumAgeFailureAction;
