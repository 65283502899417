import React from "react";
import { EventStatus } from "../../../../models/enums/eventStatus.enum";
import { showDialog } from "../../../../redux/dialogs/actions";
import { EventDataDto, EventListData } from "../../../../models/eventData";
import { Dispatch } from "redux";
import { DialogActionTypes } from "../../../../redux/dialogs/types";

// TODO: check what to do adapt for elearnings
export const checkIfCompleteEventIsApproved = (
  event: React.ChangeEvent<{
    name?: string;
    value: unknown;
  }>,
  editEventState: EventDataDto | EventListData,
  dispatch: Dispatch<DialogActionTypes>
) => {
  const end = editEventState.end || new Date();

  if (event.target.value === EventStatus.Completed) {
    if (editEventState.price === 0) {
      if (
        editEventState.has_open_tasks ||
        (editEventState.end !== null &&
          new Date(editEventState.end).getTime() > new Date().getTime()) ||
        !editEventState.participants_status_set
      ) {
        dispatch(
          showDialog(
            {
              title: "Veranstaltung kann nicht abgeschlossen werden",
              message: (
                <>
                  Die Veranstaltung kann nicht abgeschlossen werden. Schließen Sie vorher
                  folgende(n) Punkt(e) ab:
                  <br />
                  <br />
                  {new Date(end).getTime() > new Date().getTime() && (
                    <>
                      <b>Die Veranstaltung wurde noch nicht beendet.</b>
                      <br />
                    </>
                  )}
                  {(editEventState.vnr === null ||
                    editEventState.vnr === "" ||
                    !editEventState.vnr) && (
                    <>
                      <b>Es wurde noch keine VNR für diese Veranstaltung vergeben.</b>
                      <br />
                    </>
                  )}
                  {editEventState.has_open_tasks && (
                    <>
                      <b>Es wurden noch nicht alle Aufgaben abgeschlossen.</b>
                      <br />
                    </>
                  )}
                  {!editEventState.participants_status_set && (
                    <>
                      <b>
                        Es haben noch nicht alle Teilnehmer auf der Teilnahmeliste den
                        Status "teilgenommen" oder "nicht teilgenommen".
                      </b>
                      <br />
                    </>
                  )}
                </>
              ),
            },
            true
          )
        );
        return false;
      } else {
        return true;
      }
    } else if (
      !editEventState.is_invoiced ||
      editEventState.has_open_tasks ||
      (editEventState.end !== null &&
        new Date(editEventState.end).getTime() > new Date().getTime())
    ) {
      dispatch(
        showDialog(
          {
            title: "Veranstaltung kann nicht abgeschlossen werden",
            message: (
              <>
                Die Veranstaltung kann nicht abgeschlossen werden. Schließen Sie vorher
                folgende(n) Punkt(e) ab:
                <br />
                <br />
                {new Date(end).getTime() > new Date().getTime() && (
                  <>
                    <b>Die Veranstaltung wurde noch nicht beendet.</b>
                    <br />
                  </>
                )}
                {(editEventState.vnr === null ||
                  editEventState.vnr === "" ||
                  !editEventState.vnr) && (
                  <>
                    <b>Es wurde noch keine VNR für diese Veranstaltung vergeben.</b>
                    <br />
                  </>
                )}
                {editEventState.has_open_tasks && (
                  <>
                    <b>Es wurden noch nicht alle Aufgaben abgeschlossen.</b>
                    <br />
                  </>
                )}
              </>
            ),
          },
          true
        )
      );
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
};

// TODO no translation yet
