import * as React from "react";
import "./Cart.scss";
import { Box, Hidden, TableCell, TableRow, Tooltip } from "@material-ui/core";
import { CartItemData } from "../../../models/cartData";
import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from "@material-ui/icons/Delete";
import { removeEventFromCart } from "../../../redux/cart/actions";
import { Utils } from "../../../services/utils";
import { AppState } from "../../../redux";

interface CartItemProps {
  event: CartItemData;
  checkout?: boolean;
}

const CartItem: React.FC<CartItemProps> = (props) => {
  const dispatch = useDispatch();

  const taxRate = Utils.formatTax(props.event.value_added_tax_rate);
  const userData = useSelector((state: AppState) => state.user.currentUser);
  const shortTitle =
    props.event.title.substring(0, 25).split(" ").slice(0, -1).join(" ") + "...";

  const localStartDate = new Date(props.event.begin).toLocaleDateString();
  const localBeginOrCreateDate = new Date(
    props.event.begin || props.event.created_on
  ).toLocaleDateString();
  const nettoPrice = Utils.formatPrice(props.event.price);

  const discountAmount = props.event.discount;
  const discountAmountFormated = Utils.formatDiscountAmount(discountAmount);

  const taxAmount = Utils.formatPrice(props.event.vat_amount, true, false);

  const bruttoPrice = props.event.gross_price;

  const bruttoPriceFormated = Utils.formatPrice(bruttoPrice);

  const bookedForUserName = `${props.event.book_for.first_name} ${props.event.book_for.last_name}`;
  const participantName =
    props.event.book_for.id === userData?.id ? "mich" : bookedForUserName;

  const bookedFor = (
    <>
      gebucht für:{" "}
      {props.event.book_for.id === userData?.id ? <b></b> : <b>{bookedForUserName}</b>}
    </>
  );

  const speaker = (
    <>
      <br />
      {props.event.speakers.length === 1 && "Referent:"}
      {props.event.speakers.length > 1 && "Referenten:"}
      <br />
      {props.event.speakers.map((speaker, index) => {
        return (
          <React.Fragment key={index}>
            {speaker}
            <br />
          </React.Fragment>
        );
      })}
    </>
  );

  /*Columns
   * smDown: EventID |
   * mdUP: EventID + shortTitle |
   * */

  return (
    <TableRow>
      <Hidden smDown>
        <TableCell component="th" scope="row">
          {props.event.public_id}
        </TableCell>
      </Hidden>
      <TableCell align="left">
        <Hidden mdUp>
          #{props.event.public_id}
          <br />
        </Hidden>
        {props.event.title.length > 30 ? (
          <Tooltip title={props.event.title} arrow>
            <Box component={"span"}>{shortTitle}</Box>
          </Tooltip>
        ) : (
          props.event.title
        )}
        <Hidden smUp>
          <br />
          {localStartDate}
        </Hidden>
        <Hidden mdUp>
          <br />
          {bookedFor}
        </Hidden>
        <Hidden mdUp>{speaker}</Hidden>
      </TableCell>
      <Hidden xsDown>
        <TableCell align="left">{localBeginOrCreateDate}</TableCell>
      </Hidden>
      <Hidden smDown>
        <TableCell align="left">{nettoPrice}</TableCell>
      </Hidden>
      <TableCell align="right">{discountAmountFormated}</TableCell>
      {props.checkout && (
        <>
          <TableCell align="right" style={{ paddingLeft: "0.625rem" }}>
            {taxRate}
          </TableCell>
          <TableCell align="right" style={{ paddingLeft: "0.625rem" }}>
            {taxAmount}
          </TableCell>
        </>
      )}
      <TableCell align="right">{bruttoPriceFormated}</TableCell>
      <TableCell align="center">
        <DeleteIcon
          style={{ cursor: "pointer" }}
          onClick={() => dispatch(removeEventFromCart(props.event.id))}
        />
      </TableCell>
    </TableRow>
  );
};

export default CartItem;
