import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@material-ui/core";
import {
  LumosArrowDownSvg,
  LumosLanguageSvg,
} from "../../../assets/icons/custom-svg-components/LumosSvgCollection";
import { defaultLanguage, languageOptions } from "./languageOptions";

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(() => {
    return localStorage.getItem("selectedLanguage") || i18n.language;
  });

  useEffect(() => {
    localStorage.setItem("selectedLanguage", selectedLanguage);
  }, [selectedLanguage]);

  const selectedLanguageLabel =
    languageOptions.find((option) => option.value === selectedLanguage.split("-")[0])
      ?.label || defaultLanguage.label;

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    setSelectedLanguage(lng);
    setIsOpen(false);
  };

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (selectedLanguage: string) => {
    changeLanguage(selectedLanguage);
  };

  return (
    <Box display="inline-flex" alignItems="center" position="relative">
      <Box onClick={handleToggleDropdown} style={{ cursor: "pointer" }}>
        <div
          onClick={handleToggleDropdown}
          style={{ display: "flex", alignItems: "center" }}
        >
          <LumosLanguageSvg />
          {selectedLanguageLabel}
          <LumosArrowDownSvg />
        </div>
      </Box>
      {isOpen && (
        <Box
          position="absolute"
          top="100%"
          left="0"
          boxShadow="0 .25rem .5rem rgba(0, 0, 0, 0.1)"
          style={{ backgroundColor: "#fff", minWidth: "100%", zIndex: 600 }}
        >
          {languageOptions
            .filter((option) => option.value !== selectedLanguage)
            .map((option) => (
              <div
                key={option.value}
                onClick={() => handleOptionClick(option.value)}
                style={{ padding: ".5rem", cursor: "pointer" }}
              >
                {option.label}
              </div>
            ))}
        </Box>
      )}
    </Box>
  );
};

export default LanguageSwitcher;
