import { Dispatch } from "redux";
import { enqueueSnackbar } from "notistack";
import { onSiteAuthService } from "../../api/auth/onSiteAuthService";
import { getDynamicTranslation } from "../../components/core/localization/getStringTranslation";

import {
  OnSiteAuthActionTypes,
  OnSiteAuthRequestAction,
  OnSiteAuthSuccessAction,
  OnSiteAuthFailureAction,
  ON_SITE_AUTH_REQUEST,
  ON_SITE_AUTH_SUCCESS,
  ON_SITE_AUTH_FAILURE,
} from "./types";

import { OnSiteAuthData } from "../../models/onSiteAuthData";

/**
 * @returns {OnSiteAuthRequestAction}
 */
export function onSiteAuthRequest(): OnSiteAuthRequestAction {
  return {
    type: ON_SITE_AUTH_REQUEST,
  };
}

/**
 * @param {OnSiteAuthData} payload - The on-site authentication data
 * @returns {OnSiteAuthSuccessAction}
 */
export function onSiteAuthSuccess(payload: OnSiteAuthData): OnSiteAuthSuccessAction {
  return {
    type: ON_SITE_AUTH_SUCCESS,
    payload,
  };
}

/**
 * @param {string} payload - The error message
 * @returns {OnSiteAuthFailureAction}
 */
export function onSiteAuthFailure(error: string): OnSiteAuthFailureAction {
  return {
    type: ON_SITE_AUTH_FAILURE,
    error,
  };
}

/**
 * Creates an on-site authentication
 * @param {OnSiteAuthData} onSiteAuth - The on-site authentication data
 * @returns {Promise<void>}
 */
export function loginEmployeeAndCreateCapture(onSiteAuth: OnSiteAuthData) {
  return async (dispatch: Dispatch<OnSiteAuthActionTypes>) => {
    dispatch(onSiteAuthRequest());

    try {
      const response = await onSiteAuthService.onSiteAuthRequest(onSiteAuth);
      if (response.status === 200) {
        dispatch(onSiteAuthSuccess(response.data));
        enqueueSnackbar(
          getDynamicTranslation(
            "backendResponses.success.authCaptureCreated",
            "snackbars"
          ),
          { variant: "success" }
        );
        return Promise.resolve();
      } else {
        dispatch(onSiteAuthFailure("Failed to authenticate"));
        return Promise.reject(new Error("Failed to authenticate"));
      }
    } catch (error) {
      dispatch(onSiteAuthFailure("Failed to authenticate"));
      return Promise.reject(error);
    }
  };
}
