import { Box, Button } from "@material-ui/core";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  LumosFontSizeAccessibilitySvg,
  LumosHighContrastSvg,
} from "../../../assets/icons/custom-svg-components/LumosSvgCollection";
import { AppState } from "../../../redux";
import {
  ChangeFontSizeMode,
  toggleMonoMode,
} from "../../../redux/accessibility/actions";
import "./Accessibility.scss";
import "./TopNavAndAccessibility.scss";
import LanguageSwitcher from "../../core/localization/LanguageSwitcher";
import { useTranslation } from "react-i18next";

const TopNavAndAccessibility: React.FC = () => {
  const dispatch = useDispatch();
  const accessibility = useSelector((state: AppState) => state.accessibility);
  const { t } = useTranslation(["layout"]);

  return (
    <>
      <Box display="flex" flexDirection="row" justifyContent="flex-end">
        <LanguageSwitcher></LanguageSwitcher>
        <Box>
          <Button
            className={
              accessibility.monoMode ? "accessability-btn-mono" : "accessability-btn"
            }
            variant={accessibility.monoMode ? "contained" : undefined}
            color={"secondary"}
            id="font-size"
            title={t("hoverText.fontSize", { ns: "layout" })}
            onClick={() => {
              dispatch(ChangeFontSizeMode());
            }}
            style={{
              backgroundColor: !accessibility.monoMode ? "transparent" : undefined,
              border: "none",
            }}
          >
            <LumosFontSizeAccessibilitySvg />
          </Button>
        </Box>
        <Box>
          <Button
            className={
              accessibility.monoMode ? "accessability-btn-mono" : "accessability-btn"
            }
            variant={accessibility.monoMode ? "contained" : undefined}
            color={"secondary"}
            id="toggle-color"
            title={t("hoverText.toggleColor", { ns: "layout" })}
            onClick={() => {
              dispatch(toggleMonoMode());
            }}
            style={{
              backgroundColor: !accessibility.monoMode ? "transparent" : undefined,
              border: "none",
            }}
          >
            <LumosHighContrastSvg />
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default TopNavAndAccessibility;
