import { NIL as NIL_UUID } from "uuid";
import {
  PinboardActionTypes,
  PinboardState,
  PINBOARD_CLEAR,
  PINBOARD_FAILURE,
  PINBOARD_PAGE_COUNT,
  PINBOARD_REQUEST,
  PINBOARD_SUCCESS,
  PINBOARD_TOGGLE_OPEN_STATE,
} from "./types";

const initialState: PinboardState = {
  pinboardList: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  pinboardListLoaded: false,
  pinboard: {
    id: NIL_UUID,
    created_on: new Date(),
    created_by: NIL_UUID,
    last_changed_on: new Date(),
    last_changed_by: NIL_UUID,
    last_edited_on: new Date(),
    last_edited_by: NIL_UUID,
    title: "",
    post: "",
    is_archived: false,
    event_id: NIL_UUID,
    author: "",
    user_type: 0,
    has_answer: false,
    user_has_subscribed: false,
  },
  isPinboardOpen: true,
  pinboardLoaded: false,
  currentPage: 1,
  isLoading: false,
  error: false,
};

export function pinboardReducer(
  state = initialState,
  action: PinboardActionTypes
): PinboardState {
  switch (action.type) {
    case PINBOARD_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case PINBOARD_SUCCESS:
      return {
        ...state,
        pinboardList: action.data,
        pinboardListLoaded: true,
        isLoading: false,
      };
    case PINBOARD_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    case PINBOARD_PAGE_COUNT:
      return {
        ...state,
        currentPage: action.pageCount,
      };
    case PINBOARD_TOGGLE_OPEN_STATE:
      return {
        ...state,
        isPinboardOpen: !state.isPinboardOpen,
      };
    case PINBOARD_CLEAR:
      return initialState;
    default:
      return state;
  }
}

export default pinboardReducer;
