import apiRoutes from "./apiRoutes";
import { BaseCrudService } from "./baseCrudService";
import { OrganizationEventParticipationsData } from "../models/organizationEventParticipationsData";

/**
 * The organization event participations service
 */
export class OrganizationEventParticipationsService extends BaseCrudService {
  /**
   * Fetches the organization event participations data
   * @returns {Promise<[OrganizationEventParticipationsData]>}
   */
  async getEventParticipationsByOrganizationId(organizationId: string) {
    const response = await this.getAll<OrganizationEventParticipationsData[]>(
      `${apiRoutes.organization_event_participations}/${organizationId}/get_organization_event_participations`
    );
    return response.data;
  }
}
