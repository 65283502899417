import { Button, Grid } from "@material-ui/core";
import { Form, Formik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { RelatedTaskDeadline } from "../../../models/enums/relatedTaskDeadline.enum";
import { TaskType } from "../../../models/enums/taskType.enum";
import { TaskTemplateData } from "../../../models/taskData";
import { AppState } from "../../../redux";
import {
  addTaskTemplate,
  editTaskTemplate,
  getTaskTemplate,
} from "../../../redux/templates/tasks/actions";
import { Utils } from "../../../services/utils";
import { NoMatchPage } from "../../error/NoMatchPage";
import CustomTextField from "../../../components/forms/CustomTextField";
import CustomSelect from "../../../components/forms/selects/CustomSelect";
import usePageLoader from "../../../components/hooks/usePageLoader";
import useValidId from "../../../components/hooks/useValidId";
import routes from "../../../routing/routes";
import HeadingLumos from "../../../components/theming/HeadingLumos";
import Loader from "../../../components/theming/loader/Loader";
import * as yup from "yup";
import { useDynamicYupValidations } from "../../../libs/yup-validations";

export const CrudTaskTemplatePage: React.FC = () => {
  let { id } = useParams<{ id: string }>();

  const dispatch = useDispatch();
  const history = useHistory();

  const templates = useSelector((state: AppState) => state.taskTemplates);
  const taskTemplateNotFoundError = templates.notFoundError;

  const { isValidId } = useValidId(routes.templates_edit_task_id, id);

  useEffect(() => {
    if (isValidId && !templates.taskTemplateLoaded && !templates.error) {
      dispatch(getTaskTemplate(id));
    }
  }, [dispatch, templates.taskTemplateLoaded, id, isValidId, templates.error]);

  const { isLoadingPage } = usePageLoader();

  const { YupValidationTaskDescription, YupValidationTaskDeadline } =
    useDynamicYupValidations();

  return (
    <>
      {(isLoadingPage || templates.isLoading) && !templates.error ? (
        <>
          <Loader />
        </>
      ) : !isLoadingPage &&
        id &&
        !templates.isLoading &&
        (taskTemplateNotFoundError || !isValidId) ? (
        <>
          <NoMatchPage />
        </>
      ) : (
        <>
          <HeadingLumos>
            {id ? "Standardaufgabe bearbeiten" : "Neue Standardaufgabe erstellen"}
          </HeadingLumos>
          <Formik
            enableReinitialize
            onSubmit={(data: TaskTemplateData) => {
              id
                ? dispatch(editTaskTemplate(data, id))
                : dispatch(addTaskTemplate(data));
            }}
            validationSchema={yup.object({
              description: YupValidationTaskDescription,
              default_deadline_in_days: YupValidationTaskDeadline,
            })}
            initialValues={templates.taskTemplate}
          >
            {({ values, setFieldValue }) => (
              <Form onKeyDown={Utils.preventEnterToSubmit}>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={8}>
                    <CustomTextField
                      fullWidth
                      required
                      name="description"
                      id="description"
                      label="Aufgabenbeschreibung"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <CustomSelect
                      name="task_type"
                      id="task_type"
                      label="Veranstaltungsart"
                      labelId="task_type-label"
                      noEmptyDefaultValue
                      onChange={(
                        event: React.ChangeEvent<{
                          name?: string;
                          value: unknown;
                        }>
                      ) => {
                        setFieldValue("task_type", event.target.value);
                      }}
                      options={[
                        {
                          key: "undefined",
                          value: TaskType.Undefined,
                          label: "Präsenz und Online",
                        },
                        {
                          key: "on_site",
                          value: TaskType.OnSite,
                          label: "Präsenz",
                        },
                        {
                          key: "online",
                          value: TaskType.Online,
                          label: "Online",
                        },
                      ]}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CustomTextField
                      fullWidth
                      multiline
                      rows={3}
                      name="additional_info"
                      id="additional_info"
                      label="Zusätzliche Beschreibung"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CustomTextField
                      disabled={
                        values.event_related_deadline ===
                          RelatedTaskDeadline.Undefined ||
                        values.event_related_deadline ===
                          RelatedTaskDeadline.On_Cancelled
                      }
                      fullWidth
                      type="number"
                      name="default_deadline_in_days"
                      id="default_deadline_in_days"
                      label="Fälligkeitszeitpunkt in Tagen"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CustomSelect
                      name="event_related_deadline"
                      id="event_related_deadline"
                      label="Fälligkeitszeitpunkt"
                      labelId="event_related_deadline-label"
                      noEmptyDefaultValue
                      onChange={(
                        event: React.ChangeEvent<{
                          name?: string;
                          value: unknown;
                        }>
                      ) => {
                        setFieldValue("event_related_deadline", event.target.value);
                        setFieldValue("default_deadline_in_days", 0);
                      }}
                      options={[
                        {
                          key: "undefined",
                          value: RelatedTaskDeadline.Undefined,
                          label: "Tag der Veranstaltung",
                        },
                        {
                          key: "before",
                          value: RelatedTaskDeadline.Before,
                          label: "vor Veranstaltungsbeginn",
                        },
                        {
                          key: "after",
                          value: RelatedTaskDeadline.After,
                          label: "nach Veranstaltungsbeginn",
                        },
                        {
                          key: "on_cancelled",
                          value: RelatedTaskDeadline.On_Cancelled,
                          label: "bei Stornierung",
                        },
                      ]}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Button
                      size="large"
                      variant="contained"
                      color="primary"
                      fullWidth
                      type="submit"
                    >
                      Standardaufgabe speichern
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <Button
                      size="large"
                      variant="contained"
                      color="secondary"
                      fullWidth
                      onClick={() => {
                        history.push(routes.templates);
                      }}
                    >
                      Abbrechen
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </>
      )}
    </>
  );
};
