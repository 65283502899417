import { Dispatch } from "redux";
import { PaymentData } from "../../models/paymentData";
import { paymentMethodService } from "./../../api/index";
import {
  LOAD_PAYMENT_METHOD_DATA_SUCCESS,
  LoadPaymentMethodAction,
  PAYMENT_METHOD_FAILURE,
  PAYMENT_METHOD_REQUEST,
  SET_PAYMENT_METHODS_LOADED,
  PaymentMethodActionTypes,
  PaymentMethodFailureAction,
  PaymentMethodRequestAction,
  SetPaymentMethodsLoadedAction,
} from "./types";

export function getPaymentMethods() {
  return (dispatch: Dispatch<PaymentMethodActionTypes>) => {
    dispatch(paymentMethodRequest());
    paymentMethodService
      .getPaymentMethods()
      .then((response: PaymentData[]) => {
        dispatch(loadPaymentDataSuccess(response));
      })
      .catch(() => {
        dispatch(paymentMethodFailure());
      });
  };
}

export function loadPaymentDataSuccess(data: PaymentData[]): LoadPaymentMethodAction {
  return {
    type: LOAD_PAYMENT_METHOD_DATA_SUCCESS,
    data,
  };
}

export function setPaymentMethodsLoaded(): SetPaymentMethodsLoadedAction {
  return {
    type: SET_PAYMENT_METHODS_LOADED,
  };
}

export function paymentMethodRequest(): PaymentMethodRequestAction {
  return {
    type: PAYMENT_METHOD_REQUEST,
  };
}

export function paymentMethodFailure(): PaymentMethodFailureAction {
  return {
    type: PAYMENT_METHOD_FAILURE,
  };
}
