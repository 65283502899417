/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { AppState } from "../../../redux";
import { organizationMembershipService } from "../../../api";
import { OrganizationMembershipActivationData } from "../../../models/organizationMembershipData";
import routes from "../../../routing/routes";
import HeadingLumos from "../../../components/theming/HeadingLumos";

/**
 * MembershipConfirmationPage component is responsible for handling the activation of organization membership.
 * It verifies the activation token, activates the membership, and redirects the user accordingly.
 */
export const MembershipConfirmationPage: React.FC = () => {
  const { t } = useTranslation(["organization", "snackbars"]);
  const user = useSelector((state: AppState) => state.user.currentUser);
  const history = useHistory();
  const [userIsActivated, setUserIsActivated] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [text, setText] = useState<string>(
    t("accountConfirmation.redirectMessage", {
      ns: "organization",
    })
  );

  useEffect(() => {
    activateOrganizationMembership();
  }, []);

  useEffect(() => {
    userIsActivated &&
      setTimeout(() => {
        if (user?.id) history.push(routes.organization);
        else history.push(routes.login);
      }, 3000);
  }, [userIsActivated, user]);

  /**
   * Function to activate organization membership based on the received token.
   */
  const activateOrganizationMembership = async () => {
    const token = new URLSearchParams(window.location.search).get("token");
    if (token) {
      try {
        const data: OrganizationMembershipActivationData = { token };
        const accountActivation =
          await organizationMembershipService.activateOrganizationAssignment(data);

        if (accountActivation) {
          setText(
            t("accountConfirmation.membershipActivated", {
              ns: "organization",
            })
          );

          enqueueSnackbar(
            t("accountConfirmation.membershipActivated", {
              ns: "organization",
            }),
            { variant: "success" }
          );
          setUserIsActivated(true);
        } else {
          setText(t("accountConfirmation.linkInvalid", { ns: "organization" }));
        }
      } catch (error) {
        setText(t("accountConfirmation.errorOccurred", { ns: "organization" }));
      }
    } else {
      setText(t("accountConfirmation.linkExpired", { ns: "organization" }));
    }
  };

  return (
    <Box className={classes.container}>
      <HeadingLumos>
        {t("organizationConfirmationPageTitle", { ns: "organization" })}
      </HeadingLumos>
      <Typography className={classes.mt}>{text}</Typography>
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    paddingTop: "2%",
  },
  mt: {
    marginTop: 30,
  },
}));
