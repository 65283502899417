import { EventStatus } from "../../../../../models/enums/eventStatus.enum";
import { AccessibilityState } from "../../../../../redux/accessibility/types";

export const renderStatusColor = (
  eventWorkload: number,
  statusId: EventStatus,
  accessibility: AccessibilityState,
  maxSeatsTakenOrReserved: boolean,
  isElearning: boolean,
  signUpStopped: boolean
) => {
  if (statusId === EventStatus.Completed || statusId === EventStatus.NotVisible) {
    // TODO: what's up with this case?
  }
  if (
    ((eventWorkload >= 100 ||
      statusId === EventStatus.Full ||
      statusId === EventStatus.Cancelled ||
      maxSeatsTakenOrReserved) &&
      !isElearning) ||
    signUpStopped === true
  ) {
    return accessibility.monoMode
      ? "event-item-status-dot event-item-dot-mono-full"
      : "event-item-status-dot event-item-dot-red";
  } else if (eventWorkload < 80 || isElearning) {
    return accessibility.monoMode
      ? "event-item-status-dot event-item-dot-mono-published"
      : "event-item-status-dot event-item-dot-green";
  } else if (eventWorkload >= 80 && !isElearning) {
    return accessibility.monoMode
      ? "event-item-status-dot event-item-dot-mono-almost-full"
      : "event-item-status-dot event-item-dot-yellow";
  }
};
