import {
  CLEAR_ORGANIZATION,
  OrganizationActionTypes,
  ORGANIZATION_FAILURE,
  OrganizationState,
  ORGANIZATION_REQUEST,
  SET_ORGANIZATION_DATA,
} from "./types";

/**
 * Initial state for the organization reducer.
 */
export const initialOrganizationState: OrganizationState = {
  organizationData: [],
  isLoading: false,
  organizationDataLoaded: false,
};

/**
 * Reducer function for managing organization related state.
 * @param state Current state of the organization.
 * @param action Action dispatched to update organization state.
 * @returns Updated state of the organization.
 */
export function organizationReducer(
  state = initialOrganizationState,
  action: OrganizationActionTypes
): OrganizationState {
  switch (action.type) {
    case ORGANIZATION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ORGANIZATION_FAILURE:
      return {
        ...state,
        organizationDataLoaded: true,
        isLoading: false,
      };
    case CLEAR_ORGANIZATION:
      return initialOrganizationState;
    case SET_ORGANIZATION_DATA:
      return {
        ...state,
        organizationData: action.organizationData,
        organizationDataLoaded: true,
        isLoading: false,
      };
    default:
      return state;
  }
}

export default organizationReducer;
