import { Box, Grid } from "@material-ui/core";
import { TrainingType } from "../../../../../models/enums/trainingType.enum";
import { ParticipantListData } from "../../../../../models/participantData";
import CustomCheckbox from "../../../../forms/CustomCheckbox";
import { ParticipantsMultiStatus } from "./ParticipantMultiStatus";

export interface ParticipantsHeaderProps {
  participants: ParticipantListData[];
  index: number;
  trainingType: TrainingType;
  checkHandler?: () => void;
  checked?: boolean;
  noParticipants?: boolean;
  value: number;
}

export const ParticipantsHeader: React.FC<ParticipantsHeaderProps> = (props) => {
  return (
    <>
      {props.noParticipants ? null : (
        <Grid
          container
          className={"participant-list-item"}
          style={{ display: "flex", alignItems: "center", border: "none" }}
        >
          <Grid item xs={1}>
            <CustomCheckbox
              name={String(props.participants.length)}
              onChange={props.checkHandler}
              checked={props.checked}
            />
          </Grid>
          <Grid item xs={8} style={{ paddingLeft: "1.875rem" }}>
            <Box>
              {`${props.participants.length} Teilnehmer`}
              <Box>ausgewählt</Box>
            </Box>
          </Grid>
          <Grid item xs={8} sm={3}>
            <ParticipantsMultiStatus
              participant={props.participants}
              index={props.index}
              key={props.index}
              trainingType={props.trainingType}
              disabledSelect={props.participants.length === 0 ? true : false}
              value={props.value}
            />
          </Grid>
        </Grid>
      )}
      <Grid
        container
        className={"participant-header"}
        style={{
          width: "100%",
        }}
      />
    </>
  );
};

// TODO no translation yet
