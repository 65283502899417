import { initialUserState } from "../users/reducers";
import {
  ActivityHistoryUserState as ActivityHistoryState,
  GET_ACTIVITY_HISTORY_FAILURE,
  GET_ACTIVITY_HISTORY_REQUEST,
  SET_ACTIVITY_HISTORY,
  ActivityHistoryActionTypes,
} from "./types";

export const initialActivityHistoryState: ActivityHistoryState = {
  isLoading: false,
};

export function activityHistoryReducer(
  state = initialUserState,
  action: ActivityHistoryActionTypes
): ActivityHistoryState {
  switch (action.type) {
    case GET_ACTIVITY_HISTORY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_ACTIVITY_HISTORY_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case SET_ACTIVITY_HISTORY:
      return {
        ...state,
        isLoading: false,
        statistics: action.statistics,
      };
    default:
      return state;
  }
}

export default activityHistoryReducer;
