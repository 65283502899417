import React, { useState, useEffect } from "react";
import "./Documents.scss";
import { Button, createStyles, Grid, makeStyles, Typography } from "@material-ui/core";
import { DropzoneAreaBase, FileObject } from "material-ui-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { TrainingType } from "../../../../models/enums/trainingType.enum";
import { addDocument } from "../../../../redux/documents/actions";
import { acceptedFileTypes } from "../../../../utils/uploadAreaConfig";
import { AppState } from "../../../../redux";
import { checkForScormFile } from "../../../../api/scorm/scormService";
import { ScormFile } from "../../../../models/documentData";
import { isValidScormFile } from "../../../../api/scorm/scormUtils";

interface UploadAreaProps {
  defaultDocuments?: boolean;
  trainingType?: TrainingType;
}

// Define the maximum allowed file size for SCORM files (2 GB)
const MAX_SCORM_FILE_SIZE_IN_BYTES = 2 * 1024 * 1024 * 1024;

const zipFileTypes = [
  "application/zip",
  "application/x-zip-compressed",
  "application/x-compressed",
  "application/x-winzip",
  "application/x-gzip-compressed",
  "application/x-tar",
];

export const UploadArea: React.FC<UploadAreaProps> = ({
  trainingType = TrainingType.DefaultEvent,
  ...props
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [uploadFiles, setUploadFiles] = useState<FileObject[]>([]);
  const [existingScormFile, setExistingScormFile] = useState<ScormFile>();
  const [uploading, setUploading] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();
  const existingDocuments = useSelector(
    (state: AppState) => state.documents
  ).documentList;

  // Array to hold FormData for each uploaded document
  let documentUploadList: FormData[] = [];

  // Define accepted file formats based on the training type
  const acceptedFormats =
    trainingType === TrainingType.DefaultEvent
      ? acceptedFileTypes.defaultTraining
      : acceptedFileTypes.trainingWithScorm;

  useEffect(() => {
    // Invoke the function to check for SCORM file when the component mounts
    // or when existingDocuments changes
    (async () => {
      try {
        let scormFile = await checkForScormFile(existingDocuments);
        scormFile && setExistingScormFile(scormFile);
      } catch (e) {
        console.log(e);
      }
    })();
  }, [existingDocuments]);

  /**
   * Handles the addition of uploaded files to the existing list of files.
   * Enforces the rule that there can be only one SCORM file but unlimited files of other types.
   * Displays an error message if attempting to add more than one SCORM file.
   *
   * @param {FileObject[]} newFileObjs - An array of newly added FileObjects.
   */
  const handleFileAdd = async (newFileObjs: FileObject[]) => {
    try {
      // Function to check if a FileObject represents a SCORM file
      const isScorm = async (file: FileObject) => {
        try {
          if (zipFileTypes.includes(file.file.type)) {
            // If the file is a ZIP file, check if it's a valid SCORM file
            return await isValidScormFile(file.file);
          } else {
            // If it's not a ZIP file, it's definitely not a SCORM file
            return false;
          }
        } catch (error) {
          console.error("Error checking if file is a SCORM file:", error);
          return false;
        }
      };

      // Check if there's already a SCORM file in the existing files
      const localExistingScormFile = await uploadFiles.find(isScorm);

      // Check if any of the newly added files is a SCORM file
      const newScormFile = await Promise.all(newFileObjs.map(isScorm));

      // Check if there's already a SCORM file and a new SCORM file is being added
      if (localExistingScormFile && newScormFile.some((file) => file)) {
        // Show an error message or take appropriate action
        enqueueSnackbar("Es kann nur eine SCORM-Datei hochgeladen werden.", {
          variant: "error",
        });
        return;
      }

      // If no existing SCORM file or only non-SCORM files are present, proceed to add files
      const combinedFiles = [...uploadFiles, ...newFileObjs];
      setUploadFiles(combinedFiles);
    } catch (error) {
      // Handle any other errors that might occur during file addition
      enqueueSnackbar("Error handling file addition", {
        variant: "error",
      });
    }
  };

  // Function to handle the deletion of uploaded files
  const handleFileDelete = (deleteFileObj: FileObject) => {
    const updatedFiles = uploadFiles.filter((file) => file !== deleteFileObj);
    setUploadFiles(updatedFiles);
  };

  // Function to handle rejected files
  const handleFileRejected = () => {
    enqueueSnackbar(
      `Dieser Dateityp wird nicht unterstützt. Es können nur ${acceptedFormats.title} hochgeladen werden.`,
      { variant: "error" }
    );
  };

  /**
   * Creates a FormData object for a single file upload.
   *
   * @param {File} file - The file to be uploaded.
   * @param {boolean} isDefault - Indicates whether the file is a default document.
   * @returns {FormData} A FormData object containing file data and related properties.
   */
  const createFileFormData = async (
    file: File,
    isDefault: boolean
  ): Promise<FormData> => {
    // Initialize a new FormData object to store the file and its properties.
    const data: FormData = new FormData();

    const isZipFileType = zipFileTypes.includes(file?.type);

    const isNotDefaultEvent = trainingType !== TrainingType.DefaultEvent;

    const isScormFile =
      isZipFileType && isNotDefaultEvent && (await isValidScormFile(file));

    // Define the properties to be added to the FormData.
    const properties: Record<string, string> = {
      is_default_on_site: isDefault ? "true" : "false",
      is_default_online: isDefault ? "true" : "false",
      publish_participant: isDefault ? "true" : "false",
      publish_speaker: isDefault ? "true" : "false",

      publish_after_event: "false",

      // Set is_scorm_file to true if the file is a ZIP file and
      // the training type is not DefaultEvent.
      is_scorm_file: isScormFile ? "true" : "false",

      original_filename: file.name,
    };
    // Add each property to the FormData.
    for (const key in properties) {
      data.append(key, properties[key]);
    }
    // Append the file data to the FormData.
    data.append("file", file);
    return data;
  };

  /**
   * Handles the file upload process by creating FormData for each uploaded file
   * and dispatching an action to add the documents to the state.
   */
  const handleFileUpload = async () => {
    setUploading(true);
    // Determine if the documents are default based on props, defaulting to false.
    const isDefault: boolean = props.defaultDocuments || false;

    // Map each uploaded file to a Promise<FormData> and collect them in a new array.
    const newDocumentPromises: Promise<FormData>[] = uploadFiles.map((file) =>
      createFileFormData(file.file, isDefault)
    );

    // Wait for all promises to resolve.
    const newDocumentList: FormData[] = await Promise.all(newDocumentPromises);

    // Add the new documents to the existing document list.
    documentUploadList.push(...newDocumentList);

    // Dispatch an action to add the prepared documents to the application
    // state and send also the already saved documents
    dispatch(addDocument(documentUploadList, existingScormFile, setUploading));

    // Clear the uploaded files from the local state.
    setUploadFiles([]);
  };

  return (
    <>
      <Grid item xs={12} sm={8}>
        <DropzoneAreaBase
          dropzoneText={acceptedFormats.dropZoneText}
          maxFileSize={MAX_SCORM_FILE_SIZE_IN_BYTES}
          acceptedFiles={acceptedFormats.types}
          fileObjects={uploadFiles}
          onAdd={handleFileAdd}
          onDelete={handleFileDelete}
          onDropRejected={handleFileRejected}
          showAlerts={false}
          showPreviews={true}
          showPreviewsInDropzone={false}
          useChipsForPreview
          previewGridProps={{
            container: { spacing: 1, direction: "row" },
          }}
          previewChipProps={{
            classes: { root: classes.previewChip },
          }}
          previewText="Ausgewählte Dateien"
        />
        {existingScormFile && (
          <>
            <Typography style={{ marginTop: 6 }}>
              Dem Kurs ist bereits folgende Scorm-Datei zugeordnet:{" "}
            </Typography>
            <Typography style={{ fontWeight: "500" }}>
              {existingScormFile?.title}
            </Typography>
          </>
        )}
      </Grid>
      <Grid item sm={4} />
      <Grid item xs={12} sm={3}>
        <Button
          size="large"
          variant="contained"
          color="primary"
          fullWidth
          disabled={uploading || uploadFiles.length <= 0}
          onClick={handleFileUpload}
        >
          Hochladen
        </Button>
      </Grid>
    </>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    previewChip: {
      minWidth: 160,
      maxWidth: 210,
    },
  })
);
