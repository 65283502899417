import { Pagination } from "../../../models/pagination";
import { TaskTemplateData } from "../../../models/taskData";

export interface TaskTemplatesState {
  taskTemplateList: Pagination<TaskTemplateData>;
  taskTemplate: TaskTemplateData;
  taskTemplateLoaded: boolean;
  defaultTasksForEventPreview: TaskTemplateData[];
  defaultTasksForEventPreviewLoaded: boolean;
  currentPage: number;
  isLoading: boolean;
  error: boolean;
  notFoundError: boolean;
}

// general actions
export const TEMPLATE_REQUEST = "TEMPLATE_REQUEST";
export const TEMPLATE_FAILURE = "TEMPLATE_FAILURE";

// task Templates
export const SET_LIST_OF_TASK_TEMPLATES_SUCCESS = "SET_LIST_OF_TASK_TEMPLATES_SUCCESS";
export const SET_TASK_TEMPLATE_SUCCESS = "SET_TASK_TEMPLATE_SUCCESS";
export const DELETE_TASK_TEMPLATE = "DELETE_TASK_TEMPLATE";
export const SET_TASK_TEMPLATE_PAGE_COUNT = "SET_TASK_TEMPLATE_PAGE_COUNT";

export const CLEAR_TASK_TEMPLATE = "CLEAR_TASK_TEMPLATE";

export const SET_DEFAULT_TASKS_FOR_EVENT_PREVIEW = "SET_DEFAULT_TASKS_FOR_EVENT_PREVIEW";
export const RESET_DEFAULT_TASKS_FOR_EVENT_PREVIEW =
  "RESET_DEFAULT_TASKS_FOR_EVENT_PREVIEW";

// actions that are the same for all api calls (request and failure)
export interface TemplateRequestAction {
  type: typeof TEMPLATE_REQUEST;
}

export interface TemplateFailureAction {
  type: typeof TEMPLATE_FAILURE;
  notFoundError: boolean;
}

// other event actions
export interface SetListOfTaskTemplatesSuccessAction {
  type: typeof SET_LIST_OF_TASK_TEMPLATES_SUCCESS;
  data: Pagination<TaskTemplateData>;
}

export interface SetTaskTemplateSuccessAction {
  type: typeof SET_TASK_TEMPLATE_SUCCESS;
  data: TaskTemplateData;
}

export interface DeleteTaskTemplateSuccessAction {
  type: typeof DELETE_TASK_TEMPLATE;
  id: string;
}

export interface SetTaskTemplatePageCountAction {
  type: typeof SET_TASK_TEMPLATE_PAGE_COUNT;
  pageCount: number;
}

export interface SetDefaultTasksForEventPreviewAction {
  type: typeof SET_DEFAULT_TASKS_FOR_EVENT_PREVIEW;
  data: TaskTemplateData[];
}

export interface ResetDefaultTasksForEventPreviewAction {
  type: typeof RESET_DEFAULT_TASKS_FOR_EVENT_PREVIEW;
}

export interface TaskTemplateClearAction {
  type: typeof CLEAR_TASK_TEMPLATE;
}

export type TaskTemplatesActionTypes =
  | TemplateRequestAction
  | TemplateFailureAction
  | SetListOfTaskTemplatesSuccessAction
  | SetTaskTemplateSuccessAction
  | DeleteTaskTemplateSuccessAction
  | SetTaskTemplatePageCountAction
  | SetDefaultTasksForEventPreviewAction
  | ResetDefaultTasksForEventPreviewAction
  | TaskTemplateClearAction;
