import { ParticipantListData } from "../../models/participantData";

export interface ParticipantState {
  participantListLoaded: boolean;
  participantList: ParticipantListData[];
  isLoading: boolean;
}

export const PARTICIPANT_REQUEST = "PARTICIPANT_REQUEST";
export const EDIT_PARTICIPANT_REQUEST = "EDIT_PARTICIPANT_REQUEST";
export const PARTICIPANT_FAILURE = "PARTICIPANT_FAILURE";
export const ADD_PARTICIPANT = "ADD_PARTICIPANT";

export const SET_PARTICIPANT_LIST = "SET_PARTICIPANT_LIST";

export const EDIT_PARTICIPANT_STATUS = "EDIT_PARTICIPANT_STATUS";
export const CLEAR_PARTICIPANT = "CLEAR_PARTICIPANT";

export interface ParticipantRequestAction {
  type: typeof PARTICIPANT_REQUEST;
}

export interface ParticipantEditRequestAction {
  type: typeof EDIT_PARTICIPANT_REQUEST;
  participantId: string;
}

export interface ParticipantFailureAction {
  type: typeof PARTICIPANT_FAILURE;
  error: any;
}

export interface SetParticipantListAction {
  type: typeof SET_PARTICIPANT_LIST;
  data: ParticipantListData[];
}

export interface EditParticipantStatusAction {
  type: typeof EDIT_PARTICIPANT_STATUS;
  participantId: string;
  participantStatus: number;
}

export interface ClearParticipantAction {
  type: typeof CLEAR_PARTICIPANT;
}

export interface AddParticipantAction {
  type: typeof ADD_PARTICIPANT;
  data: ParticipantListData;
}

export type ParticipantActionTypes =
  | ParticipantRequestAction
  | ParticipantEditRequestAction
  | ParticipantFailureAction
  | SetParticipantListAction
  | EditParticipantStatusAction
  | ClearParticipantAction
  | AddParticipantAction;
