import { AxiosError } from "axios";
import { Dispatch } from "redux";
import { lecturesService } from "../../api/index";
import { LecturesData } from "../../models/lecturesData";
import { Pagination } from "../../models/pagination";
import { LectureFilterData } from "./../../models/lecturesData";
import { LectureFilterService } from "./../../services/filters/lectureFilterService";
import { initialLecturesState } from "./reducers";
import {
  ClearLecturesAction,
  CLEAR_LECTURES,
  LecturesActionTypes,
  LecturesFailureAction,
  LecturesRequestAction,
  LECTURES_FAILURE,
  LECTURES_REQUEST,
  SetLectureAction,
  SetListOfLecturesSuccessAction,
  SetPageCountAction,
  SET_LECTURE,
  SET_LIST_OF_LECTURES_SUCCESS,
  SET_LECTURES_PAGE_COUNT,
  UpdateLectureAction,
  UPDATE_LECTURE,
} from "./types";

export function getAllLectures(page: number, filterData: LectureFilterData) {
  return (dispatch: Dispatch<LecturesActionTypes>) => {
    const lectureFilterService = new LectureFilterService(page, filterData);
    const filterHasChanged = lectureFilterService.checkIfFilterHasChanged();

    if (filterHasChanged) {
      page = initialLecturesState.currentPage;
      lectureFilterService.searchFilter = { page: page };
      dispatch(clearLecture()); // set initial state for lecture list
    }

    dispatch(lecturesRequest()); // !call after filter has changd check to make sure isLoading is not overwritten

    const route = lectureFilterService.getRoute();
    lectureFilterService.reflectFiltersInUrl(route);

    lecturesService
      .getLectures(route)
      .then((response: Pagination<LecturesData>) => {
        dispatch(setListOfLecturesSuccess(response));
      })
      .catch((err: AxiosError) => {
        dispatch(lecturesFailure());
      });
  };
}

export function getLecture(eventId: string) {
  return (dispatch: Dispatch<LecturesActionTypes>) => {
    dispatch(lecturesRequest());

    lecturesService
      .getLecture(eventId)
      .then((response: LecturesData) => {
        dispatch(setLecture(response));
      })
      .catch((err: AxiosError) => {
        dispatch(lecturesFailure());
      });
  };
}

export function clickedEvaluationLink(
  eventId: string,
  participantId: string,
  hasClickedLink: boolean
) {
  return (dispatch: Dispatch<LecturesActionTypes>) => {
    dispatch(lecturesRequest());

    lecturesService
      .patchUserHasClickedLink(eventId, participantId, {
        user_has_clicked_evaluation_link: hasClickedLink,
      })
      .then((response) => {
        updateUserHasClickEvaluationLink(hasClickedLink);
      })
      .catch((err: AxiosError) => {
        dispatch(lecturesFailure());
      });
  };
}

export function lecturesRequest(): LecturesRequestAction {
  return {
    type: LECTURES_REQUEST,
  };
}
export function lecturesFailure(): LecturesFailureAction {
  return {
    type: LECTURES_FAILURE,
  };
}

export function setListOfLecturesSuccess(
  data: Pagination<LecturesData>
): SetListOfLecturesSuccessAction {
  return {
    type: SET_LIST_OF_LECTURES_SUCCESS,
    data,
  };
}

export function setLecture(data: LecturesData): SetLectureAction {
  return {
    type: SET_LECTURE,
    data,
  };
}

export function clearLecture(): ClearLecturesAction {
  return {
    type: CLEAR_LECTURES,
  };
}

export function setPageCount(pageCount: number): SetPageCountAction {
  return {
    type: SET_LECTURES_PAGE_COUNT,
    pageCount,
  };
}

export function updateUserHasClickEvaluationLink(
  userHasClickEvaluationLink: boolean
): UpdateLectureAction {
  return {
    type: UPDATE_LECTURE,
    userHasClickEvaluationLink,
  };
}
