import { Grid } from "@material-ui/core";
import { useFormikContext } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ELearningFormData } from "../../../../../models/elearning";
import { EventStatus } from "../../../../../models/enums/eventStatus.enum";
import {
  TrainingType,
  mapToTrainingTypeString,
} from "../../../../../models/enums/trainingType.enum";
import { UserType } from "../../../../../models/enums/userType.enum";
import { EventFormData } from "../../../../../models/eventData";
import { AppState } from "../../../../../redux";
import { getAllChecklistItems } from "../../../../../redux/checklist/actions";
import usePermission from "../../../../../services/usePermissions";
import CustomCheckbox from "../../../../forms/CustomCheckbox";
import CustomTextField from "../../../../forms/CustomTextField";
import usePinboardActivated from "../../../../hooks/usePinboardActivated";
import routes from "../../../../../routing/routes";
import PinboardLink from "../../my-events/details/pinboard/PinboardLink";
import ChecklistEmployee from "./ChecklistEmployee";
import ChecklistSpeaker from "./ChecklistSpeaker";
import { DiscountTargetGroups } from "./Discount/DiscountTargetGroups";
import { CertificateTypes } from "./certificate/CertificateTypes";

interface MiscellaneousProps {
  eventId: string;
  typeOfTrainingToBeCreated?: TrainingType;
}

export const Miscellaneous: React.FC<MiscellaneousProps> = (props) => {
  const dispatch = useDispatch();
  const { values } = useFormikContext<EventFormData | ELearningFormData>();

  const currentUser = useSelector((state: AppState) => state.user.currentUser);
  const { isStaff } = usePermission(currentUser?.user_type);
  const checklist = useSelector((state: AppState) => state.checklist);
  const editEvent = useSelector((state: AppState) => state.event.editEvent);

  const allCertificateTypes = useSelector(
    (state: AppState) => state.certificateTypes.certificateTypes
  );

  const eventCertificateType = editEvent.certificate_type;

  const isEventCertificationActivated = editEvent.is_certification_selected;

  const history = useHistory();
  const isCreateRoute =
    history.location.pathname === routes.event_create ||
    history.location.pathname === routes.elearning_create ||
    history.location.pathname === routes.blended_learning_create;

  useEffect(() => {
    if (currentUser?.user_type === UserType.Speaker && !checklist.checklistLoaded) {
      dispatch(getAllChecklistItems());
    }
  }, [dispatch, currentUser?.user_type, checklist.checklistLoaded]);

  const canControlDiscounts =
    [EventStatus.Published, EventStatus.NotVisible].includes(values.status) ||
    isCreateRoute;
  const isPinboardActivated = usePinboardActivated(props.eventId);

  return (
    <>
      <CertificateTypes
        allCertificateTypes={allCertificateTypes}
        eventCertificateType={eventCertificateType}
        isEventCertificationActivated={isEventCertificationActivated}
      />
      {canControlDiscounts && <DiscountTargetGroups />}
      {props.typeOfTrainingToBeCreated === TrainingType.ELearning && (
        <Grid item lg={12} xs={6} sm={3} style={{ padding: ".625rem 0 .625rem 0" }}>
          <CustomCheckbox
            name="pinboardIsActivated"
            label={
              <>
                Pinnwand <b>aktivieren</b>
              </>
            }
          />
        </Grid>
      )}
      {props.eventId !== "0" && isPinboardActivated && (
        <PinboardLink eventId={props.eventId} positioning="start" />
      )}
      <Grid container spacing={4}>
        {editEvent.training_type !== undefined &&
          [TrainingType.DefaultEvent, TrainingType.BlendedLearning].includes(
            editEvent.training_type
          ) &&
          currentUser &&
          currentUser.user_type === UserType.Speaker && <ChecklistSpeaker />}
        {isStaff() && (
          <>
            {!isCreateRoute && editEvent.training_type !== TrainingType.ELearning && (
              <>
                <ChecklistEmployee />
              </>
            )}
            <Grid item xs={12}>
              <h3>
                Kommentare{" "}
                {editEvent.training_type === TrainingType.DefaultEvent ? "zur" : "zum"}{" "}
                {mapToTrainingTypeString(editEvent.training_type)}
              </h3>
              <CustomTextField
                disabled={values.status === EventStatus.Completed}
                fullWidth
                name="commentary"
                id="commentary"
                multiline={true}
                rows={10}
              />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

// TODO no translations yet
