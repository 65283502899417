import { DiscountItem } from "../models/discountData";
import apiRoutes from "./apiRoutes";
import { BaseCrudService } from "./baseCrudService";

export class DiscountService extends BaseCrudService {
  async getDiscount(eventId: string) {
    const response = await this.getAll<DiscountItem[]>(
      apiRoutes.events + "/" + eventId + apiRoutes.discounts
    );
    return response.data;
  }
  // TODO: Since discount is an array object
  // maybe we could use a single API call for each single element
  async saveDiscount(eventId: string, payload: DiscountItem[]) {
    const response = await this.create<DiscountItem[]>(
      apiRoutes.events + "/" + eventId + apiRoutes.discounts,
      payload
    );
    return response.data;
  }
}
