import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
} from "@material-ui/core";
import { AppState } from "../../../../redux";
import { fetchMemberships } from "../../../../redux/organizationMembership/action";
import { showDialog } from "../../../../redux/dialogs/actions";
import {
  removeMembership,
  acceptMembership,
} from "../../../../redux/organizationMembership/action";
import HeadingLumos from "../../../theming/HeadingLumos";
import routes from "../../../../routing/routes";

/**
 * Component for displaying a list of user organizations.
 * @returns {JSX.Element} The component.
 */
export default function UserOrganizationList() {
  const { t } = useTranslation(["common", "organization"]);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(fetchMemberships());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const memberships = useSelector((state: AppState) => state.memberships);

  /**
   * Handler function for removing a membership.
   * @param {Object} membership - The membership to be removed.
   * @param {string} title - Title for the dialog box.
   */
  const removeMembershipHandler = async (membershipId: string, title: string) => {
    dispatch(
      showDialog({
        title: title,
        message: t("leaveOrganizationNote", {
          ns: "organization",
        }),
        action: async () => {
          await dispatch(removeMembership(membershipId));
          await dispatch(fetchMemberships());
        },
      })
    );
  };

  /**
   * Handler function for accepting a membership invitation.
   * @param {string} membershipId - The ID of the membership to accept.
   */
  const acceptMembershipHandler = async (membershipId: string) => {
    try {
      await dispatch(acceptMembership(membershipId));
      await dispatch(fetchMemberships());
    } catch (error) {
      console.error("Error accepting membership:", error);
    }
  };

  return (
    <Box style={{ paddingBottom: 50 }}>
      <HeadingLumos>{t("organizations", { ns: "organization" })}</HeadingLumos>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("name", { ns: "organization" })}</TableCell>
              <TableCell>{t("contactPerson", { ns: "organization" })}</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {memberships?.memberships.length > 0 &&
              memberships?.memberships.map((membership) => {
                return (
                  <TableRow key={membership.id}>
                    <TableCell>{membership.organization_name}</TableCell>
                    <TableCell>
                      <Typography>{membership.contact_information}</Typography>
                    </TableCell>
                    <TableCell>
                      {membership.is_admin && (
                        <Button
                          id="manage-organization"
                          size="large"
                          disabled={false}
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            history.push(
                              `${routes.organization_admin_id.replace(":id", membership.organization)}`
                            )
                          }
                        >
                          {t("crud.editOrganization", { ns: "organization" })}
                        </Button>
                      )}
                    </TableCell>
                    <TableCell>
                      {membership.status === "pending" ? (
                        <Button
                          id="accept-membership-invitation"
                          size="large"
                          variant="contained"
                          color="primary"
                          onClick={() => acceptMembershipHandler(membership.id)}
                        >
                          {t("acceptMembershipInvitation", {
                            ns: "organization",
                          })}
                        </Button>
                      ) : (
                        <Button
                          id="leave-organization"
                          size="large"
                          variant="outlined"
                          color="primary"
                          onClick={() =>
                            removeMembershipHandler(
                              membership.id,
                              t("leaveOrganization", { ns: "organization" })
                            )
                          }
                        >
                          {t("leaveOrganization", { ns: "organization" })}
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
