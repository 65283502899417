import { ContentState, convertToRaw } from "draft-js";
import { NIL as NIL_UUID } from "uuid";
import { FilterData } from "../services/filters/filterData";
import { Utils } from "../services/utils";
import { SpeakerBaseData } from "./speakerData";
import { TargetGroupIdData, TargetGroupListData } from "./targetGroupData";
import { DocumentIdData } from "./documentData";

export interface EventTemplateData extends TargetGroupListData {
  url: string;
  id: string;
  title: string;
  score: number;
  price: number;
  description: string;
  speakers: SpeakerBaseData[];
  min_seats: number;
  max_seats: number;
  //documents: number[];
  //documents: string[];
  documents: DocumentIdData[];
}

export interface EventTemplateFormData extends TargetGroupIdData {
  title: string;
  score: number;
  price: number;
  description: ContentState;
  descriptionState: ContentState;
  speakers: SpeakerBaseData[];
  minseats: number;
  maxseats: number;
  //documents: number[];
  documents: string[];
  //documents: DocumentIdData[];
}

export interface EventTemplateFilterData extends FilterData<{}> {
  search: string;
}

export interface EventTemplateFilterOptions {
  search?: string;
}

export function mapEventTemplateDataToDto(
  data: EventTemplateFormData,
  id?: string
): EventTemplateData {
  const addEventTemplateDto: EventTemplateData = {
    ...data,
    url: "",
    id: id ? id : NIL_UUID,
    price: parseFloat((data.price * 100).toFixed(2)),
    description: JSON.stringify(convertToRaw(data.description)),
    min_seats: data.minseats,
    max_seats: data.maxseats,
    participation_types: data.participation_types?.map((v) => ({
      id: v,
      document_type: "",
      label: "",
    })),
    documents: data.documents?.map((v) => ({
      id: v,
    })),
    speakers: [],
  };
  return addEventTemplateDto;
}

export function mapEventTemplateDtoToFormData(
  data: EventTemplateData
): EventTemplateFormData {
  const addEventTemplateDto: EventTemplateFormData = {
    ...data,
    price: data.price / 100,
    description: Utils.convertJsonToRteState(data.description),
    descriptionState: Utils.convertJsonToRteState(data.description),
    minseats: data.min_seats,
    maxseats: data.max_seats,
    participation_types: data.participation_types?.map((v) => v.id),
    documents: data.documents?.map((v) => v.id),
    speakers: [],
  };
  return addEventTemplateDto;
}
