import { Dispatch } from "redux";
import { ActivitiyHistory } from "../../models/mailHistory";
import { usersService } from "../../api/index";
import {
  GetActivityHistoryFailureAction,
  GetActivityHistoryRequestAction,
  GET_ACTIVITY_HISTORY_FAILURE,
  GET_ACTIVITY_HISTORY_REQUEST,
  SetActivityHistoryAction,
  SET_ACTIVITY_HISTORY,
  ActivityHistoryActionTypes,
} from "./types";

export function getStatistics(userId: string) {
  return (dispatch: Dispatch<ActivityHistoryActionTypes>) => {
    dispatch(getActivityHistoryRequest());
    usersService
      .getStatistic(userId)
      .then((response: ActivitiyHistory) => {
        dispatch(setActivityHistory(response));
      })
      .catch(() => {
        dispatch(getActivityHistoryFailure());
      });
  };
}

export function getActivityHistoryRequest(): GetActivityHistoryRequestAction {
  return {
    type: GET_ACTIVITY_HISTORY_REQUEST,
  };
}

export function getActivityHistoryFailure(): GetActivityHistoryFailureAction {
  return {
    type: GET_ACTIVITY_HISTORY_FAILURE,
  };
}

export function setActivityHistory(
  statistics: ActivitiyHistory
): SetActivityHistoryAction {
  return {
    type: SET_ACTIVITY_HISTORY,
    statistics: statistics,
  };
}
