import { useRef, useEffect } from "react";

/**
 * This is a custom hook that can be used for managing timeouts.
 * The timeout will be cleared if the component destroys to prevent memory leaks or other errors / warnings from happening / appearing.
 * @param callback Function that is called after the given delay.
 * @param delay Delay in ms that determines when the callback is being executed.
 */
const useTimeout = (callback: () => void, delay: number) => {
  const timeoutRef = useRef<NodeJS.Timeout>();
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    timeoutRef.current = setTimeout(() => callbackRef.current(), delay);

    // clear timeout if the components unmounts
    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, [delay]);

  // in case you want to manually clear the timeout from the consuming component
  return timeoutRef;
};

export default useTimeout;
