import apiRoutes from "../apiRoutes";
import { BaseCrudService } from "../baseCrudService";
import { AccountInviteData } from "../../models/accountInviteData";

/**
 * Service class for managing organization invites.
 * Provides methods for retrieving organization invite data.
 */
export class AccountInvitesService extends BaseCrudService {
  /**
   * Retrieves all account invites from the API.
   * @returns {Promise<AccountInviteData[]>} A promise that resolves to an array of account invite data.
   */
  async getAccountInvitesByOrganizationId(organizationId: string) {
    const response = await this.getAll<AccountInviteData[]>(
      `${apiRoutes.organization}/${organizationId}/account_invites`
    );

    return response.data;
  }

  /**
   * Removes an invitation to join the organization .
   * @param {string} membershipId - The ID of the invite to remove.
   * @returns {Promise<void>} A Promise that resolves once the invite is removed.
   * @throws {Error} If there is an error while removing the invite.
   */
  async removeAccountInvitation(invitationId: string) {
    const response = await this.delete(apiRoutes.account_invitation, invitationId);
    return response.data;
  }
}
