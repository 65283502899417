import {
  COLOR_MODE,
  ColorModeAction,
  FONT_SIZE_MODE,
  FontSizeModeAction,
} from "./types";

export function ChangeFontSizeMode(): FontSizeModeAction {
  return { type: FONT_SIZE_MODE };
}

export function toggleMonoMode(): ColorModeAction {
  return { type: COLOR_MODE };
}
