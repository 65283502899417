import { Grid, Link, Typography } from "@material-ui/core";
import React from "react";

export const WebAccessibilityPage: React.FC = () => {
  const EMAIL_INFO = "support@lumos-lernen.de";

  return (
    <Grid container>
      <Grid item sm={12}>
        <h1>Selbstauskunft zur Barrierefreiheit</h1>
      </Grid>

      <Grid>
        <h2>Stand der Vereinbarkeit mit den Anforderungen</h2>
        <Typography paragraph>
          Die Anforderungen der Barrierefreiheit ergeben sich aus Paragraph 3 Absatz 1
          bis 4 und Paragraph 4 der Barrierefreien-Informationstechnik-Verordnung (BITV
          2.0), die auf Grundlage von § 12d Behindertengleichstellungsgesetz (BGG)
          erlassen wurden.
        </Typography>
        <Typography paragraph>
          Aufgrund der eigenen Überprüfung sind die Websites mit den zuvor genannten
          Anforderungen nicht vereinbar. Wir arbeiten derzeit daran, die Barrieren so
          schnell wie möglich zu beheben.
        </Typography>
        <Typography paragraph>
          Die nachstehend aufgeführten Inhalte sind aus den folgenden Gründen nicht
          barrierefrei:
        </Typography>
        <Typography paragraph>Unvereinbarkeit mit BITV 2.0:</Typography>
        <Typography paragraph>
          <ul style={{ paddingLeft: "4em" }}>
            <li>
              Einige Elemente des Cookie-Banners haben keine barrierefreie Benamung.
            </li>
            <li>Einige Attribute bei der Registrierung sind nicht unique.</li>
            <li>
              Einige ARIA IDs bei „Meine Buchungen“ und „Einstellungen“ sind nicht
              unique.
            </li>
            <li>
              Die beiden Seiten zur Überleitung und Rückleitung zwischen LMS und dem
              Scorm Cloud Anbieter besitzen kein valides lang-Attribut.
            </li>
            <li>
              30 Sekunden der Infektionsschutzbelehrung besitzen noch keinen Untertitel.
            </li>
          </ul>
        </Typography>
        <Typography paragraph>
          Wir sind bemüht die Barrieren zeitnah zu beseitigen. Für 2024 ist eine Prüfung
          und Zertifizierung nach BITV 2.0 geplant.
        </Typography>
      </Grid>

      <Grid>
        <h2>Datum der Erstellung der Erklärung</h2>
        <Typography paragraph>01.10.2024</Typography>
      </Grid>

      <Grid>
        <h2>Schlichtungsverfahren</h2>
        <Typography paragraph>
          Menschen mit Behinderungen können sich an die Schlichtungsstelle nach § 16
          Behindertengleichstellungsgesetz (BGG) wenden, nachdem sie uns eine Barriere
          gemeldet haben, wenn sie der Auffassung sind, die Webanwendung LUMOS
          benachteilige sie. Das Gleiche gilt für Verbände von Menschen mit
          Behinderungen, denen nach den Voraussetzungen des § 15 Absatz 3 BGG eine
          Anerkennung durch das Bundesministerium für Arbeit und Soziales erteilt worden
          ist. Betroffene können über{" "}
          <Link href={`mailto:${EMAIL_INFO}`}>{EMAIL_INFO}</Link> Kontakt aufnehmen und
          eine Barriere melden.
        </Typography>
      </Grid>
    </Grid>
  );
};
