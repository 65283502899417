import { Dispatch } from "redux";
import { pinboardService } from "../../api";
import { PinboardFormData } from "../../models/pinboardData";
import { pinboardClear } from "../pinboard/actions";
import { PinboardClearAction } from "../pinboard/types";
import {
  PinboardPostQuestionAction,
  PinboardPostQuestionFailureAction,
  PinboardPostSuccessAction,
  PinboardQuestionActionTypes,
  PinboardUpdateQuestionAction,
  PinboardUpdateQuestionFailureAction,
  PinboardUpdateQuestionSuccessAction,
  PINBOARD_POST_QUESTION,
  PINBOARD_POST_QUESTION_FAILURE,
  PINBOARD_POST_QUESTION_SUCCESS,
  PINBOARD_UPDATE_QUESTION,
  PINBOARD_UPDATE_QUESTION_FAILURE,
  PINBOARD_UPDATE_QUESTION_SUCCESS,
} from "./types";
import { enqueueSnackbar } from "notistack";

export function postQuestion(data: PinboardFormData) {
  return (dispatch: Dispatch<PinboardQuestionActionTypes | PinboardClearAction>) => {
    dispatch(pinboardPostQuestion());
    pinboardService
      .postQuestion(data)
      .then((response) => {
        dispatch(pinboardPostQuestionSuccess(response.data));
        dispatch(pinboardClear());
        enqueueSnackbar("Der Beitrag wurde erfolgreich erstellt.", {
          variant: "success",
        });
      })
      .catch(() => {
        dispatch(pinboardPostQuestionFailure());
      });
  };
}

function editQuestion(id: string, data: PinboardFormData, feedback: string) {
  return (dispatch: Dispatch<PinboardQuestionActionTypes | PinboardClearAction>) => {
    dispatch(pinboardUpdateQuestion());
    pinboardService
      .updateQuestion(id, data)
      .then((response) => {
        dispatch(pinboardUpdateQuestionSuccess(response.data));
        dispatch(pinboardClear());
        enqueueSnackbar(feedback, { variant: "success" });
      })
      .catch(() => {
        dispatch(pinboardUpdateQuestionFailure());
      });
  };
}

export function updateQuestion(id: string, data: PinboardFormData) {
  return editQuestion(id, data, "Der Beitrag wurde erfolgreich bearbeitet.");
}

export function deleteQuestion(id: string, data: PinboardFormData) {
  data.is_trash = true;
  return editQuestion(id, data, "Der Beitrag wurde erfolgreich entfernt.");
}

export function archiveQuestion(id: string, data: PinboardFormData) {
  data.is_archived = true;
  return editQuestion(id, data, "Der Beitrag wurde erfolgreich archiviert.");
}

export function unarchiveQuestion(id: string, data: PinboardFormData) {
  data.is_archived = false;
  return editQuestion(id, data, "Der Beitrag wurde erfolgreich veröffentlicht.");
}

export function followQuestion(id: string, data: PinboardFormData) {
  data.user_has_subscribed = true;
  return editQuestion(id, data, "Die Benachrichtigung wurde erfolgreich aktiviert.");
}

export function unfollowQuestion(id: string, data: PinboardFormData) {
  data.user_has_subscribed = false;
  return editQuestion(id, data, "Die Benachrichtigung wurde erfolgreich deaktiviert.");
}

export function pinboardPostQuestion(): PinboardPostQuestionAction {
  return {
    type: PINBOARD_POST_QUESTION,
  };
}

export function pinboardPostQuestionSuccess(
  data: PinboardFormData
): PinboardPostSuccessAction {
  return {
    type: PINBOARD_POST_QUESTION_SUCCESS,
    data,
  };
}

export function pinboardPostQuestionFailure(): PinboardPostQuestionFailureAction {
  return {
    type: PINBOARD_POST_QUESTION_FAILURE,
  };
}

export function pinboardUpdateQuestion(): PinboardUpdateQuestionAction {
  return {
    type: PINBOARD_UPDATE_QUESTION,
  };
}

export function pinboardUpdateQuestionSuccess(
  data: PinboardFormData
): PinboardUpdateQuestionSuccessAction {
  return {
    type: PINBOARD_UPDATE_QUESTION_SUCCESS,
    data,
  };
}

export function pinboardUpdateQuestionFailure(): PinboardUpdateQuestionFailureAction {
  return {
    type: PINBOARD_UPDATE_QUESTION_FAILURE,
  };
}
