import { GroupMembershipDocumentState, MembershipDocumentActionType } from "./types";

export const initialDocumentsState: GroupMembershipDocumentState = {
  memberships: [],
};

export function groupMembershipDocumentReducer(
  state = initialDocumentsState,
  action: MembershipDocumentActionType
): GroupMembershipDocumentState {
  switch (action.type) {
    case "MEMBERSHIPS_REQUEST":
      return { ...state, memberships: action.memberships };
    case "ADD_MEMBERSHIP":
      return {
        ...state,
        memberships: [...state.memberships, action.membership],
      };
    case "REMOVE_MEMBERSHIP":
      return {
        ...state,
        memberships: state.memberships.filter((m) => m !== action.membership),
      };
    case "CLEAR_MEMBERSHIPS":
      return { ...initialDocumentsState };
    default:
      return state;
  }
}

export default groupMembershipDocumentReducer;
