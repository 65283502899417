import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
import CustomTextField from "../../../../forms/CustomTextField";

const BillingAddressSection: React.FC = () => {
  const { t } = useTranslation(["common", "organization"]);

  return (
    <>
      <h3>{t("orderAddress", { ns: "organization" })}</h3>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <CustomTextField
            required
            fullWidth
            id="billing-address-firstname"
            name="billing_address_firstname"
            label={t("forms.billingAddressFirstname", { ns: "organization" })}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomTextField
            required
            fullWidth
            id="billing-address-lastname"
            name="billing_address_lastname"
            label={t("forms.billingAddressLastname", { ns: "organization" })}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomTextField
            required
            fullWidth
            id="billing-address.email"
            name="billing_address_email"
            label={t("forms.billingAddressEmail", { ns: "organization" })}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomTextField
            required
            fullWidth
            id="billing-address-phone"
            name="billing_address_phone"
            label={t("forms.billingAddressPhone", { ns: "organization" })}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomTextField
            fullWidth
            required
            id="billing-address-street"
            name="billing_address_street"
            label={t("profile.street", { ns: "common" })}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomTextField
            required
            fullWidth
            id="billing-address-street-number"
            name="billing_address_street_number"
            label={t("profile.streetNumber", { ns: "common" })}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomTextField
            required
            fullWidth
            id="billing-address-zip-code"
            name="billing_address_zip_code"
            label={t("profile.zipCode", { ns: "common" })}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomTextField
            required
            fullWidth
            id="billing-address-city"
            name="billing_address_city"
            label={t("profile.city", { ns: "common" })}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default BillingAddressSection;
