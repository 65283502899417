import { Divider, Grid } from "@material-ui/core";
import { useFormikContext } from "formik";
import React from "react";
import { ELearningFormData } from "../../../../../models/elearning";
import { EventStatus } from "../../../../../models/enums/eventStatus.enum";
import { TrainingType } from "../../../../../models/enums/trainingType.enum";
import { EventFormData } from "../../../../../models/eventData";
import CustomTextField from "../../../../forms/CustomTextField";

interface EventDataSectionProps {
  typeOfTrainingToBeCreated: TrainingType;
}

const EventDataSection: React.FC<EventDataSectionProps> = (props) => {
  const { values } = useFormikContext<EventFormData | ELearningFormData>();

  return (
    <>
      {props.typeOfTrainingToBeCreated === TrainingType.BlendedLearning && (
        <>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <h3>Veranstaltung</h3>
          </Grid>
        </>
      )}
      <Grid item xs={12} sm={6}>
        <CustomTextField
          fullWidth
          required={values.status === EventStatus.Published}
          disabled={values.status === EventStatus.Completed}
          name="minseats"
          InputProps={{ inputProps: { min: 1 } }}
          id="minseats"
          label="Mindestteilnehmerzahl"
          type="number"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <CustomTextField
          fullWidth
          required={values.status === EventStatus.Published}
          disabled={values.status === EventStatus.Completed}
          InputProps={{ inputProps: { min: 1 } }}
          name="maxseats"
          id="maxseats"
          label="Maximale Teilnehmerzahl"
          type="number"
        />
      </Grid>
    </>
  );
};

export default EventDataSection;

// TODO no translations yet
