import { Button } from "@material-ui/core";
import * as React from "react";
import {
  mapToTrainingTypeString,
  TrainingType,
} from "../../../../../models/enums/trainingType.enum";
import useCheckDocumentsUploads from "../../shared/useCheckDocumentsUpload";

interface CancelButtonProps {
  isEditMode: boolean;
  typeOfTrainingToBeCreated: TrainingType;
}

const CancelButton: React.FC<CancelButtonProps> = (props) => {
  const trainingType = mapToTrainingTypeString(props.typeOfTrainingToBeCreated);
  const trainingTypeNounWithArticle = `${
    props.typeOfTrainingToBeCreated !== TrainingType.DefaultEvent ? "der" : "des"
  } ${trainingType}${
    props.typeOfTrainingToBeCreated !== TrainingType.DefaultEvent ? "s" : ""
  }`;

  const { deleteUnassignedDocuments, LeavePagePrompt } = useCheckDocumentsUploads(
    props.isEditMode
      ? `Bearbeiten ${trainingTypeNounWithArticle}`
      : `Anlegen ${trainingTypeNounWithArticle}`
  );

  return (
    <>
      <Button
        size="large"
        variant="contained"
        color="secondary"
        fullWidth
        onClick={() => {
          deleteUnassignedDocuments();
        }}
      >
        Abbrechen
      </Button>
      <LeavePagePrompt />
    </>
  );
};

export default CancelButton;

// TODO no translation yet
