import { DEFAULT_CERTIFICATE_TYPE } from "../../constants";
import { DiscountItem } from "../../models/discountData";
import { EventStatus } from "../../models/enums/eventStatus.enum";
import { EventType, InternalExternalEventType } from "../../models/enums/eventType.enum";
import { TrainingType } from "./../../models/enums/trainingType.enum";
import {
  ADD_ELEARNING_SUCCESS,
  ELEARNING_FAILURE,
  ELEARNING_REQUEST,
  ELearningActionTypes,
  ELearningState,
  FINISH_FETCHING_ELEARNING,
} from "./types";

export const initialELearningState: ELearningState = {
  addedELearning: {
    vnr: "",
    title: "",
    location: "",
    city: "Frankfurt",
    zip_code: "60486",
    location_details: "",
    begin: new Date(),
    end: new Date(),
    score: 0,
    price: 0,
    min_seats: 0,
    max_seats: 0,
    description: "",
    status: EventStatus.Undefined,
    is_capped: false,
    no_reminder: false,
    no_discount: false,
    no_automatic_participation_certificate: false,
    catering: false,
    event_type: EventType.Undefined,
    training_type: TrainingType.DefaultEvent,
    participation_types: [],
    speakers: [],
    documents: [],
    commentary: "",
    tasks: [],
    template: null,
    duration_per_unit_in_minutes: 0,
    max_time_period_to_finish_elearning_module_in_weeks: 0,
    number_of_units: 0,
    pinboard_is_activated: false,
    publish_elearning_after_event: false,
    internal_external: InternalExternalEventType.Internal,
    participant_authentication: true,
    elearning_link: "",
    discounts: [] as DiscountItem[],
    is_certification_selected: false,
    certificate_type: DEFAULT_CERTIFICATE_TYPE,
  },
  isLoading: false,
  error: false,
};

export function elearningReducer(
  state = initialELearningState,
  action: ELearningActionTypes
): ELearningState {
  switch (action.type) {
    case ELEARNING_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ADD_ELEARNING_SUCCESS:
      return {
        ...state,
        addedELearning: action.data,
        isLoading: false,
        error: false,
      };
    case FINISH_FETCHING_ELEARNING:
      return {
        ...state,
        isLoading: false,
        error: false,
      };
    case ELEARNING_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.throwError,
      };
    default:
      return state;
  }
}

export default elearningReducer;
