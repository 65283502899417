/* eslint-disable react-hooks/exhaustive-deps, react/jsx-max-depth */
import { Dispatch, SetStateAction } from "react";
import { MembershipDocumentList } from "./MembershipDocumentList";
import { AddMembershipDocumentForm } from "./AddMembershipDocumentForm";
import { Card, CardContent } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { MembershipDocumentRequestDto } from "../../../../models/groupMembership";
import { FileObject } from "material-ui-dropzone";

interface MembershipDocumentsSectionProps {
  groupMembershipDocument: MembershipDocumentRequestDto;
  setGroupMembershipDocument: Dispatch<SetStateAction<MembershipDocumentRequestDto>>;
  files: FileObject[];
  setFiles: Dispatch<SetStateAction<FileObject[]>>;
}

export const MembershipDocumentsSection: React.FC<MembershipDocumentsSectionProps> = (
  props
) => {
  const { t } = useTranslation(["participationTypes", "snackbars"]);
  const { groupMembershipDocument, setGroupMembershipDocument, files, setFiles } = props;

  return (
    <>
      <Card variant="outlined" style={{ marginBottom: "2em" }}>
        <CardContent>
          <h3>{t("title", { ns: "participationTypes" })}</h3>
        </CardContent>
      </Card>
      <MembershipDocumentList />
      <AddMembershipDocumentForm
        isRegistrationPage={false}
        groupMembershipDocument={groupMembershipDocument}
        setGroupMembershipDocument={setGroupMembershipDocument}
        files={files}
        setFiles={setFiles}
      />
    </>
  );
};
