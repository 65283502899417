import {
  CHECKLIST_FAILURE,
  CHECKLIST_REQUEST,
  ChecklistActionTypes,
  ChecklistState,
  CLEAR_CHECKLIST,
  SET_CHECKLIST,
} from "./types";

export const initialChecklistState: ChecklistState = {
  checklist: [],
  checklistLoaded: false,
  isLoading: false,
};

export function checklistReducer(
  state = initialChecklistState,
  action: ChecklistActionTypes
): ChecklistState {
  switch (action.type) {
    case CHECKLIST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case CHECKLIST_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case SET_CHECKLIST:
      return {
        ...state,
        checklist: [...action.data],
        checklistLoaded: true,
      };
    case CLEAR_CHECKLIST:
      return initialChecklistState;
    default:
      return state;
  }
}

export default checklistReducer;
