export const languageOptions = [
  { value: "en", label: "English" },
  { value: "de", label: "Deutsch" },
];

export const defaultLanguage = { value: "en", label: "English" };

{
  /**
# TODO add options to languageOptions when correct translations in BE
  { value: "ar", label: "العربية" },
  { value: "bg", label: "български" },
  { value: "fr", label: "Français" },
  { value: "hu", label: "Magyar" },
  { value: "pl", label: "Polski" },
  { value: "ps", label: "پښتو" },
  { value: "ro", label: "Română" },
  { value: "ru", label: "Русский" },
  { value: "tr", label: "Türkçe" },
**/
}
