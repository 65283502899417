import { Grid } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { eventService } from "../../../../api";
import { EventStatus } from "../../../../models/enums/eventStatus.enum";
import { TrainingType } from "../../../../models/enums/trainingType.enum";
import { EventListData } from "../../../../models/eventData";
import { TaskEventData } from "../../../../models/taskData";
import CustomTextField from "../../../forms/CustomTextField";
import { NIL as NIL_UUID } from "uuid";

interface SearchEventFieldProps {
  onChangeEventId: (id: string) => void;
  initialEvent?: TaskEventData;
}

export const SearchEventField: React.FC<SearchEventFieldProps> = (props) => {
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchOptions, setSearchOptions] = useState<TaskEventData[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>();
  const [autocompleteValue, setAutocompleteValue] = useState<TaskEventData>({
    id: NIL_UUID,
    public_id: 0,
    name: "",
    location: "",
    training_type: TrainingType.DefaultEvent,
  });
  const [editedSearch, setEditedSearch] = useState<boolean>(false);

  const handleSearchQueryChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setEditedSearch(true);
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      let query = searchQuery;

      if (query != null) {
        (async () => {
          if (query === searchQuery && query.length > 0) {
            if (query[0] === "#") {
              // if there is a # in the string (when searching for a number), remove it, so the query still works as intended
              query = query.substring(1);
            }
            var results = (await eventService.getEvents("?search=" + query)).data
              .results;
            results = results.filter(
              (event: EventListData) => event.status !== EventStatus.Completed // &&
              // event.status !== EventStatus.Cancelled
              // Further down the line, tasks are supposed to be deleted for cancelled events anyway
              // for now leaving it findable is the desired solution though
            );
            setSearchOptions(
              results.map((event) => {
                return {
                  id: event.id,
                  name: event.title,
                  public_id: event.public_id,
                  location: event.location,
                } as TaskEventData;
              })
            );
          }
        })();
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchQuery, setSearchOptions]);

  useEffect(() => {
    if (!searchOpen) {
      setSearchOptions([]);
    }
  }, [searchOpen]);

  useEffect(() => {
    if (!editedSearch && props.initialEvent) {
      setAutocompleteValue(props.initialEvent);
    }
  }, [editedSearch, setAutocompleteValue, props.initialEvent]);

  return (
    <Autocomplete
      freeSolo
      id="event"
      noOptionsText={"Keine Fortbildungsmaßnahme gefunden"}
      // react shows loadingText if no options provided
      loadingText={"Keine Fortbildungsmaßnahme gefunden"}
      value={autocompleteValue}
      open={searchOpen}
      onOpen={() => {
        setSearchOpen(true);
      }}
      onClose={() => {
        setSearchOpen(false);
      }}
      // backend will filter the results
      filterOptions={(options, _) => {
        return options;
      }}
      onChange={(event, item) => {
        if (typeof item !== "string") {
          if (item?.id) props.onChangeEventId(item?.id);
        }
      }}
      options={searchOptions}
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={(option) => {
        return typeof option !== "string" && option.id !== NIL_UUID
          ? option.name + (option.public_id ? ` (#${option.public_id})` : "")
          : option.toString();
      }}
      renderOption={(option) => (
        <Grid container justify="center" alignItems="center">
          <Grid item xs={12}>
            #{option.public_id}
            <br />
            {option.name}
          </Grid>
        </Grid>
      )}
      renderInput={(params) => (
        <CustomTextField
          {...params}
          label="Fortbildungsmaßnahme"
          name={"event"}
          value={searchQuery}
          onChange={handleSearchQueryChange}
        />
      )}
    />
  );
};
