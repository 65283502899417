import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { ParticipantListData } from "../../../../../models/participantData";
import { AppState } from "../../../../../redux";
import { showDialog } from "../../../../../redux/dialogs/actions";
import CustomSelect from "../../../../forms/selects/CustomSelect";
import "./Participants.scss";
import {
  ELearningParticipationStatus,
  ParticipationStatus,
} from "../../../../../models/enums/participationStatus.enum";
import { addEventToCart } from "../../../../../redux/cart/actions";
import { useHistory } from "react-router-dom";
import { isEventOver } from "../../shared/checkEventIsOver";
import routes from "../../../../../routing/routes";
import { TrainingType } from "../../../../../models/enums/trainingType.enum";
import usePermission from "../../../../../services/usePermissions";
import { editMultipleParticipantStatus } from "../../../../../redux/participants/actions";

interface ParticipantsMultiStatusProps {
  participant: ParticipantListData[];
  disabledSelect?: boolean;
  index: number;
  trainingType: TrainingType;
  isPartOfBlendedLearning?: boolean;
  value: number;
}

export const ParticipantsMultiStatus: React.FC<ParticipantsMultiStatusProps> = (
  props
) => {
  let { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const history = useHistory();
  const currentUser = useSelector((state: AppState) => state.user.currentUser);
  const editEvent = useSelector((state: AppState) => state.event.editEvent);

  const { getParticipationStatusForSelectBasedOnUserType } = usePermission(
    currentUser?.user_type
  );

  const { getParticipationStatusForSelectBasedOnUserTypeWithoutBookingOption } =
    usePermission(currentUser?.user_type);

  const options =
    props.trainingType === TrainingType.ELearning
      ? [
          {
            key: "Bestanden",
            value: ELearningParticipationStatus.Passed,
            label: "Bestanden",
          },
          {
            key: "Nicht bestanden",
            value: ELearningParticipationStatus.NotPassed,
            label: "Nicht bestanden",
          },
        ]
      : getParticipationStatusForSelectBasedOnUserType();

  const optionsWithoutBooking =
    props.trainingType === TrainingType.ELearning
      ? [
          {
            key: "Bestanden",
            value: ELearningParticipationStatus.Passed,
            label: "Bestanden",
          },
          {
            key: "Nicht bestanden",
            value: ELearningParticipationStatus.NotPassed,
            label: "Nicht bestanden",
          },
        ]
      : getParticipationStatusForSelectBasedOnUserTypeWithoutBookingOption();

  let statusLabel = "Status";
  if (props.trainingType === TrainingType.DefaultEvent && props.isPartOfBlendedLearning)
    statusLabel += " Veranstaltung";
  if (props.trainingType === TrainingType.ELearning) statusLabel += " E-Learning";

  return (
    <CustomSelect
      disabled={props.disabledSelect || props.trainingType === TrainingType.ELearning}
      name={"eventParticipation." + props.index}
      id={"participant-status" + props.index}
      label={statusLabel}
      labelId="participant-status-label"
      value={props.value}
      noEmptyDefaultValue
      onChange={(
        event: React.ChangeEvent<{ name?: string; value: unknown }>,
        child?: React.ReactNode
      ) => {
        let appendedText = "";
        if (
          event.target.value === ParticipationStatus.Booked &&
          !editEvent.max_seats_taken &&
          props.participant[0].status !== ParticipationStatus.Booked &&
          !isEventOver(editEvent.end)
        ) {
          appendedText =
            " Sie werden im nächsten Schritt zum Checkout weitergeleitet, welchen Sie stellvertretend für den Nutzer durchführen müssen.";
        } else if (
          event.target.value === ParticipationStatus.Booked &&
          !editEvent.max_seats_taken &&
          props.participant[0].status !== ParticipationStatus.Booked
        ) {
          appendedText =
            " Die Veranstaltung ist bereits beendet. Sie werden im nächsten Schritt zum Checkout weitergeleitet, welchen Sie stellvertretend für den Nutzer durchführen müssen.";
        } else if (
          event.target.value === ParticipationStatus.Booked &&
          editEvent.max_seats_taken &&
          !isEventOver(editEvent.end)
        ) {
          appendedText =
            " Die Veranstaltung ist ausgebucht. Sie werden im nächsten Schritt zum Checkout weitergeleitet und haben dort die Möglichkeit, die Warteliste für den Nutzer zu übergehen.";
        } else if (
          event.target.value === ParticipationStatus.Booked &&
          editEvent.max_seats_taken
        ) {
          appendedText =
            " Die Veranstaltung ist bereits beendet und ausgebucht. Sie werden im nächsten Schritt zum Checkout weitergeleitet und haben dort die Möglichkeit, die Warteliste für den Nutzer zu übergehen.";
        }

        dispatch(
          showDialog({
            title: "Status des Teilnehmers ändern",
            message:
              "Sind Sie sich sicher, dass Sie den Status der Teilnehmer " +
              ' auf "' +
              options.filter((o) => o.value === event.target.value)[0]?.label +
              '" ändern möchten?' +
              appendedText,
            action: () => {
              if (
                event.target.value === ParticipationStatus.Booked &&
                props.participant.map(
                  (participant) => participant.status !== ParticipationStatus.Booked
                )
              ) {
                // when moving participant to booked manually
                dispatch(
                  props.participant.map((participant) =>
                    addEventToCart(id, participant.id)
                  )
                );
                history.push(routes.checkout);
              } else {
                dispatch(
                  editMultipleParticipantStatus(
                    editEvent,
                    props.participant.map((participant) => {
                      return {
                        participantId: participant.id,
                        participantStatus: event.target.value as number,
                      };
                    })
                  )
                );
              }
            },
          })
        );
      }}
      options={
        props.value === ParticipationStatus.Booked ? options : optionsWithoutBooking
      }
    />
  );
};

// TODO no translation yet
