import apiRoutes from "./apiRoutes";
import { BaseCrudService } from "./baseCrudService";
import { PaypalOrderData } from "../models/paypalData";

export class PaypalService extends BaseCrudService {
  async createPaypalOrder(order: string, useGiropay: boolean) {
    const paypalOrder: PaypalOrderData = {
      id: "",
      transaction_id: "",
      url: "",
      order: order,
      useGiropay: useGiropay,
      status: "undefined",
    };
    const response = await this.create<PaypalOrderData>(apiRoutes.paypal, paypalOrder);
    return response.data;
  }

  async getPaypalOrder(id: string) {
    const response = await this.get<PaypalOrderData>(apiRoutes.paypal, id);
    return response.data;
  }

  async updatePaypalOrder(paypalOrderData: PaypalOrderData) {
    const response = await this.update<PaypalOrderData>(
      apiRoutes.paypal,
      paypalOrderData.id.toString(),
      paypalOrderData
    );
    return response;
  }
}
