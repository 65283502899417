import { Divider, Grid, Hidden, Typography } from "@material-ui/core";
import React from "react";
import { getELearningDates } from "../../../models/elearning";
import { mapToEventTypeString } from "../../../models/enums/eventType.enum";
import { mapToParticipationStatusesString } from "../../../models/enums/participationStatus.enum";
import {
  mapToTrainingTypeString,
  TrainingType,
} from "../../../models/enums/trainingType.enum";
import { ReducedBookedEventsByUserData } from "../../../models/mailHistory";
import NoEventsFound from "../events/shared/NoEventsFound";
import { v4 as uuidv4 } from "uuid";
import HistoryEventLink from "./HistoryEventLink";

interface ParticipationsProps {
  participations: ReducedBookedEventsByUserData[];
  onLoad: () => void;
}

const UserParticipations: React.FC<ParticipationsProps> = (
  props: ParticipationsProps
) => {
  React.useEffect(() => {
    props.onLoad();
  }, [props]);

  return (
    <>
      <Grid container spacing={0} className={"myevents-list-header"}>
        <Hidden mdDown>
          <Grid item xs={1}>
            Kurs-Nr.
          </Grid>
        </Hidden>
        <Grid item xs={10} sm={7} md={4} lg={3}>
          <Hidden mdDown>Titel</Hidden>
          <Hidden lgUp>Veranstaltung</Hidden>
        </Grid>
        <Hidden smDown>
          <Grid item xs={2} style={{ textAlign: "center" }}>
            Veranstaltungstermin
          </Grid>
          <Grid item xs={2} style={{ textAlign: "center" }}>
            Bearbeitungszeitraum E-Learning
          </Grid>
          <Grid item xs={2} style={{ textAlign: "center" }}>
            Veranstaltungstyp
          </Grid>
        </Hidden>
        <Hidden xsDown>
          <Grid item xs={2} style={{ textAlign: "center" }}>
            Status
          </Grid>
        </Hidden>
      </Grid>
      {props.participations?.length === 0 ? (
        <NoEventsFound itemsNotFound="gebuchten Veranstaltungen des Nutzers" />
      ) : (
        props.participations?.map((booking) => {
          const { begin, end } = getELearningDates(booking);
          const eLearningDates = [
            TrainingType.ELearning,
            TrainingType.BlendedLearning,
          ].includes(booking.event.training_type)
            ? begin.toLocaleDateString() + "-" + end.toLocaleDateString()
            : "-";
          const when =
            booking.event.training_type === TrainingType.ELearning
              ? "-"
              : new Date(booking.event.begin).toLocaleDateString();
          const trainingType = [
            TrainingType.ELearning,
            TrainingType.BlendedLearning,
          ].includes(booking.event.training_type)
            ? `${mapToTrainingTypeString(booking.event.training_type)}`
            : "";
          const eventType = mapToEventTypeString(booking.event.event_type);
          const location =
            booking.event.location.length > 0 &&
            booking.event.location !== mapToEventTypeString(booking.event.event_type)
              ? ` (${booking.event.location})`
              : "";
          const where =
            `${eventType}${location}`.length > 0 ? ` (${eventType}${location})` : "";
          // remove double-parenthesis
          let whatWhere = (trainingType + where).replaceAll("))", ")");
          // remove first parenthesis if no parenthesis are needed at all
          if (whatWhere.charAt(1) === "(") whatWhere = whatWhere.replace("(", "");
          // replace last opening parenthesis with dash separating event-type and location
          if ((whatWhere.match(/\(/g) || []).length > 1)
            // eslint-disable-next-line
            whatWhere = whatWhere.replace(/\(([^\()]+)\)$/, " - $1)");
          // remove the closing parenthesis which is missing an opening parenthesis
          if (
            (whatWhere.match(/\)/g) || []).length === 1 &&
            (whatWhere.match(/\(/g) || []).length === 0
          )
            whatWhere = whatWhere.replace(")", "");
          return (
            <React.Fragment key={uuidv4()}>
              <Grid container spacing={0} className={"myevents-list-item"}>
                <Hidden mdDown>
                  <Grid item xs={1}>
                    # {booking.event.public_id}
                  </Grid>
                </Hidden>
                <Grid item xs={10} sm={7} md={4} lg={3}>
                  <Hidden lgUp>
                    # {booking.event.public_id}
                    <br />
                  </Hidden>
                  <Hidden smDown>
                    <HistoryEventLink event={booking.event} />
                  </Hidden>
                  <Hidden mdUp>
                    <b>Titel:</b> <HistoryEventLink event={booking.event} />
                  </Hidden>
                  <Hidden mdUp>
                    {when.length > 1 && (
                      <div>
                        <b>Veranstaltungstermin: </b> {when}
                      </div>
                    )}
                    {eLearningDates.length > 1 && (
                      <div>
                        <b>Bearbeitungszeitraum E-Learning: </b> {eLearningDates}
                        {booking.reactivated && (
                          <>
                            <br />
                            <Typography variant="caption">
                              <strong>
                                (verlängert bis{" "}
                                {new Date(booking.overall_end).toLocaleDateString()})
                              </strong>
                            </Typography>
                          </>
                        )}
                      </div>
                    )}
                    <div>
                      <b>Veranstaltungstyp:</b> {whatWhere}
                    </div>
                  </Hidden>
                  <Hidden smUp>
                    <br /> <b>Status:</b>{" "}
                    {mapToParticipationStatusesString(booking.status)}
                  </Hidden>
                </Grid>
                <Hidden smDown>
                  <Grid item xs={2} style={{ textAlign: "center" }}>
                    {when}
                  </Grid>
                  <Grid item xs={2} style={{ textAlign: "center" }}>
                    {eLearningDates}
                    {booking.reactivated && (
                      <>
                        <br />
                        <Typography variant="caption">
                          <strong>
                            (verlängert bis{" "}
                            {new Date(booking.overall_end).toLocaleDateString()})
                          </strong>
                        </Typography>
                      </>
                    )}
                  </Grid>
                  <Grid item xs={2} style={{ textAlign: "center" }}>
                    {whatWhere}
                  </Grid>
                </Hidden>
                <Hidden xsDown>
                  <Grid item xs={2} style={{ textAlign: "center" }}>
                    {mapToParticipationStatusesString(booking.status)}
                  </Grid>
                </Hidden>
              </Grid>
              <Divider />
            </React.Fragment>
          );
        })
      )}
    </>
  );
};

export default UserParticipations;
