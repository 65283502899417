import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Prompt, useHistory } from "react-router-dom";
import { AppState } from "../../../../redux";
import { showDialog } from "../../../../redux/dialogs/actions";
import {
  bulkDeleteDocuments,
  clearDocuments,
} from "../../../../redux/documents/actions";
import { clearSpeakers } from "../../../../redux/speaker/actions";
import useExitPrompt from "../../../hooks/useExitPrompt";
import routes from "../../../../routing/routes";

const useCheckDocumentsUploads = (title: string) => {
  const [showExitPrompt, setShowExitPrompt] = useExitPrompt(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const documents = useSelector((state: AppState) => state.documents.documentListTemp);
  const uploadedDocuments = documents.filter(
    (x) => !x?.is_default_online && !x?.is_default_on_site
  );

  const deleteUnassignedDocuments = () => {
    setShowExitPrompt(false);
    if (uploadedDocuments.length > 0) {
      dispatch(
        showDialog({
          title: `${title} abbrechen`,
          message: (
            <>
              Sind Sie sich sicher, dass Sie das {title} abbrechen möchten? Sie haben
              bereits{" "}
              <b>
                {uploadedDocuments.length}{" "}
                {uploadedDocuments.length === 1 ? "Dokument" : "Dokumente"}
              </b>{" "}
              hochgeladen,{" "}
              {uploadedDocuments.length === 1 ? "dieses wird " : "diese werden "}
              verworfen, wenn Sie fortfahren.
            </>
          ),
          action: () => {
            dispatch(bulkDeleteDocuments(uploadedDocuments.map((doc) => doc.id)));
            dispatch(clearSpeakers());
            history.push(routes.events);
          },
        })
      );
    } else {
      dispatch(clearSpeakers());
      dispatch(clearDocuments());
      history.push(routes.events);
    }
  };

  useEffect(() => {
    if (uploadedDocuments.length > 0) setShowExitPrompt(true);
  }, [setShowExitPrompt, uploadedDocuments.length]);

  const LeavePagePrompt = () => {
    return showExitPrompt ? (
      <Prompt
        when={uploadedDocuments.length > 0}
        message={`Sind Sie sich sicher, dass Sie die Seite verlassen möchten? 
          Sie haben bereits ungespeicherte Änderungen an den Dokumenten, die verworfen werden, wenn Sie fortfahren.`}
      />
    ) : (
      <></>
    );
  };

  return { deleteUnassignedDocuments, LeavePagePrompt };
};

export default useCheckDocumentsUploads;

// TODO no translation yet
