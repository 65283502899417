import { DiscountGroupWrapperMeta, DiscountItem } from "../../models/discountData";

export interface DiscountState {
  discountListLoaded: boolean;
  discountList: DiscountItem[];
  discountItemWrapper: DiscountGroupWrapperMeta[];
  isLoading: boolean;
}

export const DISCOUNT_REQUEST = "DISCOUNT_REQUEST";
export const DELETE_DISCOUNT = "DELETE_DISCOUNT";
export const ADD_DISCOUNT_GROUP = "ADD_DISCOUNT_GROUP";
export const UPDATE_DISCOUNT = "UPDATE_DISCOUNT";
export const DISCOUNT_FAILURE = "DISCOUNT_FAILURE";
export const SET_DISCOUNT_LIST = "SET_DISCOUNT_LIST";
export const SAVE_DISCOUNT_LIST = "SAVE_DISCOUNT_LIST";

export interface DiscountRequestAction {
  type: typeof DISCOUNT_REQUEST;
}

export interface SaveDiscountAction {
  type: typeof SAVE_DISCOUNT_LIST;
}

export interface AddDiscountGroupAction {
  type: typeof ADD_DISCOUNT_GROUP;
}

export interface DiscountFailureAction {
  type: typeof DISCOUNT_FAILURE;
  error: any;
}

export interface SetDiscountListAction {
  type: typeof SET_DISCOUNT_LIST;
  data: DiscountItem[];
}

export interface DeleteDiscountAction {
  type: typeof DELETE_DISCOUNT;
  group: DiscountGroupWrapperMeta;
}

export interface UpdateDiscountAction {
  type: typeof UPDATE_DISCOUNT;
  group: DiscountGroupWrapperMeta;
}

export type DiscountActionTypes =
  | DiscountRequestAction
  | DeleteDiscountAction
  | AddDiscountGroupAction
  | UpdateDiscountAction
  | DiscountFailureAction
  | SetDiscountListAction
  | SaveDiscountAction;
