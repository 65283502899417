import { Box, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { AppState } from "../../redux";
const HeadingLumos = ({ children }: { children: React.ReactNode }) => {
  const accessibility = useSelector((state: AppState) => state.accessibility);

  return (
    <Box
      className={accessibility.monoMode ? "mono-color-header-title" : "header-title"}
      mt={2}
    >
      <Typography variant="h4" gutterBottom>
        {children}
      </Typography>
    </Box>
  );
};

export default HeadingLumos;
