import routes from "../../routing/routes";
import {
  AuthActionTypes,
  AuthState,
  FETCHING_AUTH_DATA_ACTION_FINISHED,
  FETCH_MOODLE_DATA_SUCCESS,
  GET_SESSION_TOKEN_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_MOODLE_SUCCESS,
  LOGIN_SUCCESS,
  LOGOUT,
  MOODLE_ERROR,
  REQUEST,
} from "./types";

export const initialAuthState: AuthState = {
  loggedIn: false,
  isLoading: false,
  loggedInFrom: routes.login,
  queryParams: "",
  moddleLoginData: {
    moodle_live_url: "",
    player_data: {
      cm: 0,
      currentorg: "",
      display: "popup",
      mode: "normal",
      scoid: 0,
    },
    is_moodle_user_link_established: false,
  },
  moodleDataLoaded: false,
  moodleError: false,
  isLoggedInToMoodle: false,
};

export function authReducer(
  state = initialAuthState,
  action: AuthActionTypes
): AuthState {
  switch (action.type) {
    case REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        isLoading: false,
        loggedInFrom: action.from ? action.from.pathname : state.loggedInFrom,
        queryParams: action.from ? action.from.search : state.queryParams,
      };
    case FETCH_MOODLE_DATA_SUCCESS:
      return {
        ...state,
        moddleLoginData: action.data,
        moodleDataLoaded: true,
      };
    case LOGIN_MOODLE_SUCCESS:
      return {
        ...state,
        isLoggedInToMoodle: true,
      };
    case MOODLE_ERROR:
      return {
        ...state,
        moodleError: true,
      };
    case GET_SESSION_TOKEN_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        isLoading: false,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        loggedIn: false,
        isLoading: false,
      };
    case FETCHING_AUTH_DATA_ACTION_FINISHED:
      return {
        ...state,
        isLoading: false,
      };
    case LOGOUT:
      return initialAuthState;
    default:
      return state;
  }
}

export default authReducer;
