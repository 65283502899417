import { InvoiceData, MyInvoiceListData } from "./../../models/invoiceData";
import { Pagination } from "../../models/pagination";

export interface InvoiceState {
  invoiceData: InvoiceData;
  myInvoiceList: Pagination<MyInvoiceListData>;
  myInvoiceListPrint: MyInvoiceListData[];
  myInvoiceListLoaded: boolean;
  currentPage: number;
  isLoading: boolean;
}

// TODO: Remove CLEAR_INVOICE ?
export const CLEAR_INVOICE = "CLEAR_INVOICE";
export const INVOICE_REQUEST = "INVOICE_REQUEST";
export const INVOICE_FAILURE = "INVOICE_FAILURE";
export const LOAD_INVOICE_DATA_SUCCESS = "LOAD_INVOICE_DATA_SUCCESS";
export const SET_INVOICE_NUMBER = "SET_INVOICE_NUMBER";
export const SET_INVOICE_DATA = "SET_INVOICE_DATA";
export const SET_MY_INVOICE_LIST_DATA = "SET_MY_INVOICE_LIST_DATA";
export const SET_INVOICES_PAGE_COUNT = "SET_INVOICES_PAGE_COUNT";

export const SET_MY_INVOICES_LIST_SUCCESS = "SET_MY_INVOICES_LIST_SUCCESS";

export interface ClearInvoiceAction {
  type: typeof CLEAR_INVOICE;
}

export interface InvoiceRequestAction {
  type: typeof INVOICE_REQUEST;
}

export interface InvoiceFailureAction {
  type: typeof INVOICE_FAILURE;
}

export interface SetInvoiceNumberAction {
  type: typeof SET_INVOICE_NUMBER;
  invoice_number: string;
}

export interface SetInvoiceDataAction {
  type: typeof SET_INVOICE_DATA;
  invoice: InvoiceData;
}

export interface LoadInvoiceDataAction {
  type: typeof LOAD_INVOICE_DATA_SUCCESS;
  invoice: string;
}

export interface SetMyInvoiceListAction {
  type: typeof SET_MY_INVOICE_LIST_DATA;
  data: Pagination<MyInvoiceListData>;
}

export interface SetPageCountAction {
  type: typeof SET_INVOICES_PAGE_COUNT;
  pageCount: number;
}

export type InvoiceActionTypes =
  | ClearInvoiceAction
  | InvoiceRequestAction
  | InvoiceFailureAction
  | LoadInvoiceDataAction
  | SetInvoiceDataAction
  | SetInvoiceNumberAction
  | SetMyInvoiceListAction
  | SetPageCountAction;
