import { AxiosError } from "axios";
import { Dispatch } from "redux";
import { checklistService } from "../../api";
import {
  CLEAR_CHECKLIST,
  SET_CHECKLIST,
  SetChecklistAction,
  ClearChecklistAction,
  CHECKLIST_REQUEST,
  CHECKLIST_FAILURE,
  ChecklistRequestAction,
  ChecklistFailureAction,
  ChecklistActionTypes,
} from "./types";
import { ChecklistData } from "../../models/checklistData";
import { enqueueSnackbar } from "notistack";

export function getAllChecklistItems() {
  return (dispatch: Dispatch<ChecklistActionTypes>) => {
    dispatch(checklistRequest());
    checklistService
      .getAllChecklistItems()
      .then((response) => {
        dispatch(setChecklist(response));
      })
      .catch((err: AxiosError) => {
        dispatch(checklistFailure());
      });
  };
}

export function editChecklistForEvent(
  eventId: string,
  checklist: string[],
  checklist_additional_info: string
) {
  return (dispatch: Dispatch<ChecklistActionTypes>) => {
    checklistService
      .editChecklistForEvent(eventId, checklist, checklist_additional_info)
      .then(() => {
        enqueueSnackbar("Ihre Materialliste wurde erfolgreich aktualisiert.", {
          variant: "success",
        });
      })
      .catch((err: AxiosError) => {
        enqueueSnackbar("Ihre Materialliste konnte nicht aktualisiert werden.", {
          variant: "error",
        });
      });
  };
}

export function checklistRequest(): ChecklistRequestAction {
  return {
    type: CHECKLIST_REQUEST,
  };
}
export function checklistFailure(): ChecklistFailureAction {
  return {
    type: CHECKLIST_FAILURE,
  };
}

export function setChecklist(checklist: ChecklistData[]): SetChecklistAction {
  return {
    type: SET_CHECKLIST,
    data: checklist,
  };
}

export function clearChecklist(): ClearChecklistAction {
  return { type: CLEAR_CHECKLIST };
}
