import {
  LOAD_GROUP_MEMBERSHIP_DOCUMENT_TYPE_DATA_SUCCESS,
  GroupMembershipDocumentTypeActionTypes,
  GroupMembershipDocumentTypeState,
  GROUP_MEMBERSHIP_DOCUMENT_TYPE_FAILURE,
  GROUP_MEMBERSHIP_DOCUMENT_TYPE_REQUEST,
} from "./types";

export const initialGroupMembershipDocumentTypeState: GroupMembershipDocumentTypeState =
  {
    groupMembershipDocumentTypes: [],
    isLoading: false,
    groupMembershipDocumentTypesLoaded: false,
    isError: false,
  };

export function groupMembershipDocumentTypeReducer(
  state = initialGroupMembershipDocumentTypeState,
  action: GroupMembershipDocumentTypeActionTypes
): GroupMembershipDocumentTypeState {
  switch (action.type) {
    case GROUP_MEMBERSHIP_DOCUMENT_TYPE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case LOAD_GROUP_MEMBERSHIP_DOCUMENT_TYPE_DATA_SUCCESS:
      return {
        ...state,
        groupMembershipDocumentTypes: action.data,
        isLoading: false,
        groupMembershipDocumentTypesLoaded: true,
      };
    case GROUP_MEMBERSHIP_DOCUMENT_TYPE_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    default:
      return state;
  }
}

export default groupMembershipDocumentTypeReducer;
