import { PaypalOrderData } from "../../models/paypalData";

export interface PaypalOrderState {
  paypalOrderData: PaypalOrderData;
  isSending: boolean;
}

export const CLEAR_PAYPAL_ORDER = "CLEAR_PAYPAL_ORDER";
export const PAYPAL_ORDER_REQUEST = "PAYPAL_ORDER_REQUEST";
export const PAYPAL_ORDER_FAILURE = "PAYPAL_ORDER_FAILURE";
export const LOAD_PAYPAL_ORDER_DATA_SUCCESS = "LOAD_PAYPAL_ORDER_DATA_SUCCESS";
export const SET_PAYPAL_ORDER_NUMBER = "SET_PAYPAL_ORDER_NUMBER";
export const SET_PAYPAL_ORDER_DATA = "SET_PAYPAL_ORDER_DATA";

export interface ClearPaypalOrderAction {
  type: typeof CLEAR_PAYPAL_ORDER;
}

export interface PaypalOrderRequestAction {
  type: typeof PAYPAL_ORDER_REQUEST;
}

export interface PaypalOrderFailureAction {
  type: typeof PAYPAL_ORDER_FAILURE;
}

export interface SetPaypalOrderNumberAction {
  type: typeof SET_PAYPAL_ORDER_NUMBER;
  order_number: string;
}

export interface SetPaypalOrderDataAction {
  type: typeof SET_PAYPAL_ORDER_DATA;
  paypalOrder: PaypalOrderData;
}

export interface LoadPaypalOrderDataAction {
  type: typeof LOAD_PAYPAL_ORDER_DATA_SUCCESS;
  order: string;
}

export type PaypalOrderActionTypes =
  | ClearPaypalOrderAction
  | PaypalOrderRequestAction
  | PaypalOrderFailureAction
  | LoadPaypalOrderDataAction
  | SetPaypalOrderDataAction
  | SetPaypalOrderNumberAction;
