import { history } from "../../routing/history";
import { EventTemplateFilterData } from "../../models/templateData";
import FilterService from "./filterService";

export class EventTemplateFilterService extends FilterService<
  EventTemplateFilterData,
  {}
> {
  constructor(page: number, filterData: EventTemplateFilterData) {
    super({
      page: page,
      attributes: filterData,
    });
  }

  /**
   * Gets the current filter values from the query params in the URL and parses the values and assigns them to a LectureFilterData object that can be used to initalize a filter form.
   * The value for ordering attribute comes from base filter service.
   */
  parseFiltersFromUrl(): EventTemplateFilterData {
    const query = new URLSearchParams(history.location.search);
    const search = query.get("search") || "";
    const queryFilter = super.parseFiltersFromUrl(query);

    return {
      ...queryFilter,
      search: search,
    };
  }

  /**
   * Maps the string representation of the BookingsSortBy enum to one of the provided filters possibilites for sorting.
   * @param sort The enum or string value to parse to actual sort string.
   */
  mapSorting(sort: string | {}): string {
    return "";
  }

  /**
   * Maps the sort value and its direction to a possible BookingsSortBy enum value. Returns the default sorting if none of the provided values matches.
   * @param sort Value of attribute that should be sorted.
   */
  mapSortStringToEnum(sort: string): any {
    return {};
  }
}
