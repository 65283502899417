export interface TabsState {
  openTab: number;
}

export const SET_OPEN_TAB = "SET_OPEN_TAB";

// actions that are the same for all api calls (request and failure)
export interface SetOpenTabAction {
  type: typeof SET_OPEN_TAB;
  tab: number;
}

export type TabsActionTypes = SetOpenTabAction;
