import { BaseCrudService } from "../baseCrudService";
import apiRoutes from "../apiRoutes";
import { VideoAuthenticationResponseDTO } from "../../models/videoAuthentication";
import { CaptureMetaData } from "../../components/core/events/shared/authentication/Instructions";

export class VideoAuthService extends BaseCrudService {
  sendCapture(capture: CaptureMetaData): Promise<VideoAuthenticationResponseDTO> {
    return this.create(apiRoutes.authentication_captures, { ...capture });
  }
}
