import React from "react";
import HeadingLumos from "../../../components/theming/HeadingLumos";
import TabsLumos from "../../../components/theming/tabs/TabsLumos";
import { DocumentTemplates } from "../../../components/core/templates/document/DocumentTemplates";
import { EventTemplates } from "../../../components/core/templates/event/EventTemplates";
import { TaskTemplates } from "../../../components/core/templates/task/TaskTemplates";

export const TemplatesPage: React.FC = () => {
  return (
    <>
      <HeadingLumos>Vorlagen</HeadingLumos>
      <TabsLumos
        openTabType={"templates"}
        tabs={[
          {
            label: "Veranstaltungen",
            content: <EventTemplates />,
          },
          {
            label: "Standardaufgaben",
            content: <TaskTemplates />,
          },
          {
            label: "Dokumente",
            content: <DocumentTemplates />,
          },
        ]}
      />
    </>
  );
};

// TODO no translation yet
