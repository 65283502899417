import { AppState } from "../index";
import { AxiosError } from "axios";
import { Dispatch } from "redux";
import { orderService, paypalService } from "../../api";
import {
  CLEAR_PAYPAL_ORDER,
  ClearPaypalOrderAction,
  PaypalOrderActionTypes,
  PAYPAL_ORDER_FAILURE,
  PAYPAL_ORDER_REQUEST,
  PaypalOrderRequestAction,
  PaypalOrderFailureAction,
  SetPaypalOrderDataAction,
  SET_PAYPAL_ORDER_DATA,
} from "./types";
import { PaypalOrderData } from "../../models/paypalData";
import { CartItemData } from "../../models/cartData";

export function createPaypalOrder(order: string, cartData: CartItemData[]) {
  return (dispatch: Dispatch<PaypalOrderActionTypes>, getState: () => AppState) => {
    dispatch(paypalOrderRequest());
    return orderService
      .createOrder(cartData)
      .then((order) => {
        return (
          paypalService
            // TODO: Test use_giropay = true
            .createPaypalOrder(order.id, false)
            .then((paypalOrder) => {
              dispatch(setPaypalOrderData(paypalOrder));
              return paypalOrder;
            })
        );
      })
      .catch((err: AxiosError) => {
        const errorStatus = err.response?.status;
        const errorObject = err.response?.data;
        dispatch(paypalOrderFailure());
      });
  };
}

export function clearPaypalOrder(): ClearPaypalOrderAction {
  return { type: CLEAR_PAYPAL_ORDER };
}

export function paypalOrderRequest(): PaypalOrderRequestAction {
  return {
    type: PAYPAL_ORDER_REQUEST,
  };
}

export function paypalOrderFailure(): PaypalOrderFailureAction {
  return {
    type: PAYPAL_ORDER_FAILURE,
  };
}

export function setPaypalOrderData(
  paypalOrder: PaypalOrderData
): SetPaypalOrderDataAction {
  return {
    type: SET_PAYPAL_ORDER_DATA,
    paypalOrder,
  };
}
