import apiRoutes from "./apiRoutes";
import { BaseCrudService } from "./baseCrudService";
import { MetaData } from "../models/metaData";

export class MetaDataService extends BaseCrudService {
  async getMetaData() {
    const response = await this.getAll<MetaData>(apiRoutes.meta_data);
    return response;
  }
}
