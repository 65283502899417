import { CertificateTypeData } from "../../models/certificateTypeData";

export interface CertificateTypeState {
  certificateTypes: CertificateTypeData[];
  isLoading: boolean;
  certificateTypesLoaded: boolean;
  isError: boolean;
}

export const CERTIFICATE_TYPE_REQUEST = "CERTIFICATE_TYPE_REQUEST";
export const CERTIFICATE_TYPE_FAILURE = "CERTIFICATE_TYPE_FAILURE";
export const LOAD_CERTIFICATE_TYPE_DATA_SUCCESS = "LOAD_CERTIFICATE_TYPE_DATA_SUCCESS";

export interface CertificateTypeRequestAction {
  type: typeof CERTIFICATE_TYPE_REQUEST;
}

export interface CertificateTypeFailureAction {
  type: typeof CERTIFICATE_TYPE_FAILURE;
}

export interface LoadCertificateTypeAction {
  type: typeof LOAD_CERTIFICATE_TYPE_DATA_SUCCESS;
  data: CertificateTypeData[];
}

export type CertificateTypeActionTypes =
  | CertificateTypeRequestAction
  | CertificateTypeFailureAction
  | LoadCertificateTypeAction;
