import apiRoutes from "./apiRoutes";
import { BaseCrudService } from "./baseCrudService";
import { CertificateTypeData } from "../models/certificateTypeData";

export class CertificateTypeService extends BaseCrudService {
  async getCertificateTypes() {
    const response = await this.getAll<CertificateTypeData[]>(
      apiRoutes.certificate_types
    );
    return response.data;
  }
}
