export const borderFocus = {
  borderBottom: ".125rem solid var(--primary-color)",
  borderLeft: "0rem solid var(--primary-color)",
  borderRight: "0rem solid var(--primary-color)",
  borderTop: "0rem solid var(--primary-color)",
};

export const borderFocusMono = {
  borderBottom: ".125rem solid #000",
  borderLeft: "0rem solid #000",
  borderRight: "0rem solid #000",
  borderTop: "0rem solid #000",
};

export const borderDefault = {
  borderBottom: ".125rem solid #a2a2a2",
  borderLeft: "0rem solid #a2a2a2",
  borderRight: "0rem solid #a2a2a2",
  borderTop: "0rem solid #a2a2a2",
};

export const borderError = {
  borderBottom: ".125rem solid #f44336",
  borderLeft: "0rem solid #f44336",
  borderRight: "0rem solid #f44336",
  borderTop: "0rem solid #f44336",
};

export const borderMono = {
  borderBottom: ".125rem solid #000",
  borderLeft: "0rem solid #000",
  borderRight: "0rem solid #000",
  borderTop: "0rem solid #000",
};

export const validationErrorPrefix = {
  content: '"!"',
  display: "block",
  position: "absolute",
  width: "1.25rem",
  height: "1.25rem",
  top: "1.25rem",
  border: ".0625rem solid #d71541",
  borderRadius: "50%",
  color: "#d71541",
  textAlign: "center",
  left: "calc((-1.25rem) - .3125rem)",
  fontSize: "calc(1,25rem - 0.3125rem)",
  fontWeight: 600,
};

export const validationErrorPrefixMono = {
  content: '"!"',
  display: "block",
  position: "absolute",
  width: "1.25rem",
  height: "1.25rem",
  top: "1.25rem",
  border: ".0625rem solid #000",
  borderRadius: "50%",
  color: "#000",
  textAlign: "center",
  left: "calc((-1.25rem) - .3125rem)",
  fontSize: "calc(1.25rem - .3125rem)",
  fontWeight: 600,
};
