import { SpeakerData } from "../../models/speakerData";

export interface SpeakersState {
  speakerListLoaded: boolean;
  speakerEditListLoaded: boolean;
  speakerList: SpeakerData[];
  speakerSearchList: SpeakerData[];
  speakersSearchTerm: string;
  speakerListForEvent: SpeakerData[];
  isLoading: boolean;
}

export const SPEAKERS_REQUEST = "SPEAKERS_REQUEST";
export const SPEAKERS_FAILURE = "SPEAKERS_FAILURE";

export const SET_SPEAKERS_LIST = "SET_SPEAKERS_LIST";
export const SET_SPEAKERS_SEARCH_LIST = "SET_SPEAKERS_SEARCH_LIST";
export const SYNC_SPEAKERS_SEARCH_LIST = "SYNC_SPEAKERS_SEARCH_LIST";

export const ADD_SPEAKERS_TO_EVENT_LIST = "ADD_SPEAKERS_TO_EVENT_LIST";
export const REMOVE_SPEAKERS_FROM_EVENT_LIST = "REMOVE_SPEAKERS_FROM_EVENT_LIST";

export const EDIT_EVENT_SPEAKER_LIST = "EDIT_EVENT_SPEAKER_LIST";

export const EDIT_SPEAKERS_LOADED = "EDIT_SPEAKERS_LOADED";
export const CLEAR_SPEAKERS = "CLEAR_SPEAKERS";

export interface SpeakersRequestAction {
  type: typeof SPEAKERS_REQUEST;
}

export interface SpeakersFailureAction {
  type: typeof SPEAKERS_FAILURE;
  error: any;
}

export interface SetSpeakersListAction {
  type: typeof SET_SPEAKERS_LIST;
  data: SpeakerData[];
}

export interface SyncSpeakersSearchListAction {
  type: typeof SYNC_SPEAKERS_SEARCH_LIST;
}

export interface SetSpeakersSearchListAction {
  type: typeof SET_SPEAKERS_SEARCH_LIST;
  data: SpeakerData[];
  searchTerm: string;
}

export interface AddSpeakerToEventListAction {
  type: typeof ADD_SPEAKERS_TO_EVENT_LIST;
  data: SpeakerData;
}

export interface RemoveSpeakerFromEventListAction {
  type: typeof REMOVE_SPEAKERS_FROM_EVENT_LIST;
  data: SpeakerData;
}

export interface EditEventSpeakerListAction {
  type: typeof EDIT_EVENT_SPEAKER_LIST;
  data: SpeakerData[];
}

export interface EditSpeakersListLoadedAction {
  type: typeof EDIT_SPEAKERS_LOADED;
}

export interface ClearSpeakersAction {
  type: typeof CLEAR_SPEAKERS;
}

export type SpeakersActionTypes =
  | SpeakersRequestAction
  | SpeakersFailureAction
  | SetSpeakersListAction
  | SetSpeakersSearchListAction
  | SyncSpeakersSearchListAction
  | AddSpeakerToEventListAction
  | RemoveSpeakerFromEventListAction
  | EditEventSpeakerListAction
  | EditSpeakersListLoadedAction
  | ClearSpeakersAction;
