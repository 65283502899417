import React from "react";
import CustomButton, { CustomButtonProps } from "../../../../theming/CustomButton";
import { ButtonProps } from "@material-ui/core";
import { useTranslation } from "react-i18next";

interface ProceedButtonProps extends CustomButtonProps, ButtonProps {
  onClick: () => void;
}

export const ProceedButton: React.FC<ProceedButtonProps> = ({ onClick, ...other }) => {
  const { t } = useTranslation(["common", "snackbars"]);
  return (
    <CustomButton onClick={onClick} {...other}>
      {t("forms.buttons.continue", { ns: "common" })}
    </CustomButton>
  );
};
