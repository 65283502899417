import { Payment, UserBillingAddressDto } from "./../../models/billingAddressData";
import { CrudBillingAddressData } from "../../models/billingAddressData";
import { InvoiceData } from "../../models/invoiceData";
import { ReducedOrderData } from "../../models/orderData";

export interface BillingAddressState {
  billingAddressList: CrudBillingAddressData[];
  billingAddressOfLinkedUser: UserBillingAddressDto;
  linkedUser: number;
  billingAddressLoaded: boolean;
  isSending: boolean;
  payment: Payment;
}

export const BILLING_ADDRESS_REQUEST = "BILLING_ADDRESS_REQUEST";
export const BILLING_ADDRESS_FAILURE = "BILLING_ADDRESS_FAILURE";
export const SET_BILLING_ADDRESS = "SET_BILLING_ADDRESS";
export const SET_BILLING_ADDRESS_FOR_LINKED_USER = "SET_BILLING_ADDRESS_FOR_LINKED_USER";
export const RELOAD_BILLING_ADDRESS = "RELOAD_BILLING_ADDRESS";
export const BILLING_ADDRESS_CLEAR = "BILLING_ADDRESS_CLEAR";

export const SET_PAYMENT_METHOD = "SET_PAYMENT_METHOD";
export const SET_INVOICE_NUMBER = "SET_INVOICE_NUMBER";
export const SET_INVOICE_DATA = "SET_INVOICE_DATA";
export const SET_ORDER_DATA = "SET_ORDER_DATA";
export const SET_PAYPAL_DATA = "SET_PAYPAL_METHOD";
export const SET_COMPANY_BILLING_UPLOAD_FILE = "SET_COMPANY_BILLING_UPLOAD_FILE";
export const SET_CARD_DATA = "SET_CARD_DATA";

// actions that are the same for all api calls (request and failure)
export interface BillingAddressRequestAction {
  type: typeof BILLING_ADDRESS_REQUEST;
}

export interface BillingAddressFailureAction {
  type: typeof BILLING_ADDRESS_FAILURE;
}

export interface SetBillingAddressAction {
  type: typeof SET_BILLING_ADDRESS;
  data: CrudBillingAddressData[];
}

export interface SetBillingAddressForLinkedUserAction {
  type: typeof SET_BILLING_ADDRESS_FOR_LINKED_USER;
  data: UserBillingAddressDto;
  linkedUser: number;
}

export interface ReloadBillingAddressAction {
  type: typeof RELOAD_BILLING_ADDRESS;
}

export interface BillingAddressClearAction {
  type: typeof BILLING_ADDRESS_CLEAR;
}

export interface SetBillingPaymentAction {
  type: typeof SET_PAYMENT_METHOD;
  payment_method: string;
}

export interface SetBillingInvoiceNumberAction {
  type: typeof SET_INVOICE_NUMBER;
  invoice_number: string;
}

export interface SetBillingInvoiceDataAction {
  type: typeof SET_INVOICE_DATA;
  invoice: InvoiceData;
}

export interface SetBillingOrderDataAction {
  type: typeof SET_ORDER_DATA;
  order: ReducedOrderData;
}

export interface SetPaypalDataAction {
  id: string;
  type: typeof SET_PAYPAL_DATA;
  transaction_id: string;
  gross_amount: string;
  capture_id: string;
  currency_code: string;
}

export interface SetCompanyBillingUploadFile {
  type: typeof SET_COMPANY_BILLING_UPLOAD_FILE;
  upload_file: File;
}

export interface SetCardDataAction {
  type: typeof SET_CARD_DATA;
  card_number: string;
  card_expiration_date: string;
}

export type BillingAddressActionTypes =
  | BillingAddressRequestAction
  | BillingAddressFailureAction
  | SetBillingAddressAction
  | SetBillingAddressForLinkedUserAction
  | ReloadBillingAddressAction
  | BillingAddressClearAction
  | SetBillingPaymentAction
  | SetPaypalDataAction
  | SetCompanyBillingUploadFile
  | SetBillingInvoiceNumberAction
  | SetBillingInvoiceDataAction
  | SetBillingOrderDataAction
  | SetCardDataAction;
