import { ActivitiyHistory } from "../../models/mailHistory";

export interface ActivityHistoryUserState {
  isLoading: boolean;
  statistics?: ActivitiyHistory;
}

export const GET_ACTIVITY_HISTORY_REQUEST = "GET_ACTIVITY_HISTORY_REQUEST";
export const GET_ACTIVITY_HISTORY_FAILURE = "GET_ACTIVITY_HISTORY_FAILURE";
export const SET_ACTIVITY_HISTORY = "SET_ACTIVITY_HISTORY";

export interface GetActivityHistoryRequestAction {
  type: typeof GET_ACTIVITY_HISTORY_REQUEST;
}

export interface GetActivityHistoryFailureAction {
  type: typeof GET_ACTIVITY_HISTORY_FAILURE;
}

export interface SetActivityHistoryAction {
  type: typeof SET_ACTIVITY_HISTORY;
  statistics: ActivitiyHistory;
}

export type ActivityHistoryActionTypes =
  | GetActivityHistoryRequestAction
  | GetActivityHistoryFailureAction
  | SetActivityHistoryAction;
