import { Box, Grid } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { useFormikContext } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  DiscountGroupWrapper,
  DiscountGroupWrapperMeta,
  DiscountItem,
} from "../../../../../../models/discountData";
import { ELearningFormData } from "../../../../../../models/elearning";
import { EventFormData } from "../../../../../../models/eventData";
import { ParticipationType } from "../../../../../../models/targetGroupData";
import { AppState } from "../../../../../../redux";
import {
  addDiscountGroup,
  deleteDiscountGroup,
  saveDiscounts,
  setDiscountToList,
  updateDiscountGroup,
} from "../../../../../../redux/discounts/actions";
import styles from "../../../../../../styles/custom-styles.module.scss";
import CustomCheckbox from "../../../../../forms/CustomCheckbox";
import CustomButton from "../../../../../theming/CustomButton";
import DiscountItemForm from "./DiscountItemForm";

export const DiscountTargetGroups: React.FC = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const isNewEventMode = id === undefined || id === "";

  const { values, setFieldValue } = useFormikContext<
    EventFormData | ELearningFormData
  >();

  const allParticipaytionTypes = useSelector(
    (state: AppState) => state.targetGroups.targetGroups.participation_types
  );

  const discounts = useSelector((state: AppState) => state.discount.discountItemWrapper);

  const [isDirty, setIsDirty] = useState(false);
  const [isDiscountsActivated, setIsDiscountsActivated] = useState(false);

  const [targetGroupsWithoutDiscount, setTargetGroupsWithoutDiscount] = useState<
    ParticipationType[]
  >([]);

  const handleAddDiscountGroupInput = () => {
    dispatch(addDiscountGroup());
  };

  const findEmptyTargetGroups = useCallback(() => {
    const usedTargetGroups = [] as string[];
    discounts.forEach((group) => {
      usedTargetGroups.push(...group.groups);
    });

    const emptyTargetGroups = allParticipaytionTypes.filter(
      (x) => !usedTargetGroups?.includes(x.id)
    );

    setTargetGroupsWithoutDiscount(emptyTargetGroups);
  }, [discounts, allParticipaytionTypes]);

  useEffect(() => {
    findEmptyTargetGroups();
    setIsDirty(true);

    if (isNewEventMode) prepareToSave();
  }, [isDiscountsActivated]);

  useEffect(() => {
    findEmptyTargetGroups();
  }, [findEmptyTargetGroups]);

  useEffect(() => {
    dispatch(setDiscountToList(values.discounts));
    setIsDiscountsActivated(values.discounts.length > 0);
  }, []);

  const prepareToSave = () => {
    const changedDiscounts = [] as DiscountItem[];
    const eventId = id;
    if (isDiscountsActivated) {
      discounts.forEach((x) => {
        if (x.fixedAmount > 0 || x.percent > 0)
          x.groups.forEach((pid) => {
            changedDiscounts.push({
              event: eventId,
              percent: Number(x.percent),
              fixed_amount: Number(x.fixedAmount),
              participation_type: pid,
            } as DiscountItem);
          });
      });
    }
    setFieldValue("discounts", changedDiscounts);
    return changedDiscounts;
  };

  const handleSaveDiscounts = () => {
    const preparedDiscounts = prepareToSave();
    dispatch(saveDiscounts(id, preparedDiscounts));
    setIsDirty(false);
  };

  const AddDiscountButton = (
    <Grid container>
      <CustomButton
        title="Rabatt hinzufügen"
        disabled={isDiscountsActivated === false}
        onClick={handleAddDiscountGroupInput}
        customColor={styles["primary"]}
        hoverColor={styles["primary-accent-1"]}
        textColor={"#fff"}
      >
        <AddIcon />
        <Box>Rabatt hinzufügen</Box>
      </CustomButton>
      <Grid item xs={1}></Grid>
      {isNewEventMode || (
        <CustomButton
          title="Speichern"
          disabled={isDirty === false}
          onClick={handleSaveDiscounts}
          customColor={styles["primary"]}
          hoverColor={styles["primary-accent-1"]}
          textColor={"#fff"}
        >
          <Box>Speichern</Box>
        </CustomButton>
      )}
    </Grid>
  );

  const toDiscountWrapper = (
    item: DiscountGroupWrapperMeta,
    allParticipaytionTypes: ParticipationType[]
  ): DiscountGroupWrapper => {
    return {
      groupIndex: item.groupIndex,
      percent: item.percent,
      fixedAmount: item.fixedAmount,
      type: item.type,
      groups: allParticipaytionTypes?.filter((x) => item.groups.includes(x.id)),
    };
  };

  function onChangeGroup(discount: DiscountGroupWrapper) {
    const g = { ...discount, groups: discount.groups.map((x) => x.id) };
    dispatch(updateDiscountGroup(g));
    setIsDirty(true);
  }

  function onDeleteGroup(discount: DiscountGroupWrapper) {
    const g = { ...discount, groups: discount.groups.map((x) => x.id) };
    dispatch(deleteDiscountGroup(g));
  }

  return (
    <div style={{ padding: ".625rem 0 .625rem 0" }}>
      <Grid item lg={12} xs={6} sm={3} style={{ padding: ".625rem 0 .625rem 0" }}>
        <CustomCheckbox
          name="groupDiscountsActivated"
          label={<>Zielgruppenrabatt</>}
          checked={isDiscountsActivated}
          onChange={() => {
            setIsDirty(true);
            setIsDiscountsActivated(!isDiscountsActivated);
          }}
        />
      </Grid>

      {isDiscountsActivated &&
        discounts.map((discountItem, index: number) => (
          <DiscountItemForm
            key={discountItem.groupIndex}
            index={index}
            discount={toDiscountWrapper(discountItem, allParticipaytionTypes)}
            allParticipaytionTypes={allParticipaytionTypes}
            targetGroupsWithoutDiscount={targetGroupsWithoutDiscount}
            changeGroup={onChangeGroup}
            deleteGroup={onDeleteGroup}
          />
        ))}

      {AddDiscountButton}
      <Grid item></Grid>
    </div>
  );
};

// TODO no translations yet
