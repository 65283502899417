import { useTranslation } from "react-i18next";
import { Grid, Box } from "@material-ui/core";
import CustomTextField from "../../../../forms/CustomTextField";

const FutherInformationSection: React.FC = () => {
  const { t } = useTranslation(["organization"]);

  return (
    <>
      <Box style={{ paddingTop: "1rem" }}>
        <h3>{t("additionalInformation", { ns: "organization" })}</h3>
      </Box>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <CustomTextField
            required
            fullWidth
            id="tax-number"
            name="tax_number"
            label={t("forms.taxNumber", { ns: "organization" })}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomTextField
            required
            fullWidth
            id="commercial-register-number"
            name="commercial_register_number"
            label={t("forms.commercialRegisterNumber", { ns: "organization" })}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default FutherInformationSection;
