import { SelectProps } from "@material-ui/core";
import { ParsableDate } from "@material-ui/pickers/constants/prop-types";
import { FieldAttributes } from "formik";
import {
  SalutationType,
  mapSalutationTypeAsLabel,
} from "../../../models/enums/salutationType.enum";
import CustomSelect from "./CustomSelect";
import { useTranslation } from "react-i18next";

interface SalutationSelectProps {
  value?: ParsableDate;
}

const SalutationSelect: React.FC<
  SalutationSelectProps & FieldAttributes<{}> & SelectProps
> = ({ ...props }) => {
  const { t } = useTranslation(["common", "snackbars"]);
  return (
    <CustomSelect
      {...props}
      autoComplete="salutation"
      options={[
        {
          key: "herr",
          value: SalutationType.Male,
          label: mapSalutationTypeAsLabel(SalutationType.Male),
        },
        {
          key: "frau",
          value: SalutationType.Female,
          label: mapSalutationTypeAsLabel(SalutationType.Female),
        },
        {
          key: "none",
          value: SalutationType.Neutral,
          label: mapSalutationTypeAsLabel(SalutationType.Neutral),
        },
      ]}
      id={"anrede"}
      labelId={"anrede"}
      label={t("profile.overviewProfile.salutation.title", { ns: "common" })}
    />
  );
};

export default SalutationSelect;
