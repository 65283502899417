import { AxiosError } from "axios";
import { Dispatch } from "redux";
import { enqueueSnackbar } from "notistack";
import { maintenanceService } from "../../api/maintenanceService";
import { getDynamicTranslation } from "../../components/core/localization/getStringTranslation";
import {
  MaintenanceActionTypes,
  FetchBannerRequestAction,
  FetchBannerSuccessAction,
  FetchBannerFailureAction,
  FETCH_BANNER_REQUEST,
  FETCH_BANNER_SUCCESS,
  FETCH_BANNER_FAILURE,
} from "./types";
import { MaintenanceBannerData } from "../../models/maintenanceData";

/**
 * @returns {FetchBannerRequestAction}
 */
export function fetchBannerRequest(): FetchBannerRequestAction {
  return {
    type: FETCH_BANNER_REQUEST,
  };
}

/**
 * @param {MaintenanceBannerData[]} payload - The maintenance banner data
 * @returns {FetchBannerSuccessAction}
 */
export function fetchBannerSuccess(
  payload: MaintenanceBannerData[]
): FetchBannerSuccessAction {
  return {
    type: FETCH_BANNER_SUCCESS,
    payload,
  };
}

/**
 * @param {string} payload - The error message
 * @returns {FetchBannerFailureAction}
 */
export function fetchBannerFailure(error: string): FetchBannerFailureAction {
  return {
    type: FETCH_BANNER_FAILURE,
    error,
  };
}

/**
 * Fetches the maintenance banner data
 * @returns {Promise<void>}
 */
export function fetchMaintenanceBanner() {
  return async (dispatch: Dispatch<MaintenanceActionTypes>) => {
    dispatch(fetchBannerRequest());

    try {
      const response = await maintenanceService.getMaintenanceBanner();
      dispatch(fetchBannerSuccess(response));
    } catch (error) {
      const axiosError = error as AxiosError;
      const errorMessage =
        axiosError.response?.data?.message ||
        getDynamicTranslation("maintenance.fetchError", "errorPages");
      dispatch(fetchBannerFailure(errorMessage));
      enqueueSnackbar(errorMessage);
    }
  };
}
