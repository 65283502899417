import apiRoutes from "./apiRoutes";
import { BaseCrudService } from "./baseCrudService";
import { ContactFormData } from "../models/contactFormData";

export class ContactService extends BaseCrudService {
  async sendMessage(message: ContactFormData) {
    const response = await this.create(apiRoutes.contact, message);
    return response;
  }
}
