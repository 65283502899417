import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import { Grid, Button, Box, Typography } from "@material-ui/core";
import { DropzoneAreaBase, FileObject } from "material-ui-dropzone";
import { Alert } from "@material-ui/lab";
import { CURRENT_API_VERSION } from "../../../../../api/apiConfig";
import HeadingLumos from "../../../../theming/HeadingLumos";
import useCsvInviteValidation from "../../../../hooks/useCsvInviteValidation";
import BulkMembershipOverviewList from "./BulkMembershipOverviewList";
import apiRoutes from "../../../../../api/apiRoutes";
interface CsvBulkUploadProps {
  organizationId: string;
}

/**
 * Component for bulk uploading membership invites via CSV file.
 */
const CsvBulkUpload: React.FC<CsvBulkUploadProps> = (props) => {
  const { t } = useTranslation(["common", "organization"]);
  const [csvFiles, setCsvFiles] = useState<FileObject[]>([]);
  const downloadUrl = `${CURRENT_API_VERSION}${apiRoutes.download_template}`;

  const { validateCsv, validInvitations, invalidInvitations, setValidInvitations } =
    useCsvInviteValidation();

  useEffect(() => {
    // Clear uploaded CSV files if there are invalid invitations
    if (invalidInvitations?.length > 0) {
      setCsvFiles([]);
    }
  }, [invalidInvitations]);

  /**
   * Handler function for adding CSV files.
   * @param {FileObject[]} addedCsv - The CSV files to be added.
   */
  const handleAddCSV = async (addedCsv: FileObject[]) => {
    addedCsv.forEach((file) => {
      setCsvFiles((prev) => [...prev, file]);
    });
  };

  /**
   * Handler function for validating the uploaded CSV files.
   */
  const handleValidateCSV = () => {
    setValidInvitations([]);
    validateCsv(csvFiles, props?.organizationId);
  };

  return (
    <>
      <HeadingLumos>{t("membership.addMembers", { ns: "organization" })}</HeadingLumos>
      {invalidInvitations?.length > 0 && (
        <Alert severity="error" style={{ marginTop: "2rem", marginBottom: "2rem" }}>
          <Typography>
            <b>{t("csv.csvInvalidFormat", { ns: "organization" })}</b>
          </Typography>
          <Typography>
            {t("csv.csvInvalidFormatText", { ns: "organization" })}
          </Typography>
          <br />
          {invalidInvitations.map((invitation, index) => (
            <Typography key={index}>{invitation}</Typography>
          ))}
        </Alert>
      )}

      <Box style={{ marginTop: "2rem", marginBottom: "2rem" }}>
        {validInvitations?.length > 0 && invalidInvitations?.length === 0 && (
          <BulkMembershipOverviewList
            invitations={validInvitations}
            setValidInvitations={setValidInvitations}
          />
        )}
      </Box>

      <DropzoneAreaBase
        filesLimit={1}
        showAlerts={false}
        fileObjects={csvFiles}
        onAdd={handleAddCSV}
        onDelete={(deletedFile) =>
          setCsvFiles((prev) => prev.filter((f) => f !== deletedFile))
        }
        acceptedFiles={[".csv"]}
        maxFileSize={2 * 1024 * 1024}
        dropzoneText={t("csv.uploadCsvNote", { ns: "organization" })}
        onDropRejected={() => {
          enqueueSnackbar(t("csvUploadFail", { ns: "snackbars" }), {
            variant: "error",
          });
        }}
      />
      <Box style={{ paddingTop: ".5rem", paddingBottom: "1rem" }}>
        <Typography variant="caption">
          {t("csv.onlyOneFileAtaTime", { ns: "organization" })} <br />
          {t("csv.uploadFormatText", { ns: "organization" })} <br />
          {t("csv.uploadFormatExample", { ns: "organization" })} <br />
          {t("csv.donwloadUploadHint", { ns: "organization" })}
        </Typography>
      </Box>

      <Box style={{ marginTop: 20 }}>
        <Grid
          container
          spacing={2}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Grid item xs={12} sm={6} md={4}>
            <Button
              id="add-organization"
              type="submit"
              size="large"
              variant="contained"
              color="primary"
              fullWidth
              disabled={csvFiles.length === 0}
              onClick={handleValidateCSV}
            >
              {t("csv.uploadCsvButton", { ns: "organization" })}
            </Button>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <a href={downloadUrl}>
              <Button
                id="add-organization"
                type="submit"
                size="large"
                variant="outlined"
                color="primary"
                fullWidth
              >
                {t("csv.downloadCsvTemplate", { ns: "organization" })}
              </Button>
            </a>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default CsvBulkUpload;
