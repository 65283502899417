import { Box, Typography } from "@material-ui/core";
import * as React from "react";
import { useTranslation } from "react-i18next";

interface NoEventsFoundProps {
  itemsNotFound?: string;
  customText?: string;
}

const NoEventsFound: React.FC<NoEventsFoundProps> = (props) => {
  const { t } = useTranslation(["events", "snackbars"]);
  return (
    <Box key="no-events-found" my={10}>
      <Typography variant="body1" align="center">
        {props.customText ? (
          props.customText
        ) : (
          <>
            {t("eventFilter.noResult", {
              events: props.itemsNotFound ? props.itemsNotFound : t("eventType.events"), // TODO in some languages to be lowercased
            })}
            {props.itemsNotFound ? "" : t("eventFilter.noResultOptional")}.
          </>
        )}
      </Typography>
    </Box>
  );
};

export default NoEventsFound;
