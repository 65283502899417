import { UserData } from "../../models/userData";

export interface UserState {
  isLoading: boolean;
  currentUser?: UserData;
}

export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_CURRENT_USER = "GET_CURRENT_USER";
export const GET_USER_FAILURE = "GET_USER_FAILURE";

export interface GetUserRequestAction {
  type: typeof GET_USER_REQUEST;
}

export interface GetUserFailureAction {
  type: typeof GET_USER_FAILURE;
}

export interface GetCurrentUserAction {
  type: typeof GET_CURRENT_USER;
  user: UserData;
}

export type UserActionTypes =
  | GetUserRequestAction
  | GetUserFailureAction
  | GetCurrentUserAction;
