import { AxiosError } from "axios";
import { enqueueSnackbar } from "notistack";
import { Dispatch } from "redux";
import { discountService } from "../../api/index";
import { DiscountGroupWrapperMeta, DiscountItem } from "../../models/discountData";
import {
  ADD_DISCOUNT_GROUP,
  AddDiscountGroupAction,
  DELETE_DISCOUNT,
  DISCOUNT_FAILURE,
  DISCOUNT_REQUEST,
  DiscountActionTypes,
  DiscountFailureAction,
  DiscountRequestAction,
  SET_DISCOUNT_LIST,
  SetDiscountListAction,
  UPDATE_DISCOUNT,
} from "./types";

export function getDiscounts(eventId: string) {
  return (dispatch: Dispatch<DiscountActionTypes>) => {
    dispatch(discountsRequest());

    discountService
      .getDiscount(eventId)
      .then((response) => {
        dispatch(setDiscountToList(response));
      })
      .catch((err: AxiosError) => {
        dispatch(discountFailure(err));
        enqueueSnackbar("Die Rabatte konnten nicht geladen werden!", {
          variant: "error",
        });
      });
  };
}

export function saveDiscounts(eventId: string, discounts: DiscountItem[]) {
  return (dispatch: Dispatch<DiscountActionTypes>) => {
    dispatch(discountsRequest());

    discountService
      .saveDiscount(eventId, discounts)
      .then((response) => {
        enqueueSnackbar("Die Rabatte wurden gespeichert!", {
          variant: "success",
        });
      })
      .catch((err: AxiosError) => {
        dispatch(discountFailure(err));
        enqueueSnackbar("Die Rabatte konnten nicht gespeichert werden!", {
          variant: "error",
        });
      });
  };
}

export function updateDiscountGroup(group: DiscountGroupWrapperMeta) {
  return {
    type: UPDATE_DISCOUNT,
    group: group,
  };
}

export function deleteDiscountGroup(group: DiscountGroupWrapperMeta) {
  return {
    type: DELETE_DISCOUNT,
    group: group,
  };
}

export function discountsRequest(): DiscountRequestAction {
  return {
    type: DISCOUNT_REQUEST,
  };
}
export function addDiscountGroup(): AddDiscountGroupAction {
  return {
    type: ADD_DISCOUNT_GROUP,
  };
}

export function discountFailure(error: any): DiscountFailureAction {
  return {
    type: DISCOUNT_FAILURE,
    error,
  };
}

export function setDiscountToList(data: DiscountItem[]): SetDiscountListAction {
  return {
    type: SET_DISCOUNT_LIST,
    data,
  };
}
