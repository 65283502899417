import { BaseCrudService } from "./baseCrudService";
import apiRoutes from "./apiRoutes";
import { GroupMembershipDocumentTypeData } from "../models/groupMembershipDocumentTypeData";

export class GroupMembershipDocumentTypeService extends BaseCrudService {
  async getGroupMembershipDocumentTypes() {
    const response = await this.getAll<GroupMembershipDocumentTypeData[]>(
      apiRoutes.group_membership_document_types
    );
    return response.data;
  }
}
