import React, { useState } from "react";
import { Link } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { getEditRouteBasedOnTrainingType } from "../../../routing/routes";
import { useSelector } from "react-redux";
import { AppState } from "../../../redux";
import {
  ActivityHistoryEventData,
  ActivityHistoryMinimalEventData,
} from "../../../models/eventData";
import styles from "../../../styles/custom-styles.module.scss";

interface HistoryEventLinkProps {
  event: ActivityHistoryEventData | ActivityHistoryMinimalEventData;
}

const HistoryEventLink: React.FunctionComponent<HistoryEventLinkProps> = (props) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const accessibility = useSelector((state: AppState) => state.accessibility);

  return (
    <Link
      target="_blank"
      rel="noreferrer"
      component={RouterLink}
      to={`${getEditRouteBasedOnTrainingType(props.event.training_type)}/${
        props.event.id
      }`}
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
      style={{
        fontWeight: "bold",
        cursor: "pointer",
        color: accessibility.monoMode
          ? "#000"
          : isHovered
            ? styles.primary
            : styles.berry,
      }}
    >
      {props.event.title}
    </Link>
  );
};

export default HistoryEventLink;
