import { Button, FormControl, Grid, Hidden, Typography } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { validate as isValidUUID } from "uuid";
import * as yup from "yup";
import { usersService } from "../../../api";
import { useDynamicYupValidations } from "../../../libs/yup-validations";
import { ResetPasswordRequestData } from "../../../models/userData";
import CustomPasswordField from "../../../components/forms/CustomPasswordField";
import routes from "../../../routing/routes";
import "./ResetPasswordPage.scss";
import { useTranslation } from "react-i18next";

const initialData = {
  password: "",
  confirmPassword: "",
};

export const ResetPasswordPage: React.FC = () => {
  const { t } = useTranslation(["authentication", "snackbars"]);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const token = useTokenFromUrl();

  const { YupValidationConfirmPassword } = useDynamicYupValidations();

  const validationSchema = yup.object({
    password: YupValidationConfirmPassword("confirmPassword"),
    confirmPassword: YupValidationConfirmPassword("password"),
  });

  return (
    <>
      <div className="BaseForm">
        <h4 className="Heading">
          {t("password.forgotten.passwordNewHeader", {
            ns: "authentication",
          }).toUpperCase()}
        </h4>
        <Typography className="Text">
          {t("password.forgotten.noteResetRequest", {
            ns: "authentication",
          })}
        </Typography>
        <Formik
          initialValues={initialData}
          onSubmit={async (values) => {
            const data: ResetPasswordRequestData = {
              reset_password_token: token,
              password: values.password,
              confirm_password: values.confirmPassword,
            };
            usersService.resetPassword(data).then((success) => {
              if (success) {
                enqueueSnackbar(
                  t("profile.passwordChanged", {
                    ns: "snackbars",
                  }),
                  {
                    variant: "success",
                  }
                );
                history.push(routes.login);
              } else {
                enqueueSnackbar(
                  t("profile.passwordLinkInvalid", {
                    ns: "snackbars",
                  }),
                  { variant: "error" }
                );
              }
            });
          }}
          validationSchema={validationSchema}
        >
          <Form className="Form">
            <Grid container spacing={4}>
              <Grid item lg={6} xs={12}>
                <Field
                  as={CustomPasswordField}
                  fullWidth
                  id="password"
                  label={t("password.change.passwordNew", {
                    ns: "authentication",
                  })}
                  name="password"
                />
              </Grid>
              <Grid item lg={6} xs={12}>
                <Field
                  as={CustomPasswordField}
                  fullWidth
                  id="confirmPassword"
                  label={t("password.change.passwordNewConfirm", {
                    ns: "authentication",
                  })}
                  name="confirmPassword"
                />
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Hidden mdDown>
                <Grid item lg={2} md={2} xl={3}></Grid>
              </Hidden>
              <Grid item xs={12} lg={8} xl={6}>
                <FormControl className="Button" fullWidth>
                  <Button
                    type="submit"
                    size="large"
                    variant="contained"
                    color="primary"
                    disableElevation
                    disableRipple
                  >
                    {t("password.forgotten.passwordSave", {
                      ns: "authentication",
                    })}
                  </Button>
                </FormControl>
              </Grid>
            </Grid>
          </Form>
        </Formik>
      </div>
    </>
  );
};

const useTokenFromUrl = () => {
  const token = useLocation().pathname.split("/")[2];
  if (isValidUUID(token)) {
    return token;
  }
  return "";
};
