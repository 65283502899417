/* eslint-disable react/jsx-max-depth */
import { Box, Button, Container, Grid, Hidden } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import { AppState } from "../../../redux";
import routes from "../../../routing/routes";
import "./Header.scss";
import { Navigation } from "./navigation/Navigation";
import { Profile } from "./profile/Profile";
import TopNavAndAccessibility from "./TopNavAndAccessibility";
import React, { useEffect, useState } from "react";
import LeavePageDialog from "../dialog/LeavePageDialog";
import { useTranslation } from "react-i18next";
import { metaDataService } from "../../../api";
import Loader from "../../theming/loader/Loader";

const logoLinkHref = process.env.REACT_APP_LOGO_LINK_HREF ?? "/";

const extensions = ["png", "svg"];
let logo: any;

for (const ext of extensions) {
  try {
    logo = require(`../../../assets/general/customer_header.${ext}`);
    break;
  } catch (e) {
    // eslint-disable no-empty
  }
}

if (!logo) {
  throw new Error("Logo File not found");
}

export const Header: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const user = useSelector((state: AppState) => state.user);
  const auth = useSelector((state: AppState) => state.auth);
  const accessibility = useSelector((state: AppState) => state.accessibility);
  const { t } = useTranslation(["layout", "snackbars"]);

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const handleConfirm = () => {
    setDialogOpen(false);
    if (logoLinkHref) {
      window.open(logoLinkHref, "_blank", "noopener,noreferrer");
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const [customer, setCustomer] = useState("");
  const [metaDataIsLoading, setMetaDataIsLoading] = useState(true);

  useEffect(() => {
    if (customer.length === 0) {
      metaDataService
        .getMetaData()
        .then((metaData) => {
          setCustomer(metaData.data.customer);
          setMetaDataIsLoading(false);
        })
        .catch(() => {
          setMetaDataIsLoading(false);
        });
    }
  }, [customer, metaDataIsLoading]);

  const contentText = t("dialogues.leavePage", {
    linkHref: logoLinkHref,
    customer: customer,
  });

  if (customer.length === 0 && metaDataIsLoading) {
    return <Loader />;
  }

  const hideBurgerMenu = !user?.currentUser?.terms_agreed_at;

  return (
    <header className="box-shadow">
      <Container>
        <Grid container>
          <Hidden smUp>
            <Grid item xs={12}>
              <TopNavAndAccessibility />
            </Grid>
          </Hidden>

          <Grid item xs={12}>
            <Box display="flex">
              <Box className="navigation-logo">
                {logo ? (
                  <Button
                    style={{ backgroundColor: "transparent" }}
                    onClick={handleOpenDialog}
                  >
                    <img
                      src={logo}
                      className={
                        accessibility.monoMode
                          ? "navbar-header-logo-mono"
                          : "navbar-header-logo"
                      }
                      alt="logo"
                    />
                  </Button>
                ) : null}
              </Box>
              <LeavePageDialog
                content={contentText}
                open={dialogOpen}
                onClose={handleCloseDialog}
                onConfirm={handleConfirm}
              />
              <Box flexGrow={1}>
                <Hidden xsDown>
                  <Grid item xs={12}>
                    <TopNavAndAccessibility />
                  </Grid>
                </Hidden>
                <Box display="flex" justifyContent="flex-end">
                  {auth.loggedIn ? (
                    user.isLoading ? (
                      <Box display="flex" alignItems="center">
                        <Box display="flex" flexDirection="column" mr="1rem">
                          <Skeleton width={100} height={18} />
                          <Skeleton width={100} height={24} />
                        </Box>
                        <Skeleton variant="circle" width={50} height={50} />
                      </Box>
                    ) : (
                      <>
                        {!hideBurgerMenu && <Profile />}
                        {!hideBurgerMenu && <Navigation />}
                      </>
                    )
                  ) : (
                    location.pathname !== routes.login && (
                      <Button
                        id="submit-login-profile"
                        style={{
                          height: "3.875rem",
                          padding: "0 .625rem",
                          fontSize: "1rem",
                          position: "relative",
                          top: ".25rem",
                        }}
                        size="large"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          history.push(routes.login);
                        }}
                      >
                        {t("header.login")}
                      </Button>
                    )
                  )}
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </header>
  );
};
