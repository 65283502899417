import { faReply } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button } from "@material-ui/core";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  mapToPopupTitle,
  PinboardPopupType,
} from "../../../../../../models/enums/pinboardType.enum";
import { PinboardListData } from "../../../../../../models/pinboardData";
import { AppState } from "../../../../../../redux";
import "../../../../../../pages/core/pinboard/Pinboard.scss";
import PinboardPopup from "./PinboardPopup";

interface IPinboardAnswerButtonProps {
  question: PinboardListData;
  close: () => void;
}

const PinboardAnswerButton: React.FunctionComponent<IPinboardAnswerButtonProps> = (
  props
) => {
  const accessibility = useSelector((state: AppState) => state.accessibility);
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    props.close();
  };

  return (
    <Box className={accessibility.monoMode ? "pinboard-button-mono" : "pinboard-button"}>
      <Button onClick={handleClickOpen}>
        <FontAwesomeIcon icon={faReply} style={{ marginRight: "0.43rem" }} />{" "}
        {mapToPopupTitle(PinboardPopupType.PostAnswer)}
      </Button>
      <PinboardPopup
        question={props.question}
        open={open}
        close={handleClose}
        popupType={PinboardPopupType.PostAnswer}
      />
    </Box>
  );
};

export default PinboardAnswerButton;
