import * as React from "react";
import {
  Box,
  createStyles,
  makeStyles,
  Popover,
  Theme,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

interface TargetGroupsDontMatchPopoverProps {
  openWrongTargetGroup: boolean;
  anchorElWrongTargetGroup: HTMLElement | null;
  handlePopoverCloseWrongTargetGroup: () => void;
  book_for_me?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      pointerEvents: "none",
    },
    paper: {
      padding: theme.spacing(1),
    },
  })
);

const TargetGroupsDontMatchPopover: React.FC<TargetGroupsDontMatchPopoverProps> = (
  props
) => {
  const { t } = useTranslation(["events"]);
  const classes = useStyles();

  return (
    <Popover
      id="wrong-targetgroup-popover"
      className={classes.popover}
      classes={{
        paper: classes.paper,
      }}
      open={props.openWrongTargetGroup}
      anchorEl={props.anchorElWrongTargetGroup}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      onClose={props.handlePopoverCloseWrongTargetGroup}
      disableRestoreFocus
    >
      <Box component={"div"} style={{ textAlign: "center" }}>
        <Typography>
          <b>{t("targetGroup.alert_1", { ns: "events" })}</b>
          <br />
          <br />
          {t("targetGroup.alert_2", { ns: "events" })}
          <br />
          {t("targetGroup.alert_3", { ns: "events" })}
          <br />
        </Typography>
      </Box>
    </Popover>
  );
};

export default TargetGroupsDontMatchPopover;

// TODO Popover size fixed, line breaks dynamically
