import { AxiosError } from "axios";
import { Dispatch } from "redux";
import { speakerService } from "../../api/index";
import {
  SPEAKERS_FAILURE,
  SPEAKERS_REQUEST,
  SpeakersFailureAction,
  SpeakersRequestAction,
  SpeakersActionTypes,
  SetSpeakersListAction,
  SET_SPEAKERS_LIST,
  AddSpeakerToEventListAction,
  ADD_SPEAKERS_TO_EVENT_LIST,
  REMOVE_SPEAKERS_FROM_EVENT_LIST,
  RemoveSpeakerFromEventListAction,
  ClearSpeakersAction,
  CLEAR_SPEAKERS,
  EditSpeakersListLoadedAction,
  EDIT_SPEAKERS_LOADED,
  SetSpeakersSearchListAction,
  SET_SPEAKERS_SEARCH_LIST,
  SyncSpeakersSearchListAction,
  SYNC_SPEAKERS_SEARCH_LIST,
  EditEventSpeakerListAction,
  EDIT_EVENT_SPEAKER_LIST,
} from "./types";
import { SpeakerData } from "../../models/speakerData";
import { enqueueSnackbar } from "notistack";

export function getSpeakers() {
  return (dispatch: Dispatch<SpeakersActionTypes>) => {
    dispatch(speakersRequest());

    speakerService
      .getSpeakers()
      .then((response) => {
        dispatch(SetSpeakersToList(response));
      })
      .catch((err: AxiosError) => {
        dispatch(speakersFailure(err));
        enqueueSnackbar(
          "Die Referenten konnten nicht abgerufen werden. Laden Sie die Seite neu und wiederholen den Vorgang!",
          { variant: "error" }
        );
      });
  };
}

export function searchSpeakers(searchTerm: string, speakerList: SpeakerData[]) {
  return (dispatch: Dispatch<SpeakersActionTypes>) => {
    const results = speakerList.filter(
      (speakers) =>
        speakers.first_name
          .toLocaleLowerCase()
          .includes(searchTerm.toLocaleLowerCase()) ||
        speakers.last_name
          .toLocaleLowerCase()
          .includes(searchTerm.toLocaleLowerCase()) ||
        speakers.email.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
    );
    dispatch(SetSpeakersSearchList(results, searchTerm));
  };
}

export function speakersRequest(): SpeakersRequestAction {
  return {
    type: SPEAKERS_REQUEST,
  };
}

export function speakersFailure(error: any): SpeakersFailureAction {
  return {
    type: SPEAKERS_FAILURE,
    error,
  };
}

export function SetSpeakersToList(data: SpeakerData[]): SetSpeakersListAction {
  return {
    type: SET_SPEAKERS_LIST,
    data,
  };
}

export function SetSpeakersSearchList(
  data: SpeakerData[],
  searchTerm: string
): SetSpeakersSearchListAction {
  return {
    type: SET_SPEAKERS_SEARCH_LIST,
    data,
    searchTerm,
  };
}

export function SyncSpeakersSearchList(): SyncSpeakersSearchListAction {
  return {
    type: SYNC_SPEAKERS_SEARCH_LIST,
  };
}

export function AddSpeakerToEventList(data: SpeakerData): AddSpeakerToEventListAction {
  return {
    type: ADD_SPEAKERS_TO_EVENT_LIST,
    data,
  };
}

export function RemoveSpeakerFromEventList(
  data: SpeakerData
): RemoveSpeakerFromEventListAction {
  return {
    type: REMOVE_SPEAKERS_FROM_EVENT_LIST,
    data,
  };
}

export function EditEventSpeakerList(data: SpeakerData[]): EditEventSpeakerListAction {
  return {
    type: EDIT_EVENT_SPEAKER_LIST,
    data,
  };
}

export function editSpeakersLoaded(): EditSpeakersListLoadedAction {
  return {
    type: EDIT_SPEAKERS_LOADED,
  };
}

export function clearSpeakers(): ClearSpeakersAction {
  return {
    type: CLEAR_SPEAKERS,
  };
}
