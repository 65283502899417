/**
 * Formats a given date string in the format dd.mm.yyyy.
 * @param {string} inputDate - The input date string to be formatted.
 * @returns {string} - The formatted date string.
 */
export function formatDate(inputDate: string): string {
  const date = new Date(inputDate);

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear();

  return `${day}.${month}.${year}`;
}
