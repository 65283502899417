import { Box, Divider, Grid } from "@material-ui/core";
import { Pagination as PaginationUI } from "@material-ui/lab";
import { Form, Formik } from "formik";
import * as React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { mapStatusIdToStatusString } from "../../../models/enums/eventStatus.enum";
import { BookingsSortBy } from "../../../models/enums/sortBy.enum";
import { LectureFilterData } from "../../../models/lecturesData";
import { PAGE_SIZE } from "../../../models/pagination";
import { AppState } from "../../../redux";
import { getAllLectures, setPageCount } from "../../../redux/lectures/actions";
import { LectureFilterService } from "../../../services/filters/lectureFilterService";
import HeadingLumos from "../../../components/theming/HeadingLumos";
import Loader from "../../../components/theming/loader/Loader";
import NoEventsFound from "../../../components/core/events/shared/NoEventsFound";
import LectureFilterForm from "../../../components/core/events/my-lectures/LectureFilterForm";
import MyEventTitleContent, {
  SUB_EVENTS,
} from "../../../components/core/events/my-events/MyEventTitleContent";
import { TrainingType } from "../../../models/enums/trainingType.enum";
import { v4 as uuidv4 } from "uuid";

interface IMyLecturesProps {}

export const UserLectureListPage: React.FunctionComponent<IMyLecturesProps> = (
  props
) => {
  const dispatch = useDispatch();

  const page = useSelector((state: AppState) => state.lectures.currentPage);

  const lecturesList = useSelector((state: AppState) => state.lectures.lecturesList);
  const lectures = useSelector((state: AppState) => state.lectures);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    dispatch(setPageCount(value));
  };

  const getInitialFilterData = React.useCallback(() => {
    // ! persist the filter state after page change and set as initial filter values
    let initialFilterData: LectureFilterData = {
      status: [],
      sorting: BookingsSortBy.BeginAscending,
    };
    const filterService = new LectureFilterService(page, initialFilterData);
    initialFilterData = filterService.parseFiltersFromUrl();

    return initialFilterData;
  }, [page]);

  useEffect(() => {
    dispatch(getAllLectures(page, getInitialFilterData()));
  }, [dispatch, getInitialFilterData, page]);

  return (
    <>
      <HeadingLumos>Meine Vorträge</HeadingLumos>

      <Formik
        onSubmit={(data: LectureFilterData) => {}}
        enableReinitialize
        initialValues={getInitialFilterData()}
      >
        {() => (
          <Form>
            <LectureFilterForm page={page} />
          </Form>
        )}
      </Formik>

      <Grid container spacing={0} className={"myevents-list-header"}>
        <Grid item xs={1}>
          Kurs-Nr.
        </Grid>
        <Grid item xs={6}>
          Titel
        </Grid>
        <Grid item xs={1}>
          Startdatum
        </Grid>
        <Grid item xs={2}>
          Ort
        </Grid>
        <Grid item xs={2}>
          Status
        </Grid>
      </Grid>
      {lectures.isLoading ? (
        <Loader />
      ) : lecturesList.count === 0 ? (
        <NoEventsFound />
      ) : (
        lecturesList.results.map((event) => {
          return (
            <React.Fragment key={`event-${event.id}`}>
              <Grid container spacing={0} className={"myevents-list-item"}>
                <Grid item xs={1}>
                  # {event.public_id}
                </Grid>
                <Grid item xs={6}>
                  <MyEventTitleContent text={event.title} event={event} />
                  {event.training_type === TrainingType.BlendedLearning && (
                    <Box component={"div"} style={{ display: "block" }}>
                      {SUB_EVENTS.map((subEvent) => (
                        <MyEventTitleContent
                          key={uuidv4()}
                          text={subEvent}
                          event={event}
                        />
                      ))}
                    </Box>
                  )}
                </Grid>
                <Grid item xs={1}>
                  {new Date(event.begin).toLocaleDateString()}
                </Grid>
                <Grid item xs={2}>
                  {event.location}
                </Grid>
                <Grid item xs={2}>
                  {mapStatusIdToStatusString(event.status)}
                </Grid>
              </Grid>
              <Divider />
            </React.Fragment>
          );
        })
      )}
      <Grid container style={{ marginTop: ".625rem" }}>
        <Box component={"div"} style={{ margin: "auto" }}>
          <PaginationUI
            count={Math.ceil(lecturesList.count / PAGE_SIZE)}
            page={page}
            onChange={handleChange}
          />
        </Box>
      </Grid>
    </>
  );
};

export default UserLectureListPage;
