import { Pagination } from "../../models/pagination";
import { TaskData, TaskUserData } from "../../models/taskData";

export interface TasksState {
  tasksPage: Pagination<TaskData>;
  currentPage: number;
  tasksPageLoaded: boolean;
  editingTask: TaskData;
  editingTaskLoaded: boolean;
  isLoading: boolean;
  filterUsers: TaskUserData[];
  filterUsersLoaded: boolean;
  error: boolean;
  notFoundError: boolean;
}

export const TASKS_REQUEST = "TASKS_REQUEST";
export const TASKS_FAILURE = "TASKS_FAILURE";
export const SET_EDITING_TASK = "SET_EDITING_TASK";

export const SET_TASKS_PAGE_COUNT = "SET_TASKS_PAGE_COUNT";
export const SET_FILTER_USERS = "SET_FILTER_USERS";

export const SET_TASKS_LIST = "SET_TASKS_LIST";
export const REMOVE_TASKS_LIST = "REMOVE_TASKS_LIST";
export const UPDATE_TASKS_IN_LIST = "UPDATE_TASKS_IN_LIST";

export const CLEAR_TASKS = "CLEAR_TASKS";

export interface TasksRequestAction {
  type: typeof TASKS_REQUEST;
}

export interface TasksFailureAction {
  type: typeof TASKS_FAILURE;
  notFoundError: boolean;
}

export interface SetEditingTaskAction {
  type: typeof SET_EDITING_TASK;
  data: TaskData;
}

export interface SetTasksListAction {
  type: typeof SET_TASKS_LIST;
  data: Pagination<TaskData>;
}

export interface RemoveTasksListAction {
  type: typeof REMOVE_TASKS_LIST;
  id: string;
}

export interface UpdateTasksInListAction {
  type: typeof UPDATE_TASKS_IN_LIST;
  data: TaskData[];
}

export interface SetFilterUsers {
  type: typeof SET_FILTER_USERS;
  data: TaskUserData[];
}

export interface ClearTasksAction {
  type: typeof CLEAR_TASKS;
}

export interface SetPageCountAction {
  type: typeof SET_TASKS_PAGE_COUNT;
  pageCount: number;
}

export type TasksActionTypes =
  | TasksRequestAction
  | TasksFailureAction
  | SetEditingTaskAction
  | SetTasksListAction
  | RemoveTasksListAction
  | ClearTasksAction
  | UpdateTasksInListAction
  | SetFilterUsers
  | SetPageCountAction;
