import { Divider, Grid, Typography } from "@material-ui/core";
import * as React from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../../../../redux";
import CustomCheckbox from "../../../../forms/CustomCheckbox";

interface IChecklistEmployeeProps {}

const ChecklistEmployee: React.FC<IChecklistEmployeeProps> = () => {
  const editEventState = useSelector((state: AppState) => state.event.editEvent);

  return (
    <>
      <Grid item xs={12}>
        <h3>Materialliste für den Referenten</h3>

        {editEventState.checklist.length === 0 ? (
          <Typography>
            Es befinden sich bisher keine Elemente auf der Checkliste.
          </Typography>
        ) : (
          <>
            {editEventState.checklist.map((checklistItem, index) => {
              return (
                <Grid item xs={12} sm={6} key={`checklist-item-${index}`}>
                  <CustomCheckbox
                    name={"checklistId-" + checklistItem.id}
                    label={checklistItem.name}
                    value={checklistItem.id}
                    checked
                  />
                </Grid>
              );
            })}
          </>
        )}
      </Grid>

      {editEventState.checklist_additional_info !== "" &&
        editEventState.checklist_additional_info && (
          <Grid item xs={12}>
            <b>Zusätzliche Materialanforderungen</b> <br />
            <br />
            <span style={{ whiteSpace: "pre-wrap" }}>
              {editEventState.checklist_additional_info}
            </span>
          </Grid>
        )}

      <Grid item xs={12}>
        <Divider />
      </Grid>
    </>
  );
};

export default ChecklistEmployee;

// TODO no translations yet
