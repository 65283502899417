import { PaymentData } from "../../models/paymentData";

export interface PaymentMethodState {
  paymentMethods: PaymentData[];
  isLoading: boolean;
  paymentMethodsLoaded: boolean;
  isError: boolean;
}

export const PAYMENT_METHOD_REQUEST = "PAYMENT_METHOD_REQUEST";
export const PAYMENT_METHOD_FAILURE = "PAYMENT_METHOD_FAILURE";
export const LOAD_PAYMENT_METHOD_DATA_SUCCESS = "LOAD_PAYMENT_METHOD_DATA_SUCCESS";
export const SET_PAYMENT_METHODS_LOADED = "SET_PAYMENT_METHODS_LOADED";

export interface PaymentMethodRequestAction {
  type: typeof PAYMENT_METHOD_REQUEST;
}

export interface SetPaymentMethodsLoadedAction {
  type: typeof SET_PAYMENT_METHODS_LOADED;
}

export interface PaymentMethodFailureAction {
  type: typeof PAYMENT_METHOD_FAILURE;
}

export interface LoadPaymentMethodAction {
  type: typeof LOAD_PAYMENT_METHOD_DATA_SUCCESS;
  data: PaymentData[];
}

export type PaymentMethodActionTypes =
  | PaymentMethodRequestAction
  | PaymentMethodFailureAction
  | SetPaymentMethodsLoadedAction
  | LoadPaymentMethodAction;
