import { TaskStatus } from "../../models/enums/taskStatus.enum";
import { TaskData } from "../../models/taskData";
import {
  CLEAR_TASKS,
  REMOVE_TASKS_LIST,
  SET_EDITING_TASK,
  SET_FILTER_USERS,
  SET_TASKS_LIST,
  SET_TASKS_PAGE_COUNT,
  TasksActionTypes,
  TasksState,
  TASKS_FAILURE,
  TASKS_REQUEST,
  UPDATE_TASKS_IN_LIST,
} from "./types";
import { NIL as NIL_UUID } from "uuid";

export const initialEditTaskData: TaskData = {
  id: NIL_UUID,
  description: "",
  status: TaskStatus.Open,
};

export const initialTasksState: TasksState = {
  tasksPage: {
    count: 0,
    results: [] as TaskData[],
    next: null,
    previous: null,
  },
  currentPage: 1,
  tasksPageLoaded: false,
  isLoading: false,
  editingTaskLoaded: false,
  editingTask: initialEditTaskData,
  filterUsers: [],
  filterUsersLoaded: false,
  error: false,
  notFoundError: false,
};

function removeTaskFromList(taskListForEvent: TaskData[], id: string) {
  // ! NOTE: always use copy and don't work on state directly to prevent nasty side effects
  const copiedTasksList = [...taskListForEvent];
  let index = copiedTasksList.findIndex((task) => task.id === id);
  copiedTasksList.splice(index, 1);
  return copiedTasksList;
}

export function tasksReducer(
  state = initialTasksState,
  action: TasksActionTypes
): TasksState {
  switch (action.type) {
    case TASKS_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: false,
        notFoundError: false,
      };
    case TASKS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
        notFoundError: action.notFoundError,
      };
    case SET_TASKS_LIST:
      return {
        ...state,
        tasksPage: action.data,
        isLoading: false,
        tasksPageLoaded: true,
        error: false,
        notFoundError: false,
      };
    case REMOVE_TASKS_LIST:
      return {
        ...state,
        tasksPage: {
          ...state.tasksPage,
          count: state.tasksPage.count - 1,
          results: removeTaskFromList(state.tasksPage.results, action.id),
        },
        /* for instant UI update list gets updated, but using tasksPageLoaded = true list will be updated in background */
        tasksPageLoaded: false,
        isLoading: false,
        error: false,
        notFoundError: false,
      };
    case SET_EDITING_TASK:
      return {
        ...state,
        editingTask: action.data,
        editingTaskLoaded: true,
        isLoading: false,
        error: false,
        notFoundError: false,
      };
    case UPDATE_TASKS_IN_LIST:
      return {
        ...state,
        tasksPage: {
          ...state.tasksPage,
          results: [
            ...state.tasksPage.results.map((task) => {
              var newTask = action.data.find((newTask) => task.id === newTask.id);
              if (!newTask) {
                newTask = { ...task };
              }
              return newTask;
            }),
          ],
        },
        isLoading: false,
        error: false,
        notFoundError: false,
      };
    case SET_TASKS_PAGE_COUNT:
      return {
        ...state,
        currentPage: action.pageCount,
        error: false,
        notFoundError: false,
      };
    case SET_FILTER_USERS:
      return {
        ...state,
        filterUsers: action.data,
        filterUsersLoaded: true,
        isLoading: false,
        error: false,
        notFoundError: false,
      };
    case CLEAR_TASKS:
      return initialTasksState;
    default:
      return state;
  }
}

export default tasksReducer;
