/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";
import { useSnackbar } from "notistack";
import HeadingLumos from "../../../components/theming/HeadingLumos";
import { ResendEmailLink } from "../../../components/core/registration/ResendEmailLink";
import { useTranslation } from "react-i18next";

const successText = "registration.success.text";

export const RegistrationSuccessPage: React.FC = () => {
  const { t } = useTranslation(["authentication", "common", "snackbars"]);
  const [text, setText] = useState<string>(successText);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  useEffect(() => {
    enqueueSnackbar(t("registration.success.text", { ns: "authentication" }), {
      variant: "success",
    });
  }, []);

  return (
    <Box className={classes.container}>
      <HeadingLumos>
        {t("registration.success.title", { ns: "authentication" })}
      </HeadingLumos>
      <Typography className={classes.mt}>{t(text, { ns: "authentication" })}</Typography>
      <ResendEmailLink setText={setText} />
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    paddingTop: "2%",
  },
  mt: {
    marginTop: 30,
  },
}));
