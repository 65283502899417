import { faComments } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Link } from "@material-ui/core";
import * as React from "react";
import { useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { MyELearningListData } from "../../../../../../models/elearning";
import { ParticipationStatus } from "../../../../../../models/enums/participationStatus.enum";
import { mapUserToPinboardRoute } from "../../../../../../models/enums/pinboardType.enum";
import { AppState } from "../../../../../../redux";
import styles from "../../../../../../styles/custom-styles.module.scss";
import routes from "../../../../../../routing/routes";
import "../../../../../../pages/core/event/UserEventsPage.scss";

interface PinboardLinkProps {
  eventId: string;
  booking?: MyELearningListData;
  anchorText?: string;
  className?: string;
  positioning?: "start" | "flex-end" | "center";
}

const PinboardLink: React.FC<PinboardLinkProps> = (props) => {
  const accessibility = useSelector((state: AppState) => state.accessibility);
  const currentUser = useSelector((state: AppState) => state.user.currentUser);
  const url = mapUserToPinboardRoute(
    currentUser,
    window.location.pathname.startsWith(routes.blended_learning_edit)
  ).replace(":id", props.eventId);

  const primaryColor = props.anchorText === "0" ? styles.secondary : styles.primary;

  return (
    <Box
      style={
        props.positioning === "start"
          ? undefined
          : {
              marginLeft: "auto",
              marginRight: "0.5rem",
              display: "flex",
              justifyContent: props.positioning || "center",
              alignItems: "center",
            }
      }
      className={props.className}
    >
      {(props.booking === undefined ||
        ![ParticipationStatus.Cancelled, ParticipationStatus.CancelledByStaff].includes(
          props.booking.status
        )) && (
        <Link component={RouterLink} to={url} target="_blank" rel="noreferrer">
          <FontAwesomeIcon
            size="2x"
            style={{ marginRight: "0.43rem" }}
            color={accessibility.monoMode ? "#000" : primaryColor}
            icon={faComments}
          />{" "}
          <span>{props.anchorText ? props.anchorText : "Pinnwand"}</span>
        </Link>
      )}
    </Box>
  );
};

export default PinboardLink;
