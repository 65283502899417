import {
  CLEAR_ORGANIZATION_TYPE,
  OrganizationTypeActionTypes,
  ORGANIZATION_TYPE_FAILURE,
  OrganizationTypeState,
  ORGANIZATION_TYPE_REQUEST,
  SET_ORGANIZATION_TYPE_DATA,
} from "./types";

/**
 * Initial state for the organization type reducer.
 */
export const initialOrganizationTypeState: OrganizationTypeState = {
  organizationTypeData: [],
  isLoading: false,
  organizationTypeDataLoaded: false,
};

/**
 * Reducer function for managing organization type related state.
 * @param state Current state of the organization type.
 * @param action Action dispatched to update organization type state.
 * @returns Updated state of the organization type.
 */
export function organizationTypeReducer(
  state = initialOrganizationTypeState,
  action: OrganizationTypeActionTypes
): OrganizationTypeState {
  switch (action.type) {
    case ORGANIZATION_TYPE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ORGANIZATION_TYPE_FAILURE:
      return {
        ...state,
        organizationTypeDataLoaded: true,
        isLoading: false,
      };
    case CLEAR_ORGANIZATION_TYPE:
      return initialOrganizationTypeState;
    case SET_ORGANIZATION_TYPE_DATA:
      return {
        ...state,
        organizationTypeData: action.data,
        organizationTypeDataLoaded: true,
        isLoading: false,
      };
    default:
      return state;
  }
}

export default organizationTypeReducer;
