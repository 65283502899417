import { LinkAccountData } from "../../models/linkAccountData";

export interface LinkAccountState {
  linkAccountList: LinkAccountData[];
  linkAccountListLoaded: boolean;
  isLoading: boolean;
  verifyLanr: boolean;
  verifiedLanr: boolean;
  linkLanrRequest: boolean;
  linkLanrRequestSuccess: boolean;
  verifyingLanrRequestCode: boolean;
  verifyLanrRequestCodeAccepted: boolean;
  verifyLanrRequestCodeErrorMessage: string;
  tryLinkAccountWithoutLogin: boolean;
}

export const FETCH_LINKED_ACCOUNTS_REQUEST = "FETCH_LINKED_ACCOUNTS_REQUEST";
export const FETCH_LINKED_ACCOUNTS_FAILURE = "FETCH_LINKED_ACCOUNTS_FAILURE";

export const SET_LINK_ACCOUNT_LIST = "SET_LINK_ACCOUNT_LIST";
export const DELETE_LINK_ACCOUNT_FROM_LIST = "DELETE_LINK_ACCOUNT_FROM_LIST";

export const VERIFY_LANR_REQUEST = "VERIFY_LANR_REQUEST";
export const VERIFY_LANR_FAILURE = "VERIFY_LANR_FAILURE";
export const VERIFY_LANR_SUCCESS = "VERIFY_LANR_SUCCESS";

export const LINK_LANR_REQUEST = "LINK_LANR_REQUEST";
export const LINK_LANR_REQUEST_SUCCESS = "LINK_LANR_REQUEST_SUCCESS";

export const LINK_LANR_CODE_REQUEST = "LINK_LANR_CODE_REQUEST";
export const LINK_LANR_CODE_SUCCESS = "LINK_LANR_CODE_SUCCESS";
export const LINK_LANR_CODE_FAILURE = "LINK_LANR_CODE_FAILURE";

export const SET_TRY_LINK_ACCOUNT_WITHOUT_LOGIN = "SET_TRY_LINK_ACCOUNT_WITHOUT_LOGIN";

export const CLEAR_LANR = "CLEAR_LANR";

export interface FetchLinkedAccountsRequestAction {
  type: typeof FETCH_LINKED_ACCOUNTS_REQUEST;
}

export interface FetchLinkedAccountsFailureAction {
  type: typeof FETCH_LINKED_ACCOUNTS_FAILURE;
}

export interface SetLinkAccountListAction {
  type: typeof SET_LINK_ACCOUNT_LIST;
  data: LinkAccountData[];
}

export interface DeleteFromLinkAccountListAction {
  type: typeof DELETE_LINK_ACCOUNT_FROM_LIST;
  id: string;
}

export interface VerifyLanrRequestAction {
  type: typeof VERIFY_LANR_REQUEST;
}

export interface VerifyLanrFailureAction {
  type: typeof VERIFY_LANR_FAILURE;
}

export interface VerifyLanrSuccessAction {
  type: typeof VERIFY_LANR_SUCCESS;
}

export interface VerifyLanrClearAction {
  type: typeof CLEAR_LANR;
}

export interface LinkLanrRequestAction {
  type: typeof LINK_LANR_REQUEST;
}

export interface LinkLanrRequestSuccessAction {
  type: typeof LINK_LANR_REQUEST_SUCCESS;
  data: LinkAccountData;
}

export interface LinkLanrCodeRequestAction {
  type: typeof LINK_LANR_CODE_REQUEST;
}

export interface LinkLanrCodeSuccessAction {
  type: typeof LINK_LANR_CODE_SUCCESS;
}

export interface TryLinkAccountWithoutLoginAction {
  type: typeof SET_TRY_LINK_ACCOUNT_WITHOUT_LOGIN;
}

export interface LinkLanrCodeFailureAction {
  type: typeof LINK_LANR_CODE_FAILURE;
  errorMessage?: string;
}

export type LinkAccountActionTypes =
  | FetchLinkedAccountsFailureAction
  | FetchLinkedAccountsRequestAction
  | SetLinkAccountListAction
  | DeleteFromLinkAccountListAction
  | VerifyLanrRequestAction
  | VerifyLanrFailureAction
  | VerifyLanrSuccessAction
  | VerifyLanrClearAction
  | LinkLanrRequestAction
  | LinkLanrRequestSuccessAction
  | LinkLanrCodeRequestAction
  | LinkLanrCodeSuccessAction
  | TryLinkAccountWithoutLoginAction
  | LinkLanrCodeFailureAction;
