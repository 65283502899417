import { AxiosError } from "axios";
import { Dispatch } from "redux";
import { enqueueSnackbar } from "notistack";
import { organizationService } from "../../api";
import {
  CLEAR_ORGANIZATION,
  ClearOrganizationAction,
  DELETE_ORGANIZATION,
  DeleteOrganizationSuccessAction,
  OrganizationActionTypes,
  ORGANIZATION_FAILURE,
  ORGANIZATION_REQUEST,
  ORGANIZATION_SUCCESS,
  OrganizationRequestAction,
  OrganizationFailureAction,
  OrganizationSuccessAction,
  SetOrganizationDataAction,
  SET_ORGANIZATION_DATA,
} from "./types";
import { OrganizationData } from "../../models/organizationData";
import { getDynamicTranslation } from "../../components/core/localization/getStringTranslation";

/**
 * Fetches organization data by ID.
 * @param {string} id - The ID of the organization.
 * @returns {Function} A function that dispatches actions based on the API response.
 */
export function getOrganizationById(id: string) {
  return (dispatch: Dispatch<OrganizationActionTypes>) => {
    dispatch(organizationRequest());
    organizationService
      .getOrganizationById(id)
      .then((response) => {
        dispatch(setOrganizationData([response]));
      })
      .catch((err: AxiosError) => {
        dispatch(organizationFailure());
      });
  };
}

/**
 * Fetches all organizations.
 * @returns {Function} A function that dispatches actions based on the API response.
 */
export function getOrganizations() {
  return (dispatch: Dispatch<OrganizationActionTypes>) => {
    dispatch(organizationRequest());
    organizationService
      .getOrganizations()
      .then((response) => {
        dispatch(setOrganizationData(response));
      })
      .catch((err: AxiosError) => {
        dispatch(organizationFailure());
      });
  };
}

/**
 * Adds a new organization.
 * @param {OrganizationData} data - The organization data to be added.
 * @returns {Function} A function that dispatches actions based on the API response.
 */
export function addOrganization(data: OrganizationData) {
  return (dispatch: Dispatch<OrganizationActionTypes>) => {
    dispatch(organizationRequest());
    organizationService
      .addOrganization(data)
      .then((response) => {
        dispatch(organizationSuccess());
        enqueueSnackbar(
          getDynamicTranslation(
            "backendResponses.success.organizationCreated",
            "snackbars"
          ),
          {
            variant: "success",
          }
        );
      })
      .catch((err: AxiosError) => {
        dispatch(organizationFailure());
      });
  };
}

/**
 * Edits an existing organization.
 * @param {string} id - The ID of the organization to edit.
 * @param {OrganizationData} data - The updated organization data.
 * @returns {Function} A function that dispatches actions based on the API response.
 */
export function editOrganization(id: string, data: OrganizationData) {
  return (dispatch: Dispatch<OrganizationActionTypes>) => {
    dispatch(organizationRequest());
    organizationService
      .editOrganization(id, data)
      .then((response) => {
        enqueueSnackbar(
          getDynamicTranslation(
            "backendResponses.success.organizationUpdated",
            "snackbars"
          ),
          {
            variant: "success",
          }
        );
      })
      .catch((err: AxiosError) => {
        dispatch(organizationFailure());
      });
  };
}

/**
 * Deletes an organization.
 * @param {string} id - The ID of the organization to delete.
 * @returns {Function} A function that dispatches actions based on the API response.
 */
export function deleteOrganization(id: string) {
  return (dispatch: Dispatch<OrganizationActionTypes>) => {
    organizationService
      .deleteOrganization(id)
      .then(() => {
        dispatch(deleteOrganizationSuccess(id));
        enqueueSnackbar(
          getDynamicTranslation(
            "backendResponses.success.organizationDeleted",
            "snackbars"
          ),
          {
            variant: "success",
          }
        );
      })
      .catch((err: AxiosError) => {
        dispatch(organizationFailure());
      });
  };
}

/**
 * Action creator for successful organization deletion.
 * @param {string} id - The ID of the deleted organization.
 * @returns {DeleteOrganizationSuccessAction} The action object.
 */
export function deleteOrganizationSuccess(id: string): DeleteOrganizationSuccessAction {
  return {
    type: DELETE_ORGANIZATION,
    id,
  };
}

/**
 * Action creator for clearing organization data.
 * @returns {ClearOrganizationAction} The action object.
 */
export function clearOrganization(): ClearOrganizationAction {
  return { type: CLEAR_ORGANIZATION };
}

/**
 * Action creator for organization request.
 * @returns {OrganizationRequestAction} The action object.
 */
export function organizationRequest(): OrganizationRequestAction {
  return {
    type: ORGANIZATION_REQUEST,
  };
}

/**
 * Action creator for organization request failure.
 * @returns {OrganizationFailureAction} The action object.
 */
export function organizationFailure(): OrganizationFailureAction {
  return {
    type: ORGANIZATION_FAILURE,
  };
}

/**
 * Action creator for organization request success.
 * @returns {OrganizationSuccessAction} The action object.
 */
export function organizationSuccess(): OrganizationSuccessAction {
  return {
    type: ORGANIZATION_SUCCESS,
  };
}

/**
 * Action creator for setting organization data.
 * @param {OrganizationData[]} organizationData - The organization data to set.
 * @returns {SetOrganizationDataAction} The action object.
 */
export function setOrganizationData(
  organizationData: OrganizationData[]
): SetOrganizationDataAction {
  return {
    type: SET_ORGANIZATION_DATA,
    organizationData,
  };
}
