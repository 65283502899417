import { ParticipationType } from "./../models/targetGroupData";
import apiRoutes from "./apiRoutes";
import { BaseCrudService } from "./baseCrudService";

export class TargetGroupService extends BaseCrudService {
  async getParticipationTypes() {
    const response = await this.getAll<ParticipationType[]>(
      apiRoutes.participation_types
    );
    return response.data;
  }
}
