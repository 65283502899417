import { useState, useEffect } from "react";
import { pinboardNoAlertService } from "../../api";
import { validate as isValidUUID } from "uuid";

const usePinboardActivated = (eventId: string) => {
  const [isPinboardActivated, setIsPinboardActivated] = useState(false);

  useEffect(() => {
    if (!isValidUUID(eventId)) return;

    pinboardNoAlertService
      .getPinboardIsActivated(eventId)
      .then((response) => {
        if (response.length === 0) {
          setIsPinboardActivated(false);
        } else {
          setIsPinboardActivated(true);
        }
      })
      .catch(() => {
        setIsPinboardActivated(true);
      });
  }, [eventId]);

  return isPinboardActivated;
};

export default usePinboardActivated;
