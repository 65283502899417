import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";

interface BillForUserProps {
  orderIsFreeOfCharge: boolean;
}

const BillForUser: React.FC<BillForUserProps> = ({ orderIsFreeOfCharge }) => {
  const { t } = useTranslation(["booking"]);
  const translationKey = orderIsFreeOfCharge
    ? "invoiceForm.orderFreeOfCharge"
    : "invoiceForm.noOrganizationAvailable";

  return (
    <>
      <Grid item lg={12}>
        {t(translationKey, { ns: "booking" })}
      </Grid>
    </>
  );
};

export default BillForUser;
