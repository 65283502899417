import { Box, Button, Grid } from "@material-ui/core";
import CachedIcon from "@material-ui/icons/Cached";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomSelectArrow } from "../../../../../../assets/icons/custom-svg-components/LumosSvgCollection";
import { AppState } from "../../../../../../redux";
import {
  pinboardClear,
  pinboardToggleOpenState,
} from "../../../../../../redux/pinboard/actions";
import styles from "./../../../../../../styles/custom-styles.module.scss";

const PinboardToggleActions: React.FC = () => {
  const isPinboardOpen = useSelector((state: AppState) => state.pinboard.isPinboardOpen);
  const dispatch = useDispatch();

  const handleChange = () => {
    dispatch(pinboardToggleOpenState());
  };

  function refetchPinboard() {
    dispatch(pinboardClear());
  }

  return (
    <Grid container>
      <Grid item xs={6}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          style={{ marginTop: "0.5em" }}
        >
          <Button
            onClick={handleChange}
            className={"event-item-details-icon"}
            style={{
              fontSize: "1.15rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            {isPinboardOpen ? (
              <>
                Schließen
                <CustomSelectArrow
                  strokeColor={styles.primary}
                  size={"1.5em"}
                  margin={"0 0 0 0.25em"}
                  className={"event-item-shrink-icon"}
                />
              </>
            ) : (
              <>
                Öffnen
                <CustomSelectArrow
                  strokeColor={styles.primary}
                  size={"1.5em"}
                  margin={"0 0 0 0.25em"}
                  className={"event-item-expand-icon"}
                />
              </>
            )}
          </Button>
          <Button
            onClick={refetchPinboard}
            className={"event-item-details-icon"}
            style={{
              fontSize: "1.15rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            Aktualisieren{" "}
            <CachedIcon
              color="primary"
              fontSize="large"
              style={{ marginLeft: "0.25em", fontSize: "1.5rem" }}
            />
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default PinboardToggleActions;
