import React from "react";
import { Box, Badge, Tooltip } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { AppState } from "../../../../redux";
import routes from "../../../../routing/routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { defaultPalette } from "../../../theming/defaultTheme";
import { useTranslation } from "react-i18next";

export const Cart: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation(["events", "snackbars"]);

  const cartItemsCount = useSelector(
    (state: AppState) => state.cart.cart?.cart_items?.length || 0
  );
  const isLoading = useSelector((state: AppState) => state.cart.isLoading);
  const accessibility = useSelector((state: AppState) => state.accessibility);

  return (
    <Box mb={1} ml={2}>
      {cartItemsCount === 0 ? (
        <Tooltip
          color="primary"
          title={isLoading ? "" : t("cart.none", { ns: "events" })}
          arrow
        >
          <FontAwesomeIcon
             icon={faCartShopping}
             size="2x"
             color={accessibility.monoMode ? "#000" : "#efefef"}
             style={{ marginRight: "5px" }}
             onClick={() => {
                history.push(routes.checkout)}
             }
          />
        </Tooltip>
      ) : (
        <Badge
          badgeContent={cartItemsCount}
          color="primary"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          overlap="circular"
        >
          <Tooltip
            color="primary"
            title={t("cart.items", { ns: "events" })}
            arrow
          >
            <FontAwesomeIcon
              icon={faCartShopping}
              size="2x"
              color={accessibility.monoMode ? "#000" : defaultPalette.primary?.main}
              style={{ marginRight: "5px", cursor: "pointer" }}
              onClick={() => {
                history.push(routes.checkout)}
              }
            />
          </Tooltip>
        </Badge>
      )}
    </Box>
  );
};
