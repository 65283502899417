export enum EventType {
  Undefined = 0,
  OnSite = 10,
  Online = 20,
}

export enum InternalExternalEventType {
  Internal = 10,
  External = 20,
}

export function mapInternalExternalEventTypeToString(
  internalExternalEvenType: InternalExternalEventType
): string {
  switch (internalExternalEvenType) {
    case InternalExternalEventType.Internal:
      return "Intern";
    case InternalExternalEventType.External:
      return "Extern";
    default:
      return "";
  }
}

export function mapToEventTypeString(eventType?: EventType): string {
  switch (eventType) {
    case EventType.Undefined:
      return "";
    case EventType.OnSite:
      return "Präsenz-Veranstaltung";
    case EventType.Online:
      return "Online-Veranstaltung";
    default:
      return "";
  }
}

{
  /*
TODO refactor filtering using keys instead of strings
*/
}
export function mapToEventTypeStringTranslationKey(eventTypeString?: string): string {
  switch (eventTypeString) {
    case "Präsenz-Veranstaltung":
      return "eventLocation.onSiteEvent";
    case "Online-Veranstaltung":
      return "eventLocation.onlineEvent";
    default:
      return "";
  }
}

export function mapToEventTypeKeyString(eventType?: EventType): string {
  switch (eventType) {
    case EventType.Undefined:
      return "";
    case EventType.OnSite:
      return "onsite";
    case EventType.Online:
      return "online";
    default:
      return "";
  }
}

{
  /*
TODO handle dynamically in FE 2
*/
}
export function mapToTargetGroupTranslationKey(targetGroup: string): string {
  switch (targetGroup) {
    case "Standard":
      return "targetGroups.default";
    case "Menschen mit Behinderung":
      return "targetGroups.withDisabilities";
    case "Schüler:innen":
      return "targetGroups.studentsSchool";
    case "Studierende":
      return "targetGroups.studentsUniversity";
    case "Sonstige":
      return "targetGroups.others";
    default:
      return targetGroup;
  }
}
