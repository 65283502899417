import heic2any from "heic2any";
import { FileObject } from "material-ui-dropzone";
/**
 * Converts a HEIC file to PNG format.
 * This function converts a HEIC file to PNG format using the heic2any library.
 * If the conversion is successful, it returns a FileObject representing the converted PNG file,
 * along with its preview data URL.
 * If the conversion fails, it logs an error and returns the original HEIC file.
 * @param heicFile The HEIC file to be converted.
 * @returns A promise resolving to a FileObject representing the converted PNG file.
 */
export const convertHEIC = async (heicFile: File): Promise<FileObject> => {
  try {
    const pngBlobs = await heic2any({
      blob: heicFile,
      toType: "image/png",
    });

    // Ensure pngBlobs is always an array of BlobPart
    const blobParts: BlobPart[] = Array.isArray(pngBlobs) ? pngBlobs : [pngBlobs];

    // Combine multiple blobs into a single blob
    const pngBlob = new Blob(blobParts, { type: "image/png" });

    // Create a File object from the PNG blob
    const pngFile = new File([pngBlob], `${heicFile.name}.png`, {
      type: "image/png",
    });

    // Return the converted file object with its preview data URL
    return { file: pngFile, data: URL.createObjectURL(pngFile) };
  } catch (error) {
    // return the original HEIC file
    return { file: heicFile, data: URL.createObjectURL(heicFile) };
  }
};
