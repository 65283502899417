import {
  MinimumAgeState,
  MinimumAgeActionTypes,
  FETCH_MINIMUM_AGE_REQUEST,
  FETCH_MINIMUM_AGE_SUCCESS,
  FETCH_MINIMUM_AGE_FAILURE,
} from "./types";

// The initial state for the minimum age feature.
const initialMinimumAgeState: MinimumAgeState = {
  minimumAge: {},
  isLoading: false,
  error: null,
};

/**
 * The minimum age reducer handles the state for the minimum age feature.
 * The reducer handles the actions for fetching the minimum age data.
 * @param {MinimumAgeState} state - The minimum age state
 * @param {MinimumAgeActionTypes} action - The action to be performed
 * @returns {MinimumAgeState} The new minimum age state
 **/
const minimumAgeReducer = (
  state = initialMinimumAgeState,
  action: MinimumAgeActionTypes
): MinimumAgeState => {
  switch (action.type) {
    case FETCH_MINIMUM_AGE_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case FETCH_MINIMUM_AGE_SUCCESS:
      return {
        ...state,
        minimumAge: action.payload,
        isLoading: false,
        error: null,
      };
    case FETCH_MINIMUM_AGE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default minimumAgeReducer;
