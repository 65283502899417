import { getDynamicTranslation } from "../../components/core/localization/getStringTranslation";

export enum TrainingType {
  DefaultEvent = 0,
  ELearning = 10,
  BlendedLearning = 20,
}

export function mapToTrainingTypeString(trainingType?: TrainingType): string {
  switch (trainingType) {
    case TrainingType.DefaultEvent:
      return getDynamicTranslation("eventType.event", "events");
    case TrainingType.ELearning:
      return getDynamicTranslation("eventType.eLearning", "events");
    case TrainingType.BlendedLearning:
      return getDynamicTranslation("eventType.blendedLearning", "events");
    default:
      return getDynamicTranslation("eventType.event", "events");
  }
}

export function mapTrainingTypeToArticleWithNoun(
  typeOfTrainingToBeCreated: TrainingType,
  trainingType: string
): string {
  return typeOfTrainingToBeCreated === TrainingType.DefaultEvent
    ? "die Veranstaltung"
    : `das ${trainingType}`;
}
// TODO handle differently because of translations
export function mapTrainingTypeToArticleWithNounPossessiveCase(
  typeOfTrainingToBeCreated: TrainingType,
  trainingType: string
) {
  const trainingTypeNounWithArticle = `${
    typeOfTrainingToBeCreated !== TrainingType.DefaultEvent ? "des" : "der"
  } ${trainingType}${
    typeOfTrainingToBeCreated !== TrainingType.DefaultEvent ? "s" : ""
  }`;
  return trainingTypeNounWithArticle;
}

// TODO handle differently because of translations
