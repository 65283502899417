import {
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  InputProps,
} from "@material-ui/core";
import { FieldAttributes, useField } from "formik";
import * as React from "react";
import { Visibility, VisibilityOff } from "@material-ui/icons";

interface CustomPasswordFieldProps {
  label?: string;
}

const CustomPasswordField: React.FC<
  FieldAttributes<CustomPasswordFieldProps> & InputProps
> = (props) => {
  const [clear, setClear] = React.useState(false);
  const [field, meta] = useField<{}>(props);
  const error = meta.error && meta.touched ? meta.error : ""; // get validation error text

  const endAdornment = (
    <InputAdornment position="end">
      <IconButton
        aria-label="Passwortsichtbarkeit umschalten"
        onClick={() => setClear((p) => !p)}
        onMouseDown={() => setClear((p) => !p)}
      >
        {clear ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    </InputAdornment>
  );

  return (
    <FormControl style={{ width: "100%" }}>
      <InputLabel id={props.id ?? undefined}>{props.label}</InputLabel>
      <Input
        {...field}
        {...props}
        type={clear ? "text" : "password"}
        endAdornment={endAdornment}
        error={!!error}
      />
      {error && <FormHelperText error={!!error}>{error}</FormHelperText>}
    </FormControl>
  );
};

export default CustomPasswordField;

// TODO no translation yet (or "aria-label="Passwortsichtbarkeit umschalten" to English)
