import { Box, CircularProgress } from "@material-ui/core";
import React from "react";
import {
  mapToTrainingTypeString,
  TrainingType,
} from "../../../../models/enums/trainingType.enum";

interface ProcessingEventProps {
  isEditMode: boolean;
  typeOfTrainingToBeCreated: TrainingType;
}

const ProcessingEvent: React.FC<ProcessingEventProps> = (props) => {
  return (
    <Box display="flex" alignItems="center">
      <CircularProgress style={{ marginRight: "0.625rem" }} />
      {props.isEditMode
        ? "Änderungen werden gespeichert..."
        : `${mapToTrainingTypeString(props.typeOfTrainingToBeCreated)} wird angelegt...`}
    </Box>
  );
};

export default ProcessingEvent;

// TODO no translation yet
