import { groupMembershipService } from "../../api";
import {
  ADD_MEMBERSHIP,
  CLEAR_MEMBERSHIPS,
  MembershipDocumentActionType,
  MEMBERSHIPS_REQUEST,
  REMOVE_MEMBERSHIP,
} from "./types";
import { Dispatch } from "react";
import { GroupMembership, MembershipDocumentType } from "../../models/groupMembership";
import { enqueueSnackbar } from "notistack";

export function fetchMembershipDocuments() {
  return (dispatch: Dispatch<MembershipDocumentActionType>) => {
    groupMembershipService.fetchAll().then(({ data }) => {
      dispatch({
        type: MEMBERSHIPS_REQUEST,
        memberships: data.map((d) => ({ id: d.id, user: d.user, document: d })),
      });
    });
  };
}

export function removeGroupMembership(item: GroupMembership) {
  return (dispatch: Dispatch<MembershipDocumentActionType>) => {
    if (item.id) {
      groupMembershipService.deleteMembershipDocument(item.id.toString()).then(() => {
        dispatch({
          type: REMOVE_MEMBERSHIP,
          membership: item,
        });
      });
    } else {
      enqueueSnackbar("Dokument hat keine ID.", { variant: "error" });
    }
  };
}

/**
 * Submits a group membership document.
 * @param file The file to be uploaded.
 * @param type The type of membership document.
 * @param valid_until The validity date of the document.
 * @param setLoading Optional state setter function to control loading state.
 */
export function submitGroupMembership(
  file: File | string,
  type: MembershipDocumentType | string,
  valid_until: string | null, // eslint-disable-line
  document_has_no_expiry_date: boolean, // eslint-disable-line
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>
) {
  return async (dispatch: Dispatch<MembershipDocumentActionType>) => {
    try {
      const {
        data: { id, user, ...rest },
      } = await groupMembershipService.submitMembershipDokument(
        file,
        type,
        valid_until,
        document_has_no_expiry_date
      );

      dispatch({
        type: ADD_MEMBERSHIP,
        membership: { id, user, document: { id, user, ...rest } },
      });

      enqueueSnackbar("Upload erfolgreich!", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("Fehler beim Hochladen des Dokuments.", {
        variant: "error",
      });
    } finally {
      // TODO: what exactly causes eslint no-unused-expressions error ?
      setLoading && setLoading(false); // eslint-disable-line
    }
  };
}

export function clear() {
  return (dispatch: Dispatch<MembershipDocumentActionType>) => {
    dispatch({
      type: CLEAR_MEMBERSHIPS,
    });
  };
}
