import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from "@material-ui/core";
import { FieldAttributes, useField } from "formik";
import * as React from "react";
import { SelectData } from "./selectData";
import { useTranslation } from "react-i18next";

interface CustomSelectProps {
  id: string;
  labelId: string;
  label: string;
  options: SelectData[];
  defaultText?: string; // used for empty option
  defaultValueForEmptyElement?: number | string;
  noEmptyDefaultValue?: boolean;
}

const CustomSelect: React.FunctionComponent<
  FieldAttributes<{}> & CustomSelectProps & SelectProps
> = ({
  defaultText,
  defaultValueForEmptyElement,
  noEmptyDefaultValue,
  labelId,
  label,
  options,
  required,
  ...props
}) => {
  const [field, meta] = useField<{}>(props);
  const error = meta.error && meta.touched ? meta.error : ""; // get validation error text
  const { t } = useTranslation(["common", "enums", "snackbars"]);
  return (
    <>
      <FormControl fullWidth variant="filled" error={!!error}>
        <InputLabel required={required} id={labelId}>
          {label}
        </InputLabel>
        <Select
          {...field}
          {...props}
          MenuProps={{
            disableAutoFocus: true,
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
          }}
        >
          {!noEmptyDefaultValue && (
            <MenuItem value={defaultValueForEmptyElement ?? ""}>
              {defaultText
                ? t(defaultText, { ns: "enums" })
                : t("forms.defaultCustomSelect", { ns: "common" })}
            </MenuItem>
          )}
          {options?.map((option) => (
            <MenuItem key={option.key} value={option.value} disabled={!!option.disabled}>
              {option.label ? option.label : t(option.key, { ns: "enums" })}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{error}</FormHelperText>
      </FormControl>
    </>
  );
};

export default CustomSelect;
