import { EditorState } from "draft-js";
import { Form, Formik } from "formik";
import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  ELearningFormData,
  mapEditELearningDtoToEventForm,
} from "../../../models/elearning";
import { EventStatus } from "../../../models/enums/eventStatus.enum";
import {
  EventType,
  InternalExternalEventType,
} from "../../../models/enums/eventType.enum";
import {
  mapToTrainingTypeString,
  TrainingType,
} from "../../../models/enums/trainingType.enum";
import { EventFormData } from "../../../models/eventData";
import { mapEventTemplateDtoToFormData } from "../../../models/templateData";
import { AppState } from "../../../redux";
import { billingAddressClear } from "../../../redux/billingAddress/actions";
import { getBlendedLearning, getELearning } from "../../../redux/e-learnings/actions";
import { getEvent } from "../../../redux/events/actions";
import { getParticipants } from "../../../redux/participants/actions";
import { setPreview } from "../../../redux/preview/actions";
import { Utils } from "../../../services/utils";
import { NoMatchPage } from "../../error/NoMatchPage";
import usePageLoader from "../../../components/hooks/usePageLoader";
import useValidId from "../../../components/hooks/useValidId";
import routes from "../../../routing/routes";
import HeadingLumos from "../../../components/theming/HeadingLumos";
import Loader from "../../../components/theming/loader/Loader";
import eventValidationSchema from "../../../components/core/events/shared/eventValidationSchema";
import { PreviewEvent } from "../../../components/core/events/shared/preview/PreviewEvent";
import EventFormButtons from "../../../components/core/events/create/EventFormButtons";
import EventTabs from "../../../components/core/events/create/EventTabs";
import FieldsMissingAlert from "../../../components/core/events/create/FieldsMissingAlert";
import useEditFormContext from "../../../components/core/events/create/useEditEventContext";
import { DEFAULT_CERTIFICATE_TYPE } from "../../../constants";

export const EVENT_DEFAULT_START_TIME: number = 15; // always start at 15:00
export const EVENT_DEFAULT_INTERVAL: number = 30; // 30 minutes interval for default end time of event

interface CrudEventProps {
  typeOfTrainingToBeCreated?: TrainingType;
}

/**
 * Component responsible for creating and editing events or training sessions.
 *
 * @param props Props for the CrudEvent component.
 */
export const CrudEventPage: React.FC<CrudEventProps> = (props: CrudEventProps) => {
  let { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const { isLoadingPage } = usePageLoader();

  const templates = useSelector((state: AppState) => state.eventTemplates);
  const preview = useSelector((state: AppState) => state.preview);
  const participant = useSelector((state: AppState) => state.participant);
  const eventIsLoading = useSelector((state: AppState) => state.event.isLoading);
  const eLearningIsLoading = useSelector((state: AppState) => state.elearning.isLoading);
  const editEventState = useSelector((state: AppState) => state.event.editEvent);
  const eventFetchingError = useSelector((state: AppState) => state.event.error);
  const notFoundError = useSelector((state: AppState) => state.event.notFoundError);
  const elearningFetchingError = useSelector((state: AppState) => state.elearning.error);
  const error = eventFetchingError || elearningFetchingError;
  const editEventStateLoaded = useSelector(
    (state: AppState) => state.event.editEventLoaded
  );

  const isLoading = eLearningIsLoading || eventIsLoading;

  const trainingType = props.typeOfTrainingToBeCreated || editEventState.training_type;
  const { editEventWithStatusChecks } = useEditFormContext(id, trainingType);

  let routeToCheck = routes.blended_learning_edit_id;
  switch (trainingType) {
    case TrainingType.DefaultEvent:
      routeToCheck = routes.event_edit_id;
      break;
    case TrainingType.ELearning:
      routeToCheck = routes.elearning_edit_id;
      break;
  }

  const { isValidId } = useValidId(routeToCheck, id) || true;

  const isEditMode = id ? true : false;

  const [deleted, setDeleted] = React.useState(false);
  const [showEventPreview, setShowEventPreview] = React.useState({
    open: false,
    template: false,
  });

  const handleClose = () => {
    setShowEventPreview({ open: false, template: false });
  };

  function hasBeenDeletedHandler() {
    setDeleted(true);
  }

  useEffect(() => {
    if (
      isEditMode &&
      isValidId &&
      !error &&
      !participant.participantListLoaded &&
      !participant.isLoading
    ) {
      dispatch(getParticipants(id));
    }
  }, [
    dispatch,
    error,
    id,
    isEditMode,
    isValidId,
    participant.isLoading,
    participant.participantListLoaded,
  ]);

  useEffect(() => {
    if (
      isEditMode &&
      !editEventStateLoaded &&
      !isLoading &&
      !participant.isLoading &&
      !participant.participantListLoaded &&
      !error &&
      !deleted
    ) {
      if (props.typeOfTrainingToBeCreated === TrainingType.DefaultEvent) {
        dispatch(getEvent(id));
      } else if (props.typeOfTrainingToBeCreated === TrainingType.ELearning) {
        dispatch(getELearning(id));
      } else if (props.typeOfTrainingToBeCreated === TrainingType.BlendedLearning) {
        dispatch(getBlendedLearning(id));
      }
    }
    dispatch(billingAddressClear());
  }, [
    dispatch,
    editEventState,
    editEventStateLoaded,
    id,
    isLoading,
    isEditMode,
    deleted,
    props.typeOfTrainingToBeCreated,
    error,
    participant.isLoading,
    participant.participantListLoaded,
  ]);

  const trainingTypeAsString = mapToTrainingTypeString(props.typeOfTrainingToBeCreated);

  const initData = {
    title: "",
    vnr: "",
    score: 0,
    price: 0,
    minseats: 1,
    maxseats: 1,
    participation_types: [],
    status: EventStatus.NotVisible,
    isCapped: false,
    noReminder: false,
    noDiscount: false,
    noAutomaticParticipationCertificate: false,
    description: EditorState.createEmpty().getCurrentContent(),
    descriptionState: EditorState.createEmpty().getCurrentContent(),
    speakers: [],
    location: "",
    city: "Frankfurt",
    zip_code: "60486",
    locationDetails: "",
    eventType: EventType.Undefined,
    trainingType: props.typeOfTrainingToBeCreated,
    beginDate: moment().startOf("day"),
    endDate: moment().startOf("day"),
    beginTime: moment().set({
      hour: EVENT_DEFAULT_START_TIME,
      minute: 0,
      second: 0,
      millisecond: 0,
    }),
    endTime: moment().set({
      hour: EVENT_DEFAULT_START_TIME,
      minute: EVENT_DEFAULT_INTERVAL,
      second: 0,
      millisecond: 0,
    }), // let the event last at least 30 minutes per default
    catering: false,
    internalExternalEvent: InternalExternalEventType.Internal,
    documents: [],
    commentary: "",
    searchSpeakers: "",
    tasks: [],
    checklist: [],
    checklist_additional_info: "",
    evaluationLink: "",
    evaluationLinkSpeaker: "",
    numberOfUnits: 0,
    durationPerUnitInMinutes: 0,
    maxTimePeriodToFinishElearningModuleInWeeks: 0,
    pinboardIsActivated: false,
    publishELearningAfterEvent: false,
    participantAuthentication: true,
    value_added_tax_rate: undefined,
    discounts: [],
    is_certification_selected: false,
    certificate_type: DEFAULT_CERTIFICATE_TYPE,
  } as ELearningFormData;

  const getInitialValues = () => {
    if (isEditMode && isValidId) {
      return mapEditELearningDtoToEventForm(editEventState);
    }

    if (preview.previewEventLoaded) {
      return preview.previewEvent;
    }

    if (templates.eventTemplateMode) {
      return {
        ...initData,
        ...mapEventTemplateDtoToFormData(templates.eventTemplate),
      } as EventFormData;
    }

    return initData;
  };

  const handleSubmit = (data: EventFormData | ELearningFormData) => {
    if (isEditMode) {
      editEventWithStatusChecks(data);
    } else {
      if (data.submitWithTemplate) {
        dispatch(setPreview(data));
        setShowEventPreview({ open: true, template: true });
      } else {
        dispatch(setPreview(data));
        setShowEventPreview({ open: true, template: false });
      }
    }
  };

  const getHeadingText = () => {
    if (isEditMode) {
      return `${trainingTypeAsString} bearbeiten`;
    }

    const isLearning = props.typeOfTrainingToBeCreated !== TrainingType.DefaultEvent;
    return `Neue${isLearning ? "s" : ""} ${trainingTypeAsString} anlegen`;
  };

  if ((isLoadingPage || isLoading) && !error) {
    return <Loader loadingText="Bitte warten" />;
  }

  if (!isLoadingPage && !isLoading && isEditMode && (notFoundError || !isValidId)) {
    return <NoMatchPage />;
  }

  return (
    <>
      <HeadingLumos>
        {getHeadingText()}
        {isEditMode && editEventState && editEventState.public_id && (
          <span style={{ textTransform: "none" }}>
            {" "}
            (Kurs {editEventState.public_id})
          </span>
        )}
      </HeadingLumos>
      <Formik
        enableReinitialize
        onSubmit={handleSubmit}
        initialValues={getInitialValues()}
        validationSchema={
          isEditMode
            ? eventValidationSchema(trainingType, editEventState)
            : eventValidationSchema(trainingType)
        }
      >
        {({ isValid, submitCount }) => (
          <>
            <Form onKeyDown={Utils.preventEnterToSubmit}>
              <EventTabs
                typeOfTrainingToBeCreated={trainingType}
                viewMode={isEditMode ? "editEvent" : "createEvent"}
                eventId={id}
              />
              {!isValid && submitCount > 0 && <FieldsMissingAlert />}
              <EventFormButtons
                id={id}
                isEditMode={isEditMode && isValidId}
                hasBeenDeleted={hasBeenDeletedHandler}
                typeOfTrainingToBeCreated={trainingType}
                {...props}
              />
              <PreviewEvent
                showEventPreview={showEventPreview}
                typeOfTrainingToBeCreated={trainingType}
                onClose={handleClose}
              />
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};

// TODO no translation yet
