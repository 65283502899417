import { Grid } from "@material-ui/core";
import { useFormikContext } from "formik";
import React from "react";
import { useSelector } from "react-redux";
import { ELearningFormData } from "../../../../../models/elearning";
import { EventStatus } from "../../../../../models/enums/eventStatus.enum";
import { EventFormData } from "../../../../../models/eventData";
import { AppState } from "../../../../../redux";
import CustomMultiSelect from "../../../../forms/selects/CustomMultiSelect";
import { SelectData } from "../../../../forms/selects/selectData";

interface TargetGroupSectionProps {}

const TargetGroupSection: React.FC<TargetGroupSectionProps> = (props) => {
  const { values, setFieldValue } = useFormikContext<
    EventFormData | ELearningFormData
  >();
  const targetGroups = useSelector((state: AppState) => state.targetGroups);
  const participation_types = values.participation_types
    ? values.participation_types
    : [];

  return (
    <>
      <Grid item xs={12}>
        <h3>Zielgruppenauswahl</h3>
      </Grid>
      <Grid item xs={12} sm={6}>
        <CustomMultiSelect
          disabled={values.status === EventStatus.Completed}
          defaultOptions={participation_types}
          hasCustomChangeHandler={true}
          customChangeHandler={(values) => {
            setFieldValue("participation_types", values);
          }}
          required
          name="participation_types"
          id="targetGroup"
          label="Zielgruppe"
          labelId="participation-type-label"
          options={
            targetGroups.targetGroups.participation_types.map((x) => {
              return {
                key: x.id,
                value: x.id,
                label: x.label,
              };
            }) as SelectData[]
          }
        />
      </Grid>
    </>
  );
};

export default TargetGroupSection;

// TODO no translations yet
