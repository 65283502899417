export interface AccessibilityState {
  fontSizeMode: "default" | "big" | "bigger";
  monoMode: boolean;
}

export const FONT_SIZE_MODE = "FONT_SIZE_MODE";
export const COLOR_MODE = "COLOR_MODE";

export interface FontSizeModeAction {
  type: typeof FONT_SIZE_MODE;
}

export interface ColorModeAction {
  type: typeof COLOR_MODE;
}

export type AccessibilityActionTypes = FontSizeModeAction | ColorModeAction;
