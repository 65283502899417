import { Divider, Grid, Hidden, Tooltip } from "@material-ui/core";
import React, { useEffect } from "react";
import { MailHistoryData } from "../../../models/mailHistory";
import { Utils } from "../../../services/utils";
import NoEventsFound from "../events/shared/NoEventsFound";
import CommentIcon from "@material-ui/icons/Comment";
import HistoryEventLink from "./HistoryEventLink";

interface MailHistoryProps {
  mailHistory: MailHistoryData[];
  onLoad: () => void;
}

const MailHistory: React.FunctionComponent<MailHistoryProps> = ({
  mailHistory,
  onLoad,
}) => {
  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <>
      <Grid container spacing={0} className={"myevents-list-header"}>
        <Hidden smDown>
          <Grid item xs={3}>
            Mailtyp
          </Grid>
          <Grid item xs={4}>
            Mailtext (Kurzfassung/Eckdaten)
          </Grid>
          <Grid item xs={2}>
            Versanddatum
          </Grid>
          <Grid item xs={3}>
            Versendet für Veranstaltung
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Grid item xs={12}>
            Mails
          </Grid>
        </Hidden>
      </Grid>
      {mailHistory?.length === 0 ? (
        <NoEventsFound itemsNotFound="versendeten Mails" />
      ) : (
        mailHistory?.map((mail: MailHistoryData, index: number) => (
          <React.Fragment key={`mail-row-${index}`}>
            <Grid
              container
              spacing={0}
              className={"myevents-list-item"}
              onClick={() => {}}
            >
              <Hidden mdUp>
                <Grid item xs={12} sm={9} md={6} lg={5}>
                  <br /> <b>MailTyp:</b> {mail.mail_type}
                  <br /> <b>Mailtext (Kurzfassung/Eckdaten): </b>{" "}
                  {mail.mail_text && mail.mail_text !== "" ? mail.mail_text : "-"}
                  <br /> <b>Veranstaltung: </b>
                  {mail.mail_sent_for_event !== null ? (
                    <>
                      <HistoryEventLink event={mail.mail_sent_for_event} /> (#
                      {mail.mail_sent_for_event.public_id})
                    </>
                  ) : (
                    "-"
                  )}
                  <br /> <b>Versanddatum: </b>{" "}
                  {new Date(mail.sent_date).toLocaleDateString()}
                </Grid>
              </Hidden>
              <Hidden smDown>
                <Grid item xs={3}>
                  {mail.mail_type}
                </Grid>
                <Grid item xs={4}>
                  {mail.mail_text && mail.mail_text !== "" ? (
                    <>
                      {Utils.truncateNames(mail.mail_text, 80)}
                      <Tooltip
                        arrow
                        placement="right"
                        enterDelay={500}
                        leaveDelay={200}
                        style={{ fontSize: "1rem" }}
                        title={mail.mail_text ? mail.mail_text : ""}
                      >
                        <CommentIcon
                          style={{
                            marginLeft: "0.31rem",
                            fill: "var(--text-color)",
                          }}
                        />
                      </Tooltip>
                    </>
                  ) : (
                    "-"
                  )}
                </Grid>
                <Grid item xs={2}>
                  {new Date(mail.sent_date).toLocaleDateString()}
                </Grid>
                <Grid item xs={3}>
                  {mail.mail_sent_for_event !== null && (
                    <>
                      <HistoryEventLink event={mail.mail_sent_for_event} /> (#
                      {mail.mail_sent_for_event.public_id})
                    </>
                  )}
                </Grid>
              </Hidden>
            </Grid>
            <Divider />
          </React.Fragment>
        ))
      )}
    </>
  );
};

export default MailHistory;
