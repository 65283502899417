import { Button, Grid } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import { enqueueSnackbar } from "notistack";
import * as yup from "yup";
import { usersService } from "../../../api";
import { useDynamicYupValidations } from "../../../libs/yup-validations";
import { ChangePasswordRequestData } from "../../../models/userData";
import CustomPasswordField from "../../forms/CustomPasswordField";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

interface ChangePasswordForm {
  password: string;
  newPassword: string;
  confirmNewPassword: string;
}

const ChangePasswordTab: React.FC<{}> = (props) => {
  const { t } = useTranslation(["authentication", "snackbars"]);

  const { YupValidationPassword, YupValidationConfirmPassword } =
    useDynamicYupValidations();

  const validationSchema = yup.object({
    password: YupValidationPassword,
    newPassword: YupValidationConfirmPassword("confirmNewPassword"),
    confirmNewPassword: YupValidationConfirmPassword("newPassword"),
  });

  return (
    <>
      <Formik
        key={i18n.language}
        onSubmit={async (values: ChangePasswordForm) => {
          const request = {
            password: values.password,
            new_password: values.newPassword,
            new_password_confirm: values.confirmNewPassword,
          } as ChangePasswordRequestData;

          usersService.changePassword(request).then((result) => {
            if (result.ok) {
              enqueueSnackbar(t("profile.passwordChanged", { ns: "snackbars" }), {
                variant: "success",
              });
            }
          });
        }}
        enableReinitialize
        initialValues={
          {
            newPassword: "",
            password: "",
            confirmNewPassword: "",
          } as ChangePasswordForm
        }
        validationSchema={validationSchema}
      >
        <Form>
          <Grid container spacing={2} sm={8} md={8} alignItems="flex-start">
            <Grid item xs={12} sm={8} md={10}>
              <Field
                as={CustomPasswordField}
                fullWidth
                autoComplete="current-password"
                id="password"
                label={t("password.change.passwordCurrent", {
                  ns: "authentication",
                })}
                name="password"
                required
              />
            </Grid>
            <Grid item md={5}>
              <Field
                as={CustomPasswordField}
                variant="filled"
                fullWidth
                autoComplete="new-password"
                id="new-password"
                label={t("password.change.passwordNew", {
                  ns: "authentication",
                })}
                name="newPassword"
                required
              />
            </Grid>
            <Grid item md={5}>
              <Field
                as={CustomPasswordField}
                fullWidth
                autoComplete="new-password"
                id="confirm-new-password"
                label={t("password.change.passwordNewConfirm", {
                  ns: "authentication",
                })}
                name="confirmNewPassword"
                required
              />
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Button
                id="change-password"
                type="submit"
                size="large"
                disabled={false}
                variant="contained"
                color="primary"
              >
                {t("password.change.changeButton", {
                  ns: "authentication",
                })}
              </Button>
            </Grid>
          </Grid>
        </Form>
      </Formik>
    </>
  );
};

export default ChangePasswordTab;
