import routes from "../../routing/routes";
import { UserData } from "../userData";
import { UserType } from "./userType.enum";

export enum PinboardPopupType {
  Undefined = 0,
  PostQuestion = 10,
  UpdateQuestion = 20,
  UnarchiveQuestion = 30,
  ArchiveQuestion = 40,
  PostAnswer = 50,
  UpdateAnswer = 60,
  ArchiveAnswer = 70,
  UnarchiveAnswer = 80,
}

export function mapToPopupTitle(popup?: PinboardPopupType): string {
  switch (popup) {
    case PinboardPopupType.Undefined:
      return "";
    case PinboardPopupType.PostQuestion:
      return "Neuer Beitrag";
    case PinboardPopupType.UpdateQuestion:
      return "Beitrag bearbeiten";
    case PinboardPopupType.ArchiveQuestion:
      return "Beitrag archivieren";
    case PinboardPopupType.UnarchiveQuestion:
      return "Beitrag veröffentlichen";
    case PinboardPopupType.PostAnswer:
      return "Antworten";
    case PinboardPopupType.UpdateAnswer:
      return "Antwort bearbeiten";
    case PinboardPopupType.ArchiveAnswer:
      return "Antwort archivieren";
    case PinboardPopupType.UnarchiveAnswer:
      return "Antwort veröffentlichen";
    default:
      return "";
  }
}

export function mapToPopupAction(popup?: PinboardPopupType): string {
  if (popup === undefined) {
    return "Speichern";
  }
  if ([PinboardPopupType.PostQuestion, PinboardPopupType.PostAnswer].includes(popup)) {
    return "Beitrag veröffentlichen";
  }
  if (
    [PinboardPopupType.UpdateQuestion, PinboardPopupType.UpdateAnswer].includes(popup)
  ) {
    return "Änderung speichern";
  }
  return "Speichern";
}

export function mapUserToPinboardRoute(
  user: UserData | undefined,
  isBlendedLearning: boolean
) {
  switch (user?.user_type) {
    case UserType.Apprentice:
    case UserType.Employee:
      return isBlendedLearning
        ? routes.pinboard_staff_blended_learning
        : routes.pinboard_staff_elearning;
    case UserType.Speaker:
      return routes.pinboard_speaker;
    case UserType.Participant:
      return routes.pinboard_participant;
    default:
      return routes.pinboard_participant;
  }
}
