import {
  DocumentData,
  DocumentUploadDataResponse,
  DocumentByIdDataResponse,
  ScormFile,
} from "../models/documentData";
import apiRoutes from "./apiRoutes";
import { BaseCrudService } from "./baseCrudService";
import { uploadToScormService, deleteCourseById } from "./scorm/scormService";

export class DocumentsService extends BaseCrudService {
  /**
   * Fetches a document from the Django server based on its ID.
   *
   * This function uses the `get` method to send a GET request to the Django server,
   * targeting the endpoint responsible for retrieving a document by its ID.
   *
   * @param {string} documentID - The ID of the document to retrieve.
   * @returns {Promise<DocumentByIdDataResponse>} A promise that resolves to the server response data.
   */
  async getDocumentByIdDjangoResponse(documentID: string) {
    const response = await this.get<DocumentByIdDataResponse>(
      apiRoutes.documents,
      documentID + "/get_document_by_id"
    );
    return response.data;
  }

  /**
   * Uploads a document to the Django server.
   *
   * This function is intended for documents that are not SCORM files. It uses the
   * `create` method to send a multipart/form-data request containing the document
   * information to the Django server.
   *
   * @param {FormData} data - The FormData containing the document information.
   * @returns {Promise<AxiosResponse<DocumentUploadDataResponse>>} A promise that resolves to the server response.
   */
  async uploadToDjango(data: FormData) {
    // Configuration for the request
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    // Use the create method to upload the document to the Django server
    return await this.create<FormData, DocumentUploadDataResponse>(
      apiRoutes.documents,
      data,
      {},
      config
    );
  }

  /**
   * Uploads SCORM data to both a Django server and a Scorm Cloud service.
   *
   * This function handles the upload process for SCORM data. It checks if the SCORM file
   * already exists on the Django server. If it does, it retrieves the existing document's
   * information. If not, it uploads the SCORM file to the Django server and retrieves the
   * newly created document's ID. Subsequently, it uploads the SCORM file to the Scorm Cloud
   * service.
   *
   * @param {FormData} data - FormData containing SCORM file and other relevant information.
   * @param {ScormFile} existingScormFile - Optional: Existing SCORM file information.
   * @returns {Promise<AxiosResponse>} A promise that resolves when the upload is complete,
   * containing the response from the Django server or Scorm Cloud service.
   *
   * @throws Throws an error if the SCORM upload to Scorm Cloud service fails.
   *
   */
  async uploadToScorm(data: FormData, existingScormFile?: ScormFile) {
    // Extract the SCORM file from the FormData
    const scormFile = data.get("file") as File;
    let courseId = null;
    let djangoResponse = null;

    // Check if an existing SCORM file is provided
    if (existingScormFile?.id) {
      courseId = existingScormFile?.id;
      // Retrieve document information from Django server
      djangoResponse = await this.getDocumentByIdDjangoResponse(courseId);
    } else {
      // Create a new FormData without the file for Django server
      const formDataWithoutFile = new FormData();
      // Append the non-file fields to the formDataWithoutFile
      data.forEach((value, key) => {
        if (key !== "file") {
          formDataWithoutFile.append(key, value as string);
        }
      });
      // Upload to Django server without the actual file
      djangoResponse = await this.uploadToDjango(formDataWithoutFile);
      // Get the ID from the Django server response
      courseId = djangoResponse?.data?.id;
    }

    // Create a new FormData with only the file for Scorm cloud
    const scormFormData = new FormData();

    // Append the SCORM file to the FormData
    scormFormData.append("file", scormFile);

    // Upload to SCORM
    const scormResponse = await uploadToScormService(scormFormData, courseId);
    if (scormResponse.status !== 200) {
      throw new Error("SCORM upload to Scorm Cloud failed");
    }

    // Return the Django server response
    return djangoResponse;
  }

  /**
   * Uploads a document to the server, handling SCORM files separately.
   *
   * This function determines whether the document is a SCORM file based on the
   * 'is_scorm_file' field in the FormData. If it is a SCORM file, the function
   * delegates the upload process to the `uploadToScorm` function. If not, it
   * uploads the document to the Django server using the `uploadToDjango` function.
   *
   * @param {FormData} data - The FormData containing the document information.
   * @param {ScormFile} existingScormFile - Optional: Existing SCORM file information.
   * @returns {Promise<AxiosResponse>} A promise that resolves to the server response.
   *
   * @throws Throws an error if the SCORM upload to Scorm Cloud service fails.
   */
  async uploadDocument(data: FormData, existingScormFile?: ScormFile) {
    // Check if the document is a SCORM file
    const isScormFile = data.get("is_scorm_file") === "true";

    if (isScormFile) {
      // If it's a SCORM file, upload to Scorm Cloud
      return await this.uploadToScorm(data, existingScormFile);
    } else {
      // If it's not a SCORM file, upload to Django server
      return await this.uploadToDjango(data);
    }
  }

  /**
   * Deletes a document from the server, handling SCORM files separately.
   *
   * If the document is a SCORM file, it first deletes the corresponding SCORM
   * course using the `deleteCourseById` function from the Scorm Cloud service.
   * Then, it proceeds to delete the document from the Django server.
   *
   * @param {DocumentData} document - The document to be deleted.
   * @returns {Promise<AxiosResponse>} A promise that resolves to the server response.
   *
   * @throws Throws an error if the SCORM course deletion from Scorm Cloud fails.
   */
  async deleteDocument(document: DocumentData) {
    try {
      // If the document is a SCORM file, delete the corresponding SCORM course
      if (document.is_scorm_file) {
        await deleteCourseById(document.id);
      }
    } catch (e) {}
    // Delete the document from the Django server
    const response = await this.delete<DocumentData>(
      apiRoutes.documents,
      document.id.toString()
    );
    return response;
  }

  async bulkDeleteDocument(ids: string[]) {
    return Promise.all(
      ids.map(async (id) => {
        return (await this.delete<DocumentData>(apiRoutes.documents, id)).data;
      })
    );
  }

  async editDocumentState(document: DocumentData, data: FormData) {
    const response = await this.edit(apiRoutes.documents, document.id.toString(), data);
    return response;
  }

  async getDefaultDocuments(eventType: string, trainingType: string) {
    const response = await this.getAll<DocumentData[]>(apiRoutes.documents_default, {
      event_type: eventType,
      training_type: trainingType,
    });
    return response.data;
  }

  async getAllDefaultDocuments() {
    const response = await this.getAll<DocumentData[]>(apiRoutes.documents_default);
    return response.data;
  }

  async getDocument(id: string) {
    const response = await this.get<DocumentData>(apiRoutes.documents, id);
    return response.data;
  }

  async getDocumentsByEvent(eventId: string, isTemplate: boolean, trainingType: string) {
    let route = "";
    if (isTemplate) {
      route = apiRoutes.templates_event;
    } else {
      route = apiRoutes.events;
    }
    const response = await this.getAll<DocumentData[]>(
      route + "/" + eventId.toString() + apiRoutes.documents,
      { training_type: trainingType }
    );
    return response.data;
  }
}
