import * as React from "react";
import { Box, Typography } from "@material-ui/core";
import { CloudOff } from "@material-ui/icons";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { usersService } from "../../../api";
import { PlayerData } from "../../../models/userData";
import { AppState } from "../../../redux";
import { clearBookings } from "../../../redux/bookings/actions";
import { pinboardClear } from "../../../redux/pinboard/actions";
import routes from "../../../routing/routes";
import HeadingLumos from "../../../components/theming/HeadingLumos";
import Loader from "../../../components/theming/loader/Loader";
import { Pinboard } from "../pinboard/Pinboard";
import styles from "./../../../styles/custom-styles.module.scss";
import "./LmsElearningViewPage.scss";

// TODO: delete moodle related functionalities

interface LmsElearningViewProps {}

export const ScormPlayer = () => {
  const formRef = useRef<HTMLFormElement>(null);

  const [moodleData, setMoodleData] = useState<PlayerData>({
    cm: 0,
    currentorg: "",
    display: "popup",
    mode: "normal",
    scoid: 0,
  });
  const [moodleLiveUrl, setMoodleLiveUrl] = useState("");

  let { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (id && id !== "0") {
      (async () => {
        let result = await usersService.fetchMoodleData(+id);

        if (result) {
          setMoodleData(result.player_data);
          setMoodleLiveUrl(result.moodle_live_url);
        }
      })();
    }
  }, [id]);

  useEffect(() => {
    if (moodleData.cm !== 0 && moodleLiveUrl !== "") formRef.current?.submit();
  }, [moodleData, moodleLiveUrl]);

  return (
    <>
      <Box
        style={{
          backgroundColor: "white",
          height: "100vh",
          width: "100%",
          position: "absolute",
          top: 0,
          zIndex: 1000000,
        }}
      >
        <Box style={{ padding: "6.25rem" }}></Box>
        <Loader />
        <form
          ref={formRef}
          action={moodleLiveUrl + "mod/scorm/player.php"}
          method="post"
          name="scorm-player-form"
          id="scorm-player-form"
        >
          <input type="hidden" name="mode" size={15} value={moodleData.mode} />
          <input type="hidden" name="cm" size={15} value={moodleData.cm} />
          <input type="hidden" name="scoid" size={15} value={moodleData.scoid} />
          <input
            type="hidden"
            name="currentorg"
            size={15}
            value={moodleData.currentorg}
          />
          <input type="hidden" name="display" size={15} value={moodleData.display} />
        </form>
      </Box>
    </>
  );
};

export const LmsElearningViewPage: React.FC<LmsElearningViewProps> = (props) => {
  const bookings = useSelector((state: AppState) => state.booking);
  const authData = useSelector((state: AppState) => state.auth);
  const moddleLoginData = authData.moddleLoginData;
  const isLoading = authData.isLoading;
  const moodleDataLoaded = authData.moodleDataLoaded;

  const [loadingFrame, setLoadingFrame] = useState(true);
  // is everything alright with moodle?
  const moodleSynchronisationFailed =
    (((!moddleLoginData.is_moodle_user_link_established &&
      !isLoading &&
      moodleDataLoaded) ||
      (!moodleDataLoaded && moddleLoginData.moodle_live_url === "")) &&
      !loadingFrame) ||
    (!authData.isLoggedInToMoodle && !loadingFrame);

  const dispatch = useDispatch();

  let { booking_id } = useParams<{ booking_id: string }>();

  useEffect(() => {
    if (authData.moodleError || moodleDataLoaded) {
      setLoadingFrame(false);
    }
  }, [authData.moodleError, moodleDataLoaded, moodleSynchronisationFailed]);

  useEffect(() => {
    dispatch(pinboardClear());
    dispatch(clearBookings());
  }, [dispatch]);

  return (
    <>
      <HeadingLumos>{bookings.myEvent.event.title || "E-Learning"}</HeadingLumos>
      {loadingFrame ? (
        <Loader />
      ) : (
        <Box className="overlay">
          <iframe
            scrolling="no"
            id="moodle-content"
            src={
              !moodleSynchronisationFailed
                ? `${routes.scorm_player}/${bookings.myEvent.event.id}`
                : //   " #region-main"
                  "about:blank"
            }
            width="1024"
            height="672"
            title="Moodle LMS E-Learning"
          ></iframe>
          {moodleSynchronisationFailed && (
            <Box
              className="moodle-does-not-work-overlay"
              minHeight={"42rem"}
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <CloudOff
                style={{
                  color: styles.orange,
                  marginBottom: "0.5em",
                  fontSize: "6rem",
                }}
              />
              <Typography variant="h2" style={{ textAlign: "center" }}>
                <strong>
                  Es konnte keine Verbindung zum Lernmanagementsystem aufgebaut werden.{" "}
                  <br />
                  Wir arbeiten bereits an einer Lösung. Bitte probieren Sie es später
                  noch einmal.
                </strong>
              </Typography>
            </Box>
          )}
        </Box>
      )}

      <Box mt={4}>
        <Pinboard bookingId={booking_id} />
      </Box>
    </>
  );
};

// TODO still needed? No translations yet
