import { AxiosError } from "axios";
import { Dispatch } from "redux";
import { ApiHelper } from "../../api/apiHelper";
import { documentsService } from "../../api/index";
import { DocumentData, ScormFile } from "../../models/documentData";
import {
  AddDefaultDocumentsListAction,
  AddDocumentsListAction,
  ADD_DEFAULT_DOCUMENTS_LIST,
  ADD_DOCUMENTS_LIST,
  ChangeDocumentsPublishStateListAction,
  CHANGE_DOCUMENT_PUBLISH_STATE,
  ClearDocumentsAction,
  CLEAR_DOCUMENTS,
  DocumentsActionTypes,
  DocumentsFailureAction,
  DocumentsRequestAction,
  DOCUMENTS_FAILURE,
  DOCUMENTS_REQUEST,
  MarkDocumentForDelete,
  MARK_DOCUMENT_FOR_DELETE,
  RemoveDocumentsListAction,
  REMOVE_DOCUMENTS_LIST,
  SetDocumentsListAction,
  SetDocumentsLoadedAction,
  SET_DOCUMENTS_LIST,
  SET_DOCUMENTS_LOADED,
} from "./types";
import { enqueueSnackbar } from "notistack";

type SetUploadingFunction = React.Dispatch<React.SetStateAction<boolean>>;

/**
 * Action creator to add documents to the application state.
 * Dispatches actions to handle document upload, updates the state accordingly,
 * and displays appropriate snackbars based on the upload status.
 *
 * @param {FormData[]} documents - Array of FormData objects containing information about the documents to be uploaded.
 * @param {ScormFile} existingScormFile - Optional existing SCORM file associated with the documents.
 * @returns {ThunkAction<void, RootState, unknown, DocumentsActionTypes>} A thunk function that dispatches actions.
 */
export function addDocument(
  documents: FormData[],
  existingScormFile?: ScormFile,
  setUploading?: SetUploadingFunction
) {
  return (dispatch: Dispatch<DocumentsActionTypes>) => {
    dispatch(documentsRequest());

    // Iterate through each document and initiate the upload process
    documents.forEach((document) => {
      documentsService
        .uploadDocument(document, existingScormFile)
        .then((response) => {
          // Check if the uploaded document matches the existing SCORM file ID
          if (response?.id !== undefined && response?.id === existingScormFile?.id) {
            // if it matches, do not update the list
            dispatch(setDocumentsLoaded());
          } else {
            // if it does not match, update the list
            dispatch(addToDocumentsList(response.data));
          }
          // Display a success message to the user.
          enqueueSnackbar(`Upload erfolgreich!`, { variant: "success" });
          setUploading && setUploading(false);
        })
        .catch((err: AxiosError) => {
          dispatch(documentsFailure());

          // Display error messages to the user based on the error status
          if (err.response?.status === 400) {
            enqueueSnackbar(ApiHelper.showErrorMessageFromApi(err.response?.data), {
              variant: "error",
            });
          } else {
            enqueueSnackbar(
              "Beim Hochladen des Dokuments ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut!",
              { variant: "error" }
            );
          }
        });
    });
  };
}

export function deleteDocument(document: DocumentData, removeFromList: boolean = true) {
  return (dispatch: Dispatch<DocumentsActionTypes>) => {
    dispatch(documentsRequest());
    documentsService
      .deleteDocument(document)
      .then(() => {
        if (removeFromList) {
          dispatch(removeFromDocumentsList(document));
        }
      })
      .catch((err: AxiosError) => {
        console.error("ERROR: ", err);
        dispatch(documentsFailure());
        enqueueSnackbar(
          "Beim Löschen des Dokuments ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut!",
          { variant: "error" }
        );
      });
  };
}

export function bulkDeleteDocuments(ids: string[]) {
  return (dispatch: Dispatch<any>) => {
    dispatch(documentsRequest());
    documentsService
      .bulkDeleteDocument(ids)
      .then((tasks: DocumentData[]) => {
        dispatch(clearDocuments());
      })
      .catch((err: AxiosError) => {
        dispatch(documentsFailure());
      });
  };
}

export function editDocumentState(document: DocumentData, data: FormData) {
  return (dispatch: Dispatch<DocumentsActionTypes>) => {
    dispatch(documentsRequest());
    //a default document must have at least one true default state!
    if (!document?.is_default_on_site && data.get("is_default_online") === "false") {
      document.is_default_on_site = true;
      data.append("is_default_on_site", "true");
    }

    if (!document.is_default_online && data.get("is_default_on_site") === "false") {
      document.is_default_online = true;
      data.append("is_default_online", "true");
    }

    documentsService
      .editDocumentState(document, data)
      .then(() => {
        dispatch(changeDocumentState(document));
      })
      .catch((err: AxiosError) => {
        dispatch(documentsFailure());
        enqueueSnackbar(
          `Beim Ändern des Status der Veröffentlichung von ${document.title} ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut!`,
          { variant: "error" }
        );
      });
  };
}

export function addDefaultDocuments(eventType: string = "", trainingType: string = "") {
  return (dispatch: Dispatch<DocumentsActionTypes>) => {
    dispatch(documentsRequest());
    documentsService
      .getDefaultDocuments(eventType, trainingType)
      .then((response) => {
        dispatch(addDefaultDocumentsToDocumentsList(response, eventType));
      })
      .catch((err: AxiosError) => {
        dispatch(documentsFailure());
        enqueueSnackbar(
          "Beim hinzufügen der Standarddokumente ist ein Fehler aufgetreten.\nBitte versuchen Sie es erneut!",
          { variant: "error" }
        );
      });
  };
}

export function getDocument(id: string) {
  return (dispatch: Dispatch<DocumentsActionTypes>) => {
    dispatch(documentsRequest());
    documentsService
      .getDocument(id)
      .then((response) => {
        dispatch(addToDocumentsList(response));
      })
      .catch((err: AxiosError) => {
        dispatch(documentsFailure());
        enqueueSnackbar("Beim Laden der Dokumente ist ein Fehler aufgetreten!", {
          variant: "error",
        });
      });
  };
}

export function getDocumentsByEvent(
  eventId: string,
  isTemplate: boolean,
  trainingType: string = ""
) {
  return (dispatch: Dispatch<DocumentsActionTypes>) => {
    dispatch(documentsRequest());
    documentsService
      .getDocumentsByEvent(eventId, isTemplate, trainingType)
      .then((response) => {
        dispatch(setDocumentsList(response));
      })
      .catch((err: AxiosError) => {
        dispatch(documentsFailure());
        enqueueSnackbar("Beim Laden der Dokumente ist ein Fehler aufgetreten!", {
          variant: "error",
        });
      });
  };
}

export function getAllDefaultDocuments() {
  return (dispatch: Dispatch<DocumentsActionTypes>) => {
    dispatch(documentsRequest());
    documentsService
      .getAllDefaultDocuments()
      .then((response) => {
        dispatch(setDocumentsList(response));
      })
      .catch((err: AxiosError) => {
        dispatch(documentsFailure());
      });
  };
}

export function documentsRequest(): DocumentsRequestAction {
  return {
    type: DOCUMENTS_REQUEST,
  };
}

export function documentsFailure(): DocumentsFailureAction {
  return {
    type: DOCUMENTS_FAILURE,
  };
}

/**
 * Action creator function to add a document to the documents list.
 *
 * This function creates an action object with the specified type and data payload.
 *
 * @param {DocumentData} data - The data representing the document to be added.
 * @returns {AddDocumentsListAction} An action object with the type ADD_DOCUMENTS_LIST and the specified data.
 */
export function addToDocumentsList(data: DocumentData): AddDocumentsListAction {
  return {
    type: ADD_DOCUMENTS_LIST,
    data,
  };
}

export function setDocumentsList(data: DocumentData[]): SetDocumentsListAction {
  return {
    type: SET_DOCUMENTS_LIST,
    data,
  };
}

export function addDefaultDocumentsToDocumentsList(
  data: DocumentData[],
  defaultType: string
): AddDefaultDocumentsListAction {
  return {
    type: ADD_DEFAULT_DOCUMENTS_LIST,
    data,
    defaultType,
  };
}

export function removeFromDocumentsList(data: DocumentData): RemoveDocumentsListAction {
  return {
    type: REMOVE_DOCUMENTS_LIST,
    data,
  };
}

export function changeDocumentState(
  data: DocumentData
): ChangeDocumentsPublishStateListAction {
  return {
    type: CHANGE_DOCUMENT_PUBLISH_STATE,
    data,
  };
}

export function setDocumentsLoaded(): SetDocumentsLoadedAction {
  return {
    type: SET_DOCUMENTS_LOADED,
  };
}

export function clearDocuments(): ClearDocumentsAction {
  return {
    type: CLEAR_DOCUMENTS,
  };
}

export function markDocumentForDelete(document: DocumentData): MarkDocumentForDelete {
  return {
    type: MARK_DOCUMENT_FOR_DELETE,
    document: document,
  };
}

// TODO no translation yet
