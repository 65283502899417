import { Pagination } from "../../models/pagination";
import { DashboardListData } from "../../models/dashboardData";

export interface DashboardState {
  dashboardList: Pagination<DashboardListData>;
  dashboardListLoaded: boolean;
  currentPage: number;
  isLoading: boolean;
  error: boolean;
}

export const DASHBOARD_REQUEST = "DASHBOARD_REQUEST";
export const DASHBOARD_SUCCESS = "DASHBOARD_SUCCESS";
export const DASHBOARD_FAILURE = "DASHBOARD_FAILURE";
export const DASHBOARD_PAGE_COUNT = "DASHBOARD_PAGE_COUNT";
export const DASHBOARD_CLEAR = "DASHBOARD_CLEAR";

export interface DashboardRequestAction {
  type: typeof DASHBOARD_REQUEST;
}

export interface DashboardSuccessAction {
  type: typeof DASHBOARD_SUCCESS;
  data: Pagination<DashboardListData>;
}

export interface DashboardFailureAction {
  type: typeof DASHBOARD_FAILURE;
}

export interface DashboardPageCountAction {
  type: typeof DASHBOARD_PAGE_COUNT;
  pageCount: number;
}

export interface DashboardClearAction {
  type: typeof DASHBOARD_CLEAR;
}

export type DashboardActionTypes =
  | DashboardRequestAction
  | DashboardSuccessAction
  | DashboardFailureAction
  | DashboardPageCountAction
  | DashboardClearAction;
