import apiRoutes from "./apiRoutes";
import { BaseCrudService } from "./baseCrudService";
import { ReducedOrderData } from "../models/orderData";
import { CartItemData } from "../models/cartData";

export class OrderService extends BaseCrudService {
  async createOrder(CartItemData: CartItemData[]) {
    const order: ReducedOrderData = {
      id: "",
      orderNumber: -1,
      orderSum: 0,
      events: CartItemData,
      items: [],
    };
    const response = await this.create<ReducedOrderData>(apiRoutes.orders, order);
    return response.data;
  }
}
