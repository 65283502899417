import { AxiosError } from "axios";
import { Dispatch } from "redux";
import { enqueueSnackbar } from "notistack";
import {
  AccountInvitationActionTypes,
  FETCH_ACCOUNT_INVITATIONS_REQUEST,
  FETCH_ACCOUNT_INVITATIONS_SUCCESS,
  FETCH_ACCOUNT_INVITATIONS_FAILURE,
  REMOVE_ACCOUNT_INVITATION_FAILURE,
  REMOVE_ACCOUNT_INVITATION_REQUEST,
  REMOVE_ACCOUNT_INVITATION_SUCCESS,
} from "./types";
import { accountInvitesService } from "../../api";
import { getDynamicTranslation } from "../../components/core/localization/getStringTranslation";

import { AccountInviteData } from "../../models/accountInviteData";

// Action creator to dispatch a request to fetch account invitations
export const fetchAccountInvitationsRequest = (): AccountInvitationActionTypes => ({
  type: FETCH_ACCOUNT_INVITATIONS_REQUEST,
});

// Action creator to dispatch a success action with fetched account invitations
export const fetchAccountInvitationsSuccess = (
  accountInvitations: AccountInviteData[]
): AccountInvitationActionTypes => ({
  type: FETCH_ACCOUNT_INVITATIONS_SUCCESS,
  payload: accountInvitations,
});

// Action creator to dispatch a failure action with error message for fetching account invitations
export const fetchAccountInvitationsFailure = (
  error: string
): AccountInvitationActionTypes => ({
  type: FETCH_ACCOUNT_INVITATIONS_FAILURE,
  error,
});

// Thunk action creator to fetch account invitations asynchronously
export const fetchAccountActivationByOrganizationId = (organizationId: string) => {
  return async (dispatch: Dispatch<AccountInvitationActionTypes>) => {
    dispatch(fetchAccountInvitationsRequest());
    try {
      const response =
        await accountInvitesService.getAccountInvitesByOrganizationId(organizationId);
      dispatch(fetchAccountInvitationsSuccess(response));
    } catch (error) {
      const axiosError = error as AxiosError;
      const errorMessage =
        axiosError.response?.data?.message ||
        getDynamicTranslation("error.fetchAccountInvitationByOrgaID", "organization");
      dispatch(fetchAccountInvitationsFailure(errorMessage));
      enqueueSnackbar(errorMessage, { variant: "error" });
    }
  };
};

// Action creator to dispatch a request to remove an account invitation
export const removeAccountInvitationsRequest = (): AccountInvitationActionTypes => ({
  type: REMOVE_ACCOUNT_INVITATION_REQUEST,
});

// Action creator to dispatch a success action with removed invitation ID
export const removeAccountInvitationsSuccess = (
  invitationId: string
): AccountInvitationActionTypes => ({
  type: REMOVE_ACCOUNT_INVITATION_SUCCESS,
  invitationId,
});

// Action creator to dispatch a failure action with error message for removing account invitation
export const removeAccountInvitationsFailure = (
  error: string
): AccountInvitationActionTypes => ({
  type: REMOVE_ACCOUNT_INVITATION_FAILURE,
  error,
});

// Thunk action creator to remove an account invitation asynchronously
export const removeAccountActivationByOrganizationId = (invitationId: string) => {
  return async (dispatch: Dispatch<AccountInvitationActionTypes>) => {
    dispatch(removeAccountInvitationsRequest());
    try {
      await accountInvitesService.removeAccountInvitation(invitationId);
      dispatch(removeAccountInvitationsSuccess(invitationId));
    } catch (error) {
      const axiosError = error as AxiosError;
      const errorMessage =
        axiosError.response?.data?.message ||
        getDynamicTranslation("error.removeAccountInvitationByOrgaID", "organization");
      dispatch(removeAccountInvitationsFailure(errorMessage));
      enqueueSnackbar(errorMessage, { variant: "error" });
    }
  };
};
