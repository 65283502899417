import { AxiosError } from "axios";
import { Dispatch } from "redux";
import { AppState } from "..";
import {
  OrganizationInvoiceRequestAction,
  ORGANIZATION_INVOICE_REQUEST,
  OrganizationInvoiceActionTypes,
  SetOrganizationInvoiceDataAction,
  SET_ORGANIZATION_INVOICE_DATA,
  OrganizationInvoiceFailureAction,
  ORGANIZATION_INVOICE_FAILURE,
} from "./types";
import { OrganizationInvoiceData } from "../../models/organizationInvoiceData";
import { organizationInvoiceService } from "../../api";

/**
 * Fetches the organization invoice data
 * @returns {Promise<void>}
 */

export function getInvoicesByOrganizationId(organizationId: string) {
  return (
    dispatch: Dispatch<OrganizationInvoiceActionTypes>,
    getState: () => AppState
  ) => {
    dispatch(organizationInvoiceRequest());
    return organizationInvoiceService
      .getInvoiceByOrganizationId(organizationId)
      .then((invoices: OrganizationInvoiceData[]) => {
        dispatch(setOrganizationInvoiceData(invoices));
      })
      .catch((err: AxiosError) => {
        dispatch(organizationInvoiceFailure());
      });
  };
}

/**
 * Dispatched when the organization invoice request is made
 * @returns {OrganizationInvoiceRequestAction}
 */
export function organizationInvoiceRequest(): OrganizationInvoiceRequestAction {
  return {
    type: ORGANIZATION_INVOICE_REQUEST,
  };
}

/**
 * Dispatched when the organization invoice request fails
 * @returns {OrganizationInvoiceFailureAction}
 */
export function organizationInvoiceFailure(): OrganizationInvoiceFailureAction {
  return {
    type: ORGANIZATION_INVOICE_FAILURE,
  };
}

/**
 * Sets the organization invoice data
 * @param {OrganizationInvoiceData} invoice - The organization invoice data
 */
export function setOrganizationInvoiceData(
  invoice: OrganizationInvoiceData[]
): SetOrganizationInvoiceDataAction {
  return {
    type: SET_ORGANIZATION_INVOICE_DATA,
    invoice,
  };
}
