import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import styles from "../../../styles/custom-styles.module.scss";
import CustomButton from "../../theming/CustomButton";
import { useTranslation } from "react-i18next";

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  content: React.ReactNode;
  confirm: string;
}

const AlertConfirmDialog: React.FC<Props> = ({
  open,
  onClose,
  onConfirm,
  title,
  content,
  confirm,
}) => {
  const { t } = useTranslation(["common", "snackbars"]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <CustomButton onClick={onClose} color="secondary">
          {t("forms.buttons.cancel", { ns: "common" })}
        </CustomButton>
        <CustomButton
          onClick={onConfirm}
          customColor={styles.red}
          textColor="#fff"
          hoverColor={styles["red-dark"]}
        >
          {confirm}
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default AlertConfirmDialog;
