import {
  CLEAR_ORDER,
  OrderActionTypes,
  ORDER_FAILURE,
  OrderState,
  ORDER_REQUEST,
  SET_ORDER_DATA,
} from "./types";
import { NIL as NIL_UUID } from "uuid";

export const initialOrderState: OrderState = {
  orderData: {
    id: NIL_UUID,
    orderNumber: 0,
    orderSum: -1,
    events: [],
    items: [],
  },
  isSending: false,
};

export function orderReducer(
  state = initialOrderState,
  action: OrderActionTypes
): OrderState {
  switch (action.type) {
    case ORDER_REQUEST:
      return {
        ...state,
        isSending: true,
      };
    case ORDER_FAILURE:
      return {
        ...state,
        isSending: false,
      };
    case CLEAR_ORDER:
      return initialOrderState;
    case SET_ORDER_DATA:
      return {
        ...state,
        orderData: action.order,
        isSending: false,
      };
    default:
      return state;
  }
}

export default orderReducer;
