import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";
import { ReactComponent as AddToCart } from "./../add_to_cart.svg";
import { ReactComponent as Arrow } from "./../arrow.svg";
import { ReactComponent as ArrowDown } from "./../arrow_down.svg";
import { ReactComponent as Cart } from "./../cart.svg";
import { ReactComponent as Category } from "./../category.svg";
import { ReactComponent as Date } from "./../date.svg";
import { ReactComponent as Home } from "./../home.svg";
import { ReactComponent as Id } from "./../id.svg";
import { ReactComponent as Language } from "./../language.svg";
import { ReactComponent as Maps } from "./../maps.svg";
import { ReactComponent as Points } from "./../points.svg";
import { ReactComponent as Price } from "./../price.svg";
import { ReactComponent as Print } from "./../print.svg";
import { ReactComponent as Search } from "./../search.svg";
import { ReactComponent as TargetGroup } from "./../targetgroup.svg";
import { ReactComponent as Check } from "./../check.svg";
import { ReactComponent as Time } from "./../time.svg";
import { ReactComponent as User } from "./../user.svg";
import { ReactComponent as AccessibilityFontSize } from "./../font-size.svg";
import { ReactComponent as HighContrast } from "./../high-contrast.svg";
import { ReactComponent as Education } from "./../education.svg";
import { ReactComponent as Info } from "./../info.svg";
import { ReactComponent as File } from "./../filetype.svg";
import SvgIconBase from "./SvgIconBase";
import { useSelector } from "react-redux";
import { AppState } from "../../../redux";
import { defaultPalette } from "../../../components/theming/defaultTheme";

export interface SvgHtmlProps {
  className?: string;
  fill?: string;
}

export const LumosLocationMarkerSvg: React.FC = () => {
  return (
    <>
      <SvgIcon color="primary" viewBox={"0 0 200 200"} style={{ fontSize: "3.75rem" }}>
        <path
          fill="#dfac20"
          d="M100,10.44a54,54,0,0,0-54,54c0,29.84,54,124.08,54,124.08s54-94.24,54-124.08A54,54,0,0,0,100,10.44Zm0,80.39a24.36,24.36,0,1,1,24.36-24.36A24.36,24.36,0,0,1,100,90.83Z"
        />
      </SvgIcon>
    </>
  );
};

export const LumosAddToCartSvg: React.FC<
  SvgIconProps & {
    strokeColor?: string;
    size?: string;
    margin?: string;
  }
> = ({ size, strokeColor, margin, ...props }) => {
  const accessibility = useSelector((state: AppState) => state.accessibility);
  return (
    <SvgIconBase
      component={AddToCart}
      style={
        accessibility.monoMode
          ? {
              stroke: "#000",
              fontSize: size || "1.62rem",
              color: "transparent",
              right: "1.25rem",
              margin: margin,
            }
          : {
              stroke: strokeColor || "var(--text-color)",
              fontSize: size || "1.62rem",
              color: "transparent",
              right: "1.25rem",
              margin: margin,
            }
      }
      {...props}
    />
  );
};

export const LumosCartSvg: React.FC = (props) => {
  const accessibility = useSelector((state: AppState) => state.accessibility);
  return (
    <SvgIconBase
      component={Cart}
      color="primary"
      style={
        accessibility.monoMode
          ? { stroke: "#fff", fontSize: "2.18rem" }
          : { stroke: "#fff", fontSize: "2.18rem" }
      }
    />
  );
};
export const LumosUserSvg: React.FC = () => {
  const accessibility = useSelector((state: AppState) => state.accessibility);
  return (
    <SvgIconBase
      component={User}
      style={
        accessibility.monoMode
          ? { stroke: "#000", fontSize: "3.12rem", marginBottom: "-0.15em" }
          : {
              stroke: "var(--gray-2)",
              fontSize: "3.12rem",
              marginBottom: "-0.15em",
            }
      }
    />
  );
};

export const LumosHomeSvg: React.FC<SvgHtmlProps> = (props) => {
  const accessibility = useSelector((state: AppState) => state.accessibility);

  return (
    <SvgIconBase
      style={
        accessibility.monoMode
          ? { stroke: props.fill ? props.fill : "#000", fontSize: "1.25rem" }
          : {
              stroke: props.fill ? props.fill : "",
              fontSize: "1.25rem",
            }
      }
      component={Home}
      {...props}
    />
  );
};

export const LumosPrintSvg: React.FC<SvgHtmlProps> = (props) => {
  // const accessibility = useSelector((state: AppState) => state.accessibility);
  return (
    <SvgIconBase
      style={{ stroke: "#fff", fontSize: "1.56rem" }}
      component={Print}
      {...props}
    />
  );
};

export const LumosSearchSvg: React.FC<{ fill?: string }> = (props) => {
  const accessibility = useSelector((state: AppState) => state.accessibility);
  return (
    <SvgIconBase
      style={
        accessibility.monoMode
          ? { stroke: "#000", fontSize: "2.18rem" }
          : {
              stroke: props.fill ? props.fill : "var(--text-color)",
              fontSize: "2.18rem",
            }
      }
      component={Search}
    />
  );
};

export const LumosArrowSvg: React.FC<SvgIconProps & { fill?: string }> = (props) => {
  const accessibility = useSelector((state: AppState) => state.accessibility);
  return (
    <SvgIconBase
      style={
        accessibility.monoMode
          ? { stroke: "#000", fontSize: "1rem" }
          : { stroke: "var(--text-color)", fontSize: "1rem" }
      }
      component={Arrow}
      {...props}
    />
  );
};

export const LumosArrowDownSvg: React.FC<SvgIconProps & { fill?: string }> = (props) => {
  const accessibility = useSelector((state: AppState) => state.accessibility);
  return (
    <SvgIconBase
      style={
        accessibility.monoMode
          ? { stroke: "#000", fontSize: "1rem", marginLeft: "0.12rem" }
          : { stroke: "var(--text-color)", fontSize: "1rem", marginLeft: "0.12rem" }
      }
      component={ArrowDown}
      {...props}
    />
  );
};

// !NOTE: this component is used especially for the custom selects fields
export const CustomSelectArrow: React.FC<
  SvgIconProps & {
    strokeColor?: string;
    size?: string;
    margin?: string;
  }
> = ({ size, strokeColor, margin, ...props }) => {
  const accessibility = useSelector((state: AppState) => state.accessibility);
  return (
    <SvgIconBase
      style={
        accessibility.monoMode
          ? {
              stroke: "#000",
              fontSize: size || "1.625rem",
              color: "transparent",
              right: "1.25rem",
              margin: margin,
            }
          : {
              stroke: strokeColor || "var(--text-color)",
              fontSize: size || "1.625rem",
              color: "transparent",
              right: "1.25rem",
              margin: margin,
            }
      }
      component={Arrow}
      {...props}
    />
  );
};

export const LumosIdSvg: React.FC = () => {
  const accessibility = useSelector((state: AppState) => state.accessibility);
  return (
    <SvgIconBase
      style={
        accessibility.monoMode
          ? { stroke: "#000", fontSize: "0.75rem" }
          : { stroke: "var(--text-color)", fontSize: "0.75rem" }
      }
      component={Id}
    />
  );
};

export const LumosCategorySvg: React.FC = () => {
  return (
    <SvgIconBase
      style={{ stroke: "var(--text-color)", fontSize: "1rem" }}
      component={Category}
    />
  );
};

export const LumosDateSvg: React.FC<{
  strokeColor?: string;
  fontSize?: string;
}> = (props) => {
  const accessibility = useSelector((state: AppState) => state.accessibility);
  return (
    <SvgIconBase
      style={
        accessibility.monoMode
          ? { stroke: "#000", fontSize: "1rem", marginRight: "0.31rem" }
          : {
              stroke: props.strokeColor ? props.strokeColor : "var(--text-color)",
              fontSize: props.fontSize ? props.fontSize : "1rem",
              marginRight: "0.31rem",
            }
      }
      component={Date}
    />
  );
};

export const LumosTimeSvg: React.FC = () => {
  const accessibility = useSelector((state: AppState) => state.accessibility);
  return (
    <SvgIconBase
      style={
        accessibility.monoMode
          ? { stroke: "#000", fontSize: "1rem", marginRight: "0.31rem" }
          : {
              stroke: "var(--text-color)",
              fontSize: "1rem",
              marginRight: "0.31rem",
            }
      }
      component={Time}
    />
  );
};

export const LumosMapsSvg: React.FC = () => {
  const accessibility = useSelector((state: AppState) => state.accessibility);
  return (
    <SvgIconBase
      style={
        accessibility.monoMode
          ? { stroke: "#000", fontSize: "1rem", marginRight: "0.31rem" }
          : {
              stroke: "var(--text-color)",
              fontSize: "1rem",
              marginRight: "0.31rem",
            }
      }
      component={Maps}
    />
  );
};

export const LumosPointsSvg: React.FC = () => {
  const accessibility = useSelector((state: AppState) => state.accessibility);
  return (
    <SvgIconBase
      style={
        accessibility.monoMode
          ? { stroke: "#000", fontSize: "1rem", marginRight: "0.31rem" }
          : {
              stroke: "var(--text-color)",
              fontSize: "1rem",
              marginRight: "0.31rem",
            }
      }
      component={Points}
    />
  );
};

export const LumosPriceSvg: React.FC = () => {
  const accessibility = useSelector((state: AppState) => state.accessibility);
  return (
    <SvgIconBase
      style={
        accessibility.monoMode
          ? { stroke: "#000", fontSize: "1rem", marginRight: "0.31rem" }
          : {
              stroke: "var(--text-color)",
              fontSize: "1rem",
              marginRight: "0.31rem",
            }
      }
      component={Price}
    />
  );
};

export const LumosTargetGroupSvg: React.FC = () => {
  const accessibility = useSelector((state: AppState) => state.accessibility);
  return (
    <SvgIconBase
      style={
        accessibility.monoMode
          ? { stroke: "#000", fontSize: "1rem", marginRight: "0.31rem" }
          : {
              stroke: "var(--text-color)",
              fontSize: "1rem",
              marginRight: "0.31rem",
            }
      }
      component={TargetGroup}
    />
  );
};

export const LumosCheckSvg: React.FC = () => {
  const accessibility = useSelector((state: AppState) => state.accessibility);
  return (
    <SvgIconBase
      style={
        accessibility.monoMode
          ? { stroke: "#000", fontSize: "1rem", marginRight: "0.31rem" }
          : {
              stroke: "var(--text-color)",
              fontSize: "1rem",
              marginRight: "0.31rem",
            }
      }
      component={Check}
    />
  );
};

export const LumosLanguageSvg: React.FC = () => {
  const accessibility = useSelector((state: AppState) => state.accessibility);
  return (
    <SvgIconBase
      viewBox={"0 0 640 512"}
      style={
        accessibility.monoMode
          ? {
              stroke: "#000",
              fontSize: "1.25rem",
              fill: "#000",
              marginRight: "0.125rem",
            }
          : {
              stroke: "#777",
              fontSize: "1.25rem",
              fill: "#777",
              marginRight: "0.125rem",
            }
      }
      component={Language}
    />
  );
};

export const LumosFontSizeAccessibilitySvg: React.FC = () => {
  const accessibility = useSelector((state: AppState) => state.accessibility);
  return (
    <SvgIconBase
      style={
        accessibility.monoMode
          ? { stroke: "#000", fontSize: "1.25rem", fill: "#000" }
          : {
              stroke: "#777",
              fontSize: "1.25rem",
              fill: "#777",
            }
      }
      component={AccessibilityFontSize}
    />
  );
};

export const LumosHighContrastSvg: React.FC = () => {
  const accessibility = useSelector((state: AppState) => state.accessibility);
  return (
    <SvgIconBase
      style={
        accessibility.monoMode
          ? { stroke: "#000", fontSize: "1.25rem", fill: "#000" }
          : {
              stroke: "#777",
              fontSize: "1.25rem",
              fill: "#777",
            }
      }
      component={HighContrast}
    />
  );
};

export const LumosEducationSvg: React.FC<{
  strokeColor?: string;
  fontSize?: string;
}> = (props) => {
  const accessibility = useSelector((state: AppState) => state.accessibility);
  return (
    <SvgIconBase
      style={
        accessibility.monoMode
          ? { stroke: "#000", fontSize: "1.12rem", marginRight: "0.31rem" }
          : {
              stroke: props.strokeColor ? props.strokeColor : "var(--text-color)",
              fontSize: props.fontSize ? props.fontSize : "1.12rem",
              marginRight: "0.31rem",
            }
      }
      component={Education}
    />
  );
};

export const LumosInfoSvg: React.FC = () => {
  return <SvgIconBase style={{ fontSize: "2rem" }} component={Info} />;
};

export const LumosFileSvg: React.FC<{
  strokeColor?: string;
  fontSize?: string;
}> = (props) => {
  const defaultFontSize = "2rem";
  const accessibility = useSelector((state: AppState) => state.accessibility);

  return (
    <SvgIconBase
      style={
        accessibility.monoMode
          ? { stroke: "#000", fontSize: defaultFontSize, marginRight: "0.4375rem" }
          : {
              stroke: props.strokeColor ? props.strokeColor : "var(--text-color)",
              fontSize: props.fontSize ? props.fontSize : defaultFontSize,
              marginRight: "0.4375rem",
            }
      }
      component={File}
    />
  );
};
