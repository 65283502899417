import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Icon,
  Paper,
  Popper,
  PopperPlacementType,
  Slide,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneFlip, faEnvelope, faTimes } from "@fortawesome/free-solid-svg-icons";
import "./support.scss";
import { metaDataService } from "../../api";
import { useTranslation } from "react-i18next";
import DOMPurify from "dompurify";
import CircularProgress from "@material-ui/core/CircularProgress";

const SupportButton: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState<PopperPlacementType>();
  const [german, setGerman] = useState("");
  const [english, setEnglish] = useState("");
  const [metaDataIsLoading, setMetaDataIsLoading] = useState(true);

  const { i18n } = useTranslation();

  useEffect(() => {
    metaDataService
      .getMetaData()
      .then((metaData) => {
        setGerman(metaData.data.support_hotline_german);
        setEnglish(metaData.data.support_hotline_english);
        setMetaDataIsLoading(false);
      })
      .catch(() => {
        setMetaDataIsLoading(false);
      });
  }, []);

  const handleClick =
    (newPlacement: PopperPlacementType) =>
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      setOpen((prev) => placement !== newPlacement || !prev);
      setPlacement(newPlacement);
    };

  const supportText = i18n.language === "de" ? german : english;
  const createSafeHTML = (htmlString: string) => {
    return { __html: DOMPurify.sanitize(htmlString) };
  };

  const supportContent = metaDataIsLoading ? (
    <Box display="flex" justifyContent="center" alignItems="center" height="100px">
      <CircularProgress />
    </Box>
  ) : (
    <Box className="support-content">
      {/* eslint-disable-next-line react/no-danger */}
      <p dangerouslySetInnerHTML={createSafeHTML(supportText)} />
    </Box>
  );

  return (
    <>
      <Box className="support-button" onClick={handleClick("bottom")}>
        {/* Icons vertically aligned */}
        <Icon className="support-icon">
          <FontAwesomeIcon icon={faPhoneFlip} />
        </Icon>
        <Icon className="support-icon">
          <FontAwesomeIcon icon={faEnvelope} />
        </Icon>
      </Box>

      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom-end"
        style={{ zIndex: 1300 }}
        transition
      >
        {({ TransitionProps }) => (
          <Slide direction="left" {...TransitionProps} timeout={350}>
            <Paper elevation={3} className="support-popper">
              <Button
                style={{
                  width: "1rem",
                  height: "1rem",
                  position: "absolute",
                  zIndex: 3000,
                  top: "1em",
                  right: "1em",
                  display: "block",
                }}
                onClick={handleClick("bottom")}
              >
                <Icon className="close-icon">
                  <FontAwesomeIcon icon={faTimes} />
                </Icon>
              </Button>
              {supportContent}
            </Paper>
          </Slide>
        )}
      </Popper>
    </>
  );
};

export default SupportButton;
