import { AxiosError } from "axios";
import { Pagination } from "../models/pagination";
import {
  PinboardCommentsListData,
  PinboardListData,
  PinboardFormData,
  PinboardCommentFormData,
} from "../models/pinboardData";
import apiRoutes from "./apiRoutes";
import { BaseCrudService } from "./baseCrudService";

export class PinboardService extends BaseCrudService {
  async getPinboard(page: number, eventId: string, showArchive: boolean) {
    const response = await this.getAll<Pagination<PinboardListData>>(
      `${apiRoutes.elearning_root}${apiRoutes.elearnings}/${eventId}${apiRoutes.pinboard}`,
      {
        page: page.toString(),
        archive: showArchive,
      }
    );
    return response.data;
  }

  async postQuestion(payload: PinboardFormData) {
    const response = await this.create<PinboardFormData>(
      apiRoutes.elearning_root + apiRoutes.pinboard,
      payload
    );
    return response;
  }

  async postAnswer(payload: PinboardCommentFormData) {
    const response = await this.create<PinboardCommentFormData>(
      apiRoutes.elearning_root + apiRoutes.pinboard_comments,
      payload
    );
    return response;
  }

  async updateQuestion(id: string, payload: PinboardFormData) {
    const response = await this.update<PinboardFormData>(
      apiRoutes.elearning_root + apiRoutes.pinboard,
      id,
      payload
    );
    return response;
  }

  async updateAnswer(id: string, payload: PinboardCommentFormData) {
    const response = await this.update<PinboardCommentFormData>(
      apiRoutes.elearning_root + apiRoutes.pinboard_comments,
      id,
      payload
    );
    return response;
  }
}

/**
 * Child-class overriding handleErrorResponse to avoid any alert.
 */
export class PinboardNoAlertService extends BaseCrudService {
  protected handleErrorResponse(error: AxiosError) {
    return Promise.reject<AxiosError>(error);
  }

  async getPinboardComments(
    page: number,
    topicId: string,
    showArchive: boolean = false
  ) {
    const response = await this.getAll<Pagination<PinboardCommentsListData>>(
      apiRoutes.elearning_root + apiRoutes.pinboard_comments,
      {
        topic: topicId,
        page: page.toString(),
        archive: showArchive,
      }
    );
    return response.data;
  }

  async getPinboardIsActivated(eventId: string) {
    const response = await this.getAll<{ pinboard_is_activated: boolean }[]>(
      `${apiRoutes.elearning_root}${apiRoutes.elearnings}/${eventId}${apiRoutes.pinboard_activated}`
    );
    return response.data;
  }
}
