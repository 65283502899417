import { useTranslation } from "react-i18next";
import { Grid, Box } from "@material-ui/core";
import CustomTextField from "../../../../forms/CustomTextField";

const ContactPersonSection: React.FC = () => {
  const { t } = useTranslation(["organization"]);

  return (
    <>
      <Box style={{ paddingTop: "1rem" }}>
        <h3>{t("contactPerson", { ns: "organization" })}</h3>
      </Box>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <CustomTextField
            fullWidth
            id="contact-person-firstname"
            name="contact_person_firstname"
            label={t("forms.contactPersonFirstname", { ns: "organization" })}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomTextField
            fullWidth
            id="contact-person-lastname"
            name="contact_person_lastname"
            label={t("forms.contactPersonLastname", { ns: "organization" })}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomTextField
            fullWidth
            required
            id="contact-person-email"
            name="contact_person_email"
            label={t("forms.contactPersonEmail", { ns: "organization" })}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomTextField
            fullWidth
            required
            id="contact-person-phone"
            name="contact_person_phone"
            label={t("forms.contactPersonPhone", { ns: "organization" })}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ContactPersonSection;
