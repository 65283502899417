import { Pagination } from "../models/pagination";
import apiRoutes from "./apiRoutes";
import { BaseCrudService } from "./baseCrudService";
import { FavoriteListData } from "../models/favoriteData";

export class FavoriteService extends BaseCrudService {
  async getFavorites(page: number) {
    // TODO: remove hardcoded pagination
    const response = await this.getAll<Pagination<FavoriteListData>>(
      apiRoutes.favorites,
      { page: page }
    );
    return response.data;
  }

  async getFavoritesCount() {
    const response = await this.getAll<{ count: number }>(apiRoutes.favorites_count);
    return response.data;
  }

  async addFavorite(eventId: string) {
    const response = await this.create(apiRoutes.favorites, {
      event: eventId,
      status: 100,
    });
    return response;
  }

  async deleteFavorite(eventId: string) {
    const response = await this.delete(apiRoutes.favorites, eventId);
    return response;
  }
}
