import { BillingAddressClearAction } from "./../billingAddress/types";
import { AxiosError } from "axios";
import { Dispatch } from "redux";
import { cartService } from "../../api";
import { CartItemData, CartData } from "../../models/cartData";
import {
  CartActionTypes,
  CartFailureAction,
  CartRequestAction,
  CART_FAILURE,
  CART_REQUEST,
  ClearCartAction,
  CLEAR_CART,
  SaveCartAction,
  SaveCartItemAction,
  SAVE_CART,
  SAVE_CART_ITEM,
  SetCateringOptionAction,
  SET_CATERING_OPTION,
} from "./types";
import { billingAddressClear } from "../billingAddress/actions";
import { enqueueSnackbar } from "notistack";

export function getCart() {
  return (dispatch: Dispatch<CartActionTypes>) => {
    getCartItems(dispatch);
  };
}

export function getCartItems(dispatch: Dispatch<CartActionTypes>) {
  cartService
    .getCart()
    .then((response) => {
      dispatch(saveCart(response));
    })
    .catch(() => {
      dispatch(cartFailure());
    });
}

export function addEventToCart(eventId: string, userId: string) {
  return (dispatch: Dispatch<CartActionTypes>) => {
    cartService
      .addToCart(eventId, userId)
      .then(() => {
        getCartItems(dispatch);
      })
      .catch(() => {
        dispatch(cartFailure());
      });
  };
}

export function removeEventFromCart(eventId: string, reservedPlace?: boolean) {
  return (dispatch: Dispatch<CartActionTypes | BillingAddressClearAction>) => {
    cartService
      .removeFromCart(eventId)
      .then((response) => {
        getCartItems(dispatch);
        dispatch(billingAddressClear());
        if (reservedPlace) {
          enqueueSnackbar(
            "Ihr reservierter Veranstaltungsplatz wurde erfolgreich freigegeben.",
            { variant: "success" }
          );
        }
      })
      .catch((err: AxiosError) => {
        dispatch(cartFailure());
      });
  };
}

export function cartRequest(): CartRequestAction {
  return {
    type: CART_REQUEST,
  };
}
export function cartFailure(): CartFailureAction {
  return {
    type: CART_FAILURE,
  };
}

export function setCateringOption(
  cartId: string,
  cateringOption: number
): SetCateringOptionAction {
  return {
    type: SET_CATERING_OPTION,
    cartId,
    cateringOption,
  };
}

export function saveCart(cartData: CartData): SaveCartAction {
  return {
    type: SAVE_CART,
    data: cartData,
  };
}

export function saveCartItem(cartItemList: CartItemData[]): SaveCartItemAction {
  return {
    type: SAVE_CART_ITEM,
    data: cartItemList,
  };
}

export function clearCart(): ClearCartAction {
  return { type: CLEAR_CART };
}
