import { DashboardListData } from "../models/dashboardData";
import { Pagination } from "../models/pagination";
import apiRoutes from "./apiRoutes";
import { BaseCrudService } from "./baseCrudService";

export class DashboardService extends BaseCrudService {
  getDashboard(page: number) {
    const response = this.getAll<Pagination<DashboardListData>>(
      apiRoutes.elearning_root + apiRoutes.dashboard,
      {
        page: page.toString(),
      }
    );
    return response;
  }

  async bookFree(eventId: string) {
    const response = await this.create(apiRoutes.booking, {
      event_id: eventId,
    });
    return response;
  }
}
