export interface WaitingListState {
  sendingRequest: boolean;
}

export const WAITINGLIST_REQUEST = "WAITINGLIST_REQUEST";
export const WAITINGLIST_FAILURE = "WAITINGLIST_FAILURE";
export const WAITINGLIST_SUCCESS = "WAITINGLIST_SUCCESS";

export interface WaitingListRequestAction {
  type: typeof WAITINGLIST_REQUEST;
}

export interface WaitingListFailureAction {
  type: typeof WAITINGLIST_FAILURE;
}

export interface WaitingListSuccessAction {
  type: typeof WAITINGLIST_SUCCESS;
}

export type WaitingListActionTypes =
  | WaitingListRequestAction
  | WaitingListFailureAction
  | WaitingListSuccessAction;
