import { OnSiteAuthData } from "../../models/onSiteAuthData";

// The types for the on site auth feature are defined in this file.
// The types include the state shape and the action types for the on site auth feature.
export const ON_SITE_AUTH_REQUEST = "ON_SITE_AUTH_REQUEST";
export const ON_SITE_AUTH_SUCCESS = "ON_SITE_AUTH_SUCCESS";
export const ON_SITE_AUTH_FAILURE = "ON_SITE_AUTH_FAILURE";

export interface OnSiteAuthState {
  onSiteAuth: OnSiteAuthData;
  isLoading: boolean;
  error: string | null;
}

export interface OnSiteAuthRequestAction {
  type: typeof ON_SITE_AUTH_REQUEST;
}

export interface OnSiteAuthSuccessAction {
  type: typeof ON_SITE_AUTH_SUCCESS;
  payload: OnSiteAuthData;
}

export interface OnSiteAuthFailureAction {
  type: typeof ON_SITE_AUTH_FAILURE;
  error: string;
}

export type OnSiteAuthActionTypes =
  | OnSiteAuthRequestAction
  | OnSiteAuthSuccessAction
  | OnSiteAuthFailureAction;
