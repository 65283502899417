import {
  AUTHENTICATION_ADD_CAPTURE,
  AUTHENTICATION_ADD_PASS_CAPTURE,
  AUTHENTICATION_ERROR,
  AUTHENTICATION_REMOVE_CAPTURE,
  AUTHENTICATION_REMOVE_PASS_CAPTURE,
  AUTHENTICATION_RESET,
  AUTHENTICATION_SUCCESS,
  AUTHENTICATION_FAILURE,
  SET_CAPTURE_META,
  SET_DOCUMENT_CAPTURE_RECT,
  SET_PARTICIPANT_CAPTURE_RECT,
  VideoAuthActionTypes,
  VideoAuthenticationState,
} from "./types";

const initialState: VideoAuthenticationState = {
  captures: [],
  documentCaptures: [],
  isAuthenticated: false,
  forElearning: null,
  authenticationProcessCompleted: false,
};

export function videoAuthenticationReducer(
  state: VideoAuthenticationState = initialState,
  action: VideoAuthActionTypes
): VideoAuthenticationState {
  switch (action.type) {
    case AUTHENTICATION_SUCCESS:
      let { error, ...prev } = state;
      return {
        ...initialState,
        // TODO: check isAuthenticated here
        isAuthenticated: true,
        forElearning: action.forElearning,
        authenticationProcessCompleted: true,
      };
    // TODO: Add error variable?
    case AUTHENTICATION_FAILURE:
      return {
        ...initialState,
        isAuthenticated: false,
        forElearning: action.forElearning,
        authenticationProcessCompleted: true,
      };
    case AUTHENTICATION_ERROR:
      return {
        error: "Unbekannter Fehler",
        ...initialState,
      };
    case AUTHENTICATION_RESET:
      return { ...initialState };

    case AUTHENTICATION_ADD_CAPTURE:
      return {
        ...state,
        captures: [...state.captures, action.capture],
      };

    case AUTHENTICATION_REMOVE_CAPTURE:
      return {
        ...state,
        captures: state.captures.filter((c) => c !== action.capture),
      };

    case AUTHENTICATION_ADD_PASS_CAPTURE:
      return {
        ...state,
        documentCaptures: [...state.documentCaptures, action.capture],
      };

    case AUTHENTICATION_REMOVE_PASS_CAPTURE:
      return {
        ...state,
        documentCaptures: state.documentCaptures.filter((c) => c !== action.capture),
      };

    case SET_CAPTURE_META:
      return {
        ...state,
        metaData: action.metaData,
      };

    case SET_DOCUMENT_CAPTURE_RECT: {
      const copiedMetaData = state.metaData!;
      // TODO: Somehow wrong positions are trasmitted to backend
      /*
      copiedMetaData.document_capture_x_1 = Math.floor(action.x);
      copiedMetaData.document_capture_y_1 = Math.floor(action.y);
      copiedMetaData.document_capture_x_2 = Math.floor(action.width);
      copiedMetaData.document_capture_y_2 = Math.floor(action.height);
      */
      return {
        ...state,
        metaData: copiedMetaData,
      };
    }
    case SET_PARTICIPANT_CAPTURE_RECT: {
      const copiedMetaData = state.metaData!;
      // TODO: Somehow wrong positions are trasmitted to backend
      /*
      copiedMetaData.participant_capture_x_1 = Math.floor(action.x);
      copiedMetaData.participant_capture_y_1 = Math.floor(action.y);
      copiedMetaData.participant_capture_x_2 = Math.floor(action.width);
      copiedMetaData.participant_capture_y_2 = Math.floor(action.height);
      */
      return {
        ...state,
        metaData: copiedMetaData,
      };
    }
    default:
      return state;
  }
}
