import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { enqueueSnackbar } from "notistack";
import {
  DocumentUploadDataResponse,
  DocumentData,
  ScormFile,
} from "../../models/documentData";
import {
  removeFromDocumentsList,
  markDocumentForDelete,
} from "../../redux/documents/actions";

// Base URL and API key for the SCORM service
const scormApiEndpoint = process.env.REACT_APP_SCORM_BASE_URL || "";
const scormApiKey = process.env.REACT_APP_SCORM_API_KEY || "";
// TODO const scormRealm = process.env.REACT_APP_SCORM_REALM || "";

// Base configuration for SCORM API requests
const baseScormConfig: AxiosRequestConfig = {
  headers: {
    Authorization: `Basic ${scormApiKey}`,
  },
};

/**
 * Uploads a SCORM package to the SCORM service for a specific course.
 *
 * This function is designed to handle the upload of SCORM packages to both a
 * Django server and a Scorm Cloud service. It extracts the SCORM file from the
 * provided FormData and performs the necessary actions to upload the file to both services.
 * If an existingScormFile is provided, it uses its ID to associate the new SCORM
 * package with an existing course; otherwise, it creates a new course on
 * the Django server and uses the generated ID for the Scorm Cloud upload.
 *
 * @param {FormData} data - The FormData containing the SCORM package file to be uploaded.
 * @param {string} courseId - The unique identifier of the course to which
 *        the SCORM package will be associated.
 * @returns {Promise<DocumentUploadDataResponse>} A promise that resolves to the
 *          response containing information about the uploaded document.
 * @throws If an error occurs during the upload process.
 */
export const uploadToScormService = async (
  data: FormData,
  courseId: string
): Promise<DocumentUploadDataResponse> => {
  try {
    // Configuration for the Axios request
    const config: AxiosRequestConfig = {
      headers: {
        ...baseScormConfig.headers,
        "Content-Type": "multipart/form-data",
        uploadedContentType: "application/zip",
        accept: "application/json",
      },
    };

    // Perform the POST request to upload the SCORM package
    return await axios.post(
      `${scormApiEndpoint}/courses/importJobs/upload?courseId=${courseId}&mayCreateNewVersion=true`,
      data,
      config
    );
  } catch (error) {
    // If an error occurs during the upload, throw the error
    enqueueSnackbar(
      "Beim Hochladen der Scorm Datei ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut!",
      { variant: "error" }
    );
    throw error;
  }
};

/**
 * Fetches a SCORM course by its ID from the SCORM API.
 *
 * This function is responsible for making a GET request to the SCORM API to retrieve
 * information about a specific SCORM course. It uses the provided `scormCourseId`
 * to identify the course and includes the necessary headers for authentication.
 * The resulting AxiosResponse contains the data associated with the SCORM course.
 *
 * @param scormCourseId - The ID of the SCORM course to fetch.
 * @returns A promise that resolves to the AxiosResponse containing the SCORM course data.
 * @throws If an error occurs during the request.
 */
export const getScormCourseById = async (
  scormCourseId: string
): Promise<AxiosResponse<ScormFile>> => {
  try {
    // Perform the GET request to fetch the SCORM course data
    const response = await axios.get<ScormFile>(
      `${scormApiEndpoint}/courses/${scormCourseId}`,
      baseScormConfig
    );

    return response;
  } catch (error) {
    // Handle errors that may occur during the GET request
    throw error;
  }
};

/**
 * Deletes a SCORM course by its ID from the SCORM API.
 *
 * This function is responsible for making a DELETE request to the SCORM API to
 * delete a specific SCORM course. It uses the provided `scormCourseId` to
 * identify the course and includes the necessary headers for authentication.
 * The resulting AxiosResponse contains information about the deleted SCORM course.
 *
 * @param scormCourseId - The ID of the SCORM course to be deleted.
 * @returns A promise that resolves to the AxiosResponse containing the deleted SCORM course data.
 * @throws If an error occurs during the API request.
 */
export const deleteCourseById = async (
  scormCourseId: string
): Promise<AxiosResponse<ScormFile>> => {
  try {
    // Perform the DELETE request to delete the SCORM course
    return await axios.delete<ScormFile>(
      `${scormApiEndpoint}/courses/${scormCourseId}`,
      baseScormConfig
    );
  } catch (error) {
    // Handle errors that may occur during the DELETE request
    throw error;
  }
};

/**
 * Asynchronously checks if there is any SCORM file in the existing documents.
 *
 * This function takes an array of existing documents and searches for the first
 * document with `is_scorm_file` set to true. If such a document is found,
 * it retrieves additional information about the corresponding SCORM course using its ID.
 * The resulting ScormFileInfo includes the SCORM course ID, title, and version.
 * If no SCORM file is found, the function resolves to undefined.
 *
 * @param {DocumentData[]} existingDocuments - An array of existing documents.
 * @returns {Promise<ScormFile | undefined>} A promise that resolves to ScormFileInfo
 *          if a SCORM file is found, otherwise resolves to undefined.
 */
export const checkForScormFile = async (
  existingDocuments: DocumentData[]
): Promise<ScormFile | undefined> => {
  // Check if there is any SCORM file among the existing documents
  const scormFileDocument = existingDocuments?.find(
    (document) => document.is_scorm_file
  );
  // If a SCORM file document is found
  if (scormFileDocument?.id) {
    try {
      const scormFromCloud = await getScormCourseById(scormFileDocument?.id);

      return {
        id: scormFileDocument?.id || "",
        title: scormFromCloud?.data?.title || "",
        version: scormFromCloud?.data?.version || "",
      };
    } catch (e) {}
  }
};
