import { Button } from "@material-ui/core";
import Loader from "../../../../../theming/loader/Loader";
import { useTranslation } from "react-i18next";
import "../Styles/styles.scss";

/**
 * LinkInformation component displays information and a button for accessing an e-learning link.
 *
 * @component
 * @param {Object} props - Component props
 * @param {string} props.elearningLink - The URL of the e-learning content.
 * @param {boolean} props.elearningFetched - Indicates whether the e-learning data has been fetched.
 * @returns {JSX.Element} - Rendered component.
 */
export const LinkInformation: React.FC<{
  elearningLink: string;
  elearningFetched: boolean;
}> = ({ elearningLink, elearningFetched }) => {
  const { t } = useTranslation(["events"]);

  if (!elearningFetched) {
    return <Loader />;
  }

  if (!elearningLink) {
    return (
      <>
        <h3>{t("eLearning.eLearningFail.loadFail", { ns: "events" })}</h3>
        <p>{t("eLearning.eLearningFail.failNote", { ns: "events" })}</p>
      </>
    );
  }

  return (
    <>
      <p>{t("eLearning.eLearningLinkSuccess.redirecting", { ns: "events" })}</p>
      <p>{t("eLearning.eLearningLinkSuccess.link", { ns: "events" })}</p>
      <Button
        size="medium"
        variant="contained"
        color="primary"
        id="elearning-link"
        onClick={() => window.location.replace(elearningLink)}
      >
        {t("eLearning.eLearningLinkSuccess.button", { ns: "events" })}
      </Button>
    </>
  );
};
