import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";
import clsx from "clsx";

interface SvgIconBaseProps {
  component?: React.ElementType; // set the custom component (.svg file)
}

const SvgIconBase: React.FC<SvgIconBaseProps & SvgIconProps> = (props) => {
  return (
    <SvgIcon
      className={clsx("svg-icon", props.className)}
      color="primary"
      viewBox={"0 0 200 200"}
      {...props}
    />
  );
};

export default SvgIconBase;
