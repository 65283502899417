import { OrganizationTypeData } from "../../models/organizationTypeData";

export interface OrganizationTypeState {
  organizationTypeData: OrganizationTypeData[];
  //organizationTypeList: Pagination<OrganizationTypeListData>;
  isLoading: boolean;
  organizationTypeDataLoaded: boolean;
}

export const CLEAR_ORGANIZATION_TYPE = "CLEAR_ORGANIZATION_TYPE";
export const ORGANIZATION_TYPE_REQUEST = "ORGANIZATION_TYPE_REQUEST";
export const ORGANIZATION_TYPE_FAILURE = "ORGANIZATION_TYPE_FAILURE";
export const LOAD_ORGANIZATION_TYPE_DATA_SUCCESS = "LOAD_ORGANIZATION_TYPE_DATA_SUCCESS";
export const SET_ORGANIZATION_TYPE_NUMBER = "SET_ORGANIZATION_TYPE_NUMBER";
export const SET_ORGANIZATION_TYPE_DATA = "SET_ORGANIZATION_TYPE_DATA";
export const DELETE_ORGANIZATION_TYPE = "DELETE_ORGANIZATION_TYPE";

/**
 * Action creator interface for clearing organization type data from the Redux store.
 */
export interface ClearOrganizationTypeAction {
  type: typeof CLEAR_ORGANIZATION_TYPE;
}

/**
 * Action creator interface for requesting organization type data.
 */
export interface OrganizationTypeRequestAction {
  type: typeof ORGANIZATION_TYPE_REQUEST;
}

/**
 * Action creator interface for indicating failure in fetching organization type data.
 */
export interface OrganizationTypeFailureAction {
  type: typeof ORGANIZATION_TYPE_FAILURE;
}

/**
 * Action creator interface for setting organization type data in the Redux store.
 */
export interface SetOrganizationTypeDataAction {
  type: typeof SET_ORGANIZATION_TYPE_DATA;
  //data: Pagination<OrganizationTypeListData>;
  data: OrganizationTypeData[];
}

/**
 * Action creator interface for loading organization type data.
 */
export interface LoadOrganizationTypeDataAction {
  type: typeof LOAD_ORGANIZATION_TYPE_DATA_SUCCESS;
  organization: string;
}

/**
 * Action creator interface for indicating success in deleting organization type data.
 */
export interface DeleteOrganizationTypeSuccessAction {
  type: typeof DELETE_ORGANIZATION_TYPE;
  id: string;
}

export type OrganizationTypeActionTypes =
  | ClearOrganizationTypeAction
  | OrganizationTypeRequestAction
  | OrganizationTypeFailureAction
  | LoadOrganizationTypeDataAction
  | SetOrganizationTypeDataAction
  | DeleteOrganizationTypeSuccessAction;
