import { TextField, TextFieldProps } from "@material-ui/core";
import { FieldAttributes, useField } from "formik";
import * as React from "react";

const CustomTextField: React.FC<FieldAttributes<{}> & TextFieldProps> = ({
  helperText,
  ...props
}) => {
  const [field, meta] = useField<{}>(props);
  const error = meta.error && meta.touched ? meta.error : ""; // get validation error text
  return (
    <TextField
      InputProps={{ required: false }} // don't trgger the required browser check
      variant="filled"
      {...field}
      {...props}
      helperText={error ? error : helperText}
      error={!!error}
    />
  );
};

export default CustomTextField;
