import apiRoutes from "./apiRoutes";
import { BaseCrudService } from "./baseCrudService";
import { ChecklistData } from "../models/checklistData";

export class ChecklistService extends BaseCrudService {
  async getAllChecklistItems() {
    const response = await this.getAll<ChecklistData[]>(apiRoutes.checklist);
    return response.data;
  }

  async editChecklistForEvent(
    eventId: string,
    checkList: string[],
    checklist_additional_info: string
  ) {
    const response = await this.edit<{
      checklist: string[];
      checklist_additional_info: string;
    }>(apiRoutes.events, eventId, {
      checklist: checkList,
      checklist_additional_info: checklist_additional_info,
    });
    return response;
  }
}
