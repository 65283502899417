import { Grid, Link, Typography } from "@material-ui/core";
import React from "react";
import { TERMS_AND_CONDITIONS_PDF_URL } from "../../routing/externalUrls";

export const GeneralTermsAndConditionsPage: React.FC = () => {
  const EMAIL_INFO = "support@lumos-lernen.de";
  const EMAIL_PRIVACY = "datenschutz@amanzii.com";

  return (
    <Grid container>
      <Grid item sm={12}>
        <h1>
          Allgemeine Geschäftsbedingungen von LUMOS - Die Plattform für berufliche
          Weiterbildung
        </h1>
        <Typography paragraph>
          der Firma amanzii GmbH, Frankfurter Straße 1A, 61231 Bad Nauheim, Deutschland,
          (im Folgenden „amanzii“):
        </Typography>
        <Typography paragraph>
          Die nachfolgenden Geschäftsbedingungen (AGB LUMOS) sind in deutscher Sprache
          verfasst und können von Nutzenden in den Arbeitsspeicher geladen und als PDF
          gespeichert oder ausgedruckt werden.
        </Typography>
        <Link href={TERMS_AND_CONDITIONS_PDF_URL}>AGB als PDF-Datei herunterladen</Link>
      </Grid>

      <Grid>
        <h2>§ 1 Präambel, Geltungsbereich</h2>
        <Typography paragraph>
          amanzii betreibt zu gewerblichen Zwecken unter der Domain lumos-lernen.de eine
          Plattform zur Planung und Durchführung von Weiterbildungen. Räumlicher
          Geltungsbereich und Liefergebiet sind ausschließlich Deutschland, Österreich
          und Schweiz.
        </Typography>
      </Grid>

      <Grid>
        <h2>§ 2 Konten</h2>
        <Typography paragraph>
          Für den Kauf und Zugriff auf Weiterbildungen, sowie weitere kontospezifische
          Aktivitäten auf der Plattform, wird ein Konto benötigt. Sie sind verpflichtet,
          Daten vollständig und korrekt an den vorgesehenen Eingabefeldern des Systems
          anzugeben, einschließlich einer gültigen E-Mail-Adresse. Sie sind verpflichtet,
          die Daten unter dem Menüpunkt „Einstellungen“ bei Änderung zu aktualisieren.
          Die Verantwortlichkeit für Ihr Konto und verbundene Aktivitäten (auch für
          Schäden) obliegt Ihnen. Geben Sie deshalb Ihre Zugangsdaten nicht an Dritte
          weiter. Das Konto ist nicht an Dritte übertragbar und Sie dürfen umgekehrt
          nicht das Konto eines Dritten verwenden. Bei einer Zugriffsanfrage auf Ihr
          Konto erteilen wir erst nach Überprüfung der Zugriffsdaten bzw. nach
          Identitätsnachweis eine Zugriffsberechtigung für Ihr Konto. Wenn Sie den
          Verdacht haben, dass Ihr Konto durch Dritte ohne Zustimmung verwendet wird oder
          eine sonstige Datenschutz- oder Sicherheitsverletzung vorliegen könnte, dann
          müssen Sie umgehend unser Team über{" "}
          <Link href={`mailto:${EMAIL_PRIVACY}`}>{EMAIL_PRIVACY}</Link> informieren. Zur
          Bearbeitung von Anfragen von Kontorechten benötigen wir einen entsprechenden
          Identifikationsnachweis, um zu prüfen, ob Sie der/die/* tatsächliche
          Kontoinhaber*in sind.
        </Typography>
        <Typography paragraph>
          amanzii übernimmt keine Mediatorenrolle für Konflikte zwischen Teilnehmenden
          oder Dozierenden, die Kontoinformationen offengelegt oder verbreitet haben.
        </Typography>
        <h3>Konto schließen</h3>
        <Typography paragraph>
          Sie können Ihr Konto jederzeit unter dem Menüpunkt „Einstellungen“ schließen.
          Ihre Daten werden dann, soweit nicht anders in der Datenschutzerklärung
          angegeben, gelöscht. Sie haben nach Schließung des Kontos keine
          Zugriffsberechtigung mehr auf die gebuchten Weiterbildungen und damit
          zusammenhängende Dokumente und Materialien. Im Todesfall wird das Konto
          gelöscht.
        </Typography>
      </Grid>

      <Grid>
        <h2>§ 3 Buchung von Weiterbildungen und temporärer Zugriff</h2>
        <Typography paragraph>
          amanzii erteilt Ihnen einen eingeschränkten, nicht übertragbaren Zugriff auf
          gebuchte Lerninhalte. Sie erhalten damit eine Nutzungslizenz zur einmaligen
          Durchführung und Nutzung der Inhalte. Die Plattform ist auf berufliche
          Weiterqualifikation und dementsprechend auf die Erlangung von Zertifikaten
          ausgelegt. Die Kurse sind deshalb nur zur einmaligen Durchführung und Erhalt
          des Zertifikats ausgelegt. Anschließend sind Sie nicht mehr berechtigt, auf die
          Lerninhalte zuzugreifen. Dies gilt auch für nicht bestandene Kurse. Möchten Sie
          zur Erlangung des Zertifikats eine Weiterbildung noch einmal belegen, so muss
          eine neue Nutzungslizenz erworben werden. Sie erhalten die Nutzungslizenz nach
          erfolgreichem Abschluss des Check-out-Prozesses. Die Nutzungslizenz umfasst
          ausschließlich die Durchführung und Nutzung der Lerninhalte. Weitere
          Zusatzfunktionen sind damit nicht inkludiert. Ebenfalls nicht inkludiert ist
          die Unterstützung durch Dozierende. Diese kann nur für die Durchführung von
          Präsenz- oder Onlinekursen gewährleistet werden, wenn explizit dieses
          Teilnahmeformat gebucht wird. Die Verfügbarkeit von Dozierenden beschränkt sich
          dabei auf den angegebenen Zeitraum des Präsenz- oder Onlinekurses.
        </Typography>
        <Typography paragraph>
          amanzii behält sich vor, die Lizenz und die Nutzung der Inhalte jederzeit zu
          widerrufen, wenn durch geänderte Richtlinien oder aus rechtlichen Gründen, z.
          B. Urheberrechtsverletzungen, eine weitere Nutzung der Inhalte aus genannten
          Gründen nicht vertretbar ist.
        </Typography>
        <Typography paragraph>
          Es findet kein Kauf der Inhalte statt. Sie sind damit nicht berechtigt, die
          Inhalte in irgendeiner Form weiterzuverkaufen. Dies schließt die
          Vervielfältigung durch Dritte mithilfe der Weitergabe Ihrer Kontodaten oder
          illegales Herunterladen und Veröffentlichen auf Torrent-Websites mit ein.
        </Typography>
      </Grid>
      <Grid>
        <h2>§ 4 Preise, Zahlungen und Widerrufsrecht</h2>
        <h3>Preise</h3>
        <Typography paragraph>
          Die Preise werden ausschließlich in € (Euro) angezeigt. Da es sich um eine
          Plattform für berufliche Weiterbildung handelt und Organisationen und
          Einrichtungen, die eine Lizenz und den Betrieb der Plattform für ihre Kunden
          erwerben, können, abhängig von z. B. der Branche, unterschiedliche
          Mehrwertsteuersätze für den Nettobetrag einer Weiterbildung anfallen. Diese
          sind im Warenkorb des Check-out-Prozesses und in der Rechnung entsprechend
          ausgewiesen. In der Übersicht werden die Preise pro Weiterbildung immer in
          brutto dargestellt. amanzii ist zur Erhebung und Abführung dieser Steuern
          gesetzlich verpflichtet.
        </Typography>
        <h3>Zahlungen</h3>
        <Typography paragraph>
          Mit der Buchung einer Weiterbildung erklären Sie sich zur Zahlung der
          anfallenden Gebühren einverstanden und ermächtigen amanzii Ihr Konto, Debit-
          oder Kreditkarte, abhängig von der gewählten Zahlungsart, zu belasten. amanzii
          arbeitet mit Zahlungsdienstanbietern zusammen, um Ihnen die für Sie bequemste
          digitale Zahlungsmöglichkeit zur Verfügung zu stellen und Ihre Zahlungsdaten zu
          schützen. Sie erhalten eine Nutzungslizenz basierend auf der geleisteten
          Zahlung und erklären sich zur Zahlung der Gebühren innerhalb von 30 Tagen
          bereit. Sollte eine Zahlung fehlschlagen und Sie haben bereits Zugriff auf
          Inhalte erhalten, kann eine Nutzungslizenz bei ausbleibender Zahlung durch
          amanzii wieder entzogen werden, wenn kein korrekter Zahlungseingang zu
          verzeichnen ist. Sie sind für die Wahl einer gültigen Zahlungsmethode und die
          ausreichende Deckung verantwortlich.
        </Typography>
        <h3>Widerrufsrecht</h3>
        <Typography paragraph>
          Die Möglichkeit des Widerrufs ist abhängig von der Weiterbildungsmaßnahme: Für
          dozentengeführte Online- oder Präsenzkurse können Sie die Teilnahme 3 Tage vor
          Beginn kostenfrei stornieren. Stornierungen nach Ablauf der Frist sind
          kostenpflichtig. Für dozentengeführte Online- oder Präsenzkurse mit E-Learning
          dürfen Sie das E-Learning nicht begonnen haben, um von Ihrem Recht auf die
          kostenfreie Stornierung Gebrauch zu machen.
        </Typography>
        <Typography paragraph>
          Für nicht dozentengeführte E-Learnings können Sie als Privatperson innerhalb
          einer Frist von 14 Tagen nach Kaufdatum kostenfrei stornieren, insofern Sie das
          E-Learnings noch nicht begonnen haben. Nach Ablauf der Frist oder bei bereits
          begonnenen E-Learnings wird eine Stornierungsgebühr von 8 € fällig. Diese wird
          vom Rücküberweisungsbetrag abgezogen.
        </Typography>
        <Typography paragraph>
          Bei Buchungen von nicht dozentengeführten E-Learnings über eine Organisation
          können diese jeweils gegen eine Stornierungsgebühr von 8 € storniert werden,
          insofern das E-Learning noch nicht begonnen wurde.
        </Typography>
        <Typography paragraph>
          Wenn Sie aufgrund eines Systemausfalls oder sonstiger technischer Störungen
          Ihre Weiterbildung nicht oder nicht fristgerecht durchführen können, erstattet
          Ihnen amanzii bereits erbrachte Zahlungen für den jeweiligen Kurs. Veraltetes
          technisches Equipment (z. B. Laptop, Kamera), welches die technische Qualität
          vermindert oder die Durchführung der Weiterbildung erschwert oder verhindert,
          liegt nicht in der Verantwortung von amanzii und führt deshalb nicht zur
          Erstattung der Gebühren. Sie sind dafür verantwortlich, vor der Buchung die
          Funktionsfähigkeit der benötigten technischen Ausstattung zu überprüfen. Es
          findet keine äquivalente oder pauschale Erstattung statt, wenn Sie mit den
          Inhalten der Weiterbildung nicht zufrieden sind. Bitte wenden Sie sich bei
          inhaltlichen Beschwerden an{" "}
          <Link href={`mailto:${EMAIL_INFO}`}>{EMAIL_INFO}</Link>.
        </Typography>
        <Typography paragraph>
          amanzii behält sich vor, bei Verdacht auf Missbrauch der
          Erstattungsbedingungen, z. B., wenn Sie die Lerninhalte bereits fast
          vollständig bearbeitet haben, nach Ermessen vor, die Erstattung zu verweigern
          oder Ihr Konto zu schließen.
        </Typography>
      </Grid>

      <Grid>
        <h2>§ 5 Verhaltensregeln</h2>
        <Typography paragraph>
          Sie dürfen die Plattform nur für deren rechtmäßige Zwecke nutzen. Sie dürfen
          den Service und Ihr Konto nicht zu unrechtmäßigen Zwecken verwenden. Sie sind
          für die Inhalte, die Sie auf der Plattform veröffentlichen, verantwortlich. Die
          Inhalte dürfen deutsche Gesetze und Bestimmungen, sowie das Marken- und
          Urheberrecht nicht verletzen. Sie sind dazu angehalten, Ihre Inhalte vor
          Veröffentlichung auf ihre Konformität in Bezug auf die genannten Gesetze und
          Bestimmungen, ihren Ursprung und Wahrheitsgehalt zu prüfen und gegebenenfalls
          diesen zu kennzeichnen, sowie einen kultur- und gesellschaftskonformen
          Umgangston auf der Plattform zu verwenden. Wenn Sie den Verdacht haben, dass
          Ihre Rechte oder die Rechte Dritter in der genannten Art und Weise verletzt
          werden, dann wenden Sie sich an{" "}
          <Link href={`mailto:${EMAIL_INFO}`}>{EMAIL_INFO}</Link>.
        </Typography>
        <Typography paragraph>
          Die Durchsetzung dieser Regeln obliegt dem Ermessen von amanzii. Bei
          wiederholter oder grober Verletzung behält amanzii sich vor, Ihr Konto zu
          sperren.
        </Typography>
      </Grid>

      <Grid>
        <h2>§ 6 Urheber- und Eigentumsrecht</h2>
        <Typography paragraph>
          Die Urheber- und Eigentumsrechte von Inhalten liegen bei dem/der/ *
          Ersteller*in. amanzii bietet für Ihre B2B-Kunden die Erstellung von Inhalten
          an. Die Inhalte können dann für B2B-Kunden über eine Nutzungslizenz zur
          Verfügung gestellt werden.
        </Typography>
      </Grid>

      <Grid>
        <h2>§ 7 Nutzung</h2>
        <Typography paragraph>
          amanzii stellt mit LUMOS eine Plattform bereit und kann nicht Inhalte auf
          rechtliche Korrektheit und Rechtmäßigkeit prüfen oder in irgendeiner Weise
          (redaktionell) bearbeiten. Ausgenommen davon sind von amanzii lizenzierte
          Weiterbildungen. Sie nutzen damit die bereitgestellten Inhalte auf eigene
          Gefahr, dies gilt insbesondere für Inhalte, deren Erkenntnisse Sie alltäglich
          anwenden und deren Qualität oder die nicht korrekte Anwendung zu persönlichen
          Schäden oder Schäden bei Dritten führen, z. B. Infektionsgeschehen nach einer
          Infektionsschutzbelehrung und mangelhafter Umsetzung im Beruf oder auch
          finanzielle Verluste oder rechtliche Konsequenzen bei Mitarbeiterschulung zum
          Lieferkettensorgfaltspflichtgesetz.
        </Typography>
        <Typography paragraph>
          amanzii stellt keine Dozierenden zur Verfügung und ist nicht für von der/dem
          Dozierenden verursachte Gefährdung oder Schäden verantwortlich.
        </Typography>
        <Typography paragraph>
          Inhalte können Links zu weiterführenden Websites dritter Anbieter beinhalten,
          die sich der Kontrolle von amanzii entziehen. Deshalb haftet amanzii nicht für
          deren Inhalte. Bitte prüfen Sie diese Seiten auch auf die Nutzungsbedingungen
          und Datenschutz sowie die Verwendung personenbezogener Daten.
        </Typography>
      </Grid>

      <Grid>
        <h2>§ 8 Rechte von amanzii</h2>
        <Typography paragraph>
          LUMOS - Die Plattform für berufliche Weiterbildung ist ein Produkt von amanzii.
          Das Produkt beinhaltet sowohl die Plattform, dazugehörige Websites, mobile Apps
          und Services, sowie Logos, APIs, Source-Code und von amanzii lizenzierte
          Inhalte. Sie dürfen unter keinen Umständen diese Art von eindeutig
          identifizierbaren Produkt- und Markenzeichen für Ihre Zwecke verwenden. Es
          liegt im Ermessen von amanzii Ihr Feedback zu LUMOS ohne Verpflichtungen Ihnen
          gegenüber zu verwenden.
        </Typography>
        <Typography paragraph>
          Sie dürfen sich nur in öffentlichen Bereichen der Plattform aufhalten. Nicht
          öffentliche Bereiche, z. B. Systeme von LUMOS oder dessen Dienstleistersysteme
          dürfen nicht betreten oder verändert werden. Die Manipulation und Prüfung
          sicherheitsrelevanter Funktionen und Strukturen von LUMOS ist verboten. Der
          Quellcode oder Inhalte der Plattform dürfen niemals in irgendeiner Art für Ihre
          Zwecke verwendet werden. Sie dürfen die Seiten von LUMOS nur über verfügbare
          Suchfunktionen oder Navigationselemente auf der Plattform aufrufen. Alle
          anderen automatisierten oder anderweitige Verfahren zum Aufruf der Dienste und
          Inhalte sind untersagt. Sie dürfen nicht die Herkunft von Ihnen genutzter
          Kommunikationsmittel, z. B. E-Mail-Nachrichten verschleiern und diese als von
          LUMOS versandte E-Mail-Nachrichten ausgeben. Ebenfalls ist es untersagt, die
          Plattform mit massenhaftem Versand von E-Mails und Viren zu überlasten oder zu
          stören.
        </Typography>
      </Grid>

      <Grid>
        <h2>§ 9 Gewährleistung</h2>
        <Typography paragraph>
          amanzii gewährleistet die Bereitstellung der Plattform auf Basis des mit der
          Organisation oder Einrichtung abgeschlossenen Service-Level-Agreement. Es kann
          zu geplanten Wartungsarbeiten oder Störungen kommen. Die Plattform wird ohne
          Gewähr und nach Verfügbarkeit bereitgestellt. Jegliche Art von Gewährleistung
          findet ausschließlich zwischen der Organisation oder Einrichtung und amanzii
          statt und wird individuell vertraglich vereinbart.
        </Typography>
      </Grid>

      <Grid>
        <h2>§ 10 Haftungsbeschränkung und -freistellung</h2>
        <Typography paragraph>
          Für den Fall, dass amanzii den geschuldeten Service aufgrund höherer Gewalt (d.
          h. aus Gründen, die amanzii nicht zu vertreten hat, also insbesondere Streik,
          Krieg, Naturkatastrophen, Pandemie) nicht erbringen kann, sind wir für die
          Dauer der Hinderung von unseren Leistungspflichten befreit und berechtigt, vom
          Vertrag zurückzutreten. Wir werden Sie im Fall der Unverfügbarkeit der Leistung
          unverzüglich informieren. amanzii haftet nicht für entstandene Gefährdung oder
          Schäden gem. § 7 dieser AGB.
        </Typography>
        <Typography paragraph>
          Entstehen amanzii Schäden durch von Ihnen verschuldete Verhaltensweisen und den
          Verstoß gegen diese allgemeinen Geschäftsbedingungen, so sind Sie damit
          einverstanden, amanzii in angemessenem Rahmen Ihrer Möglichkeiten vom Schaden
          freizustellen. Dies gilt auch über den Nutzungszeitraum der Plattform hinaus.
        </Typography>
      </Grid>

      <Grid>
        <h2>§ 11 Datenschutz</h2>
        <Typography paragraph>
          amanzii wird sämtliche datenschutzrechtliche Erfordernisse, insbesondere die
          Vorgaben des Telemediengesetzes und der EU-Datenschutzgrundverordnung (DSGVO),
          beachten. Die Daten werden vertraulich behandelt und nicht an Dritte
          weitergegeben, es sei denn, dies ist zur Durchführung und Abwicklung des
          Vertrages erforderlich (Logistikdienstleister, Spedition, Paketdienstleister,
          Zahlungsdienstleister, Steuerberatung). Hinsichtlich der Einzelheiten
          informiert amanzii in den online abrufbaren Datenschutzhinweisen auf
          lumos-lernen.de.
        </Typography>
      </Grid>

      <Grid>
        <h2>§ 12 Schlussbestimmungen</h2>
        <Typography paragraph>
          Vertragssprache ist Deutsch. Es gilt das deutsche Recht unter Ausschluss von
          UN-Kaufrecht. Gerichtsstand und Erfüllungsort: Soweit Sie
        </Typography>
        <Typography paragraph>
          <ul style={{ paddingLeft: "3em" }}>
            <li>
              keinen Geschäftssitz in Deutschland haben, es sei denn Sie sind
              Verbraucher*in oder
            </li>
            <li>
              Unternehmer*in, der/die ein Handelsgewerbe nach den § 1ff HGB betreibt oder
            </li>
            <li>eine Anstalt/juristische Person des öffentlichen Rechts oder</li>
            <li>öffentlich-rechtliches Sondervermögen oder</li>
            <li>
              verlegen Sie nach Vertragsschluss Ihren Wohnsitz oder gewöhnlichen
              Aufenthaltsort von Deutschland ins Ausland oder
            </li>
            <li>
              Ihr Wohn-/Geschäftssitz oder gewöhnlicher Aufenthalt ist im Zeitpunkt der
              Klageerhebung nicht bekannt,
            </li>
          </ul>
        </Typography>
        <Typography paragraph>
          liegt der Erfüllungsort und Gerichtsstand für alle Streitigkeiten zwischen
          amanzii und ihren Kunden beim sachlich zuständigen Gericht für den Sitz von
          amanzii. Wir sind ebenfalls berechtigt, Sie an Ihrem allgemeinen Gerichtsstand
          zu verklagen.
        </Typography>
      </Grid>

      <Grid>
        <h2>Salvatorische Klausel</h2>
        <Typography paragraph>
          Sollte eine Bestimmung dieser Bedingungen nichtig sein oder werden, so bleiben
          die Bedingungen im Übrigen wirksam. amanzii und Sie werden die nichtige
          Bestimmung durch eine solche wirksame ersetzen, die dem Willen von uns
          wirtschaftlich am nächsten kommt.
        </Typography>
        <Typography>Stand der AGB: 01.10.2024</Typography>
      </Grid>
    </Grid>
  );
};
