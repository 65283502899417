import { faTools } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@material-ui/core";
import * as React from "react";
import HeadingLumos from "../../components/theming/HeadingLumos";
import styles from "./../../styles/custom-styles.module.scss";
import { useTranslation } from "react-i18next";

interface MaintenanceProps {}

export const MaintenancePage: React.FC<MaintenanceProps> = (props) => {
  const { t } = useTranslation(["errorPages", "snackbars"]);
  return (
    <>
      <HeadingLumos>{t("maintenance.title", { ns: "errorPages" })}</HeadingLumos>
      <Box
        minHeight={"40vh"}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        style={{ color: styles.secondary }}
      >
        <FontAwesomeIcon
          icon={faTools}
          size="6x"
          color={styles.orange}
          style={{ marginBottom: "0.5em" }}
        />
        <Typography variant="h2" style={{ textAlign: "center" }}>
          <strong>
            {t("maintenance.detail1", { ns: "errorPages" })}
            <br />
            {t("maintenance.detail2", { ns: "errorPages" })}
          </strong>
        </Typography>
      </Box>
    </>
  );
};
