import { Button, PropTypes } from "@material-ui/core";
import React from "react";

interface EventItemBookingButtonProps {
  id: string;
  disabled: boolean;
  color: PropTypes.Color;
  text: string;
  action?: (event?: any) => void;
}

export const EventItemBookingButton: React.FC<EventItemBookingButtonProps> = (props) => {
  return (
    <Button
      id={props.id}
      disabled={props.disabled}
      onClick={props.action}
      size="medium"
      variant="contained"
      color={props.color}
      fullWidth
      style={{ marginLeft: "auto" }}
    >
      {props.text}
    </Button>
  );
};
