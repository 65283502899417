import { ELearningData } from "../../models/elearning";

export interface ELearningState {
  addedELearning: ELearningData;
  // editEvent: ELearningDto;
  // editEventLoaded: boolean;
  isLoading: boolean;
  error: boolean;
}

// general actions
export const ELEARNING_REQUEST = "ELEARNING_REQUEST";
export const ELEARNING_FAILURE = "ELEARNING_FAILURE";
export const FINISH_FETCHING_ELEARNING = "FINISH_FETCHING_ELEARNING";

// create / update elearning detail
export const ADD_ELEARNING_SUCCESS = "ADD_ELEARNING_SUCCESS";

// actions that are the same for all api calls (request and failure)
export interface ELearningRequestAction {
  type: typeof ELEARNING_REQUEST;
}

export interface ELearningFailureAction {
  type: typeof ELEARNING_FAILURE;
  throwError: boolean;
}

export interface ELearningFetchingFinishedAction {
  type: typeof FINISH_FETCHING_ELEARNING;
}

// other event actions

export interface AddELearningSuccessAction {
  type: typeof ADD_ELEARNING_SUCCESS;
  data: ELearningData;
}

export type ELearningActionTypes =
  | ELearningRequestAction
  | ELearningFailureAction
  | ELearningFetchingFinishedAction
  | AddELearningSuccessAction;
