import React from "react";
import { Button, Grid } from "@material-ui/core";
import "./CheckoutButtons.scss";
import { useHistory } from "react-router-dom";
import routes from "../../../../../routing/routes";
import { GridSize } from "@material-ui/core";
import { useTranslation } from "react-i18next";

interface CheckoutButtonsProps {
  isPaypal: boolean;
  isLoading: boolean;
}

export const CheckoutButtons: React.FC<CheckoutButtonsProps> = ({
  isPaypal,
  isLoading,
}) => {
  const { t } = useTranslation(["booking", "common", "snackbars"]);
  const history = useHistory();

  var justify: string = "space-between";
  if (isPaypal) {
    justify = "center";
  }

  const backButtonSizeBoth: GridSize = 9;
  const backButtonSizePaypalOnly: GridSize = 3;

  var backButtonSize: GridSize = backButtonSizePaypalOnly;

  if (!isPaypal) {
    backButtonSize = backButtonSizeBoth;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Grid container spacing={2} style={{ justifyContent: justify }}>
        <Grid item xs={backButtonSize} sm={backButtonSize} md={backButtonSize} lg={3}>
          <Button
            id="forms-buttons-back"
            size="medium"
            variant="contained"
            color="secondary"
            onClick={() => {
              history.push(routes.checkout);
            }}
            style={{ width: "100%" }}
          >
            {t("forms.buttons.back", { ns: "common" })}
          </Button>
        </Grid>
        {!isPaypal && (
          <Grid item xs={9} sm={9} md={9} lg={4}>
            <Button
              id="after-checkout"
              size="medium"
              variant="contained"
              color="primary"
              disabled={isLoading}
              type={"submit"}
              style={{ width: "100%" }}
            >
              {t("afterCheckout.completeBooking", { ns: "booking" })}
            </Button>
          </Grid>
        )}
      </Grid>
    </div>
  );
};
