import { Overrides } from "@material-ui/core/styles/overrides";
import { PaletteOptions } from "@material-ui/core/styles/createPalette";
import {
  borderDefault,
  borderFocusMono,
  borderMono,
  validationErrorPrefixMono,
} from "./sharedThemeVariables";

export const monoPalette: PaletteOptions = {
  background: {
    default: "#fff",
  },
  primary: {
    main: "#000",
  },
  secondary: {
    main: "#fff",
  },
  text: {
    primary: "#000",
    disabled: "#000",
  },
  error: {
    main: "#000",
  },
  success: {
    main: "#000",
  },
  contrastThreshold: 2.5,
};

export const monoOverrides = {
  MuiTooltip: {
    tooltip: {
      color: "#fff",
      backgroundColor: "#000",
    },
    arrow: {
      color: "#000",
      backgroundColor: "#fff",
    },
  },
  MUIRichTextEditor: {
    root: {
      marginTop: 20,
      width: "100%",
      border: ".0625rem solid rgba(0, 0, 0, 0.12)",
    },
    container: {
      width: "100%",
    },
    editor: {
      backgroundColor: "#fff",
      borderTop: ".0625rem solid #000",
      padding: "1.25rem",
      height: "18.75rem",
      maxHeight: "18.75rem",
      overflow: "auto",
    },
    placeHolder: {
      padding: "1.25rem",
    },
  },
  MuiSvgIcon: {
    root: {
      fontSize: "1.5rem",
    },
  },
  draftEditorProps: {
    spellCheck: true,
    stripPastedStyles: true,
  },
  MuiCheckbox: {
    root: {
      border: ".125rem solid #a2a2a2",
      backgroundColor: "#fff",
      borderRadius: 0,
      width: 32,
      height: 32,
      transition: "opacity 300ms, border 300ms",
      "&$checked": {
        color: "#000",
      },
    },
  },
  MuiToggleButton: {
    root: {
      border: ".0625rem solid #000",
      color: "#000",
      "&:hover": {
        backgroundColor: "#000",
        color: "#fff",
      },
      "&$selected": {
        color: "#fff",
        backgroundColor: "#000",
        "&:hover": {
          backgroundColor: "#fff",
          color: "#000",
        },
      },
    },
  },
  MuiTypography: {
    gutterBottom: {
      marginBottom: 16,
    },
  },
  MuiSelect: {
    icon: {
      transition: "ease-in-out 0.2s",
      transform: "rotate(90deg)",
    },
    iconOpen: {
      transition: "ease-in-out 0.2s",
      transform: "rotate(-90deg)",
    },
  },
  MuiListItem: {
    root: {
      height: "3.125rem",
      "&$selected": {
        color: "#fff",
        backgroundColor: "#000",
        "&:hover": {
          backgroundColor: "#000",
          color: "#fff",
        },
      },
    },
    button: {
      "&:hover": {
        backgroundColor: "#000",
        color: "#fff",
      },
    },
  },
  MuiButton: {
    root: {
      border: ".0625rem solid #000",
      minWidth: ".75rem",
      borderRadius: ".0625rem",
      textTransform: "none",
    },
    containedPrimary: {
      "&:hover": {
        backgroundColor: "#fff",
        color: "#000",
      },
    },
    containedSecondary: {
      "&:hover": {
        backgroundColor: "#000",
        color: "#fff",
      },
    },
    contained: {
      "&$disabled": {
        backgroundColor: "#777",
        color: "#fafafa",
      },
    },
  },
  MuiIconButton: {
    root: {
      color: "#000",
    },
  },
  MuiBadge: {
    colorPrimary: {
      color: "#000",
      backgroundColor: "#fff",
      border: ".0625rem solid #000",
    },
  },
  MuiInputLabel: {
    filled: {
      transform: "translate(.75rem, 1.4375rem) scale(1)",
    },
    formControl: {
      left: ".5rem",
      top: "-0.1875rem",
    },
  },
  MuiFormLabel: {
    root: {
      color: "#000",
      "&$focused": {
        color: "#000",
      },
    },
  },
  MuiInputAdornment: {
    positionEnd: {
      marginTop: "0rem",
      marginRight: ".625rem",
    },
  },
  MuiFilledInput: {
    root: {
      borderTopLeftRadius: "0rem",
      borderTopRightRadius: "0rem",
      backgroundColor: "#fff",
      border: ".0625rem solid #000",
      borderRadius: ".0625rem",
      "&:hover": {
        backgroundColor: "#fff",
      },
      "&$focused": {
        backgroundColor: "#fff",
        borderColor: "#000",
      },
      "&$disabled": {
        backgroundColor: "#fafafa",
      },
      "&$error": {
        "&::before": validationErrorPrefixMono,
        backgroundColor: "#fff",
        color: "#000",
        "&:hover": {
          "&:before": validationErrorPrefixMono,
          "&:after": borderMono,
        },
      },
    },
    multiline: {
      padding: "1.25rem 1.25rem",
    },
    input: {
      padding: "1.25rem 1.25rem",
    },
    underline: {
      "&:before": borderDefault,
      "&:after": borderFocusMono,
      "&:hover": {
        "&:before": borderDefault,
        "&:after": borderFocusMono,
      },
      "&$disabled": {
        "&:before": {
          borderBottomStyle: "solid",
        },
      },
    },
  },
  MuiLink: {
    root: {
      color: "#000",
      "&:hover": {
        color: "#000",
        borderBottom: ".125rem solid #000",
      },
    },
  },
  MuiTabs: {
    indicator: {
      backgroundColor: "#fff",
    },
  },
  MuiTab: {
    root: {
      "@media (min-width: 37.5rem)": {
        minWidth: "9.25rem",
      },
      backgroundColor: "#fff",
      "&$selected": {
        backgroundColor: "#000",
        color: "#fff",
      },
    },
  },
  MuiDialogContentText: {
    root: {
      color: "var(--text-color)",
    },
  },
} as Overrides;
