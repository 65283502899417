import { NIL as NIL_UUID } from "uuid";
import {
  OrganizationInvoiceState,
  OrganizationInvoiceActionTypes,
  SET_ORGANIZATION_INVOICE_DATA,
  ORGANIZATION_INVOICE_REQUEST,
  ORGANIZATION_INVOICE_FAILURE,
} from "./types";
import { OrganizationInvoiceData } from "../../models/organizationInvoiceData";
/**
 * The initial state for the organization invoice
 * @type {OrganizationInvoiceState}
 * @property {OrganizationInvoiceData} organizationInvoiceData - The organization invoice data
 * @property {boolean} isLoading - The loading state
 */
export const initialOrganizationInvoiceState: OrganizationInvoiceState = {
  organizationInvoiceData: [],
  isLoading: false,
};

/**
 * The organization invoice reducer
 * @param {OrganizationInvoiceState} state - The organization invoice state
 * @param {OrganizationInvoiceActionTypes} action - The action to perform
 * @returns {OrganizationInvoiceState}
 */
export function organizationInvoiceReducer(
  state = initialOrganizationInvoiceState,
  action: OrganizationInvoiceActionTypes
): OrganizationInvoiceState {
  switch (action.type) {
    case ORGANIZATION_INVOICE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ORGANIZATION_INVOICE_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case SET_ORGANIZATION_INVOICE_DATA:
      return {
        ...state,
        organizationInvoiceData: action.invoice,
        isLoading: false,
      };
    default:
      return state;
  }
}
