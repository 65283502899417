import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ELearningListData } from "../../../../../models/elearning";
import {
  EventStatus,
  mapStatusIdToStatusVerb,
} from "../../../../../models/enums/eventStatus.enum";
import {
  mapToTrainingTypeString,
  mapTrainingTypeToArticleWithNoun,
  mapTrainingTypeToArticleWithNounPossessiveCase,
  TrainingType,
} from "../../../../../models/enums/trainingType.enum";
import { AppState } from "../../../../../redux";
import { showDialog } from "../../../../../redux/dialogs/actions";
import { editEventStatus } from "../../../../../redux/events/actions";
import usePermission from "../../../../../services/usePermissions";
import { checkIfCompleteEventIsApproved } from "../../shared/checkIfCompleteEventIsApproved";

interface EventItemStatusDropdownProps {
  event: ELearningListData;
}

//!NOTE: don't use CustomSelect because this component needs to be inside of a form (tests fail because of undefined formik props)
export const EventItemStatusDropdown: React.FC<EventItemStatusDropdownProps> = (
  props
) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state: AppState) => state.user.currentUser);
  const { getEventStatusForSelectBasedOnUserType, getDisabledStatusSelectState } =
    usePermission(currentUser?.user_type, props.event.status);

  const options = getEventStatusForSelectBasedOnUserType(props.event.training_type);
  const trainingType = mapToTrainingTypeString(props.event.training_type);

  return (
    <>
      <FormControl fullWidth variant="filled">
        <InputLabel id={"event-status-label"}></InputLabel>
        <Select
          disabled={getDisabledStatusSelectState()}
          name="status"
          id="event-status"
          value={props.event.status}
          onChange={(e: React.ChangeEvent<any>) => {
            if (checkIfCompleteEventIsApproved(e, props.event, dispatch)) {
              if (
                (!props.event.evaluation_link || !props.event.evaluation_link_speaker) &&
                e.target.value === EventStatus.Completed
              ) {
                dispatch(
                  showDialog({
                    title: `${trainingType} abschließen`,
                    message: (
                      <>
                        Sind Sie sich sicher, dass Sie{" "}
                        {mapTrainingTypeToArticleWithNoun(
                          props.event.training_type,
                          trainingType
                        )}{" "}
                        abschließen möchten?
                        <br />
                        Dieser Vorgang kann nicht wieder rückgängig gemacht werden.
                        <br />
                        <br />
                        <b>
                          Achtung, die Eingabe der Evaluationslinks ist nicht
                          vollständig.
                          <br />
                          Überprüfen Sie folgende Links:
                          <br />
                          {!props.event.evaluation_link_blended_learning &&
                            props.event.training_type ===
                              TrainingType.BlendedLearning && (
                              <>
                                - Es wurde noch kein Evaluationslink für den Teilnehmer
                                angegeben (E-Learning-Teil des Blended-Learnings)
                                <br />
                              </>
                            )}
                          {!props.event.evaluation_link && (
                            <>
                              - Es wurde noch kein Evaluationslink für den Teilnehmer
                              angegeben <br />
                            </>
                          )}
                          {!props.event.evaluation_link_speaker &&
                            props.event.training_type !== TrainingType.ELearning && (
                              <>
                                - Es wurde noch kein Evaluationslink für den Referenten
                                angegeben <br />
                              </>
                            )}
                          <br />
                        </b>
                        Die Evaluationslinks können im Tab "Sonstiges" ergänzt werden.
                        <br />
                        <br />
                        Wenn Sie ohne Eingabe des Links fortfahren wollen, klicken Sie
                        auf "Trotzdem abschließen". In diesem Fall wird den
                        entsprechenden Nutzern nach Abschluss{" "}
                        {mapTrainingTypeToArticleWithNounPossessiveCase(
                          props.event.training_type,
                          trainingType
                        )}{" "}
                        kein Link zur Durchführung der Evaluation angezeigt.
                        <br />
                      </>
                    ),
                    action: () => {
                      dispatch(editEventStatus(e.target.value, props.event.id));
                    },
                    confirmButtonText: "Trotzdem abschließen",
                  })
                );
              } else {
                dispatch(
                  showDialog({
                    title: `Status ${mapTrainingTypeToArticleWithNounPossessiveCase(
                      props.event.training_type,
                      trainingType
                    )} ändern`,
                    message:
                      `Sind Sie sich sicher, dass Sie ${mapTrainingTypeToArticleWithNoun(
                        props.event.training_type,
                        trainingType
                      )} ${mapStatusIdToStatusVerb(e.target.value)} möchten? ` +
                      ([EventStatus.Completed, EventStatus.Cancelled].includes(
                        e.target.value
                      )
                        ? "Dieser Vorgang kann nicht wieder rückgängig gemacht werden."
                        : ""),
                    action: () => {
                      dispatch(editEventStatus(e.target.value, props.event.id));
                    },
                  })
                );
              }
            }
          }}
          MenuProps={{
            disableAutoFocus: true,
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
          }}
        >
          {options?.map((option) => (
            <MenuItem key={option.key} value={option.value} disabled={!!option.disabled}>
              {option.label ? option.label : option.value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

// TODO no translation yet
