import { BaseCrudService } from "./baseCrudService";
import apiRoutes from "./apiRoutes";
import {
  MembershipDocumentReponseDto,
  MembershipDocumentRequestDto,
  MembershipDocumentType,
} from "../models/groupMembership";
import { AxiosResponse } from "axios";

export class GroupMembershipService extends BaseCrudService {
  getFileAsDataUrl(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (typeof reader.result === "string") {
          resolve(reader.result);
        }
        reject(reader.result);
      };
      reader.readAsDataURL(file);
    });
  }
  async submitMembershipDokument(
    file: File | string,
    document_type: MembershipDocumentType | string,
    valid_until: string | null,
    document_has_no_expiry_date: boolean
  ) {
    let dataUrl = "";
    if (file instanceof File) {
      dataUrl = await this.getFileAsDataUrl(file);
    }
    const data: MembershipDocumentRequestDto = {
      file: dataUrl,
      document_type,
      valid_until,
      document_has_no_expiry_date,
    };
    return this.create<
      MembershipDocumentRequestDto,
      AxiosResponse<MembershipDocumentReponseDto>
    >(apiRoutes.group_membership_document, data);
  }

  deleteMembershipDocument(id: string) {
    return this.delete(apiRoutes.group_membership_document, id);
  }

  fetchAll(): Promise<AxiosResponse<MembershipDocumentReponseDto[]>> {
    return this.getAll(apiRoutes.group_membership_document);
  }
}
