import { ELearningData, ELearningListData } from "../../models/elearning";
import {
  CrudEventDataDto,
  EventFilterData,
  EventListData,
  UserLinkedAccountStats,
} from "../../models/eventData";
import { Pagination } from "../../models/pagination";
import { ELearningDto } from "./../../models/elearning";
import { EventFilterValues } from "./../../models/eventData";

export interface EventState {
  eventList: Pagination<EventListData>[];
  currentPage: number;
  hasMoreItemsToLoad: boolean;
  addEvent: CrudEventDataDto;
  editEvent: ELearningDto;
  editEventLoaded: boolean;
  isLoading: boolean;
  filterValues: EventFilterValues;
  filterData: EventFilterData;
  error: boolean;
  notFoundError: boolean;
}

// general actions
export const EVENTS_REQUEST = "EVENTS_REQUEST";
export const EVENTS_FAILURE = "EVENTS_FAILURE";

// event list
export const SET_LIST_OF_EVENTS_SUCCESS = "SET_LIST_OF_EVENTS_SUCCESS";
export const ADD_TO_LIST_OF_EVENTS_SUCCESS = "ADD_TO_LIST_OF_EVENTS_SUCCESS";
export const REFETCH_EVENTS_PAGE_SUCCESS = "REFETCH_EVENTS_PAGE_SUCCESS";

export const SET_EVENTS_PAGE_COUNT = "SET_EVENTS_PAGE_COUNT";
export const SET_HAS_MORE_EVENTS = "SET_HAS_MORE_EVENTS";
export const SET_FILTER_VALUES = "SET_FILTER_VALUES";
export const SET_FILTER_DATA = "SET_FILTER_DATA";

export const CLEAR_EVENT_LIST = "CLEAR_EVENT_LIST";

// create / update event detail
export const ADD_EVENT_SUCCESS = "ADD_EVENT_SUCCESS";
export const DELETE_EVENT_SUCCESS = "DELETE_EVENT_SUCCESS";
export const SET_EDIT_EVENT_SUCCESS = "SET_EDIT_EVENT_SUCCESS";
export const EDIT_EVENT_STATUS_SUCCESS = "EDIT_EVENT_STATUS_SUCCESS";
export const CLEAR_EDIT_EVENT = "CLEAR_EDIT_EVENT";

export const SET_EDIT_EVENT_INVOICE = "SET_EDIT_EVENT_INVOICE";

export const SET_EVENT_FAVORITE_STATUS = "SET_EVENT_FAVORITE_STATUS";

export const INCREMENT_WAITING_LIST_IN_EVENT = "INCREMENT_WAITING_LIST_IN_EVENT";

// actions that are the same for all api calls (request and failure)
export interface EventsRequestAction {
  type: typeof EVENTS_REQUEST;
}

export interface EventsFailureAction {
  type: typeof EVENTS_FAILURE;
  notFoundError: boolean;
}

// other event actions
export interface SetListOfEventsSuccessAction {
  type: typeof SET_LIST_OF_EVENTS_SUCCESS;
  data: Pagination<EventListData | ELearningListData>;
}

export interface AddToListOfEventsSuccessAction {
  type: typeof ADD_TO_LIST_OF_EVENTS_SUCCESS;
  data: Pagination<EventListData>;
}

export interface RefetchEventsPageSuccessAction {
  type: typeof REFETCH_EVENTS_PAGE_SUCCESS;
  page: number;
  data: Pagination<EventListData>;
}

export interface AddEventSuccessAction {
  type: typeof ADD_EVENT_SUCCESS;
  data: CrudEventDataDto | ELearningData;
}

export interface DeleteEventSuccessAction {
  type: typeof DELETE_EVENT_SUCCESS;
  eventId: string;
}

export interface SetEditEventSuccessAction {
  type: typeof SET_EDIT_EVENT_SUCCESS;
  data: ELearningDto;
}

export interface EditEventStatusSuccessAction {
  type: typeof EDIT_EVENT_STATUS_SUCCESS;
  eventId: string;
  eventStatus: number;
}

export interface ClearEditEventAction {
  type: typeof CLEAR_EDIT_EVENT;
}

export interface SetEditEventInvoiceAction {
  type: typeof SET_EDIT_EVENT_INVOICE;
}

export interface SetEventFavoriteStatusAction {
  type: typeof SET_EVENT_FAVORITE_STATUS;
  eventId: string;
  status: boolean;
}

export interface ClearEventListAction {
  type: typeof CLEAR_EVENT_LIST;
}

export interface SetPageCountAction {
  type: typeof SET_EVENTS_PAGE_COUNT;
  pageCount: number;
}

export interface SetHasMoreEventsAction {
  type: typeof SET_HAS_MORE_EVENTS;
  hasMoreEvents: boolean;
}

export interface IncrementWaitingListInEventAction {
  type: typeof INCREMENT_WAITING_LIST_IN_EVENT;
  eventId: string;
  linkedUser: UserLinkedAccountStats | null;
}

export interface SetFilterValuesAction {
  type: typeof SET_FILTER_VALUES;
  filterValues: EventFilterValues;
}

export interface SetFilterDataAction {
  type: typeof SET_FILTER_DATA;
  filterData: EventFilterData;
}

export type EventActionTypes =
  | EventsRequestAction
  | SetListOfEventsSuccessAction
  | AddToListOfEventsSuccessAction
  | RefetchEventsPageSuccessAction
  | EventsFailureAction
  | AddEventSuccessAction
  | DeleteEventSuccessAction
  | SetEditEventSuccessAction
  | EditEventStatusSuccessAction
  | ClearEditEventAction
  | SetEditEventInvoiceAction
  | SetEventFavoriteStatusAction
  | ClearEventListAction
  | SetPageCountAction
  | SetHasMoreEventsAction
  | IncrementWaitingListInEventAction
  | SetFilterValuesAction
  | SetFilterDataAction;
