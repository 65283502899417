import thunkMiddleware from "redux-thunk";
import { Action, applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import accessibilityReducer from "./accessibility/reducers";
import { AccessibilityState } from "./accessibility/types";
import authReducer from "./auth/reducers";
import { AuthState, LOGOUT } from "./auth/types";
import billingAddressReducer from "./billingAddress/reducers";
import { BillingAddressState } from "./billingAddress/types";
import bookingReducer from "./bookings/reducers";
import { BookingState } from "./bookings/types";
import cartReducer from "./cart/reducers";
import { CartState } from "./cart/types";
import checklistReducer from "./checklist/reducers";
import { ChecklistState } from "./checklist/types";
import contactReducer from "./contact/reducers";
import { ContactState } from "./contact/types";
import dialogReducer from "./dialogs/reducers";
import { DialogState } from "./dialogs/types";
import documentsReducer from "./documents/reducers";
import { DocumentsState } from "./documents/types";
import elearningReducer from "./e-learnings/reducers";
import { ELearningState } from "./e-learnings/types";
import eventReducer from "./events/reducers";
import { EventState } from "./events/types";
import favoriteReducer from "./favorites/reducers";
import { FavoriteState } from "./favorites/types";
import lecturesReducer from "./lectures/reducers";
import { LecturesState } from "./lectures/types";
import linkAccountReducer from "./linkAccount/reducers";
import { LinkAccountState } from "./linkAccount/types";
import participantReducer from "./participants/reducers";
import { ParticipantState } from "./participants/types";
import pinboardCommentReducer from "./pinboard-answer/reducers";
import { PinboardCommentState } from "./pinboard-answer/types";
import pinboardTopicReducer from "./pinboard-question/reducers";
import { PinboardTopicState } from "./pinboard-question/types";
import pinboardReducer from "./pinboard/reducers";
import { PinboardState } from "./pinboard/types";
import previewReducer from "./preview/reducers";
import { PreviewState } from "./preview/types";
import speakersReducer from "./speaker/reducers";
import { SpeakersState } from "./speaker/types";
import tabsReducer from "./tabs/reducers";
import { TabsState } from "./tabs/types";
import targetGroupReducer from "./target-groups/reducers";
import { TargetGroupState } from "./target-groups/types";
import tasksReducer from "./tasks/reducers";
import { TasksState } from "./tasks/types";
import eventTemplatesReducer from "./templates/events/reducers";
import { EventTemplatesState } from "./templates/events/types";
import taskTemplatesReducer from "./templates/tasks/reducers";
import { TaskTemplatesState } from "./templates/tasks/types";
import userReducer from "./users/reducers";
import { UserState } from "./users/types";
import waitingListReducer from "./waitinglist/reducers";
import { WaitingListState } from "./waitinglist/types";
import { DashboardState } from "./dashboard/types";
import dashboardReducer from "./dashboard/reducers";
import { ActivityHistoryUserState } from "./activityHistory/types";
import { activityHistoryReducer } from "./activityHistory/reducers";
import { VideoAuthenticationState } from "./videoAuthentication/types";
import { videoAuthenticationReducer } from "./videoAuthentication/reducers";
import groupMembershipDocumentReducer from "./group-membership-documents/reducers";
import { GroupMembershipDocumentState } from "./group-membership-documents/types";
import { DiscountState } from "./discounts/types";
import { discountReducer } from "./discounts/reducers";
import paypalReducer from "./paypal/reducers";
import { PaypalOrderState } from "./paypal/types";
import orderReducer from "./orders/reducers";
import { OrderState } from "./orders/types";
import invoiceReducer from "./invoices/reducers";
import { InvoiceState } from "./invoices/types";
import { CertificateTypeState } from "./certificateType/types";
import { certificateTypeReducer } from "./certificateType/reducers";
import organizationReducer from "./organization/reducers";
import { OrganizationState } from "./organization/types";
import organizationTypeReducer from "./organizationType/reducers";
import { OrganizationTypeState } from "./organizationType/types";
import { membershipReducer } from "./organizationMembership/reducers";
import accountInvitationReducer from "./accountInvitation/reducers";
import { MembershipState } from "./organizationMembership/types";
import { AccountInvitationState } from "./accountInvitation/types";
import { GroupMembershipDocumentTypeState } from "./groupMembershipDocumentType/types";
import groupMembershipDocumentTypeReducer from "./groupMembershipDocumentType/reducers";
import { organizationInvoiceReducer } from "./organizationInvoices/reducers";
import { OrganizationInvoiceState } from "./organizationInvoices/types";
import { MaintenanceBannerState } from "./maintenance/types";
import maintenanceReducer from "./maintenance/reducers";
import { MinimumAgeState } from "./system-settings/types";
import minimumAgeReducer from "./system-settings/reducers";
import { PaymentMethodState } from "./paymentMethod/types";
import paymentMethodReducer from "./paymentMethod/reducers";
import { organizationEventParticipationsReducer } from "./organizationEventParticipations/reducers";
import { OrganizationEventParticipationsState } from "./organizationEventParticipations/types";

// interface that defines the types of the states of all reducers
export interface AppReducers {
  user: UserState;
  accessibility: AccessibilityState;
  auth: AuthState;
  booking: BookingState;
  billingAddress: BillingAddressState;
  cart: CartState;
  checklist: ChecklistState;
  contact: ContactState;
  dialog: DialogState;
  documents: DocumentsState;
  event: EventState;
  elearning: ELearningState;
  favorite: FavoriteState;
  lectures: LecturesState;
  linkAccount: LinkAccountState;
  participant: ParticipantState;
  preview: PreviewState;
  speakers: SpeakersState;
  waitingList: WaitingListState;
  tasks: TasksState;
  eventTemplates: EventTemplatesState;
  taskTemplates: TaskTemplatesState;
  tabs: TabsState;
  organizationEventParticipations: OrganizationEventParticipationsState;
  organizationInvoice: OrganizationInvoiceState;
  targetGroups: TargetGroupState;
  pinboard: PinboardState;
  pinboardTopic: PinboardTopicState;
  pinboardComment: PinboardCommentState;
  dashboard: DashboardState;
  activityHistory: ActivityHistoryUserState;
  videoAuthentication: VideoAuthenticationState;
  groupMemberShipDocuments: GroupMembershipDocumentState;
  groupMemberShipDocumentTypes: GroupMembershipDocumentTypeState;
  discount: DiscountState;
  paypal: PaypalOrderState;
  order: OrderState;
  invoices: InvoiceState;
  certificateTypes: CertificateTypeState;
  organizations: OrganizationState;
  organizationTypes: OrganizationTypeState;
  memberships: MembershipState;
  accountInvitations: AccountInvitationState;
  maintenance: MaintenanceBannerState;
  minimumAge: MinimumAgeState;
  paymentMethods: PaymentMethodState;
}

const appReducer = combineReducers<AppReducers>({
  user: userReducer,
  accessibility: accessibilityReducer,
  auth: authReducer,
  booking: bookingReducer,
  billingAddress: billingAddressReducer,
  cart: cartReducer,
  checklist: checklistReducer,
  contact: contactReducer,
  dialog: dialogReducer,
  documents: documentsReducer,
  event: eventReducer,
  elearning: elearningReducer,
  favorite: favoriteReducer,
  lectures: lecturesReducer,
  linkAccount: linkAccountReducer,
  participant: participantReducer,
  preview: previewReducer,
  speakers: speakersReducer,
  waitingList: waitingListReducer,
  tasks: tasksReducer,
  organizationEventParticipations: organizationEventParticipationsReducer,
  organizationInvoice: organizationInvoiceReducer,
  eventTemplates: eventTemplatesReducer,
  taskTemplates: taskTemplatesReducer,
  tabs: tabsReducer,
  targetGroups: targetGroupReducer,
  pinboard: pinboardReducer,
  pinboardTopic: pinboardTopicReducer,
  pinboardComment: pinboardCommentReducer,
  dashboard: dashboardReducer,
  activityHistory: activityHistoryReducer,
  videoAuthentication: videoAuthenticationReducer,
  groupMemberShipDocuments: groupMembershipDocumentReducer,
  discount: discountReducer,
  paypal: paypalReducer,
  order: orderReducer,
  invoices: invoiceReducer,
  certificateTypes: certificateTypeReducer,
  organizations: organizationReducer,
  organizationTypes: organizationTypeReducer,
  memberships: membershipReducer,
  accountInvitations: accountInvitationReducer,
  groupMemberShipDocumentTypes: groupMembershipDocumentTypeReducer,
  maintenance: maintenanceReducer,
  minimumAge: minimumAgeReducer,
  paymentMethods: paymentMethodReducer,
});

function rootReducer(state: any, action: Action) {
  // ! sets initialState (by passing undefined) in all reducers if the user logs out
  return appReducer(action.type === LOGOUT ? undefined : state, action);
}

export type AppState = ReturnType<typeof appReducer>;

export function configureStore() {
  const middlewares = [thunkMiddleware];
  const middleWareEnhancer = applyMiddleware(...middlewares);

  const store = createStore(rootReducer, composeWithDevTools(middleWareEnhancer));

  return store;
}

export const store = configureStore(); // make accessible in entire application
