import { useState } from "react";
import { FileObject } from "material-ui-dropzone";
import { useTranslation } from "react-i18next";

export interface MembershipInviteFormData {
  first_name: string;
  last_name: string;
  email: string;
  organization_id: string;
  status?: string;
}

// Email validation regex pattern
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

/**
 * A custom hook for validating CSV files containing membership invitation data.
 * @returns {{
 *  loading: boolean,
 *  validInvitations: MembershipInviteFormData[],
 *  invalidInvitations: string[],
 *  validateCsv: (csvFiles: FileObject[], organizationId: string) => Promise<void>,
 *  setValidInvitations: React.Dispatch<React.SetStateAction<MembershipInviteFormData[]>>
 * }} The custom hook for CSV validation.
 */
export default function useCsvInviteValidation() {
  const { t } = useTranslation(["organization"]);
  const [loading, setLoading] = useState(false);
  const [validInvitations, setValidInvitations] = useState<MembershipInviteFormData[]>(
    []
  );
  const [invalidInvitations, setInvalidInvitations] = useState<string[]>([]);
  const emails = new Set<string>();
  /**
   * Checks the validity of the format of the CSV data.
   * @param {string[]} columnData - The data in each column of the CSV row.
   * @param {number} rowIndex - The index of the CSV row being validated.
   * @param {string} organizationId - The organization ID associated with the invitations.
   */
  const checkFormatValidity = (
    columnData: string[],
    rowIndex: number,
    organizationId: string
  ) => {
    // Check if all columns are empty, if so, skip this row
    if (columnData.every((col) => !col.trim())) {
      return;
    }

    let errorMessages: string[] = [];

    // Check for error fields
    if (!columnData[0]?.trim()) {
      errorMessages.push(t("csv.firstNameRequired", { ns: "organization" }));
    }
    if (!columnData[1]?.trim()) {
      errorMessages.push(t("csv.lastNameRequired", { ns: "organization" }));
    }
    if (!columnData[2]?.trim()) {
      errorMessages.push(t("csv.emailRequired", { ns: "organization" }));
    } else if (!emailRegex.test(columnData[2])) {
      errorMessages.push(t("csv.emailInvalid", { ns: "organization" }));
    } else if (emails.has(columnData[2].toLowerCase())) {
      errorMessages.push(t("csv.emailDuplicate", { ns: "organization" }));
    }

    if (errorMessages.length > 0) {
      setInvalidInvitations((prev) => [
        ...prev,
        `${t("csv.row", { ns: "organization" })} ${rowIndex + 1}: ${errorMessages.join(" ")} (${columnData.join(" ")})\n`,
      ]);
    } else {
      emails.add(columnData[2].toLowerCase());
      setValidInvitations((prev) => [
        ...prev,
        {
          first_name: columnData[0],
          last_name: columnData[1],
          email: columnData[2],
          organization_id: organizationId,
          status: "",
        },
      ]);
    }
  };

  /**
   * Validates the CSV files containing membership invitation data.
   * @param {FileObject[]} csvFiles - The CSV files to be validated.
   * @param {string} organizationId - The organization ID associated with the invitations.
   */
  const validateCsv = async (csvFiles: FileObject[], organizationId: string) => {
    setInvalidInvitations([]);
    setLoading(true);
    if (csvFiles?.length > 0) {
      const reader = new FileReader();
      const file = csvFiles[0].file;
      reader.onload = (event) => {
        const csvData = event.target?.result as string;
        const cleanedCsvData = csvData.replace(/\r/g, "");
        const rows = cleanedCsvData.split("\n"); // Split by newline to get rows

        // Detect if the first row is a header
        let startRow = 0;
        const firstRowColumns = rows[0].split(/[;,]/).map((col) => col.trim());
        const headerColumnsSet1 = ["firstname", "lastname", "email"];
        const headerColumnsSet2 = ["name", "vorname", "email"];

        if (
          headerColumnsSet1.every((col) => firstRowColumns.includes(col)) ||
          headerColumnsSet2.every((col) => firstRowColumns.includes(col))
        ) {
          // If the first row contains header columns, skip it
          startRow = 1;
        }

        // Track if any valid rows are found
        let hasValidRows = false;

        // Start processing from the detected row
        rows.slice(startRow).forEach((row, index) => {
          // Split by comma or semicolon and trim whitespace
          const columns = row.split(/[;,]/).map((col) => col.trim());
          if (columns.some((col) => col.trim() !== "")) {
            // Only check rows that are not completely empty
            checkFormatValidity(columns, index + startRow, organizationId);
            hasValidRows = true;
          }
        });
        if (!hasValidRows) {
          setInvalidInvitations([t("csv.emptyFile", { ns: "organization" })]);
        }
      };
      reader.readAsText(file);
    }
    setLoading(false);
  };

  return {
    loading,
    validInvitations,
    invalidInvitations,
    validateCsv,
    setValidInvitations,
  };
}
