import apiRoutes from "./apiRoutes";
import { BaseCrudService } from "./baseCrudService";
import {
  BillingAddressData,
  CrudBillingAddressData,
  UserBillingAddressDto,
} from "../models/billingAddressData";
import { AxiosResponse } from "axios";

export class BillingAddressService extends BaseCrudService {
  async getBillingAddresses() {
    const response = await this.getAll<CrudBillingAddressData[]>(
      apiRoutes.billing_addresses
    );
    return response.data;
  }

  async getBillingAddressesForUser(userId: number) {
    const response = await this.get<UserBillingAddressDto>(
      apiRoutes.users,
      userId + apiRoutes.billing_addresses
    );
    return response.data;
  }

  async createBillingAddress(billingAddress: BillingAddressData) {
    const response = await this.create(apiRoutes.billing_addresses, billingAddress);
    return response;
  }

  async editBillingAddress(id: number, billingAddress: BillingAddressData) {
    const response = await this.edit<
      BillingAddressData,
      AxiosResponse<CrudBillingAddressData>
    >(apiRoutes.billing_addresses, id.toString(), billingAddress);
    return response;
  }
}
