import { AccountInviteData } from "../../models/accountInviteData";

export interface AccountInvitationState {
  accountInvitations: AccountInviteData[];
  isLoading: boolean;
  error: string | null;
}

export const FETCH_ACCOUNT_INVITATIONS_REQUEST = "FETCH_ACCOUNT_INVITATIONS_REQUEST";
export const FETCH_ACCOUNT_INVITATIONS_SUCCESS = "FETCH_ACCOUNT_INVITATIONS_SUCCESS";
export const FETCH_ACCOUNT_INVITATIONS_FAILURE = "FETCH_ACCOUNT_INVITATIONS_FAILURE";
export const REMOVE_ACCOUNT_INVITATION_REQUEST = "REMOVE_ACCOUNT_INVITATION_REQUEST";
export const REMOVE_ACCOUNT_INVITATION_SUCCESS = "REMOVE_ACCOUNT_INVITATION_SUCCESS";
export const REMOVE_ACCOUNT_INVITATION_FAILURE = "REMOVE_ACCOUNT_INVITATION_FAILURE";

export interface FetchInvitationsRequestAction {
  type: typeof FETCH_ACCOUNT_INVITATIONS_REQUEST;
}

export interface FetchInvitationsSuccessAction {
  type: typeof FETCH_ACCOUNT_INVITATIONS_SUCCESS;
  payload: AccountInviteData[];
}

export interface FetchInvitationsFailureAction {
  type: typeof FETCH_ACCOUNT_INVITATIONS_FAILURE;
  error: string;
}

export interface RemoveAccountInvitationRequestAction {
  type: typeof REMOVE_ACCOUNT_INVITATION_REQUEST;
}

export interface RemoveAccountInvitationSuccessAction {
  type: typeof REMOVE_ACCOUNT_INVITATION_SUCCESS;
  invitationId: string;
}

export interface RemoveAccountInvitationFailureAction {
  type: typeof REMOVE_ACCOUNT_INVITATION_FAILURE;
  error: string;
}

export type AccountInvitationActionTypes =
  | FetchInvitationsRequestAction
  | FetchInvitationsSuccessAction
  | FetchInvitationsFailureAction
  | RemoveAccountInvitationRequestAction
  | RemoveAccountInvitationSuccessAction
  | RemoveAccountInvitationFailureAction;
