import { EditorState } from "draft-js";
import moment from "moment";
import {
  EVENT_DEFAULT_INTERVAL,
  EVENT_DEFAULT_START_TIME,
} from "../../pages/core/event/CrudEventPage";
import { DiscountItem } from "../../models/discountData";
import { EventStatus } from "../../models/enums/eventStatus.enum";
import { InternalExternalEventType } from "../../models/enums/eventType.enum";
import { TrainingType } from "../../models/enums/trainingType.enum";
import { Utils } from "../../services/utils";
import { CLEAR_PREVIEW, PreviewActionTypes, PreviewState, SET_PREVIEW } from "./types";
import { DEFAULT_CERTIFICATE_TYPE } from "../../constants";

export const initialPreviewState: PreviewState = {
  previewEvent: {
    title: "",
    vnr: "",
    score: 0,
    price: 0,
    minseats: 1,
    maxseats: 1,
    participation_types: [],
    isCapped: false,
    status: EventStatus.NotVisible,
    internalExternalEvent: InternalExternalEventType.Internal,
    noReminder: false,
    noDiscount: false,
    noAutomaticParticipationCertificate: false,
    catering: false,
    description: EditorState.createEmpty().getCurrentContent(),
    descriptionState: EditorState.createEmpty().getCurrentContent(),
    speakers: [],
    location: "",
    city: "Frankfurt",
    zip_code: "60486",
    locationDetails: "",
    eventType: 0,
    trainingType: TrainingType.DefaultEvent,
    beginDate: moment().startOf("day"),
    endDate: moment().startOf("day"),
    beginTime: Utils.roundToQuartHourFromNow(
      moment().set("hour", EVENT_DEFAULT_START_TIME)
    ),
    endTime: Utils.roundToQuartHourFromNow(
      moment().set("hour", EVENT_DEFAULT_START_TIME),
      EVENT_DEFAULT_INTERVAL
    ), // let the event last at least 30 minutes per default
    documents: [],
    commentary: "",
    searchSpeakers: "",
    tasks: [],
    checklist: [],
    checklist_additional_info: "",
    evaluationLink: "",
    evaluationLinkSpeaker: "",
    numberOfUnits: 0,
    durationPerUnitInMinutes: 0,
    maxTimePeriodToFinishElearningModuleInWeeks: 0,
    pinboardIsActivated: false,
    publishELearningAfterEvent: false,
    discounts: [] as DiscountItem[],
    is_certification_selected: false,
    certificate_type: DEFAULT_CERTIFICATE_TYPE,
  },
  previewEventLoaded: false,
};

export function previewReducer(
  state = initialPreviewState,
  action: PreviewActionTypes
): PreviewState {
  switch (action.type) {
    case SET_PREVIEW:
      return {
        ...state,
        previewEvent: action.data,
        previewEventLoaded: true,
      };
    case CLEAR_PREVIEW:
      return initialPreviewState;
    default:
      return state;
  }
}

export default previewReducer;
