import { AxiosError } from "axios";
import { Dispatch } from "redux";
import { contactService } from "../../api";
import {
  ContactRequestAction,
  ContactFailureAction,
  ContactSuccessAction,
  ContactClearAction,
  ContactActionTypes,
  CONTACT_REQUEST,
  CONTACT_FAILURE,
  CONTACT_SUCCESS,
  CONTACT_CLEAR,
} from "./types";
import { ContactFormData } from "../../models/contactFormData";
import { enqueueSnackbar } from "notistack";

export function sendContactMessage(message: ContactFormData) {
  return (dispatch: Dispatch<ContactActionTypes>) => {
    dispatch(contactRequest());
    contactService
      .sendMessage(message)
      .then((response) => {
        dispatch(contactSuccess());
      })
      .catch((err: AxiosError) => {
        dispatch(contactFailure());
        enqueueSnackbar("Ihre Nachricht konnte nicht gesendet werden!", {
          variant: "error",
        });
      });
  };
}

export function contactRequest(): ContactRequestAction {
  return {
    type: CONTACT_REQUEST,
  };
}

export function contactFailure(): ContactFailureAction {
  return {
    type: CONTACT_FAILURE,
  };
}

export function contactSuccess(): ContactSuccessAction {
  return {
    type: CONTACT_SUCCESS,
  };
}

export function contactClear(): ContactClearAction {
  return {
    type: CONTACT_CLEAR,
  };
}
