import { CertificateTypeData } from "../../../../../../models/certificateTypeData";
import CustomSelect from "../../../../../forms/selects/CustomSelect";
import { ELearningFormData } from "../../../../../../models/elearning";
import { EventFormData } from "../../../../../../models/eventData";
import { Grid } from "@material-ui/core";
import { SelectData } from "../../../../../forms/selects/selectData";
import { useFormikContext } from "formik";
import { useState } from "react";

interface CertificateTypeProps {
  allCertificateTypes: CertificateTypeData[];
  eventCertificateType: CertificateTypeData;
}

export const CertificateTypesDropDown: React.FC<CertificateTypeProps> = ({
  allCertificateTypes,
  eventCertificateType,
}) => {
  const certificateTypes = allCertificateTypes;

  const [certificateType, setCertificateType] = useState(eventCertificateType);

  const { values, setFieldValue } = useFormikContext<
    EventFormData | ELearningFormData
  >();

  const getSelectedCertificateType = (
    allCertificateTypes: CertificateTypeData[],
    certificate_type: string
  ) => {
    return allCertificateTypes.find((element) => {
      return element.certificate_type === certificate_type;
    });
  };

  const saveCertificateType = (value: string) => {
    let selectedCertificateType = getSelectedCertificateType(allCertificateTypes, value);
    if (selectedCertificateType !== undefined) {
      setCertificateType(selectedCertificateType);
    }
    setFieldValue("certificate_type", selectedCertificateType);
  };

  return (
    <Grid
      container
      key={"CertificateTypes"}
      style={{ display: "flex", width: "80%" }}
      spacing={2}
    >
      <Grid item lg={3} xs={3} sm={3}>
        <CustomSelect
          name="certificateType"
          id="certificateType"
          label="Zertifikatstyp"
          labelId="certificateTypeID"
          value={certificateType.certificate_type}
          onChange={(event: React.ChangeEvent<{ name?: string; value: any }>) => {
            saveCertificateType(event.target.value);
          }}
          options={
            certificateTypes.map((x) => {
              return {
                key: x.certificate_type,
                value: x.certificate_type,
                label: "" + x.certificate_type,
              };
            }) as SelectData[]
          }
        />
      </Grid>
    </Grid>
  );
};
