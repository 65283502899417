import React, { ChangeEvent, useEffect, useState } from "react";
import * as yup from "yup";
import i18n from "i18next";
import { AxiosError } from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, Grid } from "@material-ui/core";
import { enqueueSnackbar } from "notistack";
import { Form, Formik } from "formik";
import { AppState } from "../../../../redux";
import AddressSection from "./organization-information/AddressSection";
import BillingAddressSection from "./organization-information/BillingAddressSection";
import ContactPersonSection from "./organization-information/ContactPersonSection";
import CustomSelect from "../../../forms/selects/CustomSelect";
import CustomCheckbox from "../../../forms/CustomCheckbox";
import CustomTextField from "../../../forms/CustomTextField";
import FutherInformationSection from "./organization-information/FutherInformationSection";
import HeadingLumos from "../../../theming/HeadingLumos";
import Loader from "../../../theming/loader/Loader";
import { organizationService } from "../../../../api";
import { useDynamicYupValidations } from "../../../../libs/yup-validations";
import { getOrganizationTypes } from "../../../../redux/organizationType/actions";
import organizationFormInitialData from "../../../../utils/organizationCrudFormInitialData";
import { getOrganizations } from "../../../../redux/organization/actions";
import {
  OrganizationFormData,
  OrganizationForm,
} from "../../../../models/organizationData";

interface CrudOrganizationForm {
  organizationData?: OrganizationFormData;
}

const CrudOrganization: React.FC<CrudOrganizationForm> = (props) => {
  const { t } = useTranslation(["common", "organization"]);
  const dispatch = useDispatch();
  const [useAddressDataForBilling, setUseAddressDataForBilling] = useState(true);
  const organizationTypes = useSelector((state: AppState) => state.organizationTypes);
  const { organizationData } = props;

  const initialValues: OrganizationForm = organizationFormInitialData(organizationData);

  const {
    YupValidationOrganizationType,
    YupValidationEmail,
    YupValidationFirstName,
    YupValidationLastName,
    YupValidationOrganizationName,
    YupValidationPhone,
    YupValidationStreet,
    YupValidationStreetNumber,
    YupValidationZipCode,
    YupValidationCity,
    YupValidationOrganizationTaxNumberSelected,
  } = useDynamicYupValidations();

  const mockValidationSchema = yup.object({
    organization_type: YupValidationOrganizationType,
    name: YupValidationOrganizationName,
    contact_person_firstname: YupValidationFirstName,
    contact_person_lastname: YupValidationLastName,
    contact_person_email: YupValidationEmail,
    contact_person_phone: YupValidationPhone,
    street: YupValidationStreet,
    street_number: YupValidationStreetNumber,
    zip_code: YupValidationZipCode,
    city: YupValidationCity,
    email: YupValidationEmail,
    tax_number: YupValidationOrganizationTaxNumberSelected(
      "tax_number",
      "commercial_register_number"
    ),
    commercial_register_number: YupValidationOrganizationTaxNumberSelected(
      "tax_number",
      "commercial_register_number"
    ),
  });

  useEffect(() => {
    if (
      !organizationTypes?.isLoading &&
      !organizationTypes?.organizationTypeDataLoaded
    ) {
      dispatch(getOrganizationTypes());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationTypes]);

  const submitOrganizationData = async (
    values: OrganizationForm,
    { resetForm }: { resetForm: () => void }
  ) => {
    const request = {
      organization_type: values.organization_type,
      name: values.name,
      contact_person_firstname: values.contact_person_firstname,
      contact_person_lastname: values.contact_person_lastname,
      contact_person_email: values.contact_person_email,
      contact_person_phone: values.contact_person_phone,
      street: values.street,
      street_number: values.street_number,
      zip_code: values.zip_code,
      city: values.city,
      email: values.email,
      tax_number: values.tax_number,
      commercial_register_number: values.commercial_register_number,
      billing_address_firstname: values.billing_address_firstname,
      billing_address_lastname: values.billing_address_lastname,
      billing_address_email: values.billing_address_email,
      billing_address_phone: values.billing_address_phone,
      billing_address_street: values.billing_address_street,
      billing_address_street_number: values.billing_address_street_number,
      billing_address_zip_code: values.billing_address_zip_code,
      billing_address_city: values.billing_address_city,
    } as OrganizationForm;

    if (organizationData?.id) {
      organizationService
        .editOrganization(organizationData?.id, request)
        .then((result) => {
          if (result) {
            enqueueSnackbar(t("profile.organizationUpdated", { ns: "snackbars" }), {
              variant: "success",
            });
            dispatch(getOrganizations());
          }
        })
        .catch((err: AxiosError) => {
          console.log(err);
        });
      return;
    } else {
      organizationService
        .addOrganization(request)
        .then((result) => {
          if (result) {
            enqueueSnackbar(t("profile.organizationAdded", { ns: "snackbars" }), {
              variant: "success",
            });
            resetForm();
            dispatch(getOrganizations());
          }
        })
        .catch((err: AxiosError) => {
          console.log(err);
        });
    }
  };

  return !organizationTypes?.organizationTypeDataLoaded ? (
    <Loader />
  ) : (
    <>
      <HeadingLumos>
        {organizationData?.id
          ? t("crud.editOrganization", { ns: "organization" })
          : t("createNewOrganization", { ns: "organization" })}
      </HeadingLumos>
      <Formik
        key={i18n.language}
        onSubmit={submitOrganizationData}
        enableReinitialize
        initialValues={initialValues}
        validationSchema={mockValidationSchema}
      >
        <Form>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <CustomSelect
                required
                noEmptyDefaultValue
                id="organization-type-select"
                name="organization_type"
                label={t("forms.organizationType", { ns: "organization" })}
                labelId="organization-type-select-label"
                options={organizationTypes?.organizationTypeData?.map((item) => {
                  return {
                    key: item.id,
                    value: item.id,
                    label: item.name,
                  };
                })}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <CustomTextField
                required
                fullWidth
                id="organization-name"
                name="name"
                label={t("forms.organizationName", { ns: "organization" })}
              />
            </Grid>
          </Grid>

          <ContactPersonSection />
          <AddressSection />
          <FutherInformationSection />

          <Grid
            container
            spacing={4}
            style={{ paddingTop: "1rem", paddingBottom: "1rem" }}
          >
            <Grid item xs={12} sm={6}>
              <CustomCheckbox
                id="use-address-data-for-billing"
                name="useAddressDataForBilling"
                label={t("forms.useAddressDataForBilling", {
                  ns: "organization",
                })}
                checked={useAddressDataForBilling}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setUseAddressDataForBilling(!useAddressDataForBilling);
                }}
              />
            </Grid>
          </Grid>

          {!useAddressDataForBilling && <BillingAddressSection />}

          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Button
                id="add-organization"
                type="submit"
                size="large"
                disabled={false}
                variant="contained"
                color="primary"
                fullWidth
              >
                {organizationData?.id
                  ? t("save", { ns: "common" })
                  : t("crud.addOrganization", { ns: "organization" })}
              </Button>
            </Grid>
          </Grid>
        </Form>
      </Formik>
    </>
  );
};

export default CrudOrganization;
