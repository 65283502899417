import moment from "moment";

export const isEventOver = (endDate: Date | null) => {
  if (endDate) {
    if (new Date(endDate).getTime() < new Date().getTime()) {
      return true;
    }
    return false;
  }
  return false;
};

/**
 * Checks if the given date is expired.
 *
 * @param endDate - The date to check for expiry. If null, the function returns false.
 * @returns - Returns true if the date is before today, otherwise returns false.
 */
export const isExpired = (endDate: Date | null) => {
  if (endDate) {
    return !moment(endDate).isSameOrAfter(moment().startOf("day"));
  }
  return false;
};
