import apiRoutes from "../apiRoutes";
import { BaseCrudService } from "../baseCrudService";
import { OrganizationTypeData } from "../../models/organizationTypeData";

/**
 * Service class for managing organization types.
 * Provides methods for retrieving organization type data.
 */
export class OrganizationTypeService extends BaseCrudService {
  /**
   * Retrieves all organization types from the API.
   * @returns {Promise<OrganizationTypeData[]>} A promise that resolves to an array of organization type data.
   */
  async getOrganizationTypes() {
    const response = await this.getAll<OrganizationTypeData[]>(
      //const response = await this.getAll<Pagination<OrganizationTypeData>>(
      apiRoutes.organization_types
    );

    return response.data;
  }
}
