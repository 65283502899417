import { NIL as NIL_UUID } from "uuid";
import { PinboardFormData } from "../../models/pinboardData";
import {
  PinboardQuestionActionTypes,
  PinboardTopicState,
  PINBOARD_POST_QUESTION,
  PINBOARD_POST_QUESTION_FAILURE,
  PINBOARD_POST_QUESTION_SUCCESS,
  PINBOARD_UPDATE_QUESTION,
  PINBOARD_UPDATE_QUESTION_FAILURE,
  PINBOARD_UPDATE_QUESTION_SUCCESS,
} from "./types";

export const initialTopicState: PinboardTopicState = {
  postedQuestion: {
    title: "",
    post: "",
    event: NIL_UUID,
  },
  isLoading: false,
  error: false,
};

export function pinboardTopicReducer(
  state = initialTopicState,
  action: PinboardQuestionActionTypes
): PinboardTopicState {
  switch (action.type) {
    case PINBOARD_POST_QUESTION:
      return {
        ...state,
        isLoading: true,
      };
    case PINBOARD_POST_QUESTION_SUCCESS:
      return {
        ...state,
        postedQuestion: action.data as PinboardFormData,
        isLoading: false,
        error: false,
      };
    case PINBOARD_POST_QUESTION_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    case PINBOARD_UPDATE_QUESTION:
      return {
        ...state,
        isLoading: true,
      };
    case PINBOARD_UPDATE_QUESTION_SUCCESS:
      return {
        ...state,
        postedQuestion: action.data as PinboardFormData,
        isLoading: false,
        error: false,
      };
    case PINBOARD_UPDATE_QUESTION_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    default:
      return state;
  }
}

export default pinboardTopicReducer;
