import {
  MaintenanceBannerState,
  MaintenanceActionTypes,
  FETCH_BANNER_REQUEST,
  FETCH_BANNER_SUCCESS,
  FETCH_BANNER_FAILURE,
} from "./types";

// The initial state for the maintenance feature.
const initialMaintenanceBannerState: MaintenanceBannerState = {
  maintenanceBanner: [],
  isLoading: false,
  error: null,
};

/**
 * The maintenance reducer handles the state for the maintenance feature.
 * The reducer handles the actions for fetching the maintenance banner data.
 * @param {MaintenanceBannerState} state - The maintenance state
 * @param {MaintenanceActionTypes} action - The action to be performed
 * @returns {MaintenanceBannerState} The new maintenance state
 **/
const maintenanceReducer = (
  state = initialMaintenanceBannerState,
  action: MaintenanceActionTypes
): MaintenanceBannerState => {
  switch (action.type) {
    case FETCH_BANNER_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case FETCH_BANNER_SUCCESS:
      return {
        ...state,
        maintenanceBanner: action.payload,
        isLoading: false,
        error: null,
      };
    case FETCH_BANNER_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default maintenanceReducer;
