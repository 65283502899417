import { Dispatch } from "redux";
import { CertificateTypeData } from "../../models/certificateTypeData";
import { certificateTypeService } from "./../../api/index";
import {
  LOAD_CERTIFICATE_TYPE_DATA_SUCCESS,
  LoadCertificateTypeAction,
  CERTIFICATE_TYPE_FAILURE,
  CERTIFICATE_TYPE_REQUEST,
  CertificateTypeActionTypes,
  CertificateTypeFailureAction,
  CertificateTypeRequestAction,
} from "./types";

export function getCertificateTypes() {
  return (dispatch: Dispatch<CertificateTypeActionTypes>) => {
    dispatch(certificateTypeRequest());
    certificateTypeService
      .getCertificateTypes()
      .then((response: CertificateTypeData[]) => {
        dispatch(loadCertificateTypeDataSuccess(response));
      })
      .catch(() => {
        dispatch(certificateTypeFailure());
      });
  };
}

export function loadCertificateTypeDataSuccess(
  data: CertificateTypeData[]
): LoadCertificateTypeAction {
  return {
    type: LOAD_CERTIFICATE_TYPE_DATA_SUCCESS,
    data,
  };
}

export function certificateTypeRequest(): CertificateTypeRequestAction {
  return {
    type: CERTIFICATE_TYPE_REQUEST,
  };
}

export function certificateTypeFailure(): CertificateTypeFailureAction {
  return {
    type: CERTIFICATE_TYPE_FAILURE,
  };
}
