import { Tooltip } from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  EventStatus,
  mapStatusIdToStatusString,
} from "../../../../../models/enums/eventStatus.enum";
import {
  mapToTrainingTypeString,
  mapTrainingTypeToArticleWithNoun,
  mapTrainingTypeToArticleWithNounPossessiveCase,
  TrainingType,
} from "../../../../../models/enums/trainingType.enum";
import { AppState } from "../../../../../redux";
import { showDialog } from "../../../../../redux/dialogs/actions";
import { deleteEvent } from "../../../../../redux/events/actions";
import { Utils } from "../../../../../services/utils";
import usePinboardActivated from "../../../../hooks/usePinboardActivated";
import CustomButton from "../../../../theming/CustomButton";
import styles from "./../../../../../styles/custom-styles.module.scss";

interface DeleteEventButtonProps {
  hasBeenDeleted: () => void;
  id: string;
  typeOfTrainingToBeCreated: TrainingType;
}

const DeleteEventButton: React.FC<DeleteEventButtonProps> = (props) => {
  const dispatch = useDispatch();

  const editEventState = useSelector((state: AppState) => state.event.editEvent);

  const participant = useSelector((state: AppState) => state.participant);
  const trainingType = mapToTrainingTypeString(props.typeOfTrainingToBeCreated);

  const isPinboardActivated = usePinboardActivated(props.id);

  const deletionToolTip = (
    isParticipationExisting: boolean,
    isPinboardActivated: boolean,
    isEventVisible: boolean
  ) => {
    const participantExistingTip = isParticipationExisting
      ? Utils.capitalizeFirstLetter(
          mapTrainingTypeToArticleWithNoun(props.typeOfTrainingToBeCreated, trainingType)
        ) + " kann nicht gelöscht werden, weil bereits Teilnehmer registriert sind. "
      : "";
    const pinboardActivatedTip =
      isPinboardActivated && !isParticipationExisting
        ? "Für eine Löschung muss die Pinnwand zuerst deaktiviert werden. "
        : "";
    const eventStatusVisible =
      isEventVisible && !isParticipationExisting
        ? `Der Status ${mapTrainingTypeToArticleWithNounPossessiveCase(
            props.typeOfTrainingToBeCreated,
            trainingType
          )} muss bei einer Löschung "${mapStatusIdToStatusString(
            EventStatus.NotVisible
          )}" sein.`
        : "";
    return participantExistingTip + pinboardActivatedTip + eventStatusVisible;
  };

  return (
    <>
      <Tooltip
        arrow
        title={
          !participant.participantListLoaded ||
          isPinboardActivated ||
          editEventState.status !== EventStatus.NotVisible ||
          participant.participantList.length > 0
            ? deletionToolTip(
                participant.participantList.length > 0,
                isPinboardActivated,
                editEventState.status !== EventStatus.NotVisible
              )
            : ""
        }
      >
        <span>
          <CustomButton
            disabled={
              !participant.participantListLoaded ||
              isPinboardActivated ||
              editEventState.status !== EventStatus.NotVisible ||
              participant.participantList.length > 0
            }
            fullWidth
            size="large"
            onClick={() => {
              dispatch(
                showDialog({
                  title: `${trainingType} löschen`,
                  message: `Sind Sie sich sicher, dass Sie ${mapTrainingTypeToArticleWithNoun(
                    props.typeOfTrainingToBeCreated,
                    trainingType
                  )} löschen möchten? Dieser Vorgang kann nicht wieder rückgängig gemacht werden.`,
                  action: () => {
                    props.hasBeenDeleted();
                    // TODO: refactor sind +props.id not possible on string
                    dispatch(deleteEvent(props.id));
                  },
                })
              );
            }}
            customColor={styles.red}
            textColor={"#fff"}
            hoverColor={styles["red-dark"]}
          >
            {`${trainingType} löschen`}
          </CustomButton>
        </span>
      </Tooltip>
    </>
  );
};

export default DeleteEventButton;

// TODO no translation yet
