import { useEffect, useState } from "react";

const usePageLoader = () => {
  const [isLoadingPage, setIsLoadingPage] = useState<boolean>(true);

  useEffect(() => {
    setIsLoadingPage(false);
  }, []);

  return { isLoadingPage, setIsLoadingPage };
};

export default usePageLoader;
