import { PropTypes, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Variant } from "@material-ui/core/styles/createTypography";

export function CountDown(
  props: { seconds: number; onZero: () => any } & {
    align?: PropTypes.Alignment;
    /**
     * The content of the component.
     */
    children?: React.ReactNode;
    color?:
      | "initial"
      | "inherit"
      | "primary"
      | "secondary"
      | "textPrimary"
      | "textSecondary"
      | "error";
    display?: "initial" | "block" | "inline";
    gutterBottom?: boolean;
    noWrap?: boolean;
    paragraph?: boolean;
    variant?: Variant | "inherit";
    variantMapping?: Partial<Record<Variant, string>>;
  }
) {
  const { seconds, onZero, ...typoProps } = props;
  const [countDown, setCountDown] = useState<number>(props.seconds);

  const decrement = () => {
    setCountDown((prevState) => prevState - 1);
  };

  useEffect(() => {
    if (countDown > 0) {
      const timer = setTimeout(decrement, 1000);
      return () => clearTimeout(timer);
    } else {
      countDown === 0 && onZero();
    }
  });

  return <Typography {...typoProps}>{countDown}</Typography>;
}
