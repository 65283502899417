import { Box, Button, Collapse, Divider, Grid } from "@material-ui/core";
import * as React from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../../../../redux";
import "../../../../../pages/core/event/UserEventsPage.scss";
import { CustomSelectArrow } from "../../../../../assets/icons/custom-svg-components/LumosSvgCollection";
import DOMPurify from "dompurify";
import { Utils } from "../../../../../services/utils";
import { useTranslation } from "react-i18next";

interface EventDescriptionProps {
  description: string;
}

const EventDescription: React.FC<EventDescriptionProps> = ({ description }) => {
  const accessibility = useSelector((state: AppState) => state.accessibility);
  const { t } = useTranslation(["common", "snackbars"]);
  const [checked, setChecked] = React.useState(false);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  let html = DOMPurify.sanitize(
    Utils.convertRteStateToHtml(Utils.convertJsonToRteState(description))
  );

  return (
    <>
      <Grid item xs={12}>
        <Divider />
        <Box component={"div"} className={"myevents-details-headline"}>
          {t("description", { ns: "common" })}
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Collapse in={checked} collapsedSize={90}>
          <Box
            className={"myevents-description"}
            dangerouslySetInnerHTML={{
              __html: html,
            }}
          />
        </Collapse>
        <Grid container>
          <Grid item xs={12}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              style={{ marginTop: "0.625rem" }}
            >
              <Button
                onClick={handleChange}
                className={"event-item-details-icon"}
                style={{
                  border: accessibility.monoMode ? "none" : undefined,
                  backgroundColor: "transparent",
                }}
              >
                {checked ? (
                  <CustomSelectArrow className={"event-item-shrink-icon"} />
                ) : (
                  <CustomSelectArrow className={"event-item-expand-icon"} />
                )}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default EventDescription;
