import * as React from "react";
import { LumosDateSvg } from "../../../../../../assets/icons/custom-svg-components/LumosSvgCollection";
import EventItemDateTime from "./EventItemDateTime";

interface EventItemStartDateTimeProps {
  startDate: Date;
  endDate: Date;
}

const EventItemStartDateTime: React.FC<EventItemStartDateTimeProps> = ({
  startDate,
  endDate,
}) => {
  const eventOnlyTakesPlaceOnOneDay =
    startDate.toDateString() === endDate.toDateString();
  return (
    <>
      {eventOnlyTakesPlaceOnOneDay ? (
        <>
          <LumosDateSvg /> {startDate.toLocaleDateString()}
        </>
      ) : (
        <>
          <LumosDateSvg /> {startDate.toLocaleDateString()}
          <EventItemDateTime date={startDate} />
        </>
      )}
    </>
  );
};

export default EventItemStartDateTime;
