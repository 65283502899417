import { TrainingType } from "./enums/trainingType.enum";
import { FilterData } from "../services/filters/filterData";
import { TasksSortBy } from "./enums/sortBy.enum";
import { TaskStatus } from "./enums/taskStatus.enum";
import { Moment } from "moment";
import { NIL as NIL_UUID } from "uuid";

export interface BaseTaskData {
  id: string;
  description: string;
  status?: TaskStatus;
  due_date?: Date;
  additional_info?: string;
}

export interface TaskFilterData extends FilterData<TasksSortBy> {
  search: string;
  event: string;
  status: string[];
  due_date: string;
  responsibility: string;
}

export interface TaskData extends BaseTaskData {
  event?: TaskEventData;
  responsibility?: TaskUserData;
}

export interface EditTaskData extends BaseTaskData {
  event: string;
  responsibility: string;
}

export interface TaskTemplateData extends BaseTaskData {
  task_type: number;
  default_deadline_in_days: number;
  event_related_deadline: number;
}

export interface SimpleTaskData {
  description: string;
  due_date: Moment;
}

export function toEditTaskData(task: TaskData): EditTaskData {
  return {
    ...task,
    event: task.event ? task.event.id : NIL_UUID,
    responsibility: task.responsibility ? task.responsibility.id : NIL_UUID,
  };
}

export interface TaskEventData {
  id: string;
  public_id: number;
  name: string;
  location: string;
  training_type: TrainingType;
}

export interface TaskUserData {
  id: string;
  fullname: string;
}

export interface TasksFilterOptions {
  search?: string;
  event?: string;
  status?: string[];
  responsibility?: string;
  has_responsibility?: string;
  due_date_from?: string;
  due_date_to?: string;
}
