import { useFormikContext } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ELearningFormData } from "../../../../../models/elearning";
import { EventDataDto } from "../../../../../models/eventData";
import { LecturesData } from "../../../../../models/lecturesData";
import { AppState } from "../../../../../redux";
import { showDialog } from "../../../../../redux/dialogs/actions";
import { switchEnrolmentCap } from "../../../../../redux/events/actions";
import usePermission from "../../../../../services/usePermissions";
import CustomCheckbox from "../../../../forms/CustomCheckbox";

interface EventItemCapCheckboxProps {
  event: EventDataDto | LecturesData;
}

export const EventItemCapCheckbox: React.FC<EventItemCapCheckboxProps> = (props) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state: AppState) => state.user.currentUser);
  const { isStaff } = usePermission(currentUser?.user_type);
  const { setFieldValue, values } = useFormikContext<ELearningFormData>();

  return (
    <CustomCheckbox
      name="isCapped"
      label={<>Anmeldestopp</>}
      onChange={() => {
        dispatch(
          showDialog({
            title: `Anmeldestopp ${values.isCapped ? "de" : ""}aktivieren`,
            message: `Möchten Sie den Anmeldestopp ${
              values.isCapped ? "de" : ""
            }aktivieren?`,
            action: () => {
              dispatch(switchEnrolmentCap(!values.isCapped, props.event, isStaff()));
              setFieldValue("isCapped", !values.isCapped);
            },
          })
        );
      }}
    />
  );
};
