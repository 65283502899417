import { useEffect, useState } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { validate as isValidUUID } from "uuid";

/**
 * Custom hook to check if a given ID is valid within the context of a specific route.
 * @param {string} route - The route path to match against.
 * @param {string} id - The ID to validate.
 * @returns {Object} An object containing the validity of the ID.
 */
const useValidId = (route: string, id: string) => {
  const location = useLocation();
  const [isValidId, setIsValidId] = useState<boolean>(false);

  /**
   * Function to check if the ID is valid based on the route and ID format.
   */
  const checkValidId = () => {
    const validPath = !!matchPath(location.pathname, {
      path: route,
      exact: true,
    });
    const isValidUuid = isValidUUID(id);

    setIsValidId(isValidUuid && validPath);
  };

  useEffect(checkValidId, [id, location.pathname, route]);

  return {
    isValidId,
  };
};

export default useValidId;
