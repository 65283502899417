import { Box, Button, Grid, makeStyles } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { showDialog } from "../../../redux/dialogs/actions";
import CustomDatepicker from "../../forms/CustomDatepicker";
import CustomSelect from "../../forms/selects/CustomSelect";
import CustomTextField from "../../forms/CustomTextField";
import routes from "../../../routing/routes";
import CustomButton from "../../theming/CustomButton";
import HeadingLumos from "../../theming/HeadingLumos";
import styles from "./../../../styles/custom-styles.module.scss";
import addTaskValidationSchema from "./addTaskValidationSchema";
import { useSelector } from "react-redux";
import { AppState } from "../../../redux";
import { addTask, updateTask, getTask, deleteTask } from "../../../redux/tasks/actions";
import { useDispatch } from "react-redux";
import {
  mapToTaskStatusString,
  TaskStatus,
} from "../../../models/enums/taskStatus.enum";
import { UserData } from "../../../models/userData";
import { SelectData } from "../../forms/selects/selectData";
import { toEditTaskData } from "../../../models/taskData";
import { SearchEventField } from "./shared/SearchEventField";
import { NoMatchPage } from "../../../pages/error/NoMatchPage";
import Loader from "../../theming/loader/Loader";
import useValidId from "../../hooks/useValidId";
import usePageLoader from "../../hooks/usePageLoader";
import { NIL as NIL_UUID } from "uuid";

interface ParamTypes {
  taskId: string;
}

const useStyles = makeStyles((theme) => ({
  hideIcon: {
    "& .MuiSelect-iconFilled": {
      paddingRight: "0rem",
      display: "none",
    },
  },
}));

const CrudTask: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  // receive the id from the url params (:id)
  let taskId = useParams<ParamTypes>().taskId;
  const { isValidId } = useValidId(routes.tasks_edit_id, taskId) || true;

  const { isLoadingPage } = usePageLoader();

  const tasks = useSelector((state: AppState) => state.tasks);
  const taskNotFoundError = tasks.notFoundError;

  useEffect(() => {
    if (!tasks.editingTaskLoaded && isValidId && !tasks.isLoading && !tasks.error) {
      dispatch(getTask(taskId));
    }
  });

  // responsibility
  const currentUser = useSelector((state: AppState) =>
    state.user.currentUser ? state.user.currentUser! : ({ id: NIL_UUID } as UserData)
  );
  const [responsibilityOptions, setResponsibilityOptions] = useState([] as SelectData[]);

  const takeResponsibility = () => {
    setResponsibilityOptions([
      {
        key: currentUser.id,
        value: currentUser.id,
        label: currentUser.fullname,
      },
    ]);
  };

  const passResponsibility = () => {
    setResponsibilityOptions([
      {
        key: NIL_UUID,
        value: NIL_UUID,
        label: "Nicht zugewiesen",
      },
    ]);
  };

  useEffect(() => {
    if (
      !tasks.isLoading &&
      tasks.editingTaskLoaded &&
      responsibilityOptions.length === 0
    ) {
      if (!tasks.editingTask.responsibility) {
        passResponsibility();
      } else if (tasks.editingTask.responsibility) {
        setResponsibilityOptions([
          {
            key: tasks.editingTask.responsibility.id.toString(),
            value: tasks.editingTask.responsibility.id.toString(),
            label: tasks.editingTask.responsibility.fullname.toString(),
          },
        ]);
      }
    }
  }, [setResponsibilityOptions, responsibilityOptions, tasks]);

  const classes = useStyles();
  return (
    <>
      {(isLoadingPage || tasks.isLoading) && !tasks.error ? (
        <>
          <Loader />
        </>
      ) : !isLoadingPage &&
        taskId &&
        !tasks.isLoading &&
        (taskNotFoundError || !isValidId) ? (
        <>
          <NoMatchPage />
        </>
      ) : (
        <>
          <HeadingLumos>
            {taskId ? "Aufgabe bearbeiten" : "Neue Aufgabe anlegen"}
          </HeadingLumos>

          <Formik
            onSubmit={(data) => {
              data.responsibility =
                responsibilityOptions.length > 0
                  ? // TODO: refactor since + on string not possible
                    responsibilityOptions[0].value.toString()
                  : NIL_UUID;
              if (data.responsibility !== NIL_UUID) data.responsibility = NIL_UUID;
              data.event = data.event && data.event !== NIL_UUID ? data.event : NIL_UUID;

              if (!taskId) {
                dispatch(addTask(data));
              } else {
                dispatch(updateTask(data));
              }
            }}
            enableReinitialize
            validationSchema={
              taskId
                ? addTaskValidationSchema(tasks.editingTask)
                : addTaskValidationSchema
            }
            initialValues={toEditTaskData(tasks.editingTask)}
          >
            {({ setFieldValue }) => (
              <Form>
                <Grid container spacing={4}>
                  <Grid item xs={6}>
                    <CustomTextField
                      fullWidth
                      autoComplete="description"
                      name="description"
                      required
                      label="Aufgabenbeschreibung"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <SearchEventField
                      onChangeEventId={(id: string) => setFieldValue("event", id)}
                      initialEvent={tasks.editingTask.event}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      as={CustomDatepicker}
                      fullWidth
                      id="dueDate-picker"
                      label="Fälligkeit"
                      name="due_date"
                    />
                  </Grid>
                  <Grid item xs={6} sm={false}></Grid>
                  <Grid item xs={6}>
                    <Box display="flex">
                      <CustomSelect
                        className={classes.hideIcon}
                        fullWidth
                        noEmptyDefaultValue
                        disabled
                        label="Zuständigkeit"
                        name="responsibility"
                        id="responsibility"
                        labelId="responsibility-label"
                        options={responsibilityOptions}
                        value={
                          responsibilityOptions.length > 0
                            ? responsibilityOptions[0].value
                            : ""
                        }
                      />

                      <Box ml={2} height={60}>
                        {responsibilityOptions.length === 0 ||
                        responsibilityOptions[0].value.toString() !==
                          currentUser.id.toString() ? (
                          <Button
                            style={{ height: "inherit" }}
                            onClick={takeResponsibility}
                            fullWidth
                            variant="contained"
                            color="primary"
                          >
                            Mir zuweisen
                          </Button>
                        ) : (
                          <Button
                            style={{ height: "inherit" }}
                            onClick={passResponsibility}
                            fullWidth
                            variant="contained"
                            color="primary"
                          >
                            Zuweisen entfernen
                          </Button>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <CustomSelect
                      noEmptyDefaultValue
                      label="Status"
                      name="status"
                      id="status-select"
                      labelId="status-select-label"
                      options={[
                        TaskStatus.Open,
                        TaskStatus.InProgress,
                        TaskStatus.Done,
                      ].map((item) => {
                        return {
                          key: item.toString(),
                          value: item.toString(),
                          label: mapToTaskStatusString(item),
                        };
                      })}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <CustomTextField
                      fullWidth
                      multiline
                      rows={3}
                      autoComplete="additional_info"
                      name="additional_info"
                      label="Zusätzliche Beschreibung"
                    />
                  </Grid>
                </Grid>

                <Box my={3} display="flex" justifyContent="space-between">
                  <Box>
                    <Button
                      type="submit"
                      style={{ height: "inherit" }}
                      variant="contained"
                      color="primary"
                    >
                      {taskId ? "Änderungen speichern" : "Aufgabe anlegen"}
                    </Button>
                    {taskId ? (
                      <Button
                        type="button"
                        onClick={() => history.push(routes.tasks)}
                        style={{
                          height: "inherit",
                          marginLeft: "1rem",
                        }}
                        variant="contained"
                        color="secondary"
                      >
                        Ohne Speichern zurück
                      </Button>
                    ) : (
                      <></>
                    )}
                  </Box>
                  {taskId ? (
                    <CustomButton
                      customColor={styles.red}
                      textColor={"#fff"}
                      hoverColor={styles["red-dark"]}
                      onClick={() =>
                        dispatch(
                          showDialog({
                            title: "Aufgabe löschen",
                            message:
                              "Sind Sie sich sicher, dass Sie die Aufgabe löschen möchten?",
                            action: () => {
                              dispatch(deleteTask(taskId));
                            },
                          })
                        )
                      }
                    >
                      Aufgabe löschen
                    </CustomButton>
                  ) : (
                    <></>
                  )}
                </Box>
              </Form>
            )}
          </Formik>
        </>
      )}
    </>
  );
};

export default CrudTask;

// TODO no translation yet
