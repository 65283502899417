export const FIRST_NAME_REQUIRED = "firstName.firstNameRequired";

export const LAST_NAME_REQUIRED = "lastName.lastNameRequired";

export const EMAIL_REQUIRED = "email.emailRequired";

export const EMAIL_INVALID = "email.emailInvalid";

export const BIRTHDATE_REQUIRED = "dates.birthdateRequired";

export const DATE_FUTURE = "dates.birthdateFuture";

export const DATE_INVALID = "dates.dateInvalid";

export const AUTH_DOC_REQUIRED = "documents.authDocRequired";
