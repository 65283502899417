import React from "react";
import HeadingLumos from "../../../components/theming/HeadingLumos";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux";
import { useEffect } from "react";
import { setPageCount } from "../../../redux/invoices/actions";
import { getInvoices } from "../../../redux/invoices/actions";
import { Box, Divider, Grid, Hidden, Link } from "@material-ui/core";
import { Pagination as PaginationUI } from "@material-ui/lab";
import { PAGE_SIZE } from "../../../models/pagination";
import Loader from "../../../components/theming/loader/Loader";
import GetAppIcon from "@material-ui/icons/GetApp";
import { Utils } from "../../../services/utils";
import "./UserInvoicePage.scss";
import { useTranslation } from "react-i18next";

export const UserInvoicePage: React.FC = () => {
  const { t } = useTranslation(["common", "invoices", "snackbars"]);

  const dispatch = useDispatch();

  const accessibility = useSelector((state: AppState) => state.accessibility);
  const invoices = useSelector((state: AppState) => state.invoices);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    dispatch(setPageCount(value));
  };

  const loadData = React.useCallback(() => {
    if (!invoices.isLoading && !invoices.myInvoiceListLoaded) {
      dispatch(getInvoices(invoices.currentPage));
    }
  }, [dispatch, invoices.currentPage, invoices.isLoading, invoices.myInvoiceListLoaded]);

  useEffect(loadData, [loadData]);
  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <HeadingLumos>{t("header", { ns: "invoices" })}</HeadingLumos>
        </Grid>
        <Grid container spacing={0} className={"myinvoices-list-header"}>
          <Hidden mdDown>
            <Grid item xs={2}>
              {t("invoiceNumber", { ns: "invoices" })}
            </Grid>
          </Hidden>
          <Hidden smDown>
            <Grid item xs={2} style={{ textAlign: "center" }}>
              {t("employerProfile.company", { ns: "common" })}
            </Grid>
            <Grid item xs={2} style={{ textAlign: "center" }}>
              {t("recipientLastName", { ns: "invoices" })}
            </Grid>
            <Grid item xs={2} style={{ textAlign: "center" }}>
              {t("recipientFirstName", { ns: "invoices" })}
            </Grid>
            <Grid item xs={2} style={{ textAlign: "center" }}>
              {t("date", { ns: "invoices" })}
            </Grid>
            <Grid item xs={2} style={{ textAlign: "center" }}>
              {t("download", { ns: "common" })}
            </Grid>
          </Hidden>
        </Grid>
      </Grid>
      {invoices.isLoading ? (
        <Loader />
      ) : invoices.myInvoiceList?.count === 0 ? (
        <Box
          component="span"
          display="flex"
          alignItems="center"
          style={{ height: "6.25rem" }}
        >
          {t("none", { ns: "invoices" })}
        </Box>
      ) : (
        invoices.myInvoiceList.results?.map((invoice) => {
          return (
            <React.Fragment key={`invoice-${invoice.id}`}>
              <Grid container spacing={0} className={"myinvoices-list-item"}>
                <Hidden mdDown>
                  <Grid item xs={2}>
                    {invoice.invoice_number}
                  </Grid>
                </Hidden>
                <Hidden smDown>
                  <Grid item xs={2} style={{ textAlign: "center" }}>
                    {invoice.order.billing_address
                      ? invoice.order.billing_address.company
                      : ""}
                  </Grid>
                  <Grid item xs={2} style={{ textAlign: "center" }}>
                    {invoice.order.billing_address
                      ? invoice.order.billing_address.last_name
                      : ""}
                  </Grid>
                  <Grid item xs={2} style={{ textAlign: "center" }}>
                    {invoice.order.billing_address
                      ? invoice.order.billing_address.first_name
                      : ""}
                  </Grid>
                  <Grid item xs={2} style={{ textAlign: "center" }}>
                    {Utils.formatDateToCetFormat(invoice.invoice_date)}
                  </Grid>
                  <Grid item xs={2} style={{ textAlign: "center" }}>
                    <Link
                      //href={`https://test.lumos.testfrog.de/api/invoices/${invoice.id}/download`}
                      //download={`https://test.lumos.testfrog.de/api/invoices/${invoice.id}/download`}
                      //href={`http://localhost:8000/api/invoices/${invoice.id}/download`}
                      //download={`http://localhost:8000/api/invoices/${invoice.id}/download`}
                      href={invoice.file_name}
                      download={invoice.file_name}
                      target={"_blank"}
                      className={"myinvoices-document-download-icon"}
                    >
                      {console.log(`invoices.file_name: ` + invoice.file_name)}
                      <GetAppIcon
                        color={accessibility.monoMode ? "primary" : undefined}
                      />
                    </Link>
                  </Grid>
                </Hidden>
              </Grid>
              <Divider className={"invoice-list-separator"} />
            </React.Fragment>
          );
        })
      )}
      <Grid container style={{ marginTop: ".625rem" }}>
        <Box component={"div"} style={{ margin: "auto" }}>
          <PaginationUI
            count={Math.ceil(invoices.myInvoiceList.count / PAGE_SIZE)}
            page={invoices.currentPage}
            onChange={handleChange}
          />
        </Box>
      </Grid>
    </>
  );
};
